import React, { ReactElement, useState } from 'react'
import { Collapse } from 'react-collapse'
import './CustomTable.scss'
import { ReactComponent as ArrowTableIcon } from '../../assets/images/arrow-table.svg'
import { ReactComponent as ArrowTableActiveIcon } from '../../assets/images/arrow-table-active.svg'

interface ICustomTableHeader {
  label: string
  style: any
}

interface ICustomTableData {
  component: ReactElement
}

interface ICustomTableRow {
  row: ICustomTableData[]
  subTable: {
    header: ICustomTableHeader[]
    data: any[]
  }
}

export type CustomTableProps = {
  header: ICustomTableHeader[]
  data: ICustomTableRow[]
  countCell?: number | null
  parentTableStyle: any
  emptyText?: string
}

export const SubTable: React.FC<any> = ({ rowData, getStyleGrid, emptyText }) => {
  return (
    <div className="custom-sub-table" style={{ gridColumnStart: 1, gridColumnEnd: rowData.subTable.header.length }}>
      <div
        className="custom-sub-table__head"
        style={getStyleGrid(rowData.subTable.countCell || rowData.subTable.header.length, false)}
      >
        {rowData.subTable.header.map(({ label, style = {} }, indexSubRow) => (
          <div key={`row-sub-header-${indexSubRow}`} className="custom-sub-table__head-item" style={{ ...style }}>
            {label}
          </div>
        ))}
      </div>
      <div className="custom-sub-table__body">
        {rowData.subTable.data?.length ? (
          rowData.subTable.data?.map((dataRowSub, indexRowSubData) => (
            <div
              key={`row-data-row-sub-${indexRowSubData}`}
              className="custom-sub-table__body-row"
              style={getStyleGrid(rowData.subTable.countCell || rowData.subTable.header.length, false)}
            >
              {dataRowSub.map(({ component, style = {} }, indexDataSubRow) => (
                <div
                  key={`row-data-sub-${indexRowSubData}-${indexDataSubRow}`}
                  className="custom-sub-table__body-item"
                  style={{ ...style }}
                >
                  {component}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="custom-table__empty-data">{emptyText || 'No Data!'}</div>
        )}
      </div>
    </div>
  )
}

const Row: React.FC<any> = ({ header, rowData, getStyleGrid, countCell, parentTableStyle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const LazyComponent = rowData?.subTable?.lazyComponent
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable  jsx-a11y/no-static-element-interactions */
  return (
    <div className="custom-table__body-row">
      <div
        className="custom-table__body-pointer-row"
        style={{
          ...getStyleGrid(countCell ?? header.length, !!rowData.subTable),
          fontWeight: isOpen ? 'bold' : 'normal',
          ...parentTableStyle
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {!!rowData.subTable && (
          <div className="custom-table__body-item text-center">
            {isOpen ? <ArrowTableActiveIcon /> : <ArrowTableIcon />}
          </div>
        )}
        {rowData.row.map(({ component, style = {} }, indexRowData) => (
          <div key={`row-data-${indexRowData}`} className="custom-table__body-item" style={{ ...style }}>
            {component}
          </div>
        ))}
      </div>
      {!!rowData.subTable?.header && (
        <Collapse isOpened={isOpen}>
          {LazyComponent ? (
            <LazyComponent row={rowData} getStyleGrid={getStyleGrid} isOpen={isOpen} />
          ) : (
            <SubTable rowData={rowData} getStyleGrid={getStyleGrid} />
          )}
        </Collapse>
      )}
    </div>
  )
}

const CustomTable: React.FC<CustomTableProps> = ({
  header,
  data,
  countCell = null,
  parentTableStyle = {},
  emptyText = ''
}) => {
  const isSubTable: boolean = data.some((row) => row.subTable)
  const getStyleGrid = (count, isPadding) => ({
    display: 'grid',
    gridTemplateColumns: `${isPadding ? '40px ' : ''} 1.5fr repeat(${isSubTable ? count - 1 : count}, 1fr)`
  })

  return (
    <div className="custom-table">
      <div
        className="custom-table__head"
        style={{ ...getStyleGrid(countCell ?? header.length, isSubTable), ...parentTableStyle }}
      >
        {isSubTable && <div />}
        {header.map(({ label, style = {} }, indexHeader) => (
          <div key={`header-${indexHeader}`} className="custom-table__head-item" style={{ ...style }}>
            {label}
          </div>
        ))}
      </div>
      <div className="custom-table__body">
        {data?.length ? (
          data.map((rowData, indexRow) => (
            <Row
              key={indexRow}
              header={header}
              rowData={rowData}
              getStyleGrid={getStyleGrid}
              countCell={countCell}
              parentTableStyle={parentTableStyle}
            />
          ))
        ) : (
          <div className="custom-table__empty-data">{emptyText || 'No Data!'}</div>
        )}
      </div>
    </div>
  )
}

CustomTable.defaultProps = {
  countCell: null,
  emptyText: ''
}

export default CustomTable
