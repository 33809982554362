import React, { useState } from 'react'
import { Input, Modal, ModalBody, ModalFooter } from '@pwc-de/appkit-react'
import { useQuery } from '@apollo/client'
import { Col, Container, Row } from 'react-grid-system'
import { CalendarPicker } from '@pwc-de/appkit-react-datepicker'
import { ReadEngagement, ReadEngagementVariables } from '../../graphql/__generated__/ReadEngagement'
import { READ_ENGAGEMENT } from '../../graphql/engagement'
import { Loading, Error } from '..'
import { Engagement } from './Engagement'
import EngagementCreate from './EngagementCreate'
import EngagementUpdate from './EngagementUpdate'
import styles from './EngagementManageModal.module.scss'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'

//--------------------------------------------------------------------------
const EngagementManageModal: React.FC<any> = ({
  visible,
  cancelModalCb,
  onDeleteCb,
  isCurrentPage = false,
  engagementId = ''
}) => {
  const [engagementState, setEngagementState] = useState<Engagement>(new Engagement())
  let isCreate = !engagementId

  const { loading, error } = useQuery<ReadEngagement, ReadEngagementVariables>(READ_ENGAGEMENT, {
    variables: { id: engagementId },
    skip: !engagementId,
    onCompleted: (data) => {
      if (data) {
        isCreate = false
        setEngagementState(data.engagement)
      }
    }
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }

  const EngagementComponent = isCreate ? EngagementCreate : EngagementUpdate
  const modalTitle = isCreate ? 'Create ' : 'Update '

  const isValid = !!engagementState.name?.length

  return (
    <Modal
      className="form-modal new-modal center-middle"
      visible={visible}
      onCancel={() => cancelModalCb()}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div className="a-panel-title">{modalTitle} Engagement</div>
      </ModalHeaderWrapper>
      <ModalBody>
        <Container fluid>
          <Row>
            <Col md={12}>
              <Col md={12} className={styles.EngagementManage__column}>
                <Input
                  label={
                    <span>
                      Engagement Name <span className="required-color">*</span>
                    </span>
                  }
                  placeholder="Amce Inc."
                  id="engagementName"
                  value={engagementState.name}
                  onChange={(name: string) => {
                    setEngagementState({ ...engagementState, name })
                  }}
                />
              </Col>
              <Col md={12} className={styles.EngagementManage__column}>
                <Input
                  id="engagementManager"
                  label={<span>Manager</span>}
                  placeholder="Engagement manager name"
                  value={engagementState.manager}
                  onChange={(manager: string) => {
                    setEngagementState({ ...engagementState, manager })
                  }}
                />
              </Col>
              <Col md={12} className={styles.EngagementManage__column}>
                <Input
                  id="engagementClientContact"
                  label={<span>Primary Client Contact (Name or E-Mail)</span>}
                  placeholder="Name or contact email"
                  value={engagementState.clientContact}
                  onChange={(clientContact: string) => {
                    setEngagementState({ ...engagementState, clientContact })
                  }}
                />
              </Col>
              <Row justify="start">
                <Col md={6} className={styles.EngagementManage__column}>
                  <p className="a-form-label">
                    Start Date <span className="required-color">*</span>
                  </p>
                  <CalendarPicker
                    className="full-width"
                    defaultValue={new Date(engagementState.startDate.toString())}
                    format="dd.mm.yyyy"
                    onChange={(startDate: string) => {
                      setEngagementState({ ...engagementState, startDate })
                    }}
                  />
                </Col>
                <Col md={6} className={styles.EngagementManage__column}>
                  <p className="a-form-label">
                    End Date <span className="required-color">*</span>
                  </p>
                  <CalendarPicker
                    className="full-width"
                    defaultValue={new Date(engagementState.endDate.toString())}
                    format="dd.mm.yyyy"
                    onChange={(endDate: string) => {
                      setEngagementState({ ...engagementState, endDate })
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ModalBody>

      <ModalFooter>
        <div className="button-comp">
          <EngagementComponent
            isValid={isValid}
            engagement={engagementState}
            isCurrentPage={isCurrentPage}
            closeModalCb={cancelModalCb}
            onDeleteCb={onDeleteCb}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default EngagementManageModal
