import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Error, Loading } from '..'
import { READ_BENCHMARK } from '../../graphql/benchmark'
import { ReadBenchmark, ReadBenchmarkVariables } from '../../graphql/__generated__/ReadBenchmark'
import AccountInfoCard from '../AccountInfoCard/AccountInfoCard'
import './BenchmarkDetail.scss'
import { ReadBenchmarks_benchmarks_categories_controls } from '../../graphql/__generated__/ReadBenchmarks'
import { ControlType } from '../../graphql/__generated__/globalTypes'
import CategoryControl from './CategoryControl'

const BenchmarkDetail: React.FC<any> = () => {
  const { benchmarkId }: Readonly<any> = useParams()
  const { data, refetch, loading, error } = useQuery<ReadBenchmark, ReadBenchmarkVariables>(READ_BENCHMARK, {
    variables: { id: benchmarkId || '' }
  })

  const controlsData = useMemo(() => {
    const controlsTypeCount = {
      manual: 0,
      automatic: 0
    }

    data?.benchmark.categories.forEach((categoryItem: any) =>
      categoryItem.controls.forEach((controlItem: ReadBenchmarks_benchmarks_categories_controls) => {
        const isManual = controlItem.type === ControlType.MANUAL

        if (isManual) {
          controlsTypeCount.manual += 1
        } else {
          controlsTypeCount.automatic += 1
        }
      })
    )

    return controlsTypeCount
  }, [data])

  const totalControls = (controlsData?.manual ?? 0) + (controlsData?.automatic ?? 0)

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div className="benchmark-detail">
      <AccountInfoCard benchmarkDetail={data?.benchmark} />
      <div className="benchmark-detail__list">
        <div className="benchmark-detail__info">
          <h3 className="benchmark-detail__info-header">Controls (Total)</h3>
          <div className="benchmark-detail__info-value">{totalControls && totalControls}</div>
        </div>
        <div className="benchmark-detail__info">
          <h3 className="benchmark-detail__info-header">Controls (Manual)</h3>
          <div className="benchmark-detail__info-value">{controlsData?.manual}</div>
        </div>
        <div className="benchmark-detail__info">
          <h3 className="benchmark-detail__info-header">Controls (Automatic)</h3>
          <div className="benchmark-detail__info-value">{controlsData?.automatic}</div>
        </div>
      </div>
      {data?.benchmark && <CategoryControl control={data?.benchmark} fetch={() => refetch({ id: benchmarkId })} />}
    </div>
  )
}

export default BenchmarkDetail
