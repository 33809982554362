import React from 'react'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Panel } from '@pwc-de/appkit-react'
import { Container, Row } from 'react-grid-system'
import { useQuery } from '@apollo/client'
import { ReadUserRoles } from '../../graphql/__generated__/ReadUserRoles'
import { READ_USER_ROLES } from '../../graphql/client'

type UserRoleProps = {
  role?: string
  showErrorPanel?: boolean
  customStyle?: string
}

const AuthorizeRole: React.FC<UserRoleProps> = ({ role, showErrorPanel = false, customStyle, children }) => {
  const { data, loading, error } = useQuery<ReadUserRoles>(READ_USER_ROLES)
  if (loading) {
    return null
  }
  if (error) {
    return <p>ERROR: {error.message}</p>
  }
  if (data && data.userRoles && role && data.userRoles.includes(role)) {
    return <div style={{ display: 'inline' }}>{children}</div>
  }
  if (showErrorPanel) {
    return (
      <Panel>
        <Container fluid>
          <Row align="center" justify="center" style={{ height: 220 }}>
            <div className="a-alert-icon-wrapper">
              <span
                className="a-alert-icon appkiticon icon-alert-fill a-icon-error"
                style={{ fontSize: '2em', color: '#c92727' }}
              />
            </div>
            <div className="a-alert-text-content" style={{ paddingLeft: '0.5em' }}>
              Access denied.
            </div>
          </Row>
        </Container>
      </Panel>
    )
  }
  return null
}

AuthorizeRole.defaultProps = {
  role: '',
  showErrorPanel: false,
  customStyle: ''
}

export default AuthorizeRole
