import React from 'react'
import { CapabilityStatus } from '../../graphql/__generated__/globalTypes'

type ShowIamDataProps = {
  capabilityStatus: CapabilityStatus
  data: any
}

const ShowIamData: React.FC<ShowIamDataProps> = ({ capabilityStatus, data, children }) => {
  if (capabilityStatus === CapabilityStatus.ACTIVE && data && data.length > 0) {
    return <div>{children}</div>
  }
  return null
}

export default ShowIamData
