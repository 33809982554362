import React, { useMemo, useState, useEffect } from 'react'
import './BenchmarkCard.scss'
import { Input, Panel } from '@pwc-de/appkit-react'
import { useQuery, useSubscription } from '@apollo/client'
import TopIssuesCard from './TopIssuesCard'
import { READ_ASSESSMENT_EVENTS, READ_ASSESSMENT_SUBSCRIPTION_SCORES } from '../../graphql/assessment'
import { AssessmentExecutionStatus, Classification } from '../../graphql/__generated__/globalTypes'
import { PieChartWrapper } from '../AssessmentResult/Dashboard/ControlsChart'
import {
  ReadAssessmentSubscriptionScores,
  ReadAssessmentSubscriptionScores_subscriptionScores,
  ReadAssessmentSubscriptionScoresVariables
} from '../../graphql/__generated__/ReadAssessmentSubscriptionScores'
import { ReadAssessmentEvent, ReadAssessmentEventVariables } from '../../graphql/__generated__/ReadAssessmentEvent'
import { Error, Loading } from '..'
import { FilterContextProvider, useFilterContext } from '../AssessmentResult/Filter/FilterContext'
import { getDisplayName } from '../AssessmentResult/Util'
import { getColorByClassification } from '../ClassificationHelper'
import SubscriptionScores from '../AssessmentResult/Dashboard/SubscriptionScores'
import AssessmentResultFilter from '../AssessmentResult/Filter/Filter'
import ResultCategories from '../AssessmentResult/Results/ResultCategories'

const SearchFilter: React.FC = () => {
  const { state, dispatch } = useFilterContext()
  const [lastSearch, setLastSearch] = useState<any | null>(null)

  const handleChange = (searchString) => {
    setLastSearch(searchString)
    localStorage.setItem('inputValue', searchString)
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      localStorage.removeItem('inputValue')
      localStorage.removeItem('openValue')
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    const value = localStorage.getItem('inputValue')
    setLastSearch(value)
    dispatch({ type: 'setSearch', value })
    state.search = value || ''
    dispatch({ type: 'applyFilters', value })
  }, [])

  return (
    <Input
      className="new-input"
      placeholder="Search"
      type="text"
      value={lastSearch || state.search}
      prefix={<span className="select-toggle-icon appkiticon icon-search-outline apply-opacity-in-closed-toggle" />}
      onChange={(message: string) => {
        handleChange(message)
        dispatch({ type: 'setSearch', value: message })
        dispatch({ type: 'applyFilters', value: message })
      }}
    />
  )
}

type BenchmarkCardProps = {
  assessmentId: string
  assessmentQuery: any
  assessment: any
  setAssessment: any
  accountId: string
  benchmarkId: string
  analyticsQuery: any
  onUpdateControl: any
}

const BenchmarkCard: React.FC<BenchmarkCardProps> = ({
  assessmentId,
  assessmentQuery,
  assessment,
  setAssessment,
  accountId,
  benchmarkId,
  analyticsQuery,
  onUpdateControl
}: BenchmarkCardProps) => {
  const [isFetching, setIsFetching] = useState(false)
  const [assessmentSubscriptionScores, setAssessmentSubscriptionScores] =
    useState<ReadAssessmentSubscriptionScores_subscriptionScores[]>()

  const assessmentSubscriptionScoresQuery = useQuery<
    ReadAssessmentSubscriptionScores,
    ReadAssessmentSubscriptionScoresVariables
  >(READ_ASSESSMENT_SUBSCRIPTION_SCORES, {
    variables: { id: assessmentId! },
    onCompleted: (data) => {
      setAssessmentSubscriptionScores(data.subscriptionScores)
    }
  })

  useEffect(() => {
    const scrollPosition = localStorage.getItem('scrollValue')
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition))
    }
  }, [])

  const updateScrollPosition = (value) => {
    localStorage.setItem('scrollValue', value)
  }

  const refetchData = (force = false) => {
    if (!isFetching || force) {
      setIsFetching(true)
      assessmentQuery.refetch().then((result) => {
        setAssessment(result.data.assessment)
        assessmentSubscriptionScoresQuery.refetch().then((resultSubscriptionScores) => {
          setAssessmentSubscriptionScores(resultSubscriptionScores.data.subscriptionScores)
        })
        setIsFetching(false)
        // setFilteredAssessment(result.data.assessment);
      })
    }
  }

  const controlsData: any[] | null = useMemo(
    () =>
      assessment
        ? [
            {
              name: getDisplayName(Classification.COMPLIANT),
              value: assessment.analytics.numControlsCompliant,
              color: getColorByClassification(Classification.COMPLIANT)
            },
            {
              name: getDisplayName(Classification.NOT_COMPLIANT),
              value: assessment.analytics.numControlsNotCompliant,
              color: getColorByClassification(Classification.NOT_COMPLIANT)
            },
            {
              name: getDisplayName(Classification.NOT_APPLICABLE),
              value: assessment.analytics.numControlsNotApplicable,
              color: getColorByClassification(Classification.NOT_APPLICABLE)
            }
          ]
        : null,
    [assessment]
  )

  useSubscription<ReadAssessmentEvent, ReadAssessmentEventVariables>(READ_ASSESSMENT_EVENTS, {
    variables: { id: assessmentId! },
    skip:
      assessmentQuery.error != null ||
      (assessmentQuery.data && assessmentQuery.data.assessment.executionStatus === AssessmentExecutionStatus.DONE),
    onSubscriptionData: async ({ subscriptionData }) => {
      const source = subscriptionData?.data?.assessmentEventsByAssessment?.source || {
        __typename: 'Unknown',
        message: '',
        id: ''
      }
      switch (source.__typename) {
        case 'AssessmentCompletedEvent':
        case 'AssessmentFailedEvent': {
          console.debug(`${source?.message}`)
          refetchData(true)
          break
        }
        default: {
          console.debug(`got data ${source?.message}`)
          break
        }
      }
    },
    shouldResubscribe: true,
    onSubscriptionComplete: () => {
      console.debug(`Subscription complete`)
    }
  })

  const finishedAssessment = assessment?.executionStatus === AssessmentExecutionStatus.DONE

  if (assessmentQuery.error) return <Error />
  if (
    assessmentQuery.loading ||
    !assessmentQuery.data ||
    !assessment ||
    !assessmentSubscriptionScores ||
    assessment.executionStatus !== AssessmentExecutionStatus.DONE
  )
    return <Loading />

  const assessmentFailed = assessment.executionStatus === AssessmentExecutionStatus.FAILED

  return (
    <FilterContextProvider assessment={assessment} assessmentSubscriptionScores={assessmentSubscriptionScores}>
      <div className="benchmark-card top-right">
        <div className="d-flex">
          <div className="flex-fill benchmark-card__info-list">
            <div className="benchmark-card__info">
              <h3 className="benchmark-card__info-header">Assessment Score</h3>
              <p className="benchmark-card__info-value">
                {assessment?.analytics?.score ? (
                  <span className="benchmark-card__score-value">
                    <span>{Math.round(assessment?.analytics?.score)}</span> / 100
                  </span>
                ) : assessment.executionStatus === AssessmentExecutionStatus.FAILED ? (
                  <span
                    className="a-alert-icon appkiticon icon-alert-fill a-icon-error"
                    style={{ fontSize: '15px', color: '#c92727', padding: '1em 0' }}
                  >
                    <span className="a-alert-text-content" style={{ paddingLeft: '0.5em' }}>
                      This assessment has failed.
                    </span>
                  </span>
                ) : (
                  <span className="na-value--big">N/A</span>
                )}
              </p>
            </div>
            {controlsData?.length && (
              <div className="benchmark-card__info benchmark-card__info_mb-0 mb-3">
                <h3 className="benchmark-card__info-header">Control Breakdown</h3>
                <div className="benchmark-card__info-value">
                  <PieChartWrapper chartData={controlsData} />
                </div>
              </div>
            )}
          </div>
          <div className="flex-fill">
            {assessment.cloudSubscriptions.length > 1 && <SubscriptionScores assessment={assessment} />}
            <TopIssuesCard accountId={accountId} benchmarkId={benchmarkId} analyticsQuery={analyticsQuery} />
          </div>
        </div>
        <Panel className="op-right new-panel" title="Controls" renderRight={() => <SearchFilter />}>
          {finishedAssessment && !assessmentFailed && <AssessmentResultFilter assessment={assessment} />}
          <ResultCategories onUpdateControl={onUpdateControl} updateScrollPosition={updateScrollPosition} />
        </Panel>
      </div>
    </FilterContextProvider>
  )
}

export default BenchmarkCard
