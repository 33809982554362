import React from 'react'
import { Table } from '@pwc-de/appkit-react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Error } from '..'
import { ReadRoles } from '../../graphql/__generated__/ReadRoles'
import { GET_ROLES } from '../../graphql/role'
import LoadingPanel from '../Status/LoadingPanel'
import { setStateUrl } from '../util'

type RoleOverviewProps = {
  cloudAccountIdParams?: string
  urlParent?: string
  urlParams?: string
}

type RoleOverviewParams = {
  cloudAccountId?: string
  urlParent?: string
  urlParams?: string
}

const RoleOverview: React.FC<RoleOverviewProps> = ({
  cloudAccountIdParams: cloudAccountIdProps,
  urlParent = '',
  urlParams = ''
}) => {
  const { cloudAccountId: cloudAccountIdParams }: Readonly<RoleOverviewParams> = useParams()
  const cloudAccountId = cloudAccountIdParams || cloudAccountIdProps
  const { data, loading, error } = useQuery<ReadRoles>(GET_ROLES, {
    variables: { cloudAccountId }
  })

  if (error) {
    return <Error />
  }

  const roles = data && data.roles ? data.roles : []

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (row: any) => (
        <Link
          to={`${urlParent}${encodeURIComponent(row.original.id)}${urlParams}${
            urlParams ? `&role=${row.value}` : `?role=${row.value}`
          }`}
          onClick={() => setStateUrl({ roleName: row.value })}
        >
          {row.value}
        </Link>
      )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Users',
      accessor: 'users.length'
    }
  ]

  return (
    <LoadingPanel title="Roles" isLoading={loading} className="new-panel">
      <Table className="new-table" data={roles} columns={columns} defaultSorted={[{ id: 'name' }]} />
    </LoadingPanel>
  )
}

RoleOverview.defaultProps = {
  cloudAccountIdParams: '',
  urlParent: '',
  urlParams: ''
}

export default RoleOverview
