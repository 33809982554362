import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Query {
    currentEngagement: Engagement
    appErrors: [AppErrorType!]
    userRoles: [String!]
  }

  type AppErrorType {
    id: String!
    message: String!
  }

  extend type Mutation {
    setCurrentEngagement(engagement: Engagement!): Engagement!
  }
`

export const resolvers = {}
