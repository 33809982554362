import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Panel, Button } from '@pwc-de/appkit-react'
import { Container, Row, Col, setConfiguration } from 'react-grid-system'
import { READ_ENGAGEMENT } from '../../graphql/engagement'
import { Loading, Error, AuthorizeRole } from '..'
import { ReadEngagement, ReadEngagementVariables } from '../../graphql/__generated__/ReadEngagement'

setConfiguration({ gutterWidth: 0 })

type EngagementDetailsParams = {
  engagementId?: string
}

//--------------------------------------------------------------------------
const EngagementDetails: React.FC = () => {
  const { engagementId }: Readonly<EngagementDetailsParams> = useParams()
  const { data, loading, error } = useQuery<ReadEngagement, ReadEngagementVariables>(READ_ENGAGEMENT, {
    variables: { id: (engagementId || '')! }
  })

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }
  if (!data) {
    return <p>Not found</p>
  }

  const { engagement } = data

  return (
    <Panel
      title={engagement.name}
      renderRight={() => (
        <Link to="../edit">
          <AuthorizeRole role="admin">
            <Button id="editEngagement">Edit</Button>
          </AuthorizeRole>
        </Link>
      )}
    >
      <Container fluid>
        <Row>
          <Col md={3}>
            <p>Start Date:</p>
          </Col>
          <Col md={9}>
            <p>{new Date(engagement.startDate).toLocaleDateString()}</p>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <p>End Date:</p>
          </Col>
          <Col md={9}>
            <p>{new Date(engagement.endDate).toLocaleDateString()}</p>
          </Col>
        </Row>
        {engagement.manager && (
          <Row>
            <Col md={3}>
              <p>Manager:</p>
            </Col>
            <Col md={9}>
              <p>{engagement.manager}</p>
            </Col>
          </Row>
        )}
        {engagement.clientContact && (
          <Row>
            <Col md={3}>
              <p>Client Contact:</p>
            </Col>
            <Col md={9}>
              <p>{engagement.clientContact}</p>
            </Col>
          </Row>
        )}
      </Container>
    </Panel>
  )
}

export default EngagementDetails
