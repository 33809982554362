import gql from 'graphql-tag'

export const UserFields = gql`
  fragment UserFields on UserOutput {
    id
    name
    platform
    tenantId
    userType
    created
    passwordAssigned
    passwordLastChanged
    roles {
      id
      name
      description
    }
    groups {
      id
      name
      description
    }
  }
`

export const UserDtoFields = gql`
  fragment UserDtoFields on UserDto {
    id
    name
    created
    userType
  }
`

export const READ_USERS = gql`
  query ReadUsers($cloudAccountId: ID!) {
    users(cloudAccountId: $cloudAccountId) {
      ...UserDtoFields
    }
  }
  ${UserDtoFields}
`

export const READ_USERS_PAGINATED = gql`
  query ReadUsersPaginated($cloudAccountId: ID!, $nextLink: String) {
    usersPaginated(cloudAccountId: $cloudAccountId, nextLink: $nextLink) {
      users {
        ...UserDtoFields
      }
      pageInfo {
        nextLink
      }
    }
  }
  ${UserDtoFields}
`

export const READ_USER = gql`
  query ReadUser($cloudAccountId: ID!, $userId: String!, $userName: String!) {
    user(cloudAccountId: $cloudAccountId, userId: $userId, userName: $userName) {
      ...UserFields
    }
  }
  ${UserFields}
`
