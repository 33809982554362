import React, { useEffect, useMemo, useState } from 'react'
import { Badge, Tooltip, Button } from '@pwc-de/appkit-react'
import {
  ReadAssessment_assessment_categories_controls,
  ReadAssessment_assessment_categories_controls_controlSubscriptions,
  ReadAssessment_assessment_categories_controls_controlSubscriptions_subscriptionResult_resourceResults
} from '../../../graphql/__generated__/ReadAssessment'
import { Classification, ControlRating, ControlType } from '../../../graphql/__generated__/globalTypes'
import { getStatusFromResourceStatus, getStatusFromClassification } from '../StatusIndicator'
import styles from './ResultControls.module.scss'
import { getHeaderProps, getProps } from '../../util'
import { ReactComponent as InfoIcon } from '../../../assets/images/info.svg'

//--------------------------------------------------------------------------
export const ResultControl: React.FC<{
  control: ReadAssessment_assessment_categories_controls
  onUpdateControl: any
  updateScrollPosition: any
}> = ({ control, onUpdateControl, updateScrollPosition }) => {
  const data = [] as any
  const columns = [] as any

  control.controlSubscriptions.forEach(
    (controlSub: ReadAssessment_assessment_categories_controls_controlSubscriptions) => {
      controlSub.subscriptionResult.resourceResults.forEach(
        (
          resourceSub: ReadAssessment_assessment_categories_controls_controlSubscriptions_subscriptionResult_resourceResults
        ) => {
          data.push({
            status: resourceSub.status,
            resourceId: resourceSub.resourceId,
            resourceName: resourceSub.resourceName,
            subscriptionName: controlSub.name,
            subscriptionId: controlSub.subscriptionId,
            message: resourceSub.message,
            details: 'Details'
          })
        }
      )
    }
  )

  columns.push(
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ original }) => <>{getStatusFromResourceStatus(original.status)}</>,
      width: 60
    },
    {
      Header: 'Resource',
      accessor: 'resourceName'
    },
    {
      Header: 'Subscription',
      accessor: 'subscriptionName',
      width: 250,
      getProps,
      getHeaderProps
    },
    {
      Header: 'Details',
      Cell: ({ original }) => (
        <Tooltip
          content={
            <>
              {original.message && (
                <p>
                  <b>Status: </b>
                  {original.message}
                </p>
              )}
              <div>
                <b>ResourceID:</b> {original.resourceId}
              </div>
            </>
          }
          hasArrow
          trigger="click"
          clickToClose
          placement="bottom"
          tooltipTheme="light"
        >
          <Button kind="secondary" gray size="sm">
            Details
          </Button>
        </Tooltip>
      ),
      width: 100,
      getProps,
      getHeaderProps
    }
  )

  return (
    <ResultControlHeader
      control={control}
      onUpdateControl={onUpdateControl}
      updateScrollPosition={updateScrollPosition}
    />
  )
}

//--------------------------------------------------------------------------
function createBadgeFromRisk(risk: ControlRating) {
  switch (risk) {
    case ControlRating.CRITICAL:
      return <Badge type="dark">Critical</Badge>
    case ControlRating.HIGH:
      return <Badge type="error">High</Badge>
    case ControlRating.MEDIUM:
      return <Badge type="warning">Medium</Badge>
    case ControlRating.LOW:
      return <Badge type="success">Low</Badge>
    default:
      return <Badge type="light">Unknown</Badge>
  }
}

//--------------------------------------------------------------------------
export const ResultControlHeader: React.FC<{
  control: ReadAssessment_assessment_categories_controls
  onUpdateControl: any
  updateScrollPosition: any
}> = ({ control, onUpdateControl, updateScrollPosition }) => {
  const scoreDisplay =
    control.controlResult.classification === Classification.NOT_APPLICABLE ||
    control.controlResult.classification === Classification.UNKNOWN
      ? 'N/A'
      : `${Math.round(control.controlResult.score)}/100`

  const handleControlClick = () => {
    updateScrollPosition(window.pageYOffset)
  }

  return (
    <>
      <div style={{ height: '1px', backgroundColor: '#D8D8D8', borderRadius: '5px' }} />
      <div className={styles.ControlContainer}>
        <div className={styles.ControlContainer__info}>
          {control.isOverridden && (
            <Tooltip
              content={`Control was overridden by ${control.overrideUser} on ${new Date(
                control.overrideTimestamp
              ).toLocaleString()} with following reason: ${control.overrideReason}`}
              placement="right"
              tooltipTheme="light"
            >
              <InfoIcon />
            </Tooltip>
          )}
        </div>
        <div className={`${styles.ControlContainer__status}`}>
          {getStatusFromClassification(control.controlResult.classification)}
        </div>
        <div className={styles.ControlContainer__type}>
          {control.type === ControlType.AUTOMATIC && <div className={styles.ControlContainer__automatic}>A</div>}
          {control.type === ControlType.MANUAL && <div className={styles.ControlContainer__manual}>M</div>}
        </div>
        <div className={styles.ControlContainer__name}>
          <p
            className={styles.ControlContainer__link}
            onClick={() => {
              handleControlClick()
              onUpdateControl({
                name: `${control.controlId} ${control.controlName}`,
                value: control.controlId,
                lastSearch: localStorage.getItem('inputValue')
              })
            }}
            aria-hidden="true"
          >
            {control.controlId} {control.controlName}
          </p>
        </div>
        <div className={styles.ControlContainer__icon} />
        <div className={styles.ControlContainer__rating}>{createBadgeFromRisk(control.controlRating)}</div>
        <div className={styles.ControlContainer__score}>{scoreDisplay}</div>
      </div>
    </>
  )
}

//--------------------------------------------------------------------------
const ResultControlTooltip: React.FC<any> = ({ overrideModalCb, modifyResourcesModalCb, control }) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    <Button
      onClick={(e) => {
        e.stopPropagation()
        overrideModalCb()
      }}
      className={`a-btn-secondary mt-1 a-btn-gray ${styles.Tooltip__button}`}
      style={{ border: 'hidden' }}
    >
      <span className={`appkiticon icon-setting-outline ${styles.Tooltip__logo}`} />
      Override Control Status
    </Button>
    {control.type === ControlType.MANUAL && (
      <Button
        onClick={(e) => {
          e.stopPropagation()
          modifyResourcesModalCb()
        }}
        className={`a-btn-secondary mt-1 a-btn-gray ${styles.Tooltip__button}`}
        style={{ border: 'hidden' }}
      >
        <span className={`appkiticon icon-pencil-outline  ${styles.Tooltip__logo}`} style={{ color: '#EB8C00' }} />
        Modify Resources
      </Button>
    )}
  </div>
)

//--------------------------------------------------------------------------
export const ResultControls: React.FC<{
  controls: ReadAssessment_assessment_categories_controls[]
  onUpdateControl: any
  updateScrollPosition: any
}> = ({ controls, onUpdateControl, updateScrollPosition }) => (
  <div className={styles.ResultControls}>
    {controls.length === 0 ? (
      <div style={{ padding: '1em' }}>All control results in this category are hidden by your filters.</div>
    ) : (
      <div className={styles.ResultHeader}>
        <div className={styles.ControlContainer__info} />
        <div className={styles.ControlContainer__status}>Status</div>
        <div className={styles.ControlContainer__type}>Type</div>
        <div className={styles.ControlContainer__name}>Control</div>
        <div className={styles.ControlContainer__icon} />
        <div className={styles.ControlContainer__rating}>Risk Level</div>
        <div className={styles.ControlContainer__score}>Score</div>
      </div>
    )}
    {controls.map((control: ReadAssessment_assessment_categories_controls) => (
      <ResultControl
        key={control.id}
        control={control}
        onUpdateControl={onUpdateControl}
        updateScrollPosition={updateScrollPosition}
      />
    ))}
  </div>
)
export default ResultControls
