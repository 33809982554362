import React, { useState } from 'react'
import {
  ReadAssessment_assessment_categories,
  ReadAssessment_assessment_categories_analytics
} from '../../../graphql/__generated__/ReadAssessment'
import { Classification } from '../../../graphql/__generated__/globalTypes'
import { ResultControls } from './ResultControls'
import { DIRECTION_SORT, ISortFilter, useFilterContext } from '../Filter/FilterContext'
import CollapsibleWrapper from './CollapsibleWrapper'
import styles from './ResultCategories.module.scss'
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/arrow-table-active.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/images/arrow-table.svg'

//--------------------------------------------------------------------------
function getResourceStatusBar(analytics: ReadAssessment_assessment_categories_analytics) {
  let compliant = (analytics.numControlsCompliant / analytics.numControls) * 100.0
  let notCompliant = (analytics.numControlsNotCompliant / analytics.numControls) * 100.0
  let notApplicable = (analytics.numControlsNotApplicable / analytics.numControls) * 100.0

  // Have min-width of 5% for non-zero number of controls
  // to properly display numbers
  if (analytics.numControlsCompliant > 0) {
    compliant = Math.max(compliant, 5)
  }

  if (analytics.numControlsNotCompliant > 0) {
    notCompliant = Math.max(notCompliant, 5)
  }

  if (analytics.numControlsNotApplicable > 0) {
    notApplicable = Math.max(notApplicable, 5)
  }

  return (
    <div className={styles.CategoryScoreContainer}>
      <div className={styles.CategoryScoreContainer__compliant} style={{ width: String(compliant).concat('%') }}>
        {analytics.numControlsCompliant > 0 && analytics.numControlsCompliant}
      </div>
      <div className={styles.CategoryScoreContainer__notCompliant} style={{ width: String(notCompliant).concat('%') }}>
        {analytics.numControlsNotCompliant > 0 && analytics.numControlsNotCompliant}
      </div>
      <div
        className={styles.CategoryScoreContainer__notApplicable}
        style={{ width: String(notApplicable).concat('%') }}
      >
        {analytics.numControlsNotApplicable > 0 && analytics.numControlsNotApplicable}
      </div>
    </div>
  )
}

export const ResultCategoriesHeader: React.FC = () => {
  const { state, dispatch } = useFilterContext()

  const sortBy = (name) => {
    let value: ISortFilter

    if (state.sort?.name === name) {
      value = {
        name,
        direction: DIRECTION_SORT.asc === state.sort?.direction ? DIRECTION_SORT.desc : DIRECTION_SORT.asc
      }
    } else {
      value = { name, direction: DIRECTION_SORT.asc }
    }

    const lastSearch = localStorage.getItem('inputValue')
    dispatch({ type: 'setSort', value })
    dispatch({ type: 'applyFilters', lastSearch })
  }

  return (
    <div className={styles.CategoryHeader}>
      <div className={styles.CategoryContainer__status} />
      <div className={`${styles.CategoryContainer__name} pointer`} onClick={() => sortBy('name')} aria-hidden="true">
        <span>Category</span>
        {state?.sort?.name === 'name' &&
          (state.sort.direction === DIRECTION_SORT.asc ? (
            <span className="appkiticon icon-sorting-ascendant-fill" />
          ) : (
            <span className="appkiticon icon-sorting-descendant-fill" />
          ))}
      </div>
      <div
        className={`${styles.CategoryContainer__resources} pointer`}
        onClick={() => sortBy('control')}
        aria-hidden="true"
      >
        <span>Control Status</span>
        {state?.sort?.name === 'control' &&
          (state.sort.direction === DIRECTION_SORT.asc ? (
            <span className="appkiticon icon-sorting-ascendant-fill" />
          ) : (
            <span className="appkiticon icon-sorting-descendant-fill" />
          ))}
      </div>
      <div className={`${styles.CategoryContainer__score} pointer`} onClick={() => sortBy('score')} aria-hidden="true">
        <span>Score</span>
        {state?.sort?.name === 'score' &&
          (state.sort.direction === DIRECTION_SORT.asc ? (
            <span className="appkiticon icon-sorting-ascendant-fill" />
          ) : (
            <span className="appkiticon icon-sorting-descendant-fill" />
          ))}
      </div>
    </div>
  )
}

//--------------------------------------------------------------------------
export const ResultCategory: React.FC<{ category: ReadAssessment_assessment_categories; open?: boolean }> = ({
  category,
  open
}) => {
  const scoreDisplay =
    category.analytics.classification === Classification.NOT_APPLICABLE ||
    category.analytics.classification === Classification.UNKNOWN
      ? 'N/A'
      : `${Math.round(category.analytics.score)}/100`

  return (
    <div className={`${styles.CategoryContainer} ${open ? styles.CategoryContainerActive : ''}`}>
      <div className={styles.CategoryContainer__status}>{open ? <ArrowDownIcon /> : <ArrowUpIcon />}</div>
      <div className={styles.CategoryContainer__name}>{category.name}</div>
      <div className={styles.CategoryContainer__resources} style={{ fontWeight: 'normal' }}>
        {getResourceStatusBar(category.analytics)}
      </div>
      <div className={styles.CategoryContainer__score}>{scoreDisplay}</div>
    </div>
  )
}

const ResultCategoriesRow: React.FC<any> = ({ category, onUpdateControl, updateScrollPosition }) => {
  const [open, setOpen]: any = useState<Boolean>(false)

  return (
    <div style={{ borderBottom: open ? '1px solid #D8D8D8' : '' }}>
      <CollapsibleWrapper
        key={category.id}
        usableKey={category.id}
        transitionTime={25 * Math.max(category.controls.length, 1)}
        trigger={<ResultCategory category={category} open={open} />}
        parentOpen={open}
        parentSetOpen={setOpen}
      >
        {
          <ResultControls
            controls={category.controls}
            onUpdateControl={onUpdateControl}
            updateScrollPosition={updateScrollPosition}
          />
        }
      </CollapsibleWrapper>
    </div>
  )
}

//--------------------------------------------------------------------------
const ResultCategories: React.FC<{ onUpdateControl?: any; updateScrollPosition?: any }> = ({
  onUpdateControl,
  updateScrollPosition
}) => {
  const assessment = useFilterContext().state.filteredAssessment!

  return (
    <>
      <ResultCategoriesHeader />

      {assessment.categories.map((category: ReadAssessment_assessment_categories) => (
        <ResultCategoriesRow
          key={category.id}
          category={category}
          onUpdateControl={onUpdateControl}
          updateScrollPosition={updateScrollPosition}
        />
      ))}
    </>
  )
}

export default ResultCategories
