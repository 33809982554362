import React from 'react'
import { Input } from '@pwc-de/appkit-react'
import { CredentialsProps, CredentialsAws } from '../CloudAccount'

const CloudAccountAwsFields: React.FC<CredentialsProps> = ({ accountState, setAccountState }) => (
  <div>
    <Input
      label={
        <span>
          Access Key <span className="required-color">*</span>
        </span>
      }
      id="awsAccessKey"
      placeholder="Access Key"
      value={(accountState.credentials as CredentialsAws).accessKey}
      onChange={(accessKey: string) => {
        setAccountState({
          ...accountState,
          credentials: {
            ...accountState.credentials,
            accessKey
          }
        })
      }}
    />

    <Input
      label={
        <span>
          Secret Access Key <span className="required-color">*</span>
        </span>
      }
      id="awsSecretAccessKey"
      autoComplete="off"
      placeholder="Enter new secret access key"
      onChange={(secretAccessKey: string) => {
        setAccountState({
          ...accountState,
          credentials: {
            ...accountState.credentials,
            secretAccessKey
          }
        })
      }}
    />
  </div>
)

export default CloudAccountAwsFields
