import React from 'react'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Container, Row, Col } from 'react-grid-system'
import { Panel } from '@pwc-de/appkit-react'

const AccessDenied: React.FC = () => (
  <Container fluid>
    <Row justify="center">
      <Col sm={10} md={10} lg={8} xl={8} xxl={6}>
        <Panel>
          <div className="a-apply-theme-border-bottom-gray-level-6 a-py-20 d-flex justify-content-center">
            <div className="col-6 no-gutters a-pl-5 a-pb-5">
              <div className="a-sub-panel">
                <span className="a-text-md">Access Denied</span>
                <span className="a-text-sm">Please contact the administrator.</span>
              </div>
            </div>
          </div>
        </Panel>
      </Col>
    </Row>
  </Container>
)

export default AccessDenied
