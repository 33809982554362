import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { Button, Tooltip } from '@pwc-de/appkit-react'
import NewModal from '../Modal/NewModal'
import { DELETE_CONTROL, READ_CONTROLS } from '../../graphql/control'
import { DeleteControl, DeleteControlVariables } from '../../graphql/__generated__/DeleteControl'
import { ReactComponent as DeleteControlIcon } from '../../assets/images/icon/delete-control.svg'

import type { DeleteControlProps } from '../../models/types/control'

const ControlDeleteButton: React.FC<DeleteControlProps> = ({ controlId, cloudSolutionId }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const [mutate] = useMutation<DeleteControl, DeleteControlVariables>(DELETE_CONTROL, {
    variables: { id: controlId },
    refetchQueries: [
      {
        query: READ_CONTROLS,
        variables: { cloudSolutionId }
      }
    ]
  })

  return (
    <>
      <NewModal
        title="Delete Control"
        text="Are you sure you want to delete this control? This action cannot be undone."
        visibility={{ show: showModal, setShow: setShowModal }}
        buttons={{
          cont: {
            text: 'Delete',
            onClick: mutate,
            className: 'a-btn__delete'
          },
          cancel: {
            text: 'Cancel'
          }
        }}
      />
      <Button onClick={() => setShowModal(!showModal)}>
        <Tooltip content="Delete" placement="left">
          <DeleteControlIcon />
        </Tooltip>
      </Button>
    </>
  )
}

export default ControlDeleteButton
