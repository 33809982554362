import React from 'react'
import './ControlPage.scss'
import { Input, Panel } from '@pwc-de/appkit-react'
import { AssessmentResultControlOverride } from './ResultControlOverrideStatus'
import { ReadAssessment_assessment_categories_controls } from '../../graphql/__generated__/ReadAssessment'
import { Loading } from '../index'
import ResourcesControl from './ResourcesControl'
import { useFilterContext } from '../AssessmentResult/Filter/FilterContext'
import { ControlType } from '../../graphql/__generated__/globalTypes'

type ControlPageProps = {
  control: ReadAssessment_assessment_categories_controls
  assessmentId: string
}

const ControlPage: React.FC<ControlPageProps> = ({ control, assessmentId }) => {
  if (!control) {
    return <Loading />
  }

  return (
    <div className="control-page">
      <ResourcesControl control={control} />
      <Panel className="new-panel control-page__remediation" title="Remediation">
        <p
          style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{
            __html: control?.remediation
              ?.replaceAll('<', '&lt;')
              .replaceAll('>', '&gt;')
              .replace('Note:', '<b>Note:</b>')
          }}
        />
      </Panel>
      <Panel className="new-panel" title="Override Control Status">
        <AssessmentResultControlOverride control={control} assessmentId={assessmentId} />
      </Panel>
    </div>
  )
}

export default ControlPage
