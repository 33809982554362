class AssignedControl {
  id: string = ''

  pluginName: string = ''
}

class Category {
  id: string = ''

  name: string = ''

  controls: AssignedControl[] = []
}

export default Category
