import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Tooltip } from '@pwc-de/appkit-react'
import FileSaver from 'file-saver'
import { EXPORT_ASSESSMENT_CSV } from '../../graphql/assessment'
import { ExportAssessment, ExportAssessmentVariables } from '../../graphql/__generated__/ExportAssessment'
import { Error } from '..'
import { AssessmentExecutionStatus } from '../../graphql/__generated__/globalTypes'
import { ReactComponent as UploadIcon } from '../../assets/images/upload.svg'
import './AssessmentExport.scss'

type AssessmentExportProps = {
  assessmentId: string
  status: AssessmentExecutionStatus
  isIcon?: boolean
}

const AssessmentExport: React.FC<AssessmentExportProps> = ({ assessmentId, status, isIcon = false }) => {
  const [isDownload, setIsDownload] = useState(false)
  const { loading, error }: any = useQuery<ExportAssessment, ExportAssessmentVariables>(EXPORT_ASSESSMENT_CSV, {
    variables: { id: assessmentId! },
    skip: !isDownload,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const content =
        data && data.exportAssessment && data.exportAssessment.encodedFile ? data.exportAssessment.encodedFile : ''

      const fileName =
        data && data.exportAssessment && data.exportAssessment.fileName ? data.exportAssessment.fileName : ''

      const file = new File([atob(content)], `${fileName}`, { type: 'text/plain' })

      FileSaver.saveAs(file)
      setIsDownload(false)
    }
  })

  if (error) {
    return <Error />
  }

  if (isIcon) {
    if (loading || status !== AssessmentExecutionStatus.DONE) {
      return (
        <Tooltip content="Results not (yet) available" placement="right">
          <button className="assessment-export__upload-btn" type="button" disabled>
            <UploadIcon />
          </button>
        </Tooltip>
      )
    }
    return (
      <button className="assessment-export__upload-btn" type="button" onClick={() => setIsDownload(true)}>
        <UploadIcon />
      </button>
    )
  } else {
    if (loading || status !== AssessmentExecutionStatus.DONE) {
      return (
        <Tooltip content="Results not (yet) available" placement="right">
          <Button disabled>Download</Button>
        </Tooltip>
      )
    }
    return <Button onClick={() => setIsDownload(true)}>Download</Button>
  }
}

AssessmentExport.defaultProps = {
  isIcon: false
}

export default AssessmentExport
