class Plugin {
  constructor(name: string, content: string) {
    this.name = name
    this.content = content
  }

  name: string = ''

  content: string = ''
}

export default Plugin
