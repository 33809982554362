import React from 'react'
import './CardTable.scss'

type CardTableProps = {
  header?: string
  className?: string
  rightItem?: any
  children: any[]
  countCell?: number
}

const CardTable: React.FC<CardTableProps> = ({ header, className, rightItem, countCell = 1, children }) => (
  <div className={`card-table ${className}`}>
    {!!header && (
      <div className="card-table__top-line">
        <h3 className="card-table__header">{header}</h3>
        {!!rightItem && rightItem}
      </div>
    )}
    <div className="card-table__items">
      {children.map((component: any, index: number) => (
        <div key={index} className="card-table__item" style={{ gridTemplateColumns: `repeat(${countCell}, 1fr)` }}>
          {component}
        </div>
      ))}
    </div>
  </div>
)

CardTable.defaultProps = {
  countCell: 1
}

export default CardTable
