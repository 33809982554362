import React from 'react'
import { ModalHeader } from '@pwc-de/appkit-react'

//--------------------------------------------------------------------------
const ModalHeaderWrapper: React.FC<any> = ({ children }) => (
  <ModalHeader closable={false}>
    <div className="a-modal-manual-control-header">
      <div className="modal-title-left">
        <div className="modal-title">{children}</div>
      </div>
    </div>
  </ModalHeader>
)

export default ModalHeaderWrapper
