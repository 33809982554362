import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, useApolloClient } from '@apollo/client'
import { Button, Input, Table } from '@pwc-de/appkit-react'
import { READ_ENGAGEMENTS } from '../../graphql/engagement'
import { READ_CURRENT_ENGAGEMENT } from '../../graphql/client'
import { Loading, Error, AuthorizeRole } from '..'
import { parseDate } from '../util'
import { ReadEngagements, ReadEngagementsVariables } from '../../graphql/__generated__/ReadEngagements'
import EngagementManageModal from './EngagementManageModal'
import styles from './EngagementOverview.module.scss'
import ControlOverviewFilterC from '../Control/ControlOverviewFilter'
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg'
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg'
import EngagementDeleteModal from './EngagementDeleteModal'
import Layout from '../Layout/Layout'
import './EngagementOverview.scss'

//--------------------------------------------------------------------------
const EngagementOverview: React.FC = () => {
  const [search, setSearch] = useState('')
  const [engagementId, setEngagementId] = useState('')
  const [engagementName, setEngagementName] = useState('')
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

  const openUpdateModal = (id: string, name: string) => {
    setEngagementId(id)
    setEngagementName(name)
    setIsUpdateModalVisible(true)
  }

  const closeUpdateModal = () => {
    setIsUpdateModalVisible(false)
  }

  const openCreateModal = () => {
    setIsCreateModalVisible(true)
  }

  const closeCreateModal = () => {
    setIsCreateModalVisible(false)
  }

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }

  return (
    <>
      <p className={styles.EngagementOverview__description}>Engagements</p>
      <Layout
        className="new-panel benchmark-overview"
        title="Engagements"
        subtitle="Engagements"
        actions={() => (
          <AuthorizeRole role="admin">
            <EngagementCreateButton className="a-btn-new a-btn-header" isAddIcon={false} createCb={openCreateModal}>
              Create Engagement
            </EngagementCreateButton>
          </AuthorizeRole>
        )}
        renderRight={() => {
          return (
            <Input
              className="new-input"
              placeholder="Search"
              type="text"
              value={search}
              prefix={
                <span className="select-toggle-icon appkiticon icon-search-outline apply-opacity-in-closed-toggle" />
              }
              onChange={(message: string) => {
                setSearch(message)
              }}
            />
          )
        }}
      >
        <Engagements openCreateCb={openCreateModal} openUpdateCb={openUpdateModal} search={search} />

        {isDeleteModalVisible && (
          <EngagementDeleteModal
            visible={isDeleteModalVisible}
            cancelModalCb={closeDeleteModal}
            engagementId={engagementId}
            engagementName={engagementName}
          />
        )}

        {isCreateModalVisible && (
          <EngagementManageModal visible={isCreateModalVisible} cancelModalCb={closeCreateModal} />
        )}

        {isUpdateModalVisible && (
          <EngagementManageModal
            visible={isUpdateModalVisible}
            cancelModalCb={closeUpdateModal}
            engagementId={engagementId}
            onDeleteCb={() => {
              setIsDeleteModalVisible(true)
              setIsUpdateModalVisible(false)
            }}
          />
        )}
      </Layout>
    </>
  )
}

type EngagementsProps = {
  search: string
  openCreateCb: any
  openUpdateCb: any
}

//--------------------------------------------------------------------------
const Engagements: React.FC<EngagementsProps> = ({ search, openCreateCb, openUpdateCb }) => {
  const [filter, setFilter] = useState<any>({
    additionalFilter: {
      status: { placeholder: 'All Statuses', options: new Set<string>(['Active', 'Inactive']), value: '' },
      managers: { placeholder: 'All Managers', options: new Set<string>([]), value: '' }
    }
  })

  const { data, loading, error } = useQuery<ReadEngagements, ReadEngagementsVariables>(READ_ENGAGEMENTS, {
    variables: { active: true },
    fetchPolicy: 'network-only'
  })

  const items = useMemo(() => {
    return data?.engagements?.length
      ? data?.engagements
          .filter((item) => item.name.includes(search))
          .map((item) => {
            const date = new Date()
            const endDate = new Date(item.endDate)
            endDate.setDate(endDate.getDate() + 1)

            return { ...item, status: +endDate - +date > 0 }
          })
          .filter((item) => {
            const { value } = filter.additionalFilter.status
            return value === '' ? true : value === 'Active' ? item.status : !item.status
          })
          .filter((item) => {
            const value = filter.additionalFilter.managers.value?.trim()
            return value ? value === item.manager.trim() : true
          })
      : []
  }, [search, filter, data])

  useEffect(() => {
    const managers = new Set<string>([])
    data?.engagements?.forEach((item) => {
      if (item.manager && !managers.has(item.manager)) {
        managers.add(item.manager)
      }
    })

    setFilter({
      ...filter,
      additionalFilter: {
        ...filter.additionalFilter,
        managers: { ...filter.additionalFilter.managers, options: managers, value: '' }
      }
    })
  }, [data])

  if (loading) {
    return <Loading />
  }
  if (error) {
    return <Error />
  }
  if (!data) {
    return <p>Not found</p>
  }

  return (
    <div className="engagements-overview">
      <div className="control-filter d-flex justify-content-between filter-section-table m-0">
        <p className="control-filter__title mb-0">Filter options</p>
        <ControlOverviewFilterC filter={filter} setFilter={setFilter} showSearchOnList={false} />
      </div>
      {!data.engagements.length ? (
        <div className={styles.Engagement__emptyContainer}>
          <AuthorizeRole role="admin">
            <h3 className={`mb-2 ${styles.Engagement__subHeader}`}>No Engagements</h3>
            <p className="a-mb-30">There doesn’t appear to be any engagements. Create an engagement now.</p>
            <EngagementCreateButton createCb={openCreateCb} className="a-btn-new" isAddIcon={false}>
              Create Engagement
            </EngagementCreateButton>
          </AuthorizeRole>
        </div>
      ) : (
        <Table
          className={`new-table minHeight`}
          data={items}
          columns={[
            {
              Header: 'Engagement Name',
              accessor: 'name',
              Cell: ({ original }) => (
                <Link
                  className="a-link a-new-link"
                  to={`/engagements/${original.id}/analytics_dashboard?engagementId=${original.id}&engagementName=${original.name}`}
                >
                  {original.name}
                </Link>
              )
            },
            {
              Header: 'Status',
              accessor: 'status',
              sortMethod: (a, b) => {
                return Number(b) - Number(a)
              },
              Cell: ({ original }) =>
                original.status ? (
                  <span className="a-badge a-badge-success">Active</span>
                ) : (
                  <span className="a-badge a-badge-attention">Inactive</span>
                )
            },
            {
              Header: 'Start Date',
              accessor: 'startDate',
              Cell: ({ original }) => <div>{parseDate(original.startDate)}</div>
            },
            {
              Header: 'End Date',
              accessor: 'endDate',
              Cell: ({ original }) => <div>{parseDate(original.endDate)}</div>
            },
            {
              Header: 'Manager',
              accessor: 'manager',
              Cell: ({ original }) => <div>{original?.manager}</div>
            },
            {
              Header: '',
              Cell: ({ original }) => (
                <div className="d-flex justify-content-end align-center">
                  <EditIcon className="pointer" onClick={() => openUpdateCb(original.id, original.name)} />
                  <Link
                    style={{ marginLeft: 18 }}
                    to={`/engagements/${original.id}/analytics_dashboard?engagementId=${original.id}&engagementName=${original.name}`}
                  >
                    <EyeIcon className="pointer" onClick={() => openUpdateCb(original.id, original.name)} />
                  </Link>
                </div>
              )
            }
          ]}
          defaultSorted={[{ id: 'status' }]}
          defaultPageSize={15}
          pageSizeOptions={[5, 15, 20, 25, 50, 100]}
          showPageSizeOptions
        />
      )}
    </div>
  )
}

//--------------------------------------------------------------------------
const EngagementCreateButton: React.FC<any> = ({ createCb, isAddIcon, className, children }) => {
  const isIcon = isAddIcon ?? true

  return (
    <AuthorizeRole role="admin">
      <Button id="createEngagement" className={`${className || ''}`} onClick={() => createCb()}>
        <span className={`appkiticon ${isIcon ? 'icon-plus-outline' : ''} ${styles.Engagement__create_span}`} />
        {children}
      </Button>
    </AuthorizeRole>
  )
}

//--------------------------------------------------------------------------
const CurrentEngagementLink: React.FC<{ engagement; isNewStyle }> = ({ engagement, isNewStyle }) => {
  const client = useApolloClient()

  return (
    <Link
      className={`${isNewStyle ? 'new-link' : ''}`}
      to={`/engagements/${engagement.id}/analytics_dashboard?engagementId=${engagement.id}&engagementName=${engagement.name}`}
      onClick={() => {
        client.writeQuery({ query: READ_CURRENT_ENGAGEMENT, data: { currentEngagement: engagement } })
      }}
    >
      {engagement.name}
    </Link>
  )
}

export default EngagementOverview
