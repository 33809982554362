import React from 'react'
import './StaticPage.scss'
import { Panel } from '@pwc-de/appkit-react'

const Imprint: React.FC = () => (
  <>
    <div className="layout-container__title layout-container__title mb-4">Imprint</div>
    <Panel className="new-table static">
      <div className="static-page">
        <div className="static-page__wrap">
          <p>
            Imprint pursuant to Section 5 of Telemediengesetz (German Telemedia Act) and §§ 2,3 Service Information
            Obligations Ordinance (DL-InfoV) as well as § 55 Interstate Broadcasting Treaty (RStV).
          </p>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">PwC Solutions GmbH</h2>
            <p>Friedrich-Ebert-Anlage 35-37</p>
            <p>60327 Frankfurt am Main</p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">Contact</h2>
            <p>E-Mail: DE_Kontakt@pwc.com</p>
            <p>Phone: +49 69 9585-0</p>
            <p>Fax: +49 69 9585-1000</p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">Location</h2>
            <p>Frankfurt am Main, Germany</p>
            <p>Local Court Frankfurt am Main HRB 1087600</p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">Managing Directors:</h2>
            <p>Olaf Acker</p>
            <p>Rusbeh Hashemian</p>
            <p>Mark Dinko Orlic</p>
            <p>Markus Vehlow</p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">Value Added Tax ID</h2>
            <p>DE312576587</p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">Codes of Conduct:</h2>
            <p>PwC Solutions GmbH is subject to the following Codes of Conduct:</p>
            <ul>
              <li>
                PwC Ethics Principles / PwC Code of Conduct (Download in the German language is accessible via
                www.pwc.de/de/ethikcode)
              </li>
              <li>
                Ten Principles of UN Global Compact (accessible in German and English via http://www.globalcompact.de/)
              </li>
            </ul>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">Extrajudicial Dispute Resolution:</h2>
            <p>
              In accordance with our obligation under Section 36 (1) of the Consumer Dispute Resolution Act (VSBG), we
              declare that we are neither willing nor obliged to participate in any dispute resolution proceedings
              before a consumer arbitration board within the meaning of Section 2 VSGB. In accordance with our
              obligation under the Regulation on Online Dispute Resolution in Consumer Matters, we point out that the EU
              platform for extrajudicial online dispute resolution can be reached under the following link:
              http://ec.europa.eu/consumers/odr/.
            </p>
          </div>
          <p>You can contact us by the following e-mail address DE_Kontakt@pwc.com</p>
        </div>
      </div>
    </Panel>
  </>
)

export default Imprint
