import React, { useMemo } from 'react'

import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Container, Row, Col } from 'react-grid-system'
import { useQuery } from '@apollo/client'
import { Routes, Route, useLocation } from 'react-router-dom'
import parseQueryString from 'query-string'
import {
  BenchmarkManage,
  BenchmarkOverview,
  CategoryOverview,
  CategoryManage,
  CategoryControlOverview,
  CategoryControlAssign,
  ControlOverview,
  ControlManage,
  ConfigImportExportOverview,
  BreadcrumbNav
} from '../index'
import { ReadBenchmark, ReadBenchmarkVariables } from '../../graphql/__generated__/ReadBenchmark'
import { READ_BENCHMARK } from '../../graphql/benchmark'
import { ReadCategory, ReadCategoryVariables } from '../../graphql/__generated__/ReadCategory'
import { READ_CATEGORY } from '../../graphql/category'
import './Content.scss'
import DashboardConfig from '../DashboardConfig/DashboardConfig'
import Redirect from '../Nav/Redirect'
import ConfigSidebar from './ConfigSidebar'
import BenchmarkDetail from '../BenchmarkDetail/BenchmarkDetail'
import Breadcrumbs, { IBredcrumb } from '../Breadcrumbs/Breadcrumbs'
import { pageListDashboard } from '../Dashboard/AnalyticsDashboard'

export type BreadcrumbType = {
  id: any
}

const ContentConfig: React.FC<any> = () => {
  const location = useLocation()
  const queryParams = parseQueryString.parse(location?.search)
  const benchmarkBreadcrumbs = [
    {
      name: 'Benchmarks',
      path: 'benchmarks'
    },
    {
      name: 'Categories',
      path: 'categories'
    },
    {
      name: 'Controls',
      path: 'controls'
    },
    {
      name: 'Benchmark Summary',
      path: 'dashboard'
    },
    {
      name: 'Import/Export',
      path: 'import_export'
    }
  ]

  const controlsBreadcrumbs = [
    {
      name: 'Controls',
      path: 'controls'
    }
  ]

  const breadcrumbs = useMemo(() => {
    if (/^\/configuration/.test(location.pathname)) {
      const bredcrumbs: IBredcrumb[] = [] as IBredcrumb[]

      if (location.pathname === '/configuration/dashboard') {
        bredcrumbs.push({ label: 'Benchmark Summary', url: '/configuration/dashboard' } as IBredcrumb)
      }

      if (/^\/configuration\/cloud\/controls/.test(location.pathname)) {
        bredcrumbs.push({
          label: 'Controls',
          url: `/configuration/cloud/controls`
        } as IBredcrumb)
      } else if (queryParams.cloudSolutionId) {
        bredcrumbs.push({
          label: 'Benchmarks',
          url: `/configuration/cloud/${queryParams.cloudSolutionId}/benchmarks`
        } as IBredcrumb)
      }

      if (queryParams.benchmarkId) {
        bredcrumbs.push({
          label: queryParams.benchmarkName,
          url: `/configuration/cloud/${queryParams.cloudSolutionId}/benchmarks/${queryParams.benchmarkId}/detail?benchmarkId=${queryParams.benchmarkId}&benchmarkName=${queryParams.benchmarkName}`
        } as IBredcrumb)
      }

      if (queryParams.controlId) {
        bredcrumbs.push({
          label: queryParams.controlName,
          url: `/configuration/cloud/${queryParams.cloudSolutionId}/benchmarks/${queryParams.controlsId}/detail?benchmarkId=${queryParams.controlId}&benchmarkName=${queryParams.controlName}`
        } as IBredcrumb)
      }

      return bredcrumbs
    }

    return []
  }, [location, queryParams])

  return (
    <div className="config" style={{ height: '100%' }}>
      <Container className="config__content" fluid style={{ flex: 1, height: '100%', width: '100%' }}>
        <Row style={{ height: '100%' }}>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Routes>
                  <Route path="dashboard" element={<Breadcrumbs items={breadcrumbs} />} />
                  <Route path="cloud/:cloudSolutionId/benchmarks/*" element={<Breadcrumbs items={breadcrumbs} />} />

                  <Route path="cloud/controls/*" element={<Breadcrumbs items={breadcrumbs} />} />
                  <Route path="import_export" element={<Breadcrumbs items={breadcrumbs} />} />
                </Routes>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Routes>
                  <Route path="/" element={<Redirect to="dashboard" />} />
                  <Route path="dashboard" element={<DashboardConfig />} />
                  <Route path="cloud/:cloudSolutionId/benchmarks" element={<BenchmarkOverview />} />
                  <Route path="cloud/:cloudSolutionId/benchmarks/create" element={<BenchmarkManage />} />
                  <Route path="cloud/:cloudSolutionId/benchmarks/:benchmarkId" element={<BenchmarkManage />} />
                  <Route
                    path="cloud/:cloudSolutionId/benchmarks/:benchmarkId/categories"
                    element={<CategoryOverview />}
                  />
                  <Route
                    path="cloud/:cloudSolutionId/benchmarks/:benchmarkId/categories/create"
                    element={<CategoryManage />}
                  />
                  <Route
                    path="cloud/:cloudSolutionId/benchmarks/:benchmarkId/categories/:categoryId"
                    element={<CategoryManage />}
                  />
                  <Route
                    path="cloud/:cloudSolutionId/benchmarks/:benchmarkId/categories/:categoryId/controls"
                    element={<CategoryControlOverview />}
                  />
                  <Route path="cloud/:cloudSolutionId/benchmarks/:benchmarkId/detail" element={<BenchmarkDetail />} />
                  <Route
                    path="cloud/:cloudSolutionId/benchmarks/:benchmarkId/categories/:categoryId/controls/assign"
                    element={<CategoryControlAssign />}
                  />
                  <Route path="cloud/controls/" element={<ControlOverview />} />
                  <Route path="cloud/controls/manage/:controlId" element={<ControlManage />} />
                  <Route path="cloud/controls/manage/" element={<ControlManage />} />
                  <Route path="import_export" element={<ConfigImportExportOverview />} />
                </Routes>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContentConfig
