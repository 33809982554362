import React, { useCallback } from 'react'

import { Select, SelectOption } from '@pwc-de/appkit-react'
import { camelCase, getSolutionById } from '../util'

import type { ControlOverviewFilterProps } from '../../models/types/control'

import './ControlOverviewFilter.scss'

const ControlOverviewFilterC: React.FC<ControlOverviewFilterProps> = ({
  filter,
  setFilter,
  solutions,
  setCloudSolutionId,
  showSearchOnList = true
}) => {
  const onSelectHandler = useCallback(
    (key: string, selectedValue: string) => {
      if (key === 'cloudSolutions') {
        setCloudSolutionId?.(selectedValue)
      }
      filter.additionalFilter[key].value = selectedValue
      setFilter({ ...filter })
    },
    [filter, setCloudSolutionId, setFilter]
  )
  return (
    <div className="control-filter__container">
      {Object.entries(filter.additionalFilter).map(([key, { label, options }]) => {
        const allLabelText = filter?.additionalFilter?.[key]?.placeholder || label

        return (
          <div key={key} className="control-filter__item" style={key === 'cloudSolutions' ? { width: '20rem' } : {}}>
            <Select
              onSelect={(value) => onSelectHandler(key, value)}
              placeholder={allLabelText}
              value={filter.additionalFilter[key].value}
              showSearchOnList={showSearchOnList}
            >
              {key !== 'cloudSolutions' && <SelectOption value="">{allLabelText}</SelectOption>}
              {key === 'cloudSolutions'
                ? Array.from(options.values()).map((value, i) => (
                    <SelectOption key={i + 1} value={value}>
                      {getSolutionById(solutions.cloudSolutions.find(({ id }) => id === value).name)?.icon()}
                      {getSolutionById(solutions.cloudSolutions.find(({ id }) => id === value).name)?.name}
                    </SelectOption>
                  ))
                : Array.from(options.values())
                    .sort((a, b) => {
                      return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' })
                    })
                    .map((value, i) => (
                      <SelectOption key={i + 1} value={value}>
                        {value}
                      </SelectOption>
                    ))}
            </Select>
          </div>
        )
      })}
    </div>
  )
}

ControlOverviewFilterC.defaultProps = {
  setCloudSolutionId: undefined,
  solutions: undefined,
  showSearchOnList: true
}

export default ControlOverviewFilterC
