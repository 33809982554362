import tinygradient from 'tinygradient'
import { Badge } from '@pwc-de/appkit-react'
import React from 'react'
import { Classification, Criticality } from '../graphql/__generated__/globalTypes'

export function getAggregatedClassificationColorByScore(score) {
  switch (score) {
    case score < 99:
      return '#db6161'
    default:
      return '#87b16e'
  }
}

export function getColorByClassification(classification: Classification) {
  switch (classification) {
    case Classification.NOT_APPLICABLE:
      return '#999999'
    case Classification.NOT_COMPLIANT:
      return '#E0301E'
    case Classification.COMPLIANT:
      return '#66BB6A'
    default:
      return '#333333'
  }
}

export function colorGradient(score) {
  // Score should be between 0-100. Number has to be between 0-1.
  const number = Math.min(Math.max(parseInt(score), 0), 100) / 100
  const gradient = tinygradient(['#dc3545', '#f0ad4e', '#28a745'])
  return gradient.rgbAt(number).toHex()
}

export function createBadgeFromRisk(risk: Criticality, size = 'small') {
  switch (risk) {
    case Criticality.CRITICAL:
      return (
        <Badge size={size} type="dark">
          Critical
        </Badge>
      )
    case Criticality.HIGH:
      return (
        <Badge size={size} type="error">
          High
        </Badge>
      )
    case Criticality.MEDIUM:
      return (
        <Badge size={size} type="warning">
          Medium
        </Badge>
      )
    case Criticality.LOW:
      return (
        <Badge size={size} type="success">
          Low
        </Badge>
      )
    default:
      return (
        <Badge size={size} type="light">
          Unknown
        </Badge>
      )
  }
}

export function createBadgeFromScore(risk: number, size = 'small') {
  switch (true) {
    case risk > 90:
      return (
        <Badge size={size} type="error">
          Critical
        </Badge>
      )
    case risk > 70:
      return (
        <Badge size={size} type="error">
          High
        </Badge>
      )
    case risk > 45:
      return (
        <Badge size={size} type="warning">
          Medium
        </Badge>
      )
    case risk > 0:
      return (
        <Badge size={size} type="success">
          Low
        </Badge>
      )
    default:
      return (
        <Badge size={size} type="light">
          Unknown
        </Badge>
      )
  }
}

export function createBadgeFromClassification(classification: Classification, size = 'small') {
  switch (classification) {
    case Classification.COMPLIANT:
      return (
        <Badge size={size} type="success">
          Compliant
        </Badge>
      )
    case Classification.NOT_COMPLIANT:
      return (
        <Badge size={size} type="error">
          Not&nbsp;Compliant
        </Badge>
      )
    default:
      return (
        <Badge size={size} type="light">
          Unknown
        </Badge>
      )
  }
}

export const CriticalityMap = {
  UNKNOWN: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  CRITICAL: 4
}
