import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { Table, Button, Tooltip } from '@pwc-de/appkit-react'
import { Container, Row, Col } from 'react-grid-system'
import { getHeaderProps, getProps } from '../util'
import { Error } from '..'
import { CapabilityStatus } from '../../graphql/__generated__/globalTypes'
import { READ_CLOUD_ACCOUNT } from '../../graphql/account'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import LoadingPanel from '../Status/LoadingPanel'
import AssessmentExport from './AssessmentExport'

type AssessmentOverviewParams = {
  cloudAccountId?: string
}

const AssessmentOverview: React.FC = () => {
  const { cloudAccountId }: Readonly<AssessmentOverviewParams> = useParams()
  const { data, error, loading } = useQuery<ReadCloudAccount, ReadCloudAccountVariables>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId! }
  })

  if (error) {
    return <Error />
  }

  const assessments = data && data.cloudAccount && data.cloudAccount.assessments ? data.cloudAccount.assessments : []

  const columns = [
    {
      Header: 'Date',
      accessor: 'timestamp',
      Cell: ({ original, value }) => (
        <Link to={`${original.id}/results`}>
          {new Date(value).toLocaleString(undefined, { timeZone: 'Europe/Berlin' })}
        </Link>
      )
    },
    {
      Header: 'Benchmark',
      accessor: 'name'
    },
    {
      Header: 'State',
      Cell: ({ original }) => `${Math.round(original.analytics.score)}%`
    },
    {
      Header: 'Control Results',
      accessor: 'numControls',
      width: Math.ceil(window.innerWidth * 0.25),
      Cell: ({ original }) => (
        <Container>
          <Row>
            <Col>{original.analytics.numControls}</Col>
            <Col style={{ color: '#87b16e' }}>{original.analytics.numControlsCompliant}</Col>
            <Col style={{ color: '#db6161' }}>{original.analytics.numControlsNotCompliant}</Col>
            <Col style={{ color: 'gray' }}>{original.analytics.numControlsNotApplicable}</Col>
          </Row>
        </Container>
      ),
      minWidth: 200
    },
    {
      Header: 'Status',
      accessor: 'executionStatus',
      getHeaderProps,
      getProps
    },
    {
      Header: '',
      accessor: 'exportAssessment',
      Cell: ({ original }) => <AssessmentExport assessmentId={original.id} status={original.executionStatus} />,
      getHeaderProps,
      getProps
    }
  ]

  const isRunAssessmentActive = data?.cloudAccount.accountCapability.runAssessments === CapabilityStatus.ACTIVE

  return (
    <LoadingPanel
      title="Assessments"
      renderRight={() =>
        data &&
        !data.cloudAccount.deleted && (
          <Link to="create">
            {isRunAssessmentActive ? (
              <Button id="createAssessment">Create Assessment</Button>
            ) : (
              <Tooltip
                id="permissionDenied"
                tooltipTheme="light"
                content={getTooltipContent(data.cloudAccount.accountCapability.runAssessments)}
                placement="right"
              >
                <Button id="createAssessment" disabled>
                  Create Assessment
                </Button>
              </Tooltip>
            )}
          </Link>
        )
      }
      isLoading={loading}
    >
      <Table data={assessments} columns={columns} resizable defaultSorted={[{ id: 'timestamp', desc: true }]} />
    </LoadingPanel>
  )
}

AssessmentOverview.defaultProps = {
  cloudAccountId: ''
}

function getTooltipContent(accountCapability: CapabilityStatus) {
  switch (accountCapability) {
    case CapabilityStatus.INVALID:
      return "Account doesn't have sufficient priviledges to run the Assessments."
    case CapabilityStatus.NOT_APPLICABLE:
      return "Account doesn't have Subscriptions/ Valid Credentials to run the Assessments."
  }
}

export default AssessmentOverview
