import React, { useState } from 'react'
import { Collapse } from 'react-collapse'
import './CollapseBlock.scss'
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down.svg'

type CollapseBlockProps = {
  label: string
  initialOpened?: boolean
  isBorderBottom?: boolean
  children: any
}

const CollapseBlock: React.FC<CollapseBlockProps> = ({
  label,
  children,
  initialOpened = false,
  isBorderBottom = false
}: CollapseBlockProps) => {
  const [isOpen, setIsOpen] = useState(initialOpened)

  return (
    <div className={`collapse-block ${isBorderBottom ? 'collapse-block--border-bottom' : ''}`}>
      <button className="collapse-block__button" onClick={() => setIsOpen(!isOpen)} type="button">
        <p className="collapse-block__text">{label}</p>
        <ArrowDown className={`collapse-block__icon ${isOpen ? 'collapse-block__icon--up' : ''}`} />
      </button>
      <Collapse isOpened={isOpen}>{children}</Collapse>
    </div>
  )
}

CollapseBlock.defaultProps = {
  initialOpened: false,
  isBorderBottom: false
}

export default CollapseBlock
