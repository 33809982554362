import React, { useMemo } from 'react'
import { Panel, Table } from '@pwc-de/appkit-react'
import { ReadAssessment } from '../../../graphql/__generated__/ReadAssessment'
import { getHeaderProps, getProps, checkNAFromScore } from '../../util'
import { getStatusFromClassification } from '../StatusIndicator'
import { CloudCheckType } from '../../../graphql/__generated__/globalTypes'
import { useFilterContext } from '../Filter/FilterContext'

//--------------------------------------------------------------------------
const SubscriptionScores: React.FC<ReadAssessment> = ({ assessment }) => {
  const { assessmentSubscriptionScores } = useFilterContext().state

  const title = useMemo(
    () => (assessment.cloudCheckType === CloudCheckType.GCP ? 'Project' : 'Subscription'),
    [assessment]
  )

  const columns = [
    {
      Header: 'Status',
      accessor: 'analytics.classification',
      Cell: ({ value }) => <div>{getStatusFromClassification(value)}</div>,
      width: 60
    },
    {
      Header: title,
      accessor: 'subscription.name'
    },
    {
      Header: 'ID',
      accessor: 'subscription.subscriptionId'
    },
    {
      Header: 'Score',
      accessor: 'analytics.score',
      Cell: ({ value }) => <div>{checkNAFromScore(value)}</div>,
      width: 70,
      getHeaderProps,
      getProps
    }
  ]

  return (
    <Panel
      className="top-right new-panel flex-fill"
      title="Multi-Account Subscriptions"
      style={{ marginBottom: '1em' }}
    >
      <Table
        className="new-table"
        data={assessmentSubscriptionScores}
        columns={columns}
        defaultSorted={[{ id: 'subscription.name' }]}
        defaultPageSize={5}
        showPageSizeOptions
      />
    </Panel>
  )
}

export default SubscriptionScores
