import React, { useState } from 'react'
import { Input, Modal, ModalBody, ModalFooter } from '@pwc-de/appkit-react'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'
import EngagementDelete from './EngagementDelete'

//--------------------------------------------------------------------------
const EngagementDeleteModal: React.FC<any> = ({ visible, cancelModalCb, engagementId, engagementName }) => {
  const [input, setInput] = useState('')

  const isValid = input === 'DELETE'

  return (
    <Modal
      className="baseline-modal-showcase form-modal new-modal center-middle"
      visible={visible}
      onCancel={() => cancelModalCb()}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div className="a-panel-title">Delete Engagement</div>
      </ModalHeaderWrapper>
      <ModalBody className="px-3">
        <h2 className="a-mt-20 a-mb-10">{engagementName}</h2>
        <p>Type ‘DELETE’ into the field below to delete this engagement. This action cannot be undone.</p>
        <Input
          className={'fix-first'}
          id="delete"
          placeholder="DELETE"
          value={input}
          onChange={(name: string) => {
            setInput(name)
          }}
        />
      </ModalBody>

      <ModalFooter>
        <EngagementDelete engagementId={engagementId} cancelModalCb={cancelModalCb} isValid={isValid} />
      </ModalFooter>
    </Modal>
  )
}

export default EngagementDeleteModal
