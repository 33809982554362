import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Link } from 'react-router-dom'
import { BenchmarkProps } from './Benchmark'
import { UPDATE_BENCHMARK } from '../../graphql/benchmark'
import { Loading, Error } from '..'
import { UpdateBenchmark, UpdateBenchmarkVariables } from '../../graphql/__generated__/UpdateBenchmark'

const BenchmarkUpdate: React.FC<BenchmarkProps> = ({ benchmark }) => {
  const updateBenchmark = JSON.parse(JSON.stringify(benchmark))
  delete updateBenchmark.benchmarkGroup.__typename
  delete updateBenchmark.benchmarkGroup.id
  delete updateBenchmark.benchmarkSource.__typename
  delete updateBenchmark.benchmarkSource.id

  const [mutate, { loading, error }] = useMutation<UpdateBenchmark, UpdateBenchmarkVariables>(UPDATE_BENCHMARK, {
    variables: updateBenchmark
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Link to="..">
        <Button id="saveBenchmark" onClick={() => mutate()}>
          Save
        </Button>
      </Link>
      <Link to="..">
        <Button gray>Cancel</Button>
      </Link>
    </>
  )
}

export default BenchmarkUpdate
