import React from 'react'
import { Col } from 'react-grid-system'

type DetailRowProps = {
  title: string
  value: string | null | undefined
}

const DetailRow: React.FC<DetailRowProps> = ({ title, value }) => {
  if (value != null) {
    return (
      <Col sm={6}>
        <div className="a-panel--user a-panel shadow-none d-flex flex-column a-bg-f5f justify-content-center align-items-center">
          <div className="a-text-sm">{title}</div>
          <div className="a-text-body a-text-616">{value}</div>
        </div>
      </Col>
    )
  }
  return null
}

export default DetailRow
