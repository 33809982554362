import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import Category from './Category'
import { UPDATE_CATEGORY } from '../../graphql/category'
import { Error, Loading } from '..'
import { UpdateCategory, UpdateCategoryVariables } from '../../graphql/__generated__/UpdateCategory'

type CategoryUpdateProps = {
  category: Category
  children: any
}

export const CategoryUpdate: React.FC<CategoryUpdateProps> = ({ children, category }) => {
  const controlIds = category.controls ? category.controls.map((control: any) => control.id) : []

  const [mutate, { loading, error }] = useMutation<UpdateCategory, UpdateCategoryVariables>(UPDATE_CATEGORY, {
    variables: { ...category, controlIds }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Button id="saveCategory" onClick={() => mutate()}>
      {children}
    </Button>
  )
}

export default CategoryUpdate
