import React from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { Classification } from '../../../graphql/__generated__/globalTypes'
import { useFilterContext } from '../Filter/FilterContext'
import { capitalizeStr } from '../../util'
import './ControlsChart.scss'

type PieChartWrapperProps = {
  chartData: any[]
}

export const PieChartWrapper: React.FC<PieChartWrapperProps> = ({ chartData }) => {
  const { dispatch } = useFilterContext()

  const series = chartData.map((data: any) => data.value)
  const colors = chartData.map((data: any) => data.color)
  const labels = chartData.map((data: any) => data.name)

  const options: ApexOptions = {
    labels,
    legend: {
      show: true,
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: (val, opts) => series[opts.seriesIndex]
    },
    plotOptions: {
      pie: {
        donut: {
          size: '54%'
        }
      }
    },
    colors,
    chart: {
      events: {
        dataPointSelection: (event, chartContext, { dataPointIndex }) => {
          let classification = Classification.NOT_APPLICABLE

          if (dataPointIndex === 0) {
            classification = Classification.COMPLIANT
          } else if (dataPointIndex === 1) {
            classification = Classification.NOT_COMPLIANT
          }

          dispatch({
            type: 'setSelectedControlClassification',
            selectedControlClassification: [classification]
          })
          dispatch({ type: 'applyFilters' })
        }
      }
    },
    tooltip: {
      custom: ({ series: seriesItem, seriesIndex, w }) => {
        const data = {
          value: seriesItem[seriesIndex],
          label: capitalizeStr(w.globals.labels[seriesIndex]),
          color: w.globals.colors[seriesIndex]
        }

        return `
          <p class="apexcharts-tooltip__header">
          <span class="apexcharts-tooltip__color" style="background: ${data.color}"></span>
          <span class="apexcharts-tooltip__text">${data.label}</span></p>
          <p class="apexcharts-tooltip__value">${data.value} Controls</p>
        `
      }
    }
  }

  return (
    <div className="chart-new" style={{ maxWidth: '240px' }}>
      <Chart options={options} series={series} type="donut" />
    </div>
  )
}

type GaugeChartWrapperProps = {
  chartName: string
  chartData: any[]
}

export const GaugeChartWrapper: React.FC<GaugeChartWrapperProps> = ({ chartName, chartData }) => {
  const series = chartData.map((data: any) => data.value)
  const colors = chartData.map((data: any) => data.color)

  const options: ApexOptions = {
    labels: [chartName],
    legend: {
      position: 'bottom'
    },
    plotOptions: {
      radialBar: {
        startAngle: -110,
        endAngle: 110,
        hollow: {
          size: '60%'
        }
      }
    },
    dataLabels: {
      enabled: true
    },
    colors
  }

  return (
    <div className="charts chart-new">
      <Chart options={options} series={series} type="radialBar" />
    </div>
  )
}
