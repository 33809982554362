import gql from 'graphql-tag'

const BenchmarkFields = gql`
  fragment BenchmarkFields on Benchmark {
    id
    name
    benchmarkGroup {
      id
      name
    }
    benchmarkSource {
      id
      name
    }
    version
    benchmarkStatus
    categories {
      id
      name
      controls {
        id
        pluginName
        controlId
        controlName
        description
        remediation
        type
      }
    }
  }
`

export const READ_BENCHMARKS = gql`
  query ReadBenchmarks($cloudSolutionId: ID!) {
    benchmarks(cloudSolutionId: $cloudSolutionId) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`

export const READ_BENCHMARK = gql`
  query ReadBenchmark($id: ID!) {
    benchmark(id: $id) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`

export const DELETE_BENCHMARK = gql`
  mutation DeleteBenchmark($id: ID!, $cloudSolutionId: ID!) {
    deleteBenchmark(value: { id: $id, cloudSolutionId: $cloudSolutionId })
  }
`

export const CREATE_BENCHMARK = gql`
  mutation CreateBenchmark(
    $cloudSolutionId: ID!
    $name: String!
    $benchmarkGroup: BenchmarkGroupInput!
    $benchmarkSource: BenchmarkSourceInput!
    $version: String!
    $benchmarkStatus: BenchmarkStatus!
  ) {
    createBenchmark(
      cloudSolutionId: $cloudSolutionId
      value: { name: $name, benchmarkGroup: $benchmarkGroup, benchmarkSource: $benchmarkSource, version: $version,
        benchmarkStatus: $benchmarkStatus }
    ) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`

export const UPDATE_BENCHMARK = gql`
  mutation UpdateBenchmark(
    $id: ID!
    $name: String!
    $benchmarkGroup: BenchmarkGroupInput!
    $benchmarkSource: BenchmarkSourceInput!
    $version: String!
    $benchmarkStatus: BenchmarkStatus!
  ) {
    updateBenchmark(
      id: $id
      value: { name: $name, benchmarkGroup: $benchmarkGroup, benchmarkSource: $benchmarkSource, version: $version,
        benchmarkStatus: $benchmarkStatus }
    ) {
      ...BenchmarkFields
    }
  }
  ${BenchmarkFields}
`
