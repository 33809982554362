import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Table, Panel } from '@pwc-de/appkit-react'
import { ReadTopIssues, ReadTopIssuesVariables } from '../../graphql/__generated__/ReadTopIssues'
import { READ_TOP_ISSUES } from '../../graphql/top-issues'
import { Loading, Error } from '..'
import { createBadgeFromRisk, CriticalityMap } from '../ClassificationHelper'
import { getHeaderProps, getProps } from '../util'

type TopIssuesCardProps = {
  accountId: string
  benchmarkId: string
  analyticsQuery: any
}

type TopIssuesDisplayProps = {
  assessmentId: string | undefined
}

const TopIssuesDisplay: React.FC<TopIssuesDisplayProps> = ({ assessmentId }) => {
  const { data, loading, error } = useQuery<ReadTopIssues, ReadTopIssuesVariables>(READ_TOP_ISSUES, {
    variables: { assessmentId: assessmentId as string },
    skip: !assessmentId
  })
  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  const columns = [
    {
      Header: 'Control',
      accessor: 'controlName',
      Cell: ({ value }) => <div title={value}>{value}</div>
    },
    {
      Header: 'Score Potential',
      accessor: 'scorePotential',
      Cell: ({ value }) => <>+{Number(value).toFixed(1)}%</>,
      sortMethod: (a, b) => {
        return a - b
      },
      getHeaderProps,
      getProps
    },
    {
      Header: 'Rating',
      accessor: 'controlRating',
      Cell: ({ value }) => <>{createBadgeFromRisk(value)}</>,
      sortMethod: (a, b) => {
        return CriticalityMap[a] - CriticalityMap[b]
      },
      getHeaderProps,
      getProps
    },
    {
      Header: 'Passed Resources',
      accessor: 'passedResources',
      Cell: ({ original, value }) => (
        <>
          {value}/{original.totalResources}
        </>
      ),
      getHeaderProps,
      getProps
    }
  ]
  return (
    <Panel className="top-right new-panel" title="Quick Wins" style={{ marginBottom: '1em' }}>
      <Table
        className="new-table lg-pagination"
        data={data?.topIssues || []}
        columns={columns}
        defaultSorted={[{ id: 'scorePotential', desc: true }]}
        defaultPageSize={5}
        showPageSizeOptions
        resizable
      />
    </Panel>
  )
}

const TopIssuesCard: React.FC<TopIssuesCardProps> = ({ accountId, benchmarkId, analyticsQuery }) => {
  const cloudAccount = analyticsQuery.data.solutionDashboard.cloudAccounts.find(
    (ca) => ca.cloudAccount.id === accountId
  )

  const benchmark = cloudAccount?.benchmarks?.find((b) => b.benchmarkId === benchmarkId)

  useEffect(() => {
    if (!benchmark?.assessmentId) {
      analyticsQuery.refetch()
    }
  }, [benchmark])

  return <TopIssuesDisplay assessmentId={benchmark?.assessmentId} />
}

export default TopIssuesCard
