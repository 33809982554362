import React from 'react'
import './StaticPage.scss'
import { Panel } from '@pwc-de/appkit-react'

const TermOfUse: React.FC = () => (
  <>
    <div className="layout-container__title layout-container__title mb-4">Terms of Use</div>
    <Panel className="new-table static">
      <div className="static-page">
        <div className="static-page__wrap">
          <p>Last updated: 20.02.2023</p>

          <div className="static-page__row">
            <h2 className="static-page__sub-header">1. General | Scope</h2>
            <p className="static-page__sub-p">
              These Terms of Use (&quot;Terms of Use&quot;) apply to all contracts (&quot;User Agreements&quot;) between
              PwC Solutions GmbH (&quot;PwC Solutions GmbH&quot;/ &quot;we&quot;/ &quot;us&quot;) and customers of this
              product (&quot;User&quot;/ &quot;you&quot;) for the use of the services defined below. These Terms of Use
              become part of each User Agreement.
            </p>
            <p className="static-page__sub-p">
              These Terms of Use apply to the exclusion of all other terms and conditions. Deviating, contradictory or
              supplementary general terms and conditions of business shall only become part of a contract of use if we
              have agreed to them in writing.
            </p>
            <p className="static-page__sub-p">
              Insofar as the terms &apos;in writing&apos;, &apos;in written form&apos; or similar terms are used in
              these Terms of Use, these refer to the written form within the meaning of § 126 BGB. The electronic
              exchange of copies, handwritten signed documents as well as documents with a simple electronic signature
              (such as by means of DocuSign or Adobe Sign) shall be sufficient in this respect. Unless otherwise
              expressly stipulated in these Terms of Use, the exchange of simple emails shall not be sufficient.
            </p>
            <p className="static-page__sub-p">
              All notices and declarations that you provide us, in particular notices of termination and setting of
              deadlines, must be provided at least in a text form within the meaning of Section 126b of the German Civil
              Code (BGB) (for clarification: a simple email is sufficient), unless otherwise specified in these Terms of
              Use.
            </p>
            <p className="static-page__sub-p">
              References in these Terms of Use to the applicability of statutory provisions are for clarification
              purposes only. Consequently, the statutory provisions shall apply even if such clarification is not
              provided, unless the statutory provisions are supplemented or expressly excluded in these Terms of Use.
            </p>
            <p className="static-page__sub-p">
              For reasons of feasibility, these Terms of Use refrain from the simultaneous use of feminine, masculine
              and diverse forms of language and use the generic masculine. All personal terms apply equally to all
              genders.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">2. Services | Conclusion of the contract of use</h2>
            <p className="static-page__sub-p">
              CCSI is intended for use by our internal employees only. You may use CCSI to configure clients cloud
              accounts and perform scans for the purpose of assessing and monitoring their security and compliance. You
              may not use CCSI for any other purpose without our prior written consent.
            </p>
            <p className="static-page__sub-p">
              We have the right to downgrade, limit or otherwise modify the Services at any time and without notice.
              Subject to the provisions of Section 8, we shall have no warranty, indemnification, maintenance, support
              or availability obligations with respect to the Services.
            </p>
            <p className="static-page__sub-p">
              We are entitled to engage subcontractors at any time to fulfill our contractual obligations.
            </p>
            <p className="static-page__sub-p">
              We will not store the text of the contract (these Terms of Use and the respective usage contract) after a
              usage contract has been concluded. The contract text is then no longer accessible to you.
            </p>
          </div>

          <div className="static-page__row">
            <h2 className="static-page__sub-header">3. Rights of use | Restrictions of use</h2>
            <p className="static-page__sub-p">
              Under the terms of the User Agreement, we grant you a non-exclusive, non-transferable, non-sublicensable,
              worldwide right to use the Services solely for your personal use during the term of the User Agreement.
            </p>
            <p className="static-page__sub-p">
              You may not (and may not permit any third party to) directly or indirectly: (a) sublicense, sell, resell,
              transfer, assign, distribute, share, lease, rent, externally commercially exploit, outsource, use on a
              timeshare or service bureau basis, or use in an application service provider or managed service provider
              environment, or otherwise generate revenue from the Services; (b) copy the Services onto a public or
              distributed network; (c) modify, adapt, translate or create derivative works based on all or any part of
              the Services (except to the extent permitted by us or as authorized under the Services); (d) modify any
              proprietary rights notices appearing in the Services or any part thereof; (e) use the Services in a manner
              that violates applicable laws and regulations (including export rules and restrictions, national security
              controls and regulations) or use outside the scope of the license described in this Section 3; (f)
              configure the Services to collect the following data: (i) data that is considered sensitive personal data
              or &apos;special categories of data&apos; within the meaning of the General Data Protection Regulation
              (&quot;GDPR&quot;) or applicable national data protection laws; (ii) passwords or other authentication
              data; or (iii) payment or other financial data, biometric data or genetic data (collectively,
              &quot;Illicit Data&quot;); or (g) use the Services to (i) store, download or transmit infringing,
              defamatory or otherwise unlawful or unauthorized material or malicious code or malware; or (ii) engage in
              phishing, spamming, denial of service attacks or other fraudulent or criminal activity; (iii) interfere
              with or disrupt the integrity or performance of any third party system or the offerings or data contained
              therein; (iv) attempt to gain unauthorized access to the Services or systems or networks; or (v) conduct,
              or engage any third party to conduct, penetration testing, vulnerability analysis or other security
              assessments.
            </p>
            <p className="static-page__sub-p">
              You may not reproduce, process, share or publicly display the content of the Services without our written
              consent, except as expressly permitted by features incorporated into Services.
            </p>
            <p className="static-page__sub-p">
              You may not export or re-export, directly or indirectly, any Services or technical data, or any copies,
              parts or products thereof, in violation of any applicable laws and regulations.
            </p>
            <p className="static-page__sub-p">You may use the Services only as described in the Product Information.</p>
            <p className="static-page__sub-p">
              You must keep your credentials confidential and not allow any third party to use your access or our
              services unless we agree to do so. We may attribute any use with your Credentials to you, and you are
              solely responsible for all activities that occur under your account. If you suspect any unauthorized use
              of your Access Data or account, or any breach of your data security, please notify us immediately at the
              following email address: DE_Kontakt@pwc.com.
            </p>
            <p className="static-page__sub-p">
              We may monitor your use of the Services and may prohibit and/or suspend any use of the Services if we
              believe that you are in violation of the provisions of this Section 3.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">4. Responsibilities of the user | User content</h2>
            <p className="static-page__sub-p">
              You represent and warrant that you will use the Services only in full compliance with all applicable laws
              and regulations. You must not engage in any abusive uses when using our Services. If we suspect that these
              terms have been violated, we may take legal action, involve the appropriate authorities, and bring legal
              proceedings against the violators. You agree to cooperate with us in any investigation of your own or any
              third party&apos;s infringement, unless applicable law prevents us from requiring such cooperation from
              you. To the extent consistent with applicable law, we reserve the right to use software, monitoring
              systems or other solutions designed to detect activities that violate these Terms.
            </p>
            <p className="static-page__sub-p">
              You are responsible for obtaining and maintaining, at your own expense, technical equipment and related
              ancillary services necessary to connect to, access, or otherwise use the Services, including, without
              limitation, hardware, software, servers, operating systems, networks, and mobile devices (&quot;Technical
              Equipment&quot;). You can view our Technical Equipment requirements, as amended from time to time, on the
              PwC store.
            </p>
            <p className="static-page__sub-p">
              You are also responsible for the safety of the Technical Equipment and any use of the Technical Equipment
              known or unknown to you.
            </p>
            <p className="static-page__sub-p">
              You are solely responsible for the content of any data displayed, posted, uploaded, stored, shared or
              transmitted by you through or by means of the Services (collectively, &quot;Content&quot;). We are not
              able to control the information you submit while using the Services or to ensure the accuracy of such
              information. We may - without notice and without liability to you - investigate complaints or suspected
              violations of the User Agreement and may take such action with respect to you as we deem appropriate. This
              includes restricting, blocking or terminating your access to the Services.
            </p>
            <p className="static-page__sub-p">
              You shall ensure that the information you deposit in the Services is always up-to-date and correct. In the
              event of any unauthorized use or suspected breach of security or misuse of the Services, you are required
              to notify us immediately.
            </p>
            <p className="static-page__sub-p">
              Your User Content belongs to you. We do not claim to be the rights holder or owner of any User Content.
              You grant us and our subcontractors a limited, personal, transferable, non-exclusive license to use your
              User Content solely for the purpose of providing our Services to you and only to the extent necessary to
              do so. We may also evaluate and use the User Content you provide in an anonymized or aggregated form to
              improve our Services.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">5.Duration | Termination</h2>
            <p className="static-page__sub-p">
              The duration of a user agreement shall commence at the time designated therein.
            </p>
            <p className="static-page__sub-p">
              Each User Agreement will remain in effect indefinitely unless terminated in accordance with the User
              Agreement by either you or us. You may terminate the User Agreement at any time by contacting one of the
              admin users.
            </p>
            <p className="static-page__sub-p">
              By terminating the User Agreement, you agree that your access to the CCSI account will be immediately and
              permanently terminated and all associated data, information, communications, preferences, and content
              (including all of our content) stored on the servers be deleted in accordance with data privacy and
              retention laws.
            </p>
            <p className="static-page__sub-p">
              Your right as well as our right to extraordinary termination of the user contract for good cause remains
              unaffected.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">6. Property rights | User data | IT security | Updates</h2>
            <p className="static-page__sub-p">
              We and our contractors, if any, own and retain all proprietary rights, including copyrights, database
              rights, patents, trade secrets, trademarks and all other intellectual property rights and technical
              solutions in and relating to the Services. You acknowledge that the rights granted under a User Agreement
              do not give you any ownership rights in the Services.
            </p>
            <p className="static-page__sub-p">
              All rights to the content and to data that you provide to us to enable the provision of the Services or
              that you provide during the use of the Services (&quot;User Data&quot;), as well as to data based on or
              derived from the content or your User Data, shall remain with you. You grant us a non-exclusive,
              non-transferable, sub-licensable, unrestricted (in terms of space and content) right to use the Content
              and your User Data solely in connection with the provision of the Services. We are not liable for the
              content or your user data.
            </p>
            <p className="static-page__sub-p">
              We will take reasonable steps to ensure that the security of the Services meets market-based industry
              standards.
            </p>
            <p className="static-page__sub-p">
              We reserve the right to make new versions and updates to the Services, including but not limited to
              changes in the design, operational procedure, technical specifications, systems and other features of the
              Services, at any time and without prior notice.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">7. Data protection notice</h2>
            <p className="static-page__sub-p">
              Your consent to our privacy policy is crucial to our handling of your personal data.
            </p>
            <p className="static-page__sub-p">
              The processing of personal data by CCSI is explained in more detail in Privacy Policy, which you can
              access from the footer menu.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">8. Limitation of Liability | Indemnification</h2>
            <p className="static-page__sub-p">
              Under certain circumstances, you may be entitled to claim damages from us. Our liability in this respect
              is limited as follows:
            </p>
            <p>
              Intent: In cases of intent, fraudulent concealment of a defect or a guarantee of quality as well as claims
              resulting from injury to life, limb or health, we shall be liable in accordance with the rules of
              mandatory statutory law.
            </p>
            <p>
              Gross negligence: In the event of gross negligence, our liability shall be limited to typical, foreseeable
              damages; this limitation of liability shall not apply insofar as damages have been caused by our executive
              employees or legal representatives, as well as in cases of breach of material contractual obligations.
            </p>
            <p>
              Slight negligence: In cases of slight negligence, we shall only be liable in the event of a breach of
              material contractual obligations, and our liability shall be limited to typical, foreseeable damages.
            </p>
            <p>
              Limitation: All claims for damages or reimbursement of expenses due to defects shall become statute-barred
              after one year. This provision shall be without prejudice to § 438 para. 1 sentence 1 no. 1a) BGB. All
              other claims against us for damages or expenses shall become time-barred two years after the claim arises.
              The provision of this clause 10.4. shall not apply in cases where the prerequisites according to clause
              10.1. are fulfilled as well as in cases of gross negligence on our part. In such cases, general statutory
              law shall apply.
            </p>
            <p>
              Independence of the limitations of liability from the cause of the claim: The limitations of liability in
              Sections 8.1. - 8.4. shall apply regardless of whether the loss, liability or damage is due to breach of
              contract, breach of a warranty promise, defect or tort liability, including liability due to negligence or
              misrepresentation, strict liability or strict liability or any other cause of action, to the extent that
              the exclusions/limitations of liability are permitted under applicable law.
            </p>
            <p className="static-page__sub-p">We are released from liability under the following circumstances:</p>
            <p>
              Any User Content posted, distributed, transmitted, originated from, or otherwise made available through
              your CCSI account;
            </p>
            <p>Your use of our services and activities when using your CCSI account.;</p>
            <p>Any breach of these Terms by you; or</p>
            <p>A violation of third-party rights or violations of the law on your part.</p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">9.Assignment | Waiver | Limitation on set-off</h2>
            <p className="static-page__sub-p">
              We may assign our legal position arising from this contractual relationship in whole or in part to third
              parties at any time without notifying you. You may not assign or subcontract any of your rights under this
              Agreement unless we expressly consent to such assignment. Nor may you assign any or all of your rights to
              your CCSI account or to our Services.
            </p>
            <p className="static-page__sub-p">
              If we do not respond to a breach of contract on your part or on the part of a third party, this shall not
              constitute a waiver of rights, even with regard to further or similar breaches of contract. Waivers by us
              are only effective if they are based on a written statement by us, signed by hand and with an effective
              proxy.
            </p>
            <p className="static-page__sub-p">
              You shall only be entitled to set off your own claims against our claims if your claims (a) have been
              legally established; (b) are undisputed; or (c) have been recognized by us.
            </p>
          </div>

          <div className="static-page__row">
            <h2 className="static-page__sub-header">10. Notifications in case of infringement</h2>
            <p className="static-page__sub-p">
              You will respect the intellectual property of us and of third parties and will not infringe the rights
              related thereto. If you believe that content and materials made available through our services infringe
              the intellectual property rights or general personal rights of third parties, please inform us immediately
              via the following email address: DE_Kontakt@pwc.com
            </p>
            <p className="static-page__sub-p">
              When we receive your notification, we may block or delete access to infringing material. We may terminate
              or cancel your CCSI account or right to access our Services due to infringement against us or third
              parties.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">11. Feedback</h2>
            <p className="static-page__sub-p">
              We do not solicit or expect you to provide comments, feedback, recommendations or suggestions to us for
              improving our Services (&quot;Feedback&quot;), but we nonetheless appreciate any suggestions on how we can
              improve our Services. If you do provide Feedback, even if you mark the Feedback as confidential, you grant
              us and our designees the right to use, distribute, reproduce, sublicense, and otherwise disseminate and
              exploit the Feedback as we see fit, without any obligation (including, without limitation, an obligation
              to pay) or restriction of any kind with respect to intellectual property or other rights.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">
              12. Complete Agreement | Severability Clause | Amendments | Updates
            </h2>
            <p className="static-page__sub-p">
              The User Agreement, including these Terms of Use, constitutes the entire agreement between you and us with
              respect to its subject matter and supersedes and excludes all prior written or oral negotiations,
              arrangements, understandings, exercises or agreements between the parties with respect to the subject
              matter hereof.
            </p>
            <p className="static-page__sub-p">
              If any provision of the license agreement, including these terms of use, is or becomes invalid or void in
              whole or in part, this shall not affect the validity of the remaining provisions. In this case, the
              invalid or void provision shall be replaced by a provision that comes as close as possible to what the
              parties would have agreed in a legally permissible manner according to the meaning and purpose of the
              original provision and the contract of use if they had recognized the invalidity or voidness of the
              original provision. If the ineffectiveness or invalidity of a provision is based on a measure of
              performance or time (deadline or date) specified therein, a provision with a legally permissible scope
              that comes closest to the original scope shall replace it. The foregoing shall also apply to any loophole
              in the User Agreement including these Terms of Use not intended by the parties. It is the express
              intention of the parties that this severability clause does not result in a mere reversal of the burden of
              proof, but that § 139 BGB is waived in its entirety.
            </p>
            <p className="static-page__sub-p">
              Amendments or supplements to a contract of use must be made in writing (the exchange of simple emails is
              sufficient). The same applies to agreements to deviate from this written form requirement or to waive it
              completely.
            </p>
            <p className="static-page__sub-p">
              We may amend and/or update these Terms of Use with effect for the future if this is necessary for
              technical, economic or legal reasons. Any amendment to these Terms of Use will be announced to you in text
              form (a simple email is sufficient) at least six (6) weeks before its intended effective date. You may
              agree or object to the amendment before the date it is intended to take effect. You will be deemed to have
              accepted the change if you do not object to the change before the date it is intended to take effect. We
              will expressly inform you of this in the relevant announcement.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">13. Applicable law | Consumer arbitration board</h2>
            <p className="static-page__sub-p">
              Any User Agreement and any dispute or claim (whether contractual or non-contractual) arising out of or in
              connection with the User Agreement or the subject matter or formation thereof shall be governed by the
              mandatory laws of the state in which you have your habitual residence (i.e. those provisions of the law of
              that state which cannot be derogated from by agreement). In all other respects, the law of the Federal
              Republic of Germany shall apply to the exclusion of the United Nations Convention on Contracts for the
              International Sale of Goods (CISG).
            </p>
            <p className="static-page__sub-p">
              The European Commission operates under https://ec.europa.eu/consumers/odr/ an arbitration board for the
              settlement of disputes in connection with online contracts. We are not obligated to participate in a
              dispute resolution procedure before this arbitration board or before any other consumer arbitration board,
              nor do we offer such a procedure.
            </p>
          </div>
        </div>
      </div>
    </Panel>
  </>
)

export default TermOfUse
