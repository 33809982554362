import React, { useState } from 'react'
import Collapsible from 'react-collapsible'

//--------------------------------------------------------------------------
const CollapsibleWrapper: React.FC<{
  usableKey: any
  children: any
  trigger: any
  parentOpen?: any
  parentSetOpen?: any
  transitionTime: number
}> = ({ usableKey, children, trigger, parentOpen, parentSetOpen, transitionTime }) => {
  const [open, setOpen] = useState<Boolean>(false)
  const isOpen = parentSetOpen ? parentOpen : open
  const handleOpen = parentSetOpen ?? setOpen

  const checkCategory =
    localStorage.getItem('openValue') && localStorage.getItem('openValue')!.includes(usableKey) ? true : false
  const lastSearch = localStorage.getItem('inputValue') ? true : false
  const handleCategory = ({ id, action }) => {
    if (action === 'add') {
      localStorage.setItem('openValue', localStorage.getItem('openValue') + id)
    } else {
      localStorage.setItem('openValue', localStorage.getItem('openValue')!.replace(id, ''))
    }
  }

  return (
    <div>
      <Collapsible
        onTriggerOpening={() => handleCategory({ id: usableKey, action: 'add' })}
        onClose={() => {
          handleCategory({ id: usableKey, action: 'remove' })
        }}
        transitionTime={transitionTime}
        trigger={trigger}
        open={checkCategory || lastSearch}
      >
        {children}
      </Collapsible>
    </div>
  )
}

export default CollapsibleWrapper
