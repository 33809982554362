import React, { useState } from 'react'
import { Button, Select, SelectOption, TextArea } from '@pwc-de/appkit-react'
import { useApolloClient, useMutation } from '@apollo/client'
import './ResultControlOverrideStatus.scss'
import { Classification } from '../../graphql/__generated__/globalTypes'
import { OVERRIDE_ASSESSMENT_CONTROL, READ_ASSESSMENT_SUBSCRIPTION_SCORES } from '../../graphql/assessment'
import { Error, Loading } from '..'
import {
  OverrideAssessmentControl,
  OverrideAssessmentControlVariables
} from '../../graphql/__generated__/OverrideAssessmentControl'
import { ReadAssessment_assessment_categories_controls as AssessmentControl } from '../../graphql/__generated__/ReadAssessment'

export class OverrideControlStatus {
  constructor(overridden: boolean, overrideStatus: Classification, overrideReason: string) {
    this.overridden = overridden
    this.overrideStatus = overrideStatus
    this.overrideReason = overrideReason
  }

  overridden: boolean = true

  overrideStatus: Classification = Classification.UNKNOWN

  overrideReason: string = ''
}

//--------------------------------------------------------------------------
export const AssessmentResultControlOverride: React.FC<{
  control: AssessmentControl
  assessmentId: string
}> = ({ control, assessmentId }) => {
  const client = useApolloClient()

  const [overrideState, setOverrideState] = useState<OverrideControlStatus>(
    new OverrideControlStatus(true, control.controlResult.classification, control.overrideReason)
  )

  const [mutate, { loading, error }] = useMutation<OverrideAssessmentControl, OverrideAssessmentControlVariables>(
    OVERRIDE_ASSESSMENT_CONTROL,
    {
      variables: {
        assessmentControlId: control.id,
        overrideAssessmentControl: overrideState
      },
      onCompleted: (data) => {}
    }
  )

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div>
      <p className="a-form-label new-form-label">
        Status<span className="text-danger">*</span>
      </p>
      <div>
        <Select
          className="new-select-list"
          placeholder="Select"
          value={overrideState.overrideStatus}
          onSelect={(overrideStatus: Classification) => {
            setOverrideState({ ...overrideState, overrideStatus })
          }}
        >
          <SelectOption key={Classification.COMPLIANT} value={Classification.COMPLIANT}>
            Compliant
          </SelectOption>
          <SelectOption key={Classification.NOT_COMPLIANT} value={Classification.NOT_COMPLIANT}>
            Not Compliant
          </SelectOption>
          <SelectOption key={Classification.NOT_APPLICABLE} value={Classification.NOT_APPLICABLE}>
            Not Applicable
          </SelectOption>
        </Select>
      </div>
      <div>
        <p className="a-form-label new-form-label">
          Reason<span className="text-danger">*</span>
        </p>
        <TextArea
          className="new-textarea"
          style={{ padding: '0.25rem' }}
          id="overrideReason"
          placeholder="Reason for overriding this Control"
          value={overrideState.overrideReason}
          onChange={(overrideReason: string) => {
            setOverrideState({ ...overrideState, overrideReason })
          }}
        />
      </div>
      <div>
        <div className="control__buttons">
          <Button
            className="a-btn a-btn-primary a-btn-sm a-btn a-btn-new a-btn-primary"
            disabled={
              overrideState.overrideReason === '' ||
              !(
                overrideState.overrideReason !== control.overrideReason ||
                overrideState.overrideStatus !== control.overrideStatus
              )
            }
            kind="transparent"
            size="lg"
            onClick={() => {
              mutate().then(() => {
                client
                  .query({
                    query: READ_ASSESSMENT_SUBSCRIPTION_SCORES,
                    variables: { id: assessmentId },
                    fetchPolicy: 'no-cache'
                  })
                  .then(({ data }) => {})
                  .catch((err) => console.error(err))
              })
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </div>
  )
}
