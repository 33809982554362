import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Table, Button, Banner } from '@pwc-de/appkit-react'
import { Error } from '..'
import { getHeaderProps, getProps } from '../util'
import { ReadCloudAccounts } from '../../graphql/__generated__/ReadCloudAccounts'
import { READ_CLOUD_ACCOUNTS } from '../../graphql/account'
import LoadingPanel from '../Status/LoadingPanel'
import { CloudCheckType, CapabilityStatus } from '../../graphql/__generated__/globalTypes'
import IamExport from './IamExport'
import CloudAccountNavLink from '../Nav/CloudAccountNavLink'

export type IamAccountOverviewProps = {
  engagementId?: string
  cloudSolutionId?: string
  isShowAccessError?: boolean
}

export type IamAccountOverviewParams = {
  engagementId?: string
  cloudSolutionId?: string
}

const IamAccountOverview: React.FC<IamAccountOverviewProps> = ({
  engagementId: engagementIdProps,
  cloudSolutionId: cloudSolutionIdProps,
  isShowAccessError = false,
  children
}) => {
  const {
    engagementId: engagementIdParams,
    cloudSolutionId: cloudSolutionIdParams
  }: Readonly<IamAccountOverviewParams> = useParams()
  const engagementId: any = engagementIdProps || engagementIdParams
  const cloudSolutionId: any = cloudSolutionIdProps || cloudSolutionIdParams

  const { data, loading, error } = useQuery<ReadCloudAccounts>(READ_CLOUD_ACCOUNTS, {
    variables: { cloudSolutionId: cloudSolutionId || '', engagementId: engagementId || '' }
  })

  if (error) {
    return <Error />
  }

  const cloudAccounts =
    data && data.cloudAccounts
      ? data.cloudAccounts.filter((account: any) => account.accountCapability.isIamAccount === CapabilityStatus.ACTIVE)
      : []

  const columns = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ original }) => (
        <>
          <Link to={`${original.id}/groups`}>
            <Button
              id="groupsButton"
              hidden={original.accountCapability.readIamGroups === CapabilityStatus.NOT_APPLICABLE}
              disabled={original.accountCapability.readIamGroups === CapabilityStatus.INVALID}
            >
              Groups
            </Button>
          </Link>
          <Link to={`${original.id}/users`}>
            <Button
              id="usersButton"
              hidden={original.accountCapability.readIamUsers === CapabilityStatus.NOT_APPLICABLE}
              disabled={original.accountCapability.readIamUsers === CapabilityStatus.INVALID}
            >
              Users
            </Button>
          </Link>
          <Link
            to={`${original.id}/roles`}
            onClick={(e) => {
              if (original.type === CloudCheckType.AWS) {
                e.preventDefault()
              }
            }}
          >
            <Button
              id="rolesButton"
              hidden={original.accountCapability.readIamRoles === CapabilityStatus.NOT_APPLICABLE}
              disabled={original.accountCapability.readIamRoles === CapabilityStatus.INVALID}
            >
              Roles
            </Button>
          </Link>
          <IamExport cloudAccountId={original.id} />
        </>
      ),
      getHeaderProps,
      getProps
    }
  ]

  return !isShowAccessError ? (
    <LoadingPanel title="Choose a cloud account" isLoading={loading}>
      {cloudAccounts.length !== 0 ? (
        children ? (
          children
        ) : (
          <Table data={cloudAccounts} columns={columns} defaultSorted={[{ id: 'name' }]} />
        )
      ) : (
        <CloudAccountNavLink />
      )}
    </LoadingPanel>
  ) : (
    <div>
      {data?.cloudAccounts?.length && cloudAccounts.length === 0 && (
        <div className="banner-container">
          <Banner content="Account has insufficient privileges to access IAM" status="warning" />
        </div>
      )}
      {cloudAccounts.length !== 0 &&
        (children ? children : <Table data={cloudAccounts} columns={columns} defaultSorted={[{ id: 'name' }]} />)}
    </div>
  )
}

IamAccountOverview.defaultProps = {
  engagementId: '',
  cloudSolutionId: '',
  isShowAccessError: false
}

export default IamAccountOverview
