import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { Button, Tooltip } from '@pwc-de/appkit-react'
import NewModal from '../Modal/NewModal'
import { ReactComponent as ArchiveControlIcon } from '../../assets/images/icon/archive-control.svg'
import { UPDATE_CONTROL, READ_CONTROL } from '../../graphql/control'
import { UpdateControl, UpdateControlVariables } from '../../graphql/__generated__/UpdateControl'
import { ControlStatus } from '../../graphql/__generated__/globalTypes'

import type { ArchiveControlProps } from '../../models/types/control'

const ControlArchiveButton: React.FC<ArchiveControlProps> = ({ control }) => {
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleClick = () => {
    const _control = { ...control }
    _control.controlStatus = ControlStatus.ARCHIVED
    mutate({ variables: _control })
  }

  const [mutate] = useMutation<UpdateControl, UpdateControlVariables>(UPDATE_CONTROL, {
    refetchQueries: [
      {
        query: READ_CONTROL,
        variables: { id: control.id }
      }
    ]
  })

  return (
    <>
      <NewModal
        title="Archive Control"
        text={`Would you like to archive ${control.controlId} ${control.controlName}?`}
        visibility={{ show: showModal, setShow: setShowModal }}
        buttons={{
          cont: {
            text: 'Archive',
            onClick: handleClick
          },
          cancel: {
            text: 'Cancel'
          }
        }}
      />
      <Button onClick={() => setShowModal(!showModal)}>
        <Tooltip content="Archive" placement="left">
          <ArchiveControlIcon />
        </Tooltip>
      </Button>
    </>
  )
}

export default ControlArchiveButton
