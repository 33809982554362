import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Badge, Table, Tooltip } from '@pwc-de/appkit-react'
import { Error } from '..'
import LoadingPanel from '../Status/LoadingPanel'
import { getAppIDHeader } from '../CloudAccount/CloudAccountOverview'
import { getHeaderProps, getProps } from '../util'
import { CapabilityStatus } from '../../graphql/__generated__/globalTypes'
import { READ_ALL_CLOUD_ACCOUNTS } from '../../graphql/account'
import { ReadAllCloudAccounts } from '../../graphql/__generated__/ReadAllCloudAccounts'
import CloudAccountNavLink from '../Nav/CloudAccountNavLink'

export type AssessmentAccountOverviewParams = {
  engagementId?: string
  cloudSolutionId?: string
}

const AssessmentAccountOverview: React.FC = () => {
  const { engagementId, cloudSolutionId }: Readonly<AssessmentAccountOverviewParams> = useParams()
  const { data, loading, error } = useQuery<ReadAllCloudAccounts>(READ_ALL_CLOUD_ACCOUNTS, {
    variables: { cloudSolutionId: cloudSolutionId || '', engagementId: engagementId || '' }
  })

  if (error) {
    return <Error />
  }

  const { cloudAccounts, appIdHeader } = getAppIDHeader(data)

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ original, value }) => <Link to={`${original.id}/assessments`}>{value}</Link>
    },
    {
      Header: appIdHeader,
      accessor: 'appID'
    },
    {
      Header: 'Account Status',
      accessor: 'deleted',
      Cell: ({ original, value }) => {
        if (value) {
          return (
            <Badge type="error" size="small">
              Deleted
            </Badge>
          )
        }
        return showAccountStatus(original.accountCapability.runAssessments)
      },
      getHeaderProps,
      getProps
    }
  ]

  return (
    <LoadingPanel title="Choose a cloud account" isLoading={loading}>
      {cloudAccounts?.length !== 0 ? (
        <Table data={cloudAccounts} columns={columns} defaultSorted={[{ id: 'name' }]} />
      ) : (
        <CloudAccountNavLink />
      )}
    </LoadingPanel>
  )
}

function showAccountStatus(accountCapability: CapabilityStatus) {
  switch (accountCapability) {
    case CapabilityStatus.ACTIVE: {
      return (
        <Badge type="dark" size="small">
          Active
        </Badge>
      )
    }
    case CapabilityStatus.INVALID: {
      return (
        <Tooltip
          id="permissionDenied"
          tooltipTheme="light"
          content="Account doesn't have sufficient privileges to run the assessments."
          placement="right"
        >
          <Badge type="warning" size="small">
            Invalid
          </Badge>
        </Tooltip>
      )
    }
    case CapabilityStatus.NOT_APPLICABLE: {
      return (
        <Tooltip
          id="invalid"
          tooltipTheme="light"
          content="Account doesn't have subscriptions / valid credentials to run assessments."
          placement="right"
        >
          <Badge type="warning" size="small">
            Invalid
          </Badge>
        </Tooltip>
      )
    }
    default: {
      return (
        <Tooltip
          id="invalid"
          tooltipTheme="light"
          content="Account doesn't have subscriptions / valid credentials to run assessments."
          placement="right"
        >
          <Badge type="warning" size="small">
            Invalid
          </Badge>
        </Tooltip>
      )
    }
  }
}

export default AssessmentAccountOverview
