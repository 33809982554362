import React from 'react'
import './StaticPage.scss'
import { Panel } from '@pwc-de/appkit-react'

const Disclaimer: React.FC = () => (
  <>
    <div className="layout-container__title layout-container__title mb-4">Disclaimer</div>
    <Panel className="new-table static">
      <div className="static-page">
        <div className="static-page__wrap">
          <p>
            The information contained in this Website is for general guidance on matters of interest, and intended for
            the personal use of the reader only. PwC Solutions GmbH (&quot;PwC&quot;) assumes liability for the content
            of its websites in accordance with legal provisions. The information is selected with due care. We have made
            every attempt to ensure that the information contained in this Site has been obtained and arranged with due
            care.
          </p>
          <p>
            PwC accepts no liability for any actions taken as response hereto. The information is provided on the
            understanding that the authors and publishers are not herein engaged in rendering legal, accounting, tax or
            other professional advice or services. As such, it should not be used as a substitute for consultation. PwC
            reserves the right to change or update the information, products or services offered on this website at any
            time without prior notice.
          </p>
          <p>
            Neither the information in this Website nor any further informational material sent to you on request shall
            be deemed to establish a contractual relationship between PwC and yourself.
          </p>
          <p>
            This website contains links to other websites maintained by third parties. Such third-party websites are not
            necessarily presented in a frame on this website. PwC has no control or influence over the content of such
            websites of third parties. The information, commodities and services provided on such sites have neither
            been checked nor approved by PwC in any way. Also, PwC does not issue any advertisement on such third-party
            websites. References and links to third-party websites do not mean that PwC adopts the content behind the
            reference or link as its own. Therefore, PwC does not assume, for whatever legal reason, any responsibility
            for the content of the websites of third parties.
          </p>
          <p>
            Use of the Internet is at the user&apos;s own risk. In particular, PwC is not liable for the technical
            failure of the Internet or access to the Internet.
          </p>
          <p>
            Any relationship arising out of this information shall be governed exclusively by the laws of Germany. The
            exclusive place of jurisdiction for any action or other legal proceedings against PwC arising out of, or in
            connection with, the information contained in this Site shall be Germany.
          </p>
        </div>
      </div>
    </Panel>
  </>
)

export default Disclaimer
