import React, { ReactElement, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import './DashboardConfig.scss'
import { Link } from 'react-router-dom'
import CustomTable, { CustomTableProps } from '../CustomTable/CustomTable'
import { ReadMetricsDashboard } from '../../graphql/__generated__/ReadMetricsDashboard'
import { READ_METRICS_DASHBOARD } from '../../graphql/metrics-dashboard'
import ProgressBarNew from '../Dashboard/ProgressBarNew'
import { getSolutionById } from '../util'
import { getSolutionName } from '../../misc/helperFunctions'

const DashboardConfig: React.FC = () => {
  const response = useQuery<ReadMetricsDashboard>(READ_METRICS_DASHBOARD)

  const getTableData = (data: any, cloudSolutionId: string) =>
    ({
      header: [
        { label: 'Benchmark' },
        { label: 'Source' },
        { label: 'Avg. Assessment Score', style: { gridColumn: '4/7', textAlign: 'right' } },
        { label: 'No. of Assessments', style: { textAlign: 'right' } }
      ],
      data: data.map((item: any) => ({
        row: [
          { component: <span className="a-text-underline">{item.group}</span> },
          { component: item.source },
          {
            component: (
              <div className="dashboard-config__progress-item">
                {item.avgScore > 0 && (
                  <>
                    <ProgressBarNew progressValue={Math.round(item.avgScore)} />{' '}
                    <span className="dashboard-config__progress-text">{Math.round(item.avgScore)}/100</span>
                  </>
                )}
              </div>
            ),
            style: { gridColumn: '4/7', textAlign: 'right' }
          },
          { component: item.assessmentCount, style: { textAlign: 'right' } }
        ],
        subTable: {
          header: [
            { label: 'Benchmark' },
            { label: 'Source' },
            { label: 'Controls (Manual)', style: { textAlign: 'right' } },
            { label: 'Controls (Automatic)', style: { textAlign: 'right' } },
            { label: 'Controls (Total)', style: { textAlign: 'right' } },
            { label: 'No. of Assessments', style: { textAlign: 'right' } }
          ],
          data: item.versions.map((versionItem) => [
            {
              component: (
                <Link
                  to={`/configuration/cloud/${cloudSolutionId}/benchmarks/${versionItem.id}/detail?benchmarkId=${versionItem.id}&benchmarkName=${versionItem.name}&cloudSolutionId=${cloudSolutionId}`}
                >
                  {versionItem.name}
                </Link>
              )
            },
            { component: versionItem.source },
            { component: versionItem.controlCount.manual, style: { textAlign: 'right' } },
            { component: versionItem.controlCount.automatic, style: { textAlign: 'right' } },
            { component: versionItem.controlCount.total, style: { textAlign: 'right' } },
            { component: versionItem.assessmentCount, style: { textAlign: 'right' } }
          ])
        }
      })),
      countCell: 6
    } as CustomTableProps)

  const sectionKeys = useMemo(() => {
    const data: any = { ...(response?.data?.metricsDashboard ?? {}) }
    delete data.__typename
    return Object.keys(data)
  }, [response])

  const getSectionName = (key: string) => {
    switch (key) {
      case 'kubernetes':
        return 'Kubernetes'
      case 'paas':
        return 'IaaS / PaaS'
      default:
        key?.replace(/_/g, ' ')
    }
  }

  return (
    <div>
      {sectionKeys?.map((key: string, i: number) => {
        return (
          <div key={key} className="dashboard-config">
            <div className={`dashboard-config__section ${i > 0 ? ' mt-5' : ''}`}>
              <h1 className="dashboard-config__section-title">{getSectionName(key)}</h1>
              <span className="dashboard-config__section-button" />
            </div>
            {sectionKeys?.length &&
              response?.data?.metricsDashboard?.[key]?.cloudSolutions?.map(
                (item, index): ReactElement => (
                  <div key={`card-${index}`} className="a-panel dashboard-config__card mb-3">
                    <div className="dashboard-config__top-line">
                      <div className="dashboard-config__header">
                        {getSolutionById(item.name)?.icon()}
                        <span>{getSolutionName(item.name)}</span>
                      </div>
                      <div className="dashboard-config__info">
                        <div className="dashboard-config__info-card">
                          <p className="dashboard-config__info-value">{item.assessmentCount}</p>
                          <p className="dashboard-config__info-label">Total amount of performed assessments</p>
                        </div>
                      </div>
                    </div>
                    <CustomTable {...getTableData(item.benchmarks, item.id)} emptyText="No Benchmarks" />
                  </div>
                )
              )}
          </div>
        )
      })}
    </div>
  )
}

export default DashboardConfig
