import React, { useMemo } from 'react'
import './AssessmentPage.scss'
import moment from 'moment'
import { Table, Panel } from '@pwc-de/appkit-react'
import { createBadgeFromClassification, createBadgeFromRisk } from '../ClassificationHelper'
import { ReadSolutionDashboard_solutionDashboard_cloudAccounts_benchmarks } from '../../graphql/__generated__/ReadSolutionDashboard'
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg'
import ProgressBar from './ProgressBar'

type AssessmentItemProps = {
  cloudAccounts: any
  onUpdateBenchmark: any
  benchmark: ReadSolutionDashboard_solutionDashboard_cloudAccounts_benchmarks
}

const AssessmentItem: React.FC<AssessmentItemProps> = ({ cloudAccounts, onUpdateBenchmark, benchmark }) => {
  const results = useMemo(() => {
    if (!cloudAccounts[0]?.assessments?.length) {
      return []
    }

    const filteredAssessments = cloudAccounts[0]?.assessments.filter(
      (assessmentItem) => assessmentItem.name === benchmark.name
    )

    return filteredAssessments
      .sort((prev, next) => (new Date(next.timestamp) as any) - (new Date(prev.timestamp) as any))
      .map((assessmentItem) => {
        const dateStr = new Date(assessmentItem.timestamp)
        return {
          value: assessmentItem.id,
          name: moment(dateStr).format('DD.MM.YYYY HH:mm:ss'),
          score: assessmentItem.analytics.score
        }
      })
  }, [cloudAccounts])

  return (
    <div className="assessment-item">
      <div className="assessment-item__row">
        <div className="assessment-item__top-line">
          <h3 className="assessment-item__title-row">{benchmark.name}</h3>
          <div className="assessment-item__values">
            <div>
              <span className="assessment-item__title">Status</span>
              {benchmark.analytics?.classification ? (
                createBadgeFromClassification(benchmark.analytics?.classification, 'medium')
              ) : (
                <span className="na-value">N/A</span>
              )}
            </div>
            <div>
              <span className="assessment-item__title">Risk Level</span>
              {benchmark?.criticality ? (
                createBadgeFromRisk(benchmark?.criticality, 'medium')
              ) : (
                <span className="na-value">N/A</span>
              )}
            </div>
            <div>
              <span className="assessment-item__title">Latest Score</span>
              {benchmark.analytics.score ? (
                <span className="assessment-item__big-text">
                  <span>{Math.round(benchmark.analytics.score)}</span> / 100
                </span>
              ) : (
                <span className="na-value--big">N/A</span>
              )}
            </div>
          </div>
        </div>
        <Table
          className="new-table"
          data={results}
          columns={[
            {
              Header: 'Assessment Date',
              accessor: 'name',
              headerClassName: 'max-header',
              className: 'max-header',
              Cell: ({ original }) => (
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    onUpdateBenchmark({
                      name: benchmark.name,
                      value: benchmark.benchmarkId,
                      assessmentId: original.value
                    })
                  }
                  aria-hidden="true"
                >
                  <span className="a-text-underline">{original.name} </span>
                  {original.value === results[0].value && <span className="a-badge a-badge-label a-ml-12">Latest</span>}
                </span>
              )
            },
            {
              Header: <div className="text-right w-100">Assessment Score</div>,
              accessor: 'score',
              Cell: ({ original }) =>
                original.score ? (
                  <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() =>
                      onUpdateBenchmark({
                        name: benchmark.name,
                        value: benchmark.benchmarkId,
                        assessmentId: original.value
                      })
                    }
                    aria-hidden="true"
                  >
                    <ProgressBar progressValue={original.score} />
                    <span className="a-ml-12">{Math.round(original.score)} / 100</span>
                  </div>
                ) : (
                  <span className="na-value">N/A</span>
                )
            },
            {
              Header: '',
              headerClassName: 'actions-cell',
              className: 'actions-cell',
              Cell: ({ original }) => (
                <div className="d-flex justify-content-end align-center">
                  <EyeIcon
                    className="pointer mt-2"
                    onClick={() =>
                      onUpdateBenchmark({
                        name: benchmark.name,
                        value: benchmark.benchmarkId,
                        assessmentId: original.value
                      })
                    }
                  />
                </div>
              )
            }
          ]}
          showPagination={false}
        />
      </div>
    </div>
  )
}

type AssessmentPageProps = {
  analyticsQuery: any
  cloudAccounts: any
  onUpdateBenchmark: any
  accountId: string
}

const AssessmentPage: React.FC<AssessmentPageProps> = ({
  analyticsQuery,
  cloudAccounts,
  onUpdateBenchmark,
  accountId
}) => {
  const cloudAccount = useMemo(() => {
    return analyticsQuery.data.solutionDashboard.cloudAccounts.find((ca) => ca.cloudAccount.id === accountId)
  }, [analyticsQuery, accountId])

  return (
    <div className="assessment-page">
      {cloudAccount?.benchmarks ? (
        cloudAccount?.benchmarks?.map((benchmark, index) => (
          <AssessmentItem
            key={index}
            cloudAccounts={cloudAccounts}
            benchmark={benchmark}
            onUpdateBenchmark={onUpdateBenchmark}
          />
        ))
      ) : (
        <Panel className="new-table" title={`Assessments`}>
          <div className="empty-text">
            <h2 className="empty-text__title">No Assessments</h2>
            <p className="empty-text__description">
              There doesn&apos;t appear to be any assessments for this account yet. Select a benchmark and run your
              first assessment.
            </p>
          </div>
        </Panel>
      )}
    </div>
  )
}

export default AssessmentPage
