import gql from 'graphql-tag'

export const READ_TOP_ISSUES = gql`
  query ReadTopIssues($assessmentId: ID!) {
    topIssues(assessmentId: $assessmentId) {
      controlId
      controlName
      scorePotential
      controlRating
      passedResources
      totalResources
    }
  }
`
