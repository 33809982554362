import gql from 'graphql-tag'

export const READ_CURRENT_ENGAGEMENT = gql`
  query currentEngagement {
    currentEngagement @client {
      id
      name
    }
  }
`

export const READ_APP_ERRORS = gql`
  query ReadAppErrors {
    appErrors @client {
      id
      message
    }
  }
`

export const READ_USER_ROLES = gql`
  query ReadUserRoles {
    userRoles @client
  }
`
