import React from 'react'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Panel } from '@pwc-de/appkit-react'
import { Container, Row } from 'react-grid-system'

const Error: React.FC = () => (
  <Panel>
    <Container fluid>
      <Row align="center" justify="center" style={{ height: 220 }}>
        <div className="a-alert-icon-wrapper">
          <span
            className="a-alert-icon appkiticon icon-alert-fill a-icon-error"
            style={{ fontSize: '2em', color: '#c92727' }}
          />
        </div>
        <div className="a-alert-text-content" style={{ paddingLeft: '0.5em' }}>
          Something went wrong.
        </div>
      </Row>
    </Container>
  </Panel>
)

export default Error
