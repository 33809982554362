import gql from 'graphql-tag'

export const EXPORT_CONFIGURATION = gql`
  query ExportConfiguration {
    exportConfiguration
  }
`

export const IMPORT_CONFIGURATION = gql`
  mutation ImportConfiguration($fileContent: String!) {
    importConfiguration(fileContent: $fileContent)
  }
`
