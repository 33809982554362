import React, { useContext, useEffect, useMemo } from 'react'
import {
  ReadAssessment_assessment,
  ReadAssessment_assessment_categories,
  ReadAssessment_assessment_categories_controls
} from '../../../graphql/__generated__/ReadAssessment'
import { Classification, ControlType } from '../../../graphql/__generated__/globalTypes'
import { ReadAssessmentSubscriptionScores_subscriptionScores } from '../../../graphql/__generated__/ReadAssessmentSubscriptionScores'

// eslint-disable-next-line
export enum DIRECTION_SORT {
  // eslint-disable-next-line
  desc = 'desc',
  // eslint-disable-next-line
  asc = 'asc'
}

export interface ISortFilter {
  name: string
  direction: DIRECTION_SORT
}

type State = {
  assessment: ReadAssessment_assessment
  filteredAssessment: ReadAssessment_assessment
  selectedControlClassification: Classification[]
  search: string
  sort: ISortFilter | null
  showCategories: string[]
  selectedCategories: string[]
  selectedControls: string[]
  selectedControlTypes: string[]
  assessmentSubscriptionScores: ReadAssessmentSubscriptionScores_subscriptionScores[]
}

//--------------------------------------------------------------------------
// This method is quite complex and does a few things:
// * It updates the current category selection
// * It handles Select All and Deselect All events for categories, which are indicated by 'appkit-select-component-select-all-key'.
// * It updates the controls such that only controls belonging to selected categories are shown.
// * Controls that are not affected by a new category selection should remain unchanged (i.e. checked or unchecked)
function reducerSetSelectedCategories(state: State, categorySelection: string[]): State {
  const newState = { ...state }

  let newCategorySelection = categorySelection.filter((eIdx) => eIdx !== 'appkit-select-component-select-all-key')

  // Handles Select All / Deselect All by setting up newCategorySelection such that
  // it contains the list of actual categories that should be selected.
  if (categorySelection.includes('appkit-select-component-select-all-key')) {
    // Deselect all
    if (categorySelection.length === newState.assessment.categories.length + 1) {
      newState.selectedCategories = []
      newState.showCategories = []
      newState.selectedControls = []
      return newState
    }

    // Select all
    newCategorySelection = newState.assessment.categories.map((category) => category.id)
  }

  const addedCategories = newCategorySelection.filter((catIdx) => !newState.selectedCategories.includes(catIdx))

  const removedCategories = newState.selectedCategories.filter((catIdx) => !newCategorySelection.includes(catIdx))

  // Keep all controls initially, that are already selected and then
  // add/remove controls for added/removed categories
  let newControls: string[] = [...newState.selectedControls]

  newState.assessment.categories.forEach((category) => {
    // For newly selected categories, add and check all corresponding controls
    if (addedCategories.includes(category.id)) {
      newControls = [...newControls, ...category.controls.map((control) => control.id)]
    }

    // For removed categories, remove all corresponding controls
    if (removedCategories.includes(category.id)) {
      newControls = newControls.filter(
        (ctrlIdx) => category.controls.findIndex((catCtrl) => ctrlIdx === catCtrl.id) === -1
      )
    }
  })

  newState.search = ''
  newState.selectedCategories = [...newCategorySelection]
  newState.showCategories = [...newCategorySelection]
  newState.selectedControls = newControls

  return newState
}

//--------------------------------------------------------------------------
// Creates a deep copy of the current assessment, applies filter and then
// updates the assessment in the parent state
function reducerApplyFilters(state: State, value: string): State {
  const newState = { ...state }
  const assessmentClone: ReadAssessment_assessment = JSON.parse(JSON.stringify(newState.assessment))

  assessmentClone.categories = assessmentClone.categories.sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
  })

  assessmentClone.categories = assessmentClone.categories
    .filter(
      (category) => newState.showCategories.includes(category.id) && newState.selectedCategories.includes(category.id)
    )
    .sort((prev, next) => {
      if (!state?.sort?.name) {
        return 0
      }

      switch (state.sort.name) {
        case 'status': {
          if (prev[state.sort.name] < next[state.sort.name]) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (prev[state.sort.name] > next[state.sort.name]) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }
          return 0
        }

        case 'score': {
          if (
            prev.analytics.classification === Classification.NOT_APPLICABLE ||
            prev.analytics.classification === Classification.UNKNOWN
          ) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (
            next.analytics.classification === Classification.NOT_APPLICABLE ||
            next.analytics.classification === Classification.UNKNOWN
          ) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }

          if (prev.analytics.score < next.analytics.score) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (prev.analytics.score > next.analytics.score) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }
          return 0
        }

        case 'control': {
          if (prev.analytics.numControlsCompliant < next.analytics.numControlsCompliant) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (prev.analytics.numControlsCompliant > next.analytics.numControlsCompliant) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }
          if (prev.analytics.numControlsNotCompliant < next.analytics.numControlsNotCompliant) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (prev.analytics.numControlsNotCompliant > next.analytics.numControlsNotCompliant) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }
          if (prev.analytics.numControlsNotApplicable < next.analytics.numControlsNotApplicable) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (prev.analytics.numControlsNotApplicable > next.analytics.numControlsNotApplicable) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }

          return 0
        }

        default:
          if (prev[state.sort.name] < next[state.sort.name]) {
            return state.sort.direction === DIRECTION_SORT.asc ? -1 : 1
          }
          if (prev[state.sort.name] > next[state.sort.name]) {
            return state.sort.direction === DIRECTION_SORT.asc ? 1 : -1
          }
          return 0
      }
    })

  assessmentClone.categories.forEach((category) => {
    category.controls = category.controls.filter(
      (control) =>
        newState.selectedControls.includes(control.id) &&
        newState.selectedControlTypes.includes(control.type) &&
        newState.selectedControlClassification.includes(control.controlResult.classification)
    )
  })

  if (!state.search && value) {
    state.search = value
    newState.search = value
  }

  if (state.search && state.search !== '') {
    assessmentClone.categories.forEach((category) => {
      category.controls = category.controls.filter(
        (control) =>
          control.controlName?.toLocaleLowerCase().includes(state.search?.toLocaleLowerCase()) ||
          control.controlId?.toLocaleLowerCase().includes(state.search?.toLocaleLowerCase())
      )
    })
  }

  newState.filteredAssessment = assessmentClone

  return newState
}

//--------------------------------------------------------------------------
// Updates control selection and handles Select All / Deselect All.
function reducerSetSelectedControls(state: State, newControlSelection: string[]): State {
  const newState = { ...state }

  const controls: ReadAssessment_assessment_categories_controls[] = newState.assessment.categories
    .filter((category) => newState.selectedCategories.includes(category.id))
    .flatMap((category) => category.controls)

  if (newControlSelection.includes('appkit-select-component-select-all-key')) {
    if (newControlSelection.length === controls.length + 1) {
      newState.selectedControls = []
    } else {
      newState.selectedControls = controls.map((ctrl) => ctrl.id)
    }
  } else {
    newState.selectedControls = newControlSelection
  }

  return newState
}

//--------------------------------------------------------------------------
function reducerSetAssessment(state: State, assessment: ReadAssessment_assessment): State {
  const newState = { ...state }

  newState.assessment = assessment

  return newState
}

function reducerSetAssessmentSubscriptionScores(
  state: State,
  assessmentSubscriptionScores: ReadAssessmentSubscriptionScores_subscriptionScores[]
): State {
  const newState = { ...state }

  newState.assessmentSubscriptionScores = assessmentSubscriptionScores

  return newState
}

function categoryMatchSearch(category: ReadAssessment_assessment_categories, value: string): boolean {
  if (value === '') return true

  if (category.name.toLocaleUpperCase().includes(value.toLocaleLowerCase())) return true

  for (let index = 0; index < category.controls.length; index += 1) {
    if (category.controls[index].controlName?.toLowerCase().includes(value.toLocaleLowerCase())) return true
    if (category.controls[index].controlId?.toLowerCase().includes(value.toLocaleLowerCase())) return true
  }

  return false
}

function reducerSetSearch(state: State, value: string): State {
  const newState = { ...state }
  const { categories } = newState.assessment
  const selectedCategoryObject: any[] = []
  newState.selectedCategories.forEach((selectedCategoriesId) => {
    selectedCategoryObject.push(
      categories.find((category: ReadAssessment_assessment_categories) => category.id === selectedCategoriesId)
    )
  })

  newState.showCategories = selectedCategoryObject
    .filter((category: ReadAssessment_assessment_categories) => categoryMatchSearch(category, value))
    .map((category: ReadAssessment_assessment_categories) => category.id)

  newState.search = value

  return newState
}

const FilterContext = React.createContext<
  | {
      state: State
      dispatch: any
    }
  | undefined
>(undefined)

type Action =
  | { type: 'setAssessment'; assessment: ReadAssessment_assessment }
  | { type: 'applyFilters'; value: string }
  | { type: 'setValue'; value: any }
  | { type: 'setSearch'; value: string }
  | { type: 'setSort'; value: ISortFilter }
  | { type: 'setSelectedCategories'; selectedCategories: string[] }
  | { type: 'setSelectedControlClassification'; selectedControlClassification: Classification[] }
  | { type: 'setSelectedControls'; selectedControls: string[] }
  | { type: 'setSelectedControlTypes'; selectedControlTypes: string[] }
  | {
      type: 'setAssessmentSubscriptionScores'
      assessmentSubscriptionScores: ReadAssessmentSubscriptionScores_subscriptionScores[]
    }

//--------------------------------------------------------------------------
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setAssessment': {
      return reducerSetAssessment(state, action.assessment)
    }
    case 'applyFilters': {
      return reducerApplyFilters(state, action.value)
    }
    case 'setValue': {
      return action.value
    }
    case 'setSelectedCategories': {
      return reducerSetSelectedCategories(state, action.selectedCategories)
    }
    case 'setSelectedControlClassification': {
      return { ...state, selectedControlClassification: action.selectedControlClassification }
    }
    case 'setSearch': {
      return reducerSetSearch(state, action.value)
    }
    case 'setSort': {
      return { ...state, sort: action.value }
    }
    case 'setSelectedControls': {
      return reducerSetSelectedControls(state, action.selectedControls)
    }
    case 'setSelectedControlTypes': {
      return { ...state, selectedControlTypes: action.selectedControlTypes }
    }
    case 'setAssessmentSubscriptionScores': {
      return reducerSetAssessmentSubscriptionScores(state, action.assessmentSubscriptionScores)
    }
  }
}

//--------------------------------------------------------------------------
export const FilterContextProvider: React.FC<{
  assessment: ReadAssessment_assessment
  assessmentSubscriptionScores: ReadAssessmentSubscriptionScores_subscriptionScores[]
}> = ({ children, assessment, assessmentSubscriptionScores }) => {
  const [state, dispatch] = React.useReducer(reducer, {
    assessment,
    filteredAssessment: assessment,
    selectedControlClassification: [],
    search: '',
    sort: null,
    selectedCategories: [],
    showCategories: [],
    selectedControls: [],
    selectedControlTypes: [],
    assessmentSubscriptionScores
  })

  useEffect(() => {
    dispatch({
      type: 'setValue',
      value: {
        assessment,
        filteredAssessment: assessment,
        selectedControlClassification: [],
        search: '',
        sort: null,
        showCategories: [],
        selectedCategories: [],
        selectedControls: [],
        selectedControlTypes: [],
        assessmentSubscriptionScores
      }
    })
    dispatch({ type: 'setSelectedCategories', selectedCategories: ['appkit-select-component-select-all-key'] })
    dispatch({ type: 'setSelectedControlTypes', selectedControlTypes: [ControlType.AUTOMATIC, ControlType.MANUAL] })
    dispatch({
      type: 'setSelectedControlClassification',
      selectedControlClassification: [
        Classification.COMPLIANT,
        Classification.NOT_COMPLIANT,
        Classification.NOT_APPLICABLE
      ]
    })
  }, [assessment])

  useEffect(() => {
    dispatch({ type: 'setAssessment', assessment })
    dispatch({ type: 'applyFilters', value: state.search })
  }, [assessment])

  useEffect(() => {
    dispatch({ type: 'setAssessmentSubscriptionScores', assessmentSubscriptionScores })
  }, [assessmentSubscriptionScores])

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch])
  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
}

export const useFilterContext = () => {
  const context = useContext(FilterContext)
  if (context === undefined) {
    throw new Error('')
  }

  return context
}
