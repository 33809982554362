import React from 'react'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Banner } from '@pwc-de/appkit-react'
import { useApolloClient, useQuery } from '@apollo/client'
import { READ_APP_ERRORS } from '../../graphql/client'
import { ReadAppErrors } from '../../graphql/__generated__/ReadAppErrors'

const AppError: React.FC = () => {
  const { data, loading, error } = useQuery<ReadAppErrors>(READ_APP_ERRORS)
  const client = useApolloClient()
  if (loading) {
    return null
  }
  if (error) {
    return <p>ERROR: {error.message}</p>
  }
  if (!data || !data.appErrors || data.appErrors.length === 0) {
    return null
  }
  const content =
    data.appErrors.length === 1
      ? data.appErrors[0].message
      : `${data.appErrors.length} errors.\n${data.appErrors[data.appErrors.length - 1].message}`
  return (
    <Banner
      key={data.appErrors[data.appErrors.length - 1].id}
      content={content}
      closeable
      onClose={() => {
        // wait for animation to finish
        setTimeout(() => {
          client.writeQuery({ query: READ_APP_ERRORS, data: { appErrors: [] } })
        }, 300)
      }}
      status="negative"
    />
  )
}

export default AppError
