import gql from 'graphql-tag'
import { AssessmentAnalyticsResultFields } from './global'

export const READ_SOLUTION_DASHBOARD = gql`
  query ReadSolutionDashboard($cloudSolutionId: ID!, $engagementId: ID!) {
    solutionDashboard(cloudSolutionId: $cloudSolutionId, engagementId: $engagementId) {
      analytics {
        ...AssessmentAnalyticsResultFields
      }
      cloudAccounts {
        cloudAccount {
          id
          name
        }
        analytics {
          ...AssessmentAnalyticsResultFields
        }
        assessments {
          id
          name
          timestamp
          analytics {
            ...AssessmentAnalyticsResultFields
          }
          criticality
          executionStatus
        }
        benchmarks {
          benchmarkId
          name
          source
          analytics {
            ...AssessmentAnalyticsResultFields
          }
          criticality
          assessmentId
        }
        criticality
      }
      criticality
    }
  }
  ${AssessmentAnalyticsResultFields}
`
