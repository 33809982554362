import React from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { ReactComponent as AzureIcon } from '../assets/images/icon/azure.svg'
import { ReactComponent as AmazonIcon } from '../assets/images/icon/amazon.svg'
import { ReactComponent as GoogleCloudIcon } from '../assets/images/icon/googleCloud.svg'
import { ReactComponent as EKSIcon } from '../assets/images/icon/eks.svg'
import { ReactComponent as AKSIcon } from '../assets/images/icon/aks.svg'
import { ReactComponent as GKEIcon } from '../assets/images/icon/gke.svg'

export const getHeaderProps = () => ({
  style: {
    textAlign: 'end',
    justifyContent: 'flex-end'
  }
})

export const getProps = () => ({
  style: {
    textAlign: 'end',
    justifyContent: 'flex-end'
  }
})

export function parseDate(dateString) {
  return moment(dateString).format('DD.MM.YYYY')
}

export const setStateUrl = (props) => window.localStorage.setItem('stateUrl', JSON.stringify(props))
export const getStateUrl = () => JSON.parse(window.localStorage.getItem('stateUrl') || '{}')

export const getIsPartialStyle = (to, func) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation()
  const isPartiallyCurrent = location.pathname.indexOf(to) > -1
  return func({ isPartiallyCurrent })
}

const AWSTemplate = {
  name: 'AWS',
  acronym: 'AWS',
  fullName : 'AWS',
  icon: (width = 40, marginRight = 20) => (
    <AmazonIcon style={{ width: `${width}px`, marginRight: `${marginRight}px` }} />
  )
}

const AzureTemplate = {
  name: 'Azure',
  acronym: 'AZ',
  fullName : 'Azure',
  icon: (width = 35, marginRight = 25) => <AzureIcon style={{ width: `${width}px`, marginRight: `${marginRight}px` }} />
}

const GCPTemplate = {
  name: 'GCP',
  acronym: 'GCP',
  fullName : 'GCP',
  icon: (width = 35, marginRight = 25) => (
    <GoogleCloudIcon style={{ width: `${width}px`, marginRight: `${marginRight}px` }} />
  )
}

const EKSTemplate = {
  name: 'EKS',
  acronym: 'EKS',
  fullName : 'AWS_EKS',
  icon: (width = 35, marginRight = 25) => <EKSIcon style={{ width: `${width}px`, marginRight: `${marginRight}px` }} />
}

const AKSTemplate = {
  name: 'AKS',
  acronym: 'AKS',
  fullName : 'Azure_AKS',
  icon: (width = 35, marginRight = 25) => <AKSIcon style={{ width: `${width}px`, marginRight: `${marginRight}px` }} />
}

const GKETemplate = {
  name: 'GKE',
  acronym: 'GKE',
  fullName : 'Google_GKE',
  icon: (width = 35, marginRight = 25) => <GKEIcon style={{ width: `${width}px`, marginRight: `${marginRight}px` }} />
}

const defaultTemplate = {
  name: '',
  acronym: '',
  fullName : '',
  icon: () => React.Fragment
}

export const getSolutionById = (id) => {
  const _id = id?.toLowerCase()
  if (_id?.startsWith('aws_eks') || _id?.startsWith('eks')) {
    return EKSTemplate
  } else if (_id?.startsWith('azure_aks') || _id?.startsWith('aks')) {
    return AKSTemplate
  } else if (_id?.startsWith('google_gke') || _id?.startsWith('gke')) {
    return GKETemplate
  } else if (_id?.startsWith('aws')) {
    return AWSTemplate
  } else if (_id?.startsWith('az')) {
    return AzureTemplate
  } else if (_id?.startsWith('gcp')) {
    return GCPTemplate
  } else {
    return defaultTemplate
  }
}

export const camelCase = (string) => {
  const lowerCaseString = string.toLowerCase()
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
}

export const checkNAFromScore = (double) => {
    if (double == 0) {
        return 'N/A'
    } else {
        return Math.round(double) + '/100'
    }
}
export const capitalizeStr = (str) => str.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase())
