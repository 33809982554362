import React, { useState } from 'react'
import { Container } from 'react-grid-system'
import { Input, Modal, ModalBody, ModalFooter } from '@pwc-de/appkit-react'
import { useQuery } from '@apollo/client'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'
import BenchmarkAssessmentDelete from './BenchmarkAssessmentDelete'

//--------------------------------------------------------------------------
const BenchmarkAssessmentDeleteModal: React.FC<any> = ({
  visible,
  cancelModalCb,
  cloudAccountId,
  benchmarkName,
  cloudSolutionId,
  engagementId
}) => {
  const [input, setInput] = useState('')
  const isValid = input === 'DELETE'

  return (
    <Modal
      className="form-modal new-modal center-middle"
      visible={visible}
      onCancel={() => cancelModalCb()}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div className="a-panel-title">Delete Benchmark</div>
      </ModalHeaderWrapper>
      <ModalBody className="px-3">
        <Container fluid>
          <h2 className="a-mt-20 a-mb-10">{benchmarkName}</h2>
          <p>
            Type ‘DELETE’ into the field below to delete all assessments for this benchmark. This action cannot be
            undone.
          </p>
          <Input
            className={'fix-first'}
            id="delete"
            placeholder="DELETE"
            value={input}
            onChange={(name: string) => {
              setInput(name)
            }}
          />
        </Container>
      </ModalBody>

      <ModalFooter>
        <BenchmarkAssessmentDelete
          cloudAccountId={cloudAccountId}
          benchmarkName={benchmarkName}
          cloudSolutionId={cloudSolutionId}
          engagementId={engagementId}
          isValid={isValid}
          cancelModalCb={cancelModalCb}
        />
      </ModalFooter>
    </Modal>
  )
}

export default BenchmarkAssessmentDeleteModal
