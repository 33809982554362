import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { UPDATE_ENGAGEMENT } from '../../graphql/engagement'
import { EngagementModalProps } from './Engagement'
import { Loading, Error } from '..'
import { UpdateEngagement, UpdateEngagementVariables } from '../../graphql/__generated__/UpdateEngagement'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'

//--------------------------------------------------------------------------
const EngagementUpdate: React.FC<EngagementModalProps> = ({
  engagement,
  isCurrentPage = false,
  isValid = true,
  closeModalCb,
  onDeleteCb
}) => {
  /* eslint-disable no-unused-vars */
  const url: string = isCurrentPage ? '' : '../engagements'
  /* eslint-disable no-unused-vars */

  const [mutate, { loading, error }] = useMutation<UpdateEngagement, UpdateEngagementVariables>(UPDATE_ENGAGEMENT, {
    variables: engagement
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div className="flex-between">
      <span
        className="pointer align-center a-text-red"
        onClick={() => {
          onDeleteCb()
        }}
        aria-hidden="true"
      >
        <DeleteIcon className="a-mr-10" style={{ color: 'red' }} /> Delete Engagement
      </span>
      <div className="flex align-center">
        <Button onClick={closeModalCb} className="a-btn a-btn-new a-btn-outline">
          Cancel
        </Button>
        <Button
          className="a-btn a-btn-new a-btn-primary"
          disabled={!isValid}
          onClick={() => {
            mutate()
            closeModalCb()
          }}
        >
          Update Engagement
        </Button>
      </div>
    </div>
  )
}

EngagementUpdate.defaultProps = {
  isCurrentPage: false,
  isValid: true
}

export default EngagementUpdate
