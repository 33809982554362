import React from 'react'
import './ProgressBarNew.scss'

type ProgressBarProps = {
  progressValue: number
}

const ProgressBarNew: React.FC<ProgressBarProps> = ({ progressValue }) => {
  const barStyle = {
    width: `${100 - progressValue}%`
  }

  return (
    <div className="progress-bar-new">
      <span style={barStyle} />
    </div>
  )
}

export default ProgressBarNew
