import React from 'react'
import { Link } from 'react-router-dom'
import './Breadcrumbs.scss'
import { useNavigate } from 'react-router'
import { Tooltip } from '@pwc-de/appkit-react'
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow-config-up.svg'

export interface IBredcrumb {
  label: any
  url: string
  isActive
}

type BreadcrumbsProps = {
  items: IBredcrumb[]
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const navigate = useNavigate()
  const backUrl = items.length === 1 && items[0]?.isActive ? items[0]?.url : items[items.length - 2]?.url
  const content = (item, index, className) => {
    return (
      <div key={index} className={className}>
        {(item.isActive === undefined ? index !== items.length - 1 : item.isActive) ? (
          <>
            <Link key={index} to={item.url}>
              {item.label}
            </Link>
            <span className="breadcrumbs__separate">/</span>
          </>
        ) : (
          <p className="breadcrumbs__current">{item.label}</p>
        )}
      </div>
    )
  }

  return (
    <div className="breadcrumbs">
      {!!backUrl && (
        <button type="button" className="breadcrumbs__back" onClick={() => navigate(backUrl)}>
          <ArrowUpIcon />
        </button>
      )}
      {items.map((item: IBredcrumb, index: number) =>
        item.label?.length <= 30 ? (
          content(item, index, '')
        ) : (
          <Tooltip id={`breadcrumbs-${index}`} tooltipTheme="light" content={item.label} placement="top" key={index}>
            {content(item, index, 'breadcrumbs__cut')}
          </Tooltip>
        )
      )}
    </div>
  )
}

export default Breadcrumbs
