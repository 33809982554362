import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Tooltip, List, ListItemText } from '@pwc-de/appkit-react'
import { useQuery } from '@apollo/client'
import { READ_CURRENT_ENGAGEMENT } from '../../graphql/client'
import UserProfile from './UserProfile'
import { ReactComponent as PwCLogo } from '../../assets/images/pwc-logo.svg'
import { getIsPartialStyle } from '../util'
import { ReactComponent as LogoutIcon } from '../../assets/images/logout.svg'
import { ReactComponent as UsersIcon } from '../../assets/images/user-managment.svg'

const linkStyle = {
  textDecoration: 'none',
  color: 'black',
  paddingRight: '20px',
  fontWeight: 'bolder',
  padding: '10px'
}

const linkStyleActive = {
  textDecoration: 'underline'
}
/* eslint-disable no-unused-vars */
const isPartiallyActive = ({ isPartiallyCurrent }: any) =>
  isPartiallyCurrent ? { ...linkStyle, ...linkStyleActive } : { ...linkStyle }

const isPartiallyActiveEngagement = ({ isPartiallyCurrent }: any) =>
  isPartiallyCurrent ? { ...linkStyle, ...linkStyleActive, background: '#eee' } : { ...linkStyle, background: '#eee' }

const isActiveEngagement = ({ isCurrent }: any) =>
  isCurrent ? { ...linkStyle, ...linkStyleActive, background: '#eee' } : { ...linkStyle, background: '#eee' }

const ActiveEngagement: React.FC = () => {
  const { data } = useQuery(READ_CURRENT_ENGAGEMENT)

  /* eslint-disable no-unused-vars */

  if (data.currentEngagement) {
    return (
      <>
        <Link style={getIsPartialStyle('.', isPartiallyActiveEngagement)} to=".">
          |
        </Link>
        <Link
          style={getIsPartialStyle(
            `/engagements/${data.currentEngagement.id}/analytics_dashboard?engagementId=${data.currentEngagement.id}`,
            isPartiallyActiveEngagement
          )}
          to={`/engagements/${data.currentEngagement.id}/analytics_dashboard?engagementId=${data.currentEngagement.id}`}
        >
          {data.currentEngagement.name}
        </Link>
      </>
    )
  }

  return null
}

type HeaderProps = {
  onLogout: any
  hasAccess: Boolean
  user: UserProfile
  userAdminUrl: string
  isUserAdmin: Boolean
}

type HeaderState = {
  showSearchBar: Boolean
}

class Header extends Component<HeaderProps, HeaderState> {
  linkToUserManagement() {
    const { userAdminUrl } = this.props
    window.open(userAdminUrl, '_blank')
  }

  render() {
    const { onLogout, hasAccess, user, isUserAdmin, children } = this.props
    return (
      <div className="nav-template template-theme-two d-flex flex-column">
        <div>
          <div className="normal-header-container">
            <nav className="a-header-wrapper a-search-header flex-wrap-reverse flex-xl-nowrap h-auto">
              <div className="a-brand-container order-1">
                <Link to="/engagements">
                  <PwCLogo style={{ width: '45px' }} />
                </Link>
                <div className="a-divider mx-3" style={{ height: 30 }} />
                <div style={{ fontSize: '18px' }}>Cloud Compliance and Security Inspector</div>
              </div>
              <div className="a-header-search-container a-lg-order-3 a-sm-order-2 a-self-adjusted-width" />
              <div className="a-actions-container a-lg-order-4 a-sm-order-2">
                <Tooltip
                  content={
                    <LogoutTooltip
                      logout={onLogout}
                      user={user}
                      isUserAdmin={isUserAdmin}
                      linkToUserManagement={this.linkToUserManagement.bind(this)}
                    />
                  }
                  trigger="click"
                  refClassName="a-account-info"
                  className="a-account-options"
                  tooltipTheme="light"
                  clickToClose
                  placement="bottom-end"
                >
                  <Button id="user-email" gray className="profile-open cursor-pointer">
                    {user.username}
                  </Button>
                </Tooltip>
              </div>
            </nav>
          </div>
        </div>
        {children}
      </div>
    )
  }
}

const adminLink = (isUserAdmin, clbk) => (
  <List>
    {isUserAdmin && (
      <ListItemText key="admin-link" onClick={() => clbk()}>
        <UsersIcon className="a-mr-5" /> User Management
      </ListItemText>
    )}{' '}
  </List>
)

const LogoutTooltip = ({ logout, user, isUserAdmin, linkToUserManagement }) => (
  <div className="profile-popup">
    <div className="profile-popup__item profile-popup__item_with-p">
      <span className="a-text-grey text-uppercase">Welcome</span>
      <br />
      <span>{user.username}</span>
    </div>
    <div className="profile-popup__item cursor-pointer">{adminLink(isUserAdmin, linkToUserManagement)}</div>
    <div className="profile-popup__item cursor-pointer" onClick={logout} aria-hidden="true">
      <div className="a-list-item-text">
        <LogoutIcon className="a-mr-5" /> Sign Out
      </div>
    </div>
  </div>
)

export default Header
