import React from 'react'
import { Avatar, Table } from '@pwc-de/appkit-react'
import { Row, Col } from 'react-grid-system'
import { useQuery } from '@apollo/client'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Error } from '..'
import { ReadUser } from '../../graphql/__generated__/ReadUser'
import { READ_USER } from '../../graphql/user'
import LoadingPanel from '../Status/LoadingPanel'
import { GroupDtoFields } from '../../graphql/__generated__/GroupDtoFields'
import { RoleDtoFields } from '../../graphql/__generated__/RoleDtoFields'
import { getStateUrl, parseDate, setStateUrl } from '../util'
import UserDetailRow from './DetailRow'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import { READ_CLOUD_ACCOUNT } from '../../graphql/account'
import ShowIamData from '../Iam/ShowIamData'

export class User {
  name: string = ''

  id: string = ''

  userType: string = ''

  tenantId?: string

  created?: string

  passwordAssigned?: boolean

  passwordLastChanged?: string

  platform?: string

  groups?: GroupDtoFields[]

  roles?: RoleDtoFields[]
}

type UserDetailsParams = {
  cloudAccountId?: string
  userId?: string
}

const UserDetails: React.FC = () => {
  const { cloudAccountId, userId }: Readonly<UserDetailsParams> = useParams()
  const { userName } = getStateUrl()
  const cloudAccountData = useQuery<ReadCloudAccount, ReadCloudAccountVariables>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId! }
  })

  const { data, loading, error } = useQuery<ReadUser>(READ_USER, {
    variables: {
      cloudAccountId,
      userId,
      userName
    }
  })

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const engagementId = queryParams.get('engagementId')
  const solutionId = queryParams.get('cloudSolutionId')
  const accountId = queryParams.get('accountId')

  if (error) {
    return <Error />
  }

  const user = data && data.user ? data.user : new User()

  const columnsGroup = [
    {
      Header: 'Group',
      accessor: 'name',
      Cell: (row) => (
        <Link to={`../../groups/${row.original.id}`} onClick={() => setStateUrl({ groupName: row.value })}>
          {row.value}
        </Link>
      )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Description',
      accessor: 'description'
    }
  ]
  let groupsTable
  if (cloudAccountData.data && cloudAccountData.data.cloudAccount) {
    groupsTable = (
      <ShowIamData
        capabilityStatus={cloudAccountData.data.cloudAccount.accountCapability.readIamGroups}
        data={user.groups}
      >
        <Row>
          <Col>
            <div className="a-py-10 border-top a-border-light a-mt-10">
              <Table data={user.groups} columns={columnsGroup} defaultSorted={[{ id: 'name' }]} defaultPageSize={10} />
            </div>
          </Col>
        </Row>
      </ShowIamData>
    )
  }

  const columnsRole = [
    {
      Header: 'Role',
      accessor: 'name',
      Cell: (row) => (
        <Link
          to={`/engagements/${engagementId}/cloud/${solutionId}/iam_accounts/${accountId}/roles/${row.original.id}${location.search}`}
        >
          {row.value}
        </Link>
      )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Description',
      accessor: 'description'
    }
  ]
  let rolesTable
  if (cloudAccountData.data && cloudAccountData.data.cloudAccount.accountCapability) {
    rolesTable = (
      <ShowIamData
        capabilityStatus={cloudAccountData.data.cloudAccount.accountCapability.readIamRoles}
        data={user.roles}
      >
        <Row>
          <Col>
            <div className="a-py-10 border-top a-border-light a-mt-10">
              <Table data={user.roles} columns={columnsRole} defaultSorted={[{ id: 'name' }]} defaultPageSize={10} />
            </div>
          </Col>
        </Row>
      </ShowIamData>
    )
  }

  return (
    <LoadingPanel title="User Details" isLoading={loading}>
      <Row>
        <Col sm={12}>
          <Avatar
            size="middle"
            icon={<span className="appkiticon icon-person-outline" />}
            className="a-mr-10"
            label=""
          />
        </Col>
        <Col sm={12}>
          <div className="a-text-heading a-mt-20">{user.name}</div>
        </Col>
        <Col sm={12}>
          <div className="a-text-caption a-text-9e9">{user.id}</div>
        </Col>
      </Row>
      <Row>
        <UserDetailRow title="User Type" value={user.userType} />
        <UserDetailRow title="Tenant" value={user.tenantId} />
        <UserDetailRow title="Created" value={user.created} />
        <UserDetailRow title="Password assigned" value={user.passwordAssigned ? String(user.passwordAssigned) : null} />
        <UserDetailRow
          title="Password last changed"
          value={user.passwordLastChanged ? parseDate(user.passwordLastChanged) : null}
        />
        <UserDetailRow title="Platform" value={user.platform} />
        <UserDetailRow
          title="Groups"
          value={user.groups && user.groups.length > 0 ? String(user.groups.length) : null}
        />
        <UserDetailRow title="Roles" value={user.roles && user.roles.length > 0 ? String(user.roles.length) : null} />
      </Row>
      {groupsTable}
      {rolesTable}
    </LoadingPanel>
  )
}

export default UserDetails
