import React, { useMemo, useState } from 'react'
import { Select, SelectOption } from '@pwc-de/appkit-react'
import moment from 'moment/moment'
import './AccountInfoCard.scss'
import { useNavigate } from 'react-router'
import { createBadgeFromClassification, createBadgeFromRisk } from '../ClassificationHelper'
import AssessmentManageModal from '../Assessment/AssessmentManageModal'
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg'
import EngagementManageModal from '../Engagement/EngagementManageModal'
import CloudAccountManageModal from '../CloudAccount/CloudAccountManageModal'
import EngagementDeleteModal from '../Engagement/EngagementDeleteModal'
import AssessmentExport from '../Assessment/AssessmentExport'
import { Classification } from '../../graphql/__generated__/globalTypes'
import { capitalizeStr } from '../util'
import CloudAccountDeleteModal from '../CloudAccount/CloudAccountDelete'

const CardAccountType = {
  default: 'default',
  score: 'score'
}

interface ICardItem {
  /* eslint-disable-next-line react/no-unused-prop-types */
  label: string
  /* eslint-disable-next-line react/no-unused-prop-types */
  value: any
  /* eslint-disable-next-line react/no-unused-prop-types */
  type: any
  /* eslint-disable-next-line react/no-unused-prop-types */
  components: any
}

type AccountInfoCardProps = {
  solutionId?: string
  solutionName?: string
  accountId?: string
  accountName?: string
  benchmarkId?: string
  benchmarkName?: string
  control?: any
  engagementQuery?: any
  masterDashboardQuery?: any
  analyticsQuery?: any
  engagementId?: string
  engagementName?: string
  pageActive?: any
  benchmarkDetail?: any
  pageList?: any[]
  results?: any[]
  assessmentSelected?: any
  setAssessmentSelected?: any
  onChangePage?: any
}

const AccountInfoCard: React.FC<AccountInfoCardProps> = ({
  solutionId,
  solutionName,
  accountId,
  accountName,
  benchmarkId,
  benchmarkName,
  control,
  engagementQuery,
  masterDashboardQuery,
  analyticsQuery,
  engagementName,
  engagementId,
  benchmarkDetail,
  pageActive,
  pageList,
  results,
  assessmentSelected,
  setAssessmentSelected,
  onChangePage
}) => {
  const [isAssessmentModalVisible, setIsAssessmentModalVisible] = useState(false)
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false)
  const isCreateAssessment = useMemo(() => solutionId && accountId, [solutionId, accountId])
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isConnectCloudAccountModalVisible, setIsConnectCloudAccountModalVisible] = useState(false)
  const [cloudSolutionActive, setCloudSolutionActive] = useState(null)
  const navigate: any = useNavigate()

  const accountData = useMemo(() => {
    if (engagementId && !solutionId && !accountId && !benchmarkId) {
      const engagement = engagementQuery.data?.engagement

      return {
        name: (
          <span className="align-center">
            {engagement?.name || engagementName}{' '}
            <EditIcon className="account-info-card__pointer" onClick={() => setIsUpdateModalVisible(true)} />
          </span>
        ),
        items: [
          {
            label: 'Status',
            value: engagement?.active,
            components: <span className="a-badge a-badge-white">{engagement?.active ? 'Active' : 'Not Active'}</span>
          },
          {
            label: 'Start Date',
            value: engagement?.startDate,
            components: (
              <span className="a-badge a-badge-white">{moment(engagement?.startDate).format('DD.MM.YYYY')}</span>
            )
          },
          {
            label: 'End Date',
            value: engagement?.endDate,
            components: (
              <span className="a-badge a-badge-white">{moment(engagement?.endDate).format('DD.MM.YYYY')}</span>
            )
          },
          {
            label: 'Manager',
            value: engagement?.manager,
            components: engagement?.manager ? (
              <span className="a-badge a-badge-white">{engagement?.manager}</span>
            ) : (
              'N/A'
            )
          },
          {
            label: 'Contact',
            value: engagement?.clientContact,
            components: engagement?.clientContact ? (
              <span className="a-badge a-badge-white">{engagement?.clientContact}</span>
            ) : (
              'N/A'
            )
          }
        ] as ICardItem[]
      }
    } else if (solutionId && !accountId && !benchmarkId) {
      const cloudSolution = masterDashboardQuery.data.masterDashboard.cloudSolutions.find((cs) => cs.id === solutionId)
      const analytics = analyticsQuery?.data?.solutionDashboard?.analytics

      return {
        name: cloudSolution?.name || solutionName,
        items: [
          {
            label: 'Status',
            type: CardAccountType.default,
            value: analytics?.classification,
            components: analytics?.classification
              ? createBadgeFromClassification(analytics?.classification, 'medium')
              : 'N/A'
          },
          {
            label: 'Risk Level',
            type: CardAccountType.default,
            value: analyticsQuery?.data?.solutionDashboard?.criticality,
            components: analyticsQuery?.data?.solutionDashboard?.criticality
              ? createBadgeFromRisk(analyticsQuery?.data?.solutionDashboard?.criticality, 'medium')
              : 'N/A'
          },
          {
            label: 'Overall Score',
            type: CardAccountType.score,
            value: analytics?.score,
            components: analytics?.score ? (
              <span className="account-info-card__score-value">
                <span>{Math.round(analytics?.score)}</span> / 100
              </span>
            ) : (
              'N/A'
            )
          }
        ] as ICardItem[]
      }
    } else if (solutionId && accountId && !benchmarkId) {
      const cloudSolution = masterDashboardQuery.data.masterDashboard.cloudSolutions.find((cs) => cs.id === solutionId)
      const cloudAccount = analyticsQuery.data.solutionDashboard.cloudAccounts.find(
        (ca) => ca.cloudAccount.id === accountId
      )

      return {
        name: (
          <span className="align-center">
            {cloudAccount?.cloudAccount?.name || accountName}{' '}
            <EditIcon
              className="account-info-card__pointer"
              onClick={() => {
                setCloudSolutionActive({ ...cloudSolution, type: cloudSolution.name.toUpperCase() })
                setIsConnectCloudAccountModalVisible(true)
              }}
            />
          </span>
        ),
        items: [
          {
            label: 'Status',
            type: CardAccountType.default,
            value: cloudAccount?.analytics?.classification,
            components: cloudAccount?.analytics?.classification
              ? createBadgeFromClassification(cloudAccount?.analytics?.classification, 'medium')
              : 'N/A'
          },
          {
            label: 'Risk Level',
            type: CardAccountType.default,
            value: cloudAccount?.criticality,
            components: cloudAccount?.criticality ? createBadgeFromRisk(cloudAccount?.criticality, 'medium') : 'N/A'
          },
          {
            label: 'Overall Score',
            type: CardAccountType.score,
            value: cloudAccount?.analytics?.score,
            components: cloudAccount?.analytics?.score ? (
              <span className="account-info-card__score-value">
                <span>{Math.round(cloudAccount?.analytics?.score)}</span> / 100
              </span>
            ) : (
              'N/A'
            )
          }
        ] as ICardItem[]
      }
    } else if (solutionId && accountId && benchmarkId && !control) {
      const cloudAccount = analyticsQuery.data.solutionDashboard.cloudAccounts.find(
        (ca) => ca.cloudAccount.id === accountId
      )

      const benchmark = cloudAccount?.benchmarks?.find((b) => b.benchmarkId === benchmarkId)

      return {
        name: benchmark?.name || benchmarkName,
        items: [
          {
            label: 'Status',
            type: CardAccountType.default,
            value: benchmark?.analytics?.classification,
            components: benchmark?.analytics?.classification
              ? createBadgeFromClassification(benchmark?.analytics?.classification, 'medium')
              : 'N/A'
          },
          {
            label: 'Risk Level',
            type: CardAccountType.default,
            value: benchmark?.criticality,
            components: benchmark?.criticality ? createBadgeFromRisk(benchmark?.criticality, 'medium') : 'N/A'
          }
        ] as ICardItem[]
      }
    } else if (solutionId && accountId && benchmarkId && control) {
      const score =
        control.controlResult.classification === Classification.NOT_APPLICABLE ||
        control.controlResult.classification === Classification.UNKNOWN
          ? null
          : control.controlResult.score

      return {
        name: `${control.controlId} ${control.controlName}`,
        items: [
          {
            label: 'Type',
            type: CardAccountType.default,
            value: control?.type,
            components: <span className="a-badge a-badge-white">{capitalizeStr(control?.type)}</span>
          },
          {
            label: 'Status',
            type: CardAccountType.default,
            value: control?.controlResult?.classification,
            components: control?.controlResult?.classification
              ? createBadgeFromClassification(control.controlResult.classification, 'medium')
              : 'N/A'
          },
          {
            label: 'Risk Level',
            type: CardAccountType.default,
            value: control?.controlRating,
            components: control?.controlRating ? createBadgeFromRisk(control?.controlRating as any) : 'N/A'
          },
          {
            label: 'Score',
            type: CardAccountType.score,
            value: score,
            components:
              score !== null ? (
                <span className="account-info-card__score-value">
                  <span>{Math.round(score)}</span> / 100
                </span>
              ) : (
                'N/A'
              )
          },
          {
            label: 'State',
            type: CardAccountType.default,
            value: control?.controlStatus,
            components: <span className="a-badge a-badge-white">{capitalizeStr(control?.controlStatus)}</span>
          }
        ] as ICardItem[]
      }
    } else if (benchmarkDetail) {
      return {
        name: benchmarkDetail.name,
        items: [
          {
            label: 'Type',
            type: CardAccountType.default,
            value: benchmarkDetail.version,
            components: <span className="a-badge a-badge-attention">{benchmarkDetail.version}</span>
          },
          {
            label: 'Publication Date',
            type: CardAccountType.default,
            value: moment(new Date()).format('DD.MM.YYYY'),
            components: new Date() ? (
              <span className="a-badge a-badge-white">{moment(new Date()).format('DD.MM.YYYY')}</span>
            ) : (
              'N/A'
            )
          },
          {
            label: 'LAST EDITED',
            type: CardAccountType.default,
            value: true,
            components: <span className="a-badge a-badge-white">Today</span>
          },
          {
            label: 'REFERENCE DOCUMENT',
            type: CardAccountType.default,
            value: true,
            components: <span className="account-info-card__link">Link Document</span>
          }
        ] as ICardItem[]
      }
    }
  }, [accountId, analyticsQuery])

  const description = useMemo(() => {
    if (control?.description) {
      return control?.description
    }

    return null
  }, [control])

  const openAssessmentModal = () => {
    setIsAssessmentModalVisible(true)
  }

  const closeAssessmentModal = () => {
    setIsAssessmentModalVisible(false)
  }

  const [isDeleteCloudAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false)

  return (
    <div className="account-info-card">
      <div className="account-info-card__top-line">
        <h2 className="account-info-card__header">{accountData?.name}</h2>
        {isCreateAssessment && (
          <div className="account-info-card__right-control">
            {solutionId && accountId && !benchmarkId && (
              <Select
                className="new-select-list account-info-card__page-list"
                value={pageActive}
                onSelect={onChangePage}
              >
                {pageList?.map((page) => (
                  <SelectOption key={page.value} value={page.value}>
                    {page.name}
                  </SelectOption>
                ))}
              </Select>
            )}
            {solutionId && accountId && benchmarkId && assessmentSelected?.value && !control && (
              <>
                <div className="account-info-card__benchmark-list">
                  <Select
                    className="new-select-list account-info-card__benchmark-list"
                    value={assessmentSelected?.value}
                    onSelect={setAssessmentSelected}
                  >
                    {results?.map((page) => (
                      <SelectOption key={page.value} value={page.value}>
                        {page.name}
                      </SelectOption>
                    ))}
                  </Select>
                </div>
                <div className="account-info-card__upload-btn">
                  <AssessmentExport
                    assessmentId={assessmentSelected.value}
                    status={assessmentSelected.executionStatus}
                    isIcon
                  />
                </div>
              </>
            )}
            {!control && (
              <button
                type="button"
                className="a-btn a-btn-new a-btn-primary account-info-card__btn"
                onClick={() => openAssessmentModal()}
              >
                Run Assessment
              </button>
            )}

            {isAssessmentModalVisible && solutionId && accountId && engagementId && (
              <AssessmentManageModal
                visible
                cancelModalCb={closeAssessmentModal}
                cloudSolutionId={solutionId}
                cloudAccountId={accountId}
                engagementId={engagementId}
                selectedBenchmark={{ benchmarkId, name: benchmarkName }}
              />
            )}
          </div>
        )}
      </div>
      <div className="account-info-card__info">
        {accountData?.items?.map(({ label, value, type, components }: ICardItem, index) => {
          switch (type) {
            case CardAccountType.score:
              return (
                <div className="account-info-card__info-item" key={index}>
                  <span className="account-info-card__info-header">{label}</span>
                  <span
                    className={`account-info-card__info-value account-info-card__info-value--big-text ${
                      value !== null && 'na-value--big'
                    }`}
                  >
                    {components}
                  </span>
                </div>
              )
            case CardAccountType.default:
            default:
              return (
                <div className="account-info-card__info-item" key={index}>
                  <span className="account-info-card__info-header">{label}</span>
                  <span className={`account-info-card__info-value ${!value && 'na-value'}`}>{components}</span>
                </div>
              )
          }
        })}
      </div>
      {description && <div className="account-info-card__description">{description}</div>}

      {isUpdateModalVisible && (
        <EngagementManageModal
          visible={isUpdateModalVisible}
          isCurrentPage
          cancelModalCb={() => setIsUpdateModalVisible(false)}
          onDeleteCb={() => {
            setIsUpdateModalVisible(false)
            setIsDeleteModalVisible(true)
          }}
          engagementId={engagementId}
        />
      )}

      {isDeleteModalVisible && (
        <EngagementDeleteModal
          visible={isDeleteModalVisible}
          cancelModalCb={() => setIsDeleteModalVisible(false)}
          engagementId={engagementId}
          engagementName={engagementName}
        />
      )}

      {isConnectCloudAccountModalVisible && (
        <CloudAccountManageModal
          visible
          isShowLoader={false}
          cloudSolution={cloudSolutionActive}
          engagementId={engagementId}
          cloudAccountId={accountId}
          deleteModalCb={() => {
            setIsConnectCloudAccountModalVisible(false)
            setIsDeleteAccountModalVisible(true)
          }}
          cancelModalCb={() => {
            setIsConnectCloudAccountModalVisible(false)
            setCloudSolutionActive(null)
          }}
        />
      )}

      {isDeleteCloudAccountModalVisible && (
        <CloudAccountDeleteModal
          id={accountId}
          engagementId={engagementId}
          cloudAccountName={accountName}
          cloudSolutionId={solutionId}
          deleteCb={() => {
            setIsDeleteAccountModalVisible(false)
            navigate(
              `engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}`
            )
          }}
          closeModalCb={() => setIsDeleteAccountModalVisible(false)}
        />
      )}
    </div>
  )
}

export default AccountInfoCard
