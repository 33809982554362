import gql from 'graphql-tag'

const CloudSolutionsMetricFields = gql`
  fragment CloudSolutionsMetricFields on CloudSolutionMetricsResultOutput {
    id
    name
    benchmarks {
      group
      source
      assessmentCount
      avgScore
      versions {
        id
        name
        source
        controlCount {
          total
          manual
          automatic
        }
        assessmentCount
      }
    }
    assessmentCount
  }
`

export const READ_METRICS_DASHBOARD = gql`
  query ReadMetricsDashboard {
    metricsDashboard {
      paas {
        cloudSolutions {
          ...CloudSolutionsMetricFields
        }
      }
      kubernetes {
        cloudSolutions {
          ...CloudSolutionsMetricFields
        }
      }
    }
  }
  ${CloudSolutionsMetricFields}
`
