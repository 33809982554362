import React from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import FileSaver from 'file-saver'
import { EXPORT_IAM_ACCOUNT_CSV } from '../../graphql/account'
import { ExportIamAccountCsv, ExportIamAccountCsvVariables } from '../../graphql/__generated__/ExportIamAccountCsv'

type IamExportProps = {
  cloudAccountId: string
}

const IamExport: React.FC<IamExportProps> = ({ cloudAccountId }) => {
  const [createAndSaveExport, { loading }] = useLazyQuery<ExportIamAccountCsv, ExportIamAccountCsvVariables>(
    EXPORT_IAM_ACCOUNT_CSV,
    {
      variables: { cloudAccountId: cloudAccountId! },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data && data.exportIamAccount) {
          saveFile(
            data.exportIamAccount.usersExportOutput.encodedFile,
            data.exportIamAccount.usersExportOutput.fileName
          )
          saveFile(
            data.exportIamAccount.groupsExportOutput.encodedFile,
            data.exportIamAccount.groupsExportOutput.fileName
          )
          saveFile(
            data.exportIamAccount.rolesExportOutput.encodedFile,
            data.exportIamAccount.rolesExportOutput.fileName
          )
        }
      }
    }
  )

  if (loading) {
    return <Button disabled>Processing</Button>
  }
  return <Button onClick={createAndSaveExport}>Download</Button>
}

function saveFile(content: string, fileName: string) {
  if (fileName !== '') {
    const savedFile = new File([atob(content)], `${fileName}`, { type: 'text/plain' })

    FileSaver.saveAs(savedFile)
  }
}

export default IamExport
