import gql from 'graphql-tag'

export const GroupFields = gql`
  fragment GroupFields on GroupOutput {
    id
    name
    description
    users {
      id
      name
      created
      userType
    }
  }
`

export const GroupDtoFields = gql`
  fragment GroupDtoFields on GroupDto {
    id
    name
    description
  }
`

export const READ_GROUPS = gql`
  query ReadGroups($cloudAccountId: ID!) {
    groups(cloudAccountId: $cloudAccountId) {
      ...GroupFields
    }
  }
  ${GroupFields}
`

export const READ_GROUP = gql`
  query ReadGroup($cloudAccountId: ID!, $groupId: String!, $groupName: String!) {
    group(cloudAccountId: $cloudAccountId, groupId: $groupId, groupName: $groupName) {
      ...GroupFields
    }
  }
  ${GroupFields}
`
