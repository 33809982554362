import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router'
import parseQueryString from 'query-string'
import { CREATE_ASSESSMENT } from '../../graphql/assessment'
import * as CreateAssessmentType from '../../graphql/__generated__/CreateAssessment'
import { Loading } from '..'
import { READ_CLOUD_ACCOUNTS } from '../../graphql/account'

export type AssessmentCreateProps = {
  cloudSolutionId: string
  solutionName: string
  cloudAccountId: string
  cloudAccountName: string
  benchmarkId: string
  benchmarkName: string
  engagementId: string
  previousAssessmentFlag: boolean
  isRunDisabled: boolean
  cancelModalCb: () => void
}

const AssessmentCreate: React.FC<AssessmentCreateProps> = ({
  cloudSolutionId,
  solutionName,
  cloudAccountId,
  cloudAccountName,
  benchmarkId,
  benchmarkName,
  engagementId,
  previousAssessmentFlag,
  isRunDisabled,
  cancelModalCb = () => {}
}) => {
  const name = 'Assessment'
  const navigate: any = useNavigate()
  let { engagementName, cloudSolutionName, accountId, accountName } = parseQueryString.parse(location?.search)
  cloudSolutionName = cloudSolutionName || solutionName
  accountId = accountId || cloudAccountId
  accountName = accountName || cloudAccountName
  engagementName = engagementName || ''

  const [mutate, { loading }] = useMutation<
    CreateAssessmentType.CreateAssessment,
    CreateAssessmentType.CreateAssessmentVariables
  >(CREATE_ASSESSMENT, {
    variables: { cloudAccountId, name, benchmarkId, previousAssessmentFlag },
    refetchQueries: [
      {
        query: READ_CLOUD_ACCOUNTS,
        variables: { cloudSolutionId, engagementId }
      }
    ],
    update: (_, { data }) => {
      if (data) {
        navigate(
          `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${cloudSolutionId}&cloudSolutionName=${cloudSolutionName}&accountName=${accountName}&accountId=${accountId}&benchmarkName=${benchmarkName}&benchmarkId=${benchmarkId}&assessmentPageId=${data.createAssessment.id}`
        )
        cancelModalCb()
      }
    }
  })

  if (loading) {
    return (
      <div className="button-comp button-comp_sm-p">
        <Loading />
      </div>
    )
  }

  return (
    <div className="button-comp">
      <Button onClick={() => cancelModalCb()} className="a-btn a-btn-new a-btn-outline">
        Cancel
      </Button>
      <Button
        id="runAssessment"
        onClick={() => mutate()}
        className="a-btn a-btn-new a-btn-primary"
        disabled={isRunDisabled}
      >
        Run Assessment
      </Button>
    </div>
  )
}

export default AssessmentCreate
