import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, Input } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router'
import { READ_BENCHMARKS } from '../../graphql/benchmark'
import { Error } from '..'
import {
  ReadBenchmarks,
  ReadBenchmarks_benchmarks,
  ReadBenchmarks_benchmarks_categories,
  ReadBenchmarks_benchmarks_categories_controls,
  ReadBenchmarksVariables
} from '../../graphql/__generated__/ReadBenchmarks'
import Layout from '../Layout/Layout'
import CustomTable, { CustomTableProps } from '../CustomTable/CustomTable'
import { ControlType } from '../../graphql/__generated__/globalTypes'

import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg'
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg'
import './BenchmarkOverview.scss'
import BenchmarkModal from './BenchmarkModal'
import BenchmarkDeleteModal from './BenchmarkDeleteModal'
import ControlOverviewFilterC from '../Control/ControlOverviewFilter'
import { ReadCloudSolutions } from '../../graphql/__generated__/ReadCloudSolutions'
import { READ_CLOUD_SOLUTIONS } from '../../graphql/cloudSolution'
import { getSolutionName } from '../../misc/helperFunctions'

type BenchmarkRouteParams = {
  cloudSolutionId?: string
}

const BenchmarkOverview: React.FC = () => {
  const navigate = useNavigate()
  const [filter, setFilter] = useState<any>({
    search: '',
    additionalFilter: {
      source: { placeholder: 'All Sources', options: new Set<string>([]), value: '' }
    }
  })
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }
  const [benchmarkEdit, setBenchmarkEdit] = useState<any>(false)
  const [benchmarkId, setBenchmarkId] = useState<any>(false)
  const [benchmarkName, setBenchmarkName] = useState<any>(false)
  const { cloudSolutionId }: Readonly<BenchmarkRouteParams> = useParams()
  const { data, refetch, loading, error } = useQuery<ReadBenchmarks, ReadBenchmarksVariables>(READ_BENCHMARKS, {
    variables: { cloudSolutionId: cloudSolutionId || '' }
  })

  const { data: solutions } = useQuery<ReadCloudSolutions>(READ_CLOUD_SOLUTIONS)
  const title = useMemo(() => {
    const solution = solutions?.cloudSolutions?.find((solution) => solution.id === cloudSolutionId)
    if (solution) {
      return `${getSolutionName(solution?.name)} Benchmarks`
    }

    return ''
  }, [data])

  const benchmarks = useMemo(() => {
    const group = {}

    if (data?.benchmarks?.length) {
      data?.benchmarks.forEach((item) => {
        if (!group?.[item.benchmarkGroup.id]) {
          group[item.benchmarkGroup.id] = {
            ...item.benchmarkGroup,
            benchmarks: [item]
          }
        } else {
          group[item.benchmarkGroup.id].benchmarks.push(item)
        }
      })

      const value = Object.values(group)

      const optionsSource = new Set<string>([])

      value.forEach((item: any) => {
        const source: string = item?.benchmarks?.[0]?.benchmarkSource?.name
        if (source) {
          optionsSource.add(source)
        }
      })

      setFilter({
        ...filter,
        additionalFilter: {
          ...filter.additionalFilter,
          source: { ...filter.additionalFilter.source, options: new Set(optionsSource) }
        }
      })

      return value
    } else {
      return []
    }
  }, [data])

  const benchmarkFiltering = useMemo(() => {
    return benchmarks
      .filter((item: any) => item.name.includes(filter.search))
      .filter((item: any) =>
        item?.benchmarks?.[0]?.benchmarkSource?.name.includes(filter.additionalFilter.source.value)
      )
  }, [benchmarks, filter])

  const getTableData = (dataTable: any[]) =>
    ({
      header: [
        { label: 'Benchmark Name' },
        { label: 'Source', style: { maxWidth: '100px' } },
        { label: 'Controls (Manual)', style: { textAlign: 'right' } },
        { label: 'Controls (Automatic)', style: { textAlign: 'right' } },
        { label: 'Controls (Total)', style: { textAlign: 'right' } },
        { label: '', style: { textAlign: 'right' } }
      ],
      data: dataTable.map((item: any) => {
        const controlsTypeCount = {
          manual: 0,
          automatic: 0,
          benchmarksCountTypeCount: {}
        }

        item.benchmarks.forEach((benchmark: ReadBenchmarks_benchmarks) =>
          benchmark.categories.forEach((categoryItem: ReadBenchmarks_benchmarks_categories) =>
            categoryItem.controls.forEach((controlItem: ReadBenchmarks_benchmarks_categories_controls) => {
              const isManual = controlItem.type === ControlType.MANUAL

              if (isManual) {
                controlsTypeCount.manual += 1
              } else {
                controlsTypeCount.automatic += 1
              }

              if (!controlsTypeCount.benchmarksCountTypeCount?.[benchmark.id]) {
                controlsTypeCount.benchmarksCountTypeCount[benchmark.id] = {
                  manual: isManual ? 1 : 0,
                  automatic: !isManual ? 1 : 0
                }
              } else if (isManual) {
                controlsTypeCount.benchmarksCountTypeCount[benchmark.id].manual += 1
              } else {
                controlsTypeCount.benchmarksCountTypeCount[benchmark.id].automatic += 1
              }
            })
          )
        )

        return {
          row: [
            { component: <span className="a-text-underline">{item?.name}</span> },
            { component: item?.benchmarks[0]?.benchmarkSource?.name, style: { maxWidth: '100px' } },
            { component: controlsTypeCount.manual, style: { textAlign: 'right' } },
            { component: controlsTypeCount.automatic, style: { textAlign: 'right' } },
            { component: controlsTypeCount.manual + controlsTypeCount.automatic || '', style: { textAlign: 'right' } },
            { component: <div />, style: { textAlign: 'right' } }
          ],
          subTable: {
            header: [
              { label: 'Benchmark' },
              { label: 'Source', style: { maxWidth: '100px' } },
              { label: 'Controls (Manual)', style: { textAlign: 'right' } },
              { label: 'Controls (Automatic)', style: { textAlign: 'right' } },
              { label: 'Controls (Total)', style: { textAlign: 'right' } },
              { label: '', style: { textAlign: 'right' } }
            ],
            data: item.benchmarks.map((benchmarksItem) => [
              {
                component: (
                  <Link
                    to={`/configuration/cloud/${cloudSolutionId}/benchmarks/${benchmarksItem.id}/detail?benchmarkId=${benchmarksItem.id}&benchmarkName=${benchmarksItem.name}&cloudSolutionId=${cloudSolutionId}`}
                  >
                    {benchmarksItem?.name}
                  </Link>
                )
              },
              { component: benchmarksItem?.benchmarkSource?.name, style: { maxWidth: '100px' } },
              {
                component: controlsTypeCount.benchmarksCountTypeCount?.[benchmarksItem.id]?.manual,
                style: { textAlign: 'right' }
              },
              {
                component: controlsTypeCount.benchmarksCountTypeCount?.[benchmarksItem.id]?.automatic,
                style: { textAlign: 'right' }
              },
              {
                component:
                  controlsTypeCount.benchmarksCountTypeCount?.[benchmarksItem.id]?.manual +
                    controlsTypeCount?.benchmarksCountTypeCount?.[benchmarksItem.id]?.automatic || '',
                style: { textAlign: 'right' }
              },
              {
                component: (
                  <div>
                    <EditIcon
                      className="a-cursor-pointer"
                      onClick={() => {
                        setBenchmarkEdit(benchmarksItem)
                        setBenchmarkId(benchmarksItem.id)
                        setBenchmarkName(benchmarksItem.name)
                        setIsCreateModalVisible(true)
                      }}
                    />
                    <EyeIcon
                      className="a-ml-8 a-cursor-pointer"
                      onClick={() =>
                        navigate(
                          `/configuration/cloud/${cloudSolutionId}/benchmarks/${benchmarksItem.id}/detail?benchmarkId=${benchmarksItem.id}&benchmarkName=${benchmarksItem.name}&cloudSolutionId=${cloudSolutionId}`
                        )
                      }
                    />
                  </div>
                ),
                style: { textAlign: 'right' }
              }
            ])
          }
        }
      }),
      countCell: 6
    } as CustomTableProps)

  if (error) {
    return <Error />
  }

  return (
    <Layout
      className="new-panel benchmark-overview"
      title={title || 'Benchmarks'}
      subtitle="Benchmarks"
      actions={() => (
        <Button
          className="a-btn-new a-btn-header"
          id="createBenchmark"
          onClick={() => {
            setBenchmarkEdit(null)
            setIsCreateModalVisible(true)
          }}
        >
          Create Benchmark
        </Button>
      )}
      loading={loading}
      renderRight={() => {
        return (
          <Input
            className="new-input"
            placeholder="Search"
            type="text"
            value={filter.search}
            prefix={
              <span className="select-toggle-icon appkiticon icon-search-outline apply-opacity-in-closed-toggle" />
            }
            onChange={(search: string) => {
              setFilter({ ...filter, search })
            }}
          />
        )
      }}
    >
      <div className="control-filter">
        <p className="control-filter__title">Filter options</p>
        <ControlOverviewFilterC filter={filter} setFilter={setFilter} showSearchOnList={false} />
      </div>

      {benchmarks.length ? (
        <CustomTable {...getTableData(benchmarkFiltering)} emptyText="No Benchmarks" />
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center a-py-50 benchmark-overview__empty">
          <h5>No Benchmarks</h5>
          <p className="a-mb-30">There doesn’t appear to be any benchmarks. Create a benchmark now.</p>
          <button
            type="button"
            className="a-btn a-btn-primary a-btn-sm a-btn-new"
            onClick={() => {
              setBenchmarkEdit(null)
              setIsCreateModalVisible(true)
            }}
          >
            Create Benchmark
          </button>
        </div>
      )}

      {isDeleteModalVisible && (
        <BenchmarkDeleteModal
          visible={isDeleteModalVisible}
          cancelModalCb={closeDeleteModal}
          benchmarkId={benchmarkId}
          benchmarkName={benchmarkName}
          cloudSolutionId={cloudSolutionId}
        />
      )}

      {isCreateModalVisible && (
        <BenchmarkModal
          cloudSolutionId={cloudSolutionId}
          benchmark={benchmarkEdit}
          visible={isCreateModalVisible}
          benchmarkGroup={benchmarks}
          onDeleteCb={() => {
            setIsDeleteModalVisible(true)
            setIsCreateModalVisible(false)
          }}
          cancelModalCb={(flag) => {
            if (flag) {
              refetch()
            }
            setIsCreateModalVisible(false)
          }}
        />
      )}
    </Layout>
  )
}

export default BenchmarkOverview
