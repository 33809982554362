import React from 'react'
import { Banner, Button, Table, Tooltip } from '@pwc-de/appkit-react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Error } from '..'
import LoadingPanel from '../Status/LoadingPanel'
import {
  ReadCloudSubscriptions,
  ReadCloudSubscriptionsVariables
} from '../../graphql/__generated__/ReadCloudSubscriptions'
import { READ_CLOUD_SUBSCRIPTIONS, READ_CLOUD_ACCOUNT, UPDATE_CLOUD_ACCOUNT_CAPABILITIES } from '../../graphql/account'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import { CapabilityStatus } from '../../graphql/__generated__/globalTypes'
import './CloudSubsOverview.scss'

type CloudSubsOverviewProps = {
  cloudAccountId?: any
}

const CloudSubsOverview: React.FC<CloudSubsOverviewProps> = ({ cloudAccountId: cloudAccountIdProps }) => {
  const { cloudAccountId: cloudAccountIdParams }: Readonly<CloudSubsOverviewProps> = useParams()
  const cloudAccountId: string = cloudAccountIdParams || cloudAccountIdProps || ''

  const cloudAccountData = useQuery<ReadCloudAccount, ReadCloudAccountVariables>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId! }
  })

  const cloudAccount =
    cloudAccountData.data && cloudAccountData.data.cloudAccount
      ? cloudAccountData.data.cloudAccount
      : {
          accountCapability: {
            runAssessments: CapabilityStatus.NOT_APPLICABLE,
            isIamAccount: CapabilityStatus.NOT_APPLICABLE,
            readIamGroups: CapabilityStatus.NOT_APPLICABLE,
            readIamUsers: CapabilityStatus.NOT_APPLICABLE,
            readIamRoles: CapabilityStatus.NOT_APPLICABLE
          }
        }

  const { data, loading, error } = useQuery<ReadCloudSubscriptions, ReadCloudSubscriptionsVariables>(
    READ_CLOUD_SUBSCRIPTIONS,
    {
      variables: { cloudAccountId },
      skip: cloudAccount.accountCapability.runAssessments !== CapabilityStatus.ACTIVE
    }
  )

  // useMutation refresh automatically cache of cloudAccount with same id
  const [refreshCapabilities, { loading: capabilityLoading }] = useMutation(UPDATE_CLOUD_ACCOUNT_CAPABILITIES, {
    variables: { id: cloudAccountId }
  })

  if (error) {
    return <Error />
  }

  const cloudSubscriptions = data && data.subscriptions ? data.subscriptions : []

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (row: any) => row.value
    },
    {
      Header: 'ID',
      accessor: 'subscriptionId',
      Cell: (row: any) => row.value
    }
  ]

  return (
    <LoadingPanel
      title="Cloud Capabilities And Subscriptions"
      className="new-panel"
      renderRight={() => (
        <Tooltip
          id="refreshCapabilities"
          tooltipTheme="light"
          content="Refresh cloud account capabilities"
          placement="left"
        >
          <Button size="md" isLoading={capabilityLoading} onClick={() => refreshCapabilities()}>
            <span className="appkiticon icon-refresh-fill" />
          </Button>
        </Tooltip>
      )}
      isLoading={loading}
    >
      {cloudAccount.accountCapability.runAssessments === CapabilityStatus.NOT_APPLICABLE && (
        <div className="banner-container">
          <Banner content="Account has no subscriptions (Azure) / projects (GCP)" status="warning" />
        </div>
      )}

      {cloudAccount.accountCapability.isIamAccount === CapabilityStatus.INVALID && (
        <div className="banner-container">
          <Banner content="Account has insufficient privileges to access IAM" status="warning" />
        </div>
      )}

      {cloudAccount.accountCapability.isIamAccount === CapabilityStatus.ACTIVE && (
        <>
          {cloudAccount.accountCapability.readIamGroups === CapabilityStatus.INVALID && (
            <div className="banner-container">
              <Banner content="Account has insufficient privileges to read IAM groups" status="warning" />
            </div>
          )}

          {cloudAccount.accountCapability.readIamUsers === CapabilityStatus.INVALID && (
            <div className="banner-container">
              <Banner content="Account has insufficient privileges to read IAM Users" status="warning" />
            </div>
          )}

          {cloudAccount.accountCapability.readIamRoles === CapabilityStatus.INVALID && (
            <div className="banner-container">
              <Banner content="Account has insufficient privileges to read IAM Roles" status="warning" />
            </div>
          )}
        </>
      )}
      {cloudSubscriptions.length > 0 && (
        <Table className="new-table" data={cloudSubscriptions} columns={columns} defaultSorted={[{ id: 'name' }]} />
      )}
    </LoadingPanel>
  )
}

CloudSubsOverview.defaultProps = {
  cloudAccountId: ''
}

export default CloudSubsOverview
