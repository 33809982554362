import gql from 'graphql-tag'

export const CategoryFields = gql`
  fragment CategoryFields on Category {
    id
    name
    controls {
      id
      pluginName
      controlId
      controlName
      description
      remediation
      controlStatus
      type
    }
  }
`

export const READ_CATEGORY = gql`
  query ReadCategory($id: ID!) {
    category(id: $id) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id)
  }
`

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($name: String!, $benchmarkId: ID!) {
    createCategory(value: { name: $name, benchmarkId: $benchmarkId }) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $name: String!, $controlIds: [ID!]!) {
    updateCategory(id: $id, value: { name: $name, controlIds: $controlIds }) {
      ...CategoryFields
    }
  }
  ${CategoryFields}
`
