export const groupArrBy = (arr, property) => {
  return arr.reduce((acc, cur) => {
    acc[cur[property]] = [...(acc[cur[property]] || []), cur]
    return acc
  }, {})
}

export const getSolutionName = (type: string) => {
  switch (type.toUpperCase()) {
    case 'AZURE':
      return 'Azure'
    case 'AWS':
      return 'AWS'
    case 'GCP':
      return 'Google Cloud'
    case 'AWS_EKS':
      return 'EKS'
    case 'AZURE_AKS':
      return 'AKS'
    case 'GOOGLE_GKE':
      return 'GKE'
    default:
      return type.replace(/_/g, ' ')
  }
}

export const capitalizeStr = (str: string) => {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
}
