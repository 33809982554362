import gql from 'graphql-tag'

export const AssessmentAnalyticsResultFields = gql`
  fragment AssessmentAnalyticsResultFields on AssessmentAnalyticsResultOutput {
    classification
    score
    numControls
    numControlsUnknown
    numControlsNotApplicable
    numControlsNotCompliant
    numControlsCompliant
  }
`
