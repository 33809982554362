import React, { useState } from 'react'
import { Input, Modal, ModalBody, ModalFooter } from '@pwc-de/appkit-react'
import { useQuery } from '@apollo/client'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'
import BenchmarkDelete from './BenchmarkDelete'
import { ReadMetricsDashboard } from '../../graphql/__generated__/ReadMetricsDashboard'
import { READ_METRICS_DASHBOARD } from '../../graphql/metrics-dashboard'

//--------------------------------------------------------------------------
const BenchmarkDeleteModal: React.FC<any> = ({
  visible,
  cancelModalCb,
  benchmarkId,
  benchmarkName,
  cloudSolutionId
}) => {
  const [input, setInput] = useState('')
  const restrictedSources = ['CIS', 'PWC', 'ISO', 'NIST']

  const totalAssessmentCount = restrictedSources.includes(benchmarkName.split(' ')[0].toUpperCase()) ? 1 : 0

  const isValid = input === 'DELETE'

  return (
    <Modal
      className="baseline-modal-showcase form-modal new-modal center-middle"
      visible={visible}
      onCancel={() => cancelModalCb()}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div className="a-panel-title">Delete Benchmark</div>
      </ModalHeaderWrapper>
      <ModalBody className="px-3">
        <h2 className="a-mt-20 a-mb-10">{benchmarkName}</h2>
        {totalAssessmentCount === 0 && (
          <p>Type ‘DELETE’ into the field below to delete this benchmark. This action cannot be undone.</p>
        )}
        {totalAssessmentCount > 0 && <p style={{ color: 'red' }}>System Benchmarks Can Not Be Deleted.</p>}
        <Input
          className={'fix-first'}
          id="delete"
          disabled={totalAssessmentCount > 0}
          placeholder="DELETE"
          value={input}
          onChange={(name: string) => {
            setInput(name)
          }}
        />
      </ModalBody>

      <ModalFooter>
        <BenchmarkDelete
          id={benchmarkId}
          cloudSolutionId={cloudSolutionId}
          isValid={isValid}
          cancelModalCb={cancelModalCb}
        />
      </ModalFooter>
    </Modal>
  )
}

export default BenchmarkDeleteModal
