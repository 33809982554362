import React, { InputHTMLAttributes } from 'react'
import { Input } from '@pwc-de/appkit-react'
import styles from './InputSearch.module.scss'

const InputSearch: React.FC<InputHTMLAttributes<HTMLInputElement>> = (props: InputHTMLAttributes<HTMLInputElement>) => (
  <div className={styles.container}>
    <svg
      className={styles['search-icon']}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.936 15.2287L10.5713 9.864C11.4573 8.81867 12 7.47467 12 6C12 2.69133 9.30867 0 6 0C2.69133 0 0 2.69133 0 6C0 9.30867 2.69133 12 6 12C7.47467 12 8.81867 11.4573 9.864 10.5707L15.2287 15.936L15.936 15.2287ZM1 6C1 3.24333 3.24333 1 6 1C8.75733 1 11 3.24333 11 6C11 8.75667 8.75733 11 6 11C3.24333 11 1 8.75667 1 6Z"
        fill="#999999"
      />
    </svg>
    <Input placeholder="Search" className={styles['search-input']} {...props} />
  </div>
)

export default InputSearch
