import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Input, Modal, ModalBody, Panel, SelectTable } from '@pwc-de/appkit-react'
import { Error, Loading } from '..'
import './BenchmarkDetail.scss'
import { ReadCategory, ReadCategoryVariables } from '../../graphql/__generated__/ReadCategory'
import { READ_CATEGORY, UPDATE_CATEGORY } from '../../graphql/category'
import Category from '../Category/Category'
import sortControls from '../Control/ControlSorting'
import { ReadAddableControls, ReadAddableControlsVariables } from '../../graphql/__generated__/ReadAddableControls'
import { READ_ADDABLE_CONTROLS } from '../../graphql/control'
import { UpdateCategory, UpdateCategoryVariables } from '../../graphql/__generated__/UpdateCategory'
import { ControlType } from '../../graphql/__generated__/globalTypes'
import './ControlsAssignModal.scss'
import ControlOverviewFilterC from '../Control/ControlOverviewFilter'
import Control from '../Control/Control'
import { capitalizeStr } from '../../misc/helperFunctions'

const ControlsAssignModal: React.FC<any> = ({ visible, categoryId, cancelModalCb }) => {
  const isFirst = useRef(true)
  const { cloudSolutionId }: Readonly<any> = useParams()
  const categoryData = useQuery<ReadCategory, ReadCategoryVariables>(READ_CATEGORY, { variables: { id: categoryId! } })

  let category = new Category()
  if (categoryData.data && categoryData.data.category) {
    category = categoryData.data.category
  }

  const categoryControlMap = sortControls(category.controls)

  const [selectedControls, setSelectedControls] = useState(categoryControlMap.map((c) => c.control))

  const [selectAll, setSelectAll] = useState<boolean>(false)

  useEffect(() => {
    if (isFirst.current && categoryData.data?.category) {
      const categoryControlMapLoc = sortControls(categoryData.data.category.controls)
      setSelectedControls(categoryControlMapLoc.map((c) => c.control))
      isFirst.current = false
    }
  }, [categoryData])

  const { data, loading, error } = useQuery<ReadAddableControls, ReadAddableControlsVariables>(READ_ADDABLE_CONTROLS, {
    variables: { cloudSolutionId: cloudSolutionId! }
  })

  if (error) {
    return <Error />
  }
  if (loading || categoryData.loading) {
    return <Loading />
  }
  if (!data) {
    return <p>Not found</p>
  }

  const controls = sortControls(data.addableControls)

  return (
    <Modal
      className="form-modal new-modal controls-assign-modal modal-dialog_lg"
      visible={visible}
      onCancel={() => cancelModalCb()}
      backdropClosable={false}
    >
      <ModalBody>
        <ControlAssign
          category={category}
          selectedControls={selectedControls}
          setSelectedControls={setSelectedControls}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
          controls={controls.map((c) => c.control)}
          cancelModalCb={cancelModalCb}
        />
      </ModalBody>
    </Modal>
  )
}

const ControlAssign: React.FC<any> = ({
  category,
  selectedControls,
  setSelectedControls,
  selectAll,
  setSelectAll,
  controls,
  cancelModalCb
}) => {
  const [filter, setFilter] = useState<any>({
    search: '',
    additionalFilter: {
      type: {
        placeholder: 'All Types',
        options: new Set<string>(Object.values(ControlType).map((key: string) => capitalizeStr(key))),
        value: ''
      }
    }
  })
  const columns = [
    {
      Header: 'Control',
      accessor: 'controlId',
      Cell: ({ original, value }) => (
        <span>
          {value} {original.controlName}
        </span>
      )
    },
    {
      Header: 'Type',
      accessor: 'type',
      className: 'type-cell',
      headerClassName: 'type-cell',
      Cell: ({ original }) => (
        <div>
          <span className="controls-assign-modal__type-control">
            {original.type === ControlType.MANUAL ? 'M' : 'A'}
          </span>
        </div>
      )
    }
  ]
  const controlsFiltering = useMemo(() => {
    return (controls ?? [])
      .filter((item: Control) => {
        return (
          item?.controlName?.toLowerCase()?.includes(filter.search.toLowerCase()) ||
          item?.controlId?.toLowerCase()?.includes(filter.search.toLowerCase())
        )
      })
      .filter((item: Control) =>
        item?.type?.toLowerCase()?.includes((filter.additionalFilter?.type?.value ?? '').toLowerCase())
      )
  }, [controls, filter])

  const [mutate, { loading, error }] = useMutation<UpdateCategory, UpdateCategoryVariables>(UPDATE_CATEGORY)

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Panel title={`Category: ${category.name}`}>
      <div className="d-flex justify-content-between filter-section-table m-0 px-0 pt-2">
        <p className="control-filter__title mb-0">Filter options</p>
        <div className="d-flex">
          <div className="control-filter__container mx-2">
            <div className="control-filter__item">
              <Input
                className="search-new-input new-input a-input-size-sm"
                placeholder="Search"
                type="text"
                value={filter.search}
                prefix={
                  <span className="select-toggle-icon appkiticon icon-search-outline apply-opacity-in-closed-toggle" />
                }
                onChange={(search: string) => {
                  setFilter({ ...filter, search })
                }}
              />
            </div>
          </div>
          <ControlOverviewFilterC filter={filter} setFilter={setFilter} showSearchOnList={false} />
        </div>
      </div>
      <div className="select-table">
        <SelectTable
          className="new-table"
          toggleSelection={toggleSelection.bind(null, selectedControls, setSelectedControls)}
          toggleAll={toggleAll.bind(null, !selectAll, setSelectAll, [], setSelectedControls, controls)}
          selectAll={selectAll}
          isSelected={isSelected.bind(null, selectedControls)}
          selectType="checkbox"
          keyField="id"
          data={controlsFiltering}
          columns={columns}
          defaultPageSize={99999}
          sortable={false}
          showPagination={false}
          showPageSizeOptions={false}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {' '}
          {selectedControls.length} of {controls.length} selected
        </div>
        <div className="select-table__buttons a-my-20">
          <Button
            className="a-btn a-btn-primary a-btn-sm a-btn a-btn-new a-btn-outline a-keyboard-hover-only-div"
            onClick={cancelModalCb}
          >
            Cancel
          </Button>
          <Button
            className="a-btn a-btn-new"
            onClick={async () => {
              const updatedCategory = { ...category }
              updatedCategory.controls = selectedControls
              await mutate({
                variables: {
                  ...updatedCategory,
                  controlIds: updatedCategory.controls ? updatedCategory.controls.map((control: any) => control.id) : []
                }
              })
              cancelModalCb(true)
            }}
          >
            Update Controls
          </Button>
        </div>
      </div>
    </Panel>
  )
}

const toggleSelection = (selection: any, setSelection: any, key: any, shift: any, row: any) => {
  const keyIndex = selection.map((s: any) => s.id).indexOf(key)
  if (keyIndex < 0) {
    setSelection([
      ...selection,
      {
        id: row.id
      }
    ])
  } else {
    setSelection([...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)])
  }
}

const toggleAll = (selectAll: any, setSelectAll: any, selection: any, setSelection: any, data: any) => {
  if (selectAll) {
    data.forEach((record: any) => {
      selection.push({
        id: record.id
      })
    })
  }
  setSelection(selection)
  setSelectAll(selectAll)
}

const isSelected = (selection: any, key: any) => selection.map((s: any) => s.id).includes(key)

export default ControlsAssignModal
