import { useMutation, useQuery } from '@apollo/client'
import { Table } from '@pwc-de/appkit-react'
import React, { useEffect, useMemo, useState } from 'react'
import { Error, Loading } from '..'
import './ControlView.scss'
import { ReadCategory, ReadCategoryVariables } from '../../graphql/__generated__/ReadCategory'
import { READ_CATEGORY, UPDATE_CATEGORY } from '../../graphql/category'
import { ControlType } from '../../graphql/__generated__/globalTypes'
import { ReactComponent as RemoveIcon } from '../../assets/images/remove.svg'
import { UpdateCategory, UpdateCategoryVariables } from '../../graphql/__generated__/UpdateCategory'

type ControlViewProps = {
  category: any
  isOpened: boolean
  fetch: any
  filter: any
  openAddControl: any
}

const ControlView: React.FC<ControlViewProps> = ({ category, isOpened, fetch, filter, openAddControl }) => {
  const [isFirstQuery, setIsFirstQuery] = useState(false)

  const { data, loading, error } = useQuery<ReadCategory, ReadCategoryVariables>(READ_CATEGORY, {
    variables: { id: category.id! },
    skip: !isFirstQuery
  })

  const filteredData = useMemo(() => {
    const newData = [] as any

    data?.category?.controls?.forEach((item: any) => {
      if (filter === '' || item.controlName?.toLowerCase()?.includes(filter?.toLowerCase())) {
        newData.push({ ...item })
      }
    })

    return newData
  }, [data, filter])

  const [mutate] = useMutation<UpdateCategory, UpdateCategoryVariables>(UPDATE_CATEGORY)

  useEffect(() => {
    if (isOpened && !isFirstQuery) {
      setIsFirstQuery(true)
    }
  }, [isOpened])

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }
  const columns = [
    {
      Header: 'Control',
      accessor: 'controlName'
    },
    {
      Header: 'Type',
      className: 'control-view__max-cell',
      headerClassName: 'control-view__max-cell',
      Cell: ({ original, value }) => (
        <div className="control-view__type-control d-inline-block">
          {original.type === ControlType.MANUAL ? 'M' : 'A'}
        </div>
      )
    },
    {
      Header: '',
      className: 'control-view__max-cell',
      headerClassName: 'control-view__max-cell',
      Cell: ({ original, value }) => (
        <div>
          <RemoveIcon
            className="control-view__icon pointer"
            onClick={async () => {
              await mutate({
                variables: {
                  id: category.id,
                  name: category.name,
                  controlIds: category.controls
                    ? category.controls
                        .map((control: any) => control.id)
                        .filter((id) => {
                          console.log(id !== original.id, id, original.id)
                          return id !== original.id
                        })
                    : []
                }
              })
              fetch()
            }}
          />
        </div>
      )
    }
  ]

  return (
    <div className="control-view">
      <Table
        className="new-table"
        data={filteredData}
        columns={columns}
        sortable={false}
        showPaginationBottom={false}
        NoDataComponent={() => (
          <div className="d-flex flex-column justify-content-center align-items-center a-py-50 control-view__empty">
            <h5>No control added</h5>
            <p className="a-mb-30">Add a control to the selected category</p>
            <button type="button" className="a-btn a-btn-primary a-btn-sm a-btn-new" onClick={openAddControl}>
              Add Controls
            </button>
          </div>
        )}
      />
    </div>
  )
}

export default ControlView
