import React, { useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { useQuery } from '@apollo/client'
import { NavLink } from 'react-router-dom'
import parseQueryString from 'query-string'
import './EngagementTable.scss'
import { getSolutionById } from '../util'
import { createBadgeFromClassification, createBadgeFromRisk } from '../ClassificationHelper'
import CardTable from '../CardTable/CardTable'
import { ReadCloudAccounts, ReadCloudAccountsVariables } from '../../graphql/__generated__/ReadCloudAccounts'
import { READ_CLOUD_ACCOUNTS } from '../../graphql/account'
import { Loading, AuthorizeRole } from '../index'
import CustomTable, { CustomTableProps, SubTable } from '../CustomTable/CustomTable'
import ProgressBarNew from './ProgressBarNew'
import {
  ReadSolutionDashboard,
  ReadSolutionDashboardVariables
} from '../../graphql/__generated__/ReadSolutionDashboard'
import { READ_SOLUTION_DASHBOARD } from '../../graphql/solution-dashboard'
import { ReactComponent as EditRowIcon } from '../../assets/images/edit-row.svg'
import { ReactComponent as RefreshIcon } from '../../assets/images/refresh.svg'
import { ReactComponent as RemoveIcon } from '../../assets/images/remove.svg'
import CloudAccountManageModal from '../CloudAccount/CloudAccountManageModal'
import AssessmentManageModal from '../Assessment/AssessmentManageModal'
import BenchmarkAssessmentDeleteModal from './BenchmarkAssessmentDeleteModal'
import { CheckCategory } from '../../graphql/__generated__/globalTypes'
import { groupArrBy } from '../../misc/helperFunctions'
import { getDisplayName } from '../AssessmentResult/Util'
import CloudAccountDeleteModal from '../CloudAccount/CloudAccountDelete'
import { CloudAccount } from '../CloudAccount/CloudAccount'

const LazySubTableData = ({ row, getStyleGrid, isOpen }) => {
  const { engagementId, engagementName } = parseQueryString.parse(location?.search)
  const [isLoadBenchmark, setIsLoadBenchmark] = useState(false)
  const [isAssessmentModalVisible, setIsAssessmentModalVisible] = useState<string>('')
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false)
  }

  const analyticsQuery = useQuery<ReadSolutionDashboard, ReadSolutionDashboardVariables>(READ_SOLUTION_DASHBOARD, {
    fetchPolicy: 'network-only',
    variables: { cloudSolutionId: row.original.cloudSolutionId!, engagementId: engagementId as string },
    skip: !isLoadBenchmark
  })

  useEffect(() => {
    if (isOpen && !isLoadBenchmark) {
      setIsLoadBenchmark(true)
    }
  }, [isOpen, isLoadBenchmark])

  const cloudAccount: any = analyticsQuery?.data?.solutionDashboard?.cloudAccounts.find(
    (ca) => ca.cloudAccount.id === row.original.id
  )

  const isActiveBenchmark = (id: string): boolean => {
    return id === isAssessmentModalVisible
  }

  const isDeleteAllowed = true

  const newRowData =
    cloudAccount?.benchmarks?.map((benchmark) => {
      return [
        {
          component: (
            <NavLink
              to={`/engagements/${engagementId}/analytics_dashboard/assessments?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${row.original.cloudSolutionId}&cloudSolutionName=${row.original.cloudSolutionName}&accountName=${cloudAccount.cloudAccount.name}&accountId=${cloudAccount.cloudAccount.id}&benchmarkName=${benchmark.name}&benchmarkId=${benchmark.benchmarkId}`}
            >
              {benchmark.name}
            </NavLink>
          )
        },
        { component: benchmark.source },
        {
          component: benchmark.analytics.score > 0 && (
            <div className="dashboard-config__progress-item">
              <ProgressBarNew progressValue={Math.round(benchmark.analytics.score)} />{' '}
              <span className="dashboard-config__progress-text">{Math.round(benchmark.analytics.score)}/100</span>
            </div>
          ),
          style: { gridColumn: '3/6', textAlign: 'right' }
        },
        {
          component: (
            <>
              <div className="category-control__min-cell">
                <AuthorizeRole role="platform-admin">
                  <RemoveIcon
                    className={`category-control__icon ${isDeleteAllowed ? '' : 'category-control__icon--gray'}`}
                    onClick={() => setIsDeleteModalVisible(benchmark.benchmarkId)}
                  />
                </AuthorizeRole>
                <RefreshIcon
                  onClick={() => setIsAssessmentModalVisible(benchmark.benchmarkId)}
                  className="category-control__icon"
                  // className="cursor-pointer"
                />
              </div>
              {isDeleteModalVisible === benchmark.benchmarkId && row.original?.cloudSolutionId && engagementId && (
                <BenchmarkAssessmentDeleteModal
                  visible
                  cancelModalCb={closeDeleteModal}
                  cloudAccountId={cloudAccount.cloudAccount.id}
                  benchmarkName={benchmark.name}
                  cloudSolutionId={row.original.cloudSolutionId}
                  engagementId={engagementId}
                />
              )}
              {isActiveBenchmark(benchmark.benchmarkId) && row.original?.cloudSolutionId && engagementId && (
                <AssessmentManageModal
                  visible
                  cancelModalCb={() => setIsAssessmentModalVisible('')}
                  cloudSolutionId={row.original.cloudSolutionId}
                  cloudSolutionName={row.original.cloudSolutionName}
                  accountId={cloudAccount.cloudAccount.id}
                  accountName={cloudAccount.cloudAccount.name}
                  cloudAccountId={row.original.id}
                  engagementId={engagementId}
                  selectedBenchmark={{ ...benchmark }}
                />
              )}
            </>
          ),
          style: { textAlign: 'right' }
        }
      ]
    }) || []

  return analyticsQuery.loading ? (
    <Loading />
  ) : (
    newRowData && (
      <SubTable
        rowData={{ ...row, subTable: { ...row.subTable, data: newRowData } }}
        getStyleGrid={getStyleGrid}
        emptyText="No Benchmark"
      />
    )
  )
}

type EngagementTableRowProps = {
  cloudSolution: any
}

const EngagementTableRow: React.FC<EngagementTableRowProps> = ({ cloudSolution }) => {
  const { engagementId, engagementName } = parseQueryString.parse(location?.search)
  const [cloudAccountID, setCloudAccountID] = useState(null)
  const [cloudAccount, setCloudAccount] = useState<CloudAccount | null>(null)
  const [isConnectCloudAccountModalVisible, setIsConnectCloudAccountModalVisible] = useState(false)
  const [isDeleteCloudAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false)

  const analyticsQuery = useQuery<ReadSolutionDashboard, ReadSolutionDashboardVariables>(READ_SOLUTION_DASHBOARD, {
    fetchPolicy: 'network-only',
    variables: { cloudSolutionId: cloudSolution.id!, engagementId: engagementId as string }
  })

  const isNotEmptyData = useMemo(() => cloudSolution?.analytics?.score !== 0, [cloudSolution])

  const { data, loading } = useQuery<ReadCloudAccounts, ReadCloudAccountsVariables>(READ_CLOUD_ACCOUNTS, {
    variables: {
      cloudSolutionId: cloudSolution.id,
      engagementId: engagementId as string
    }
  })

  const getScore = (row) => {
    if (row.assessments.length > 0) {
      const cloudAccountAnalytics = analyticsQuery?.data?.solutionDashboard?.cloudAccounts?.find(
        (ca) => ca.cloudAccount.id === row.id
      )
      if (cloudAccountAnalytics) {
        return cloudAccountAnalytics.analytics.score
      }
    }
    return 0
  }

  const getTableData = (dataTable: any) => {
    return {
      header: [
        { label: 'Account' },
        { label: 'Avg. Assessment Score', style: { gridColumn: '3/7', textAlign: 'right' } },
        { label: '', style: { textAlign: 'right' } }
      ],
      data: dataTable?.map((item: any) => {
        const avgScore = getScore(item)

        return {
          original: { ...item, cloudSolutionId: cloudSolution.id, cloudSolutionName: cloudSolution.name },
          row: [
            {
              component: (
                <NavLink
                  to={`/engagements/${engagementId}/analytics_dashboard/assessments?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${cloudSolution.id}&cloudSolutionName=${cloudSolution.name}&accountName=${item.name}&accountId=${item.id}`}
                >
                  <span className="a-text-underline">{item.name}</span>
                </NavLink>
              ),
              style: { gridColumn: '2/4' }
            },
            {
              component: (
                <div className="dashboard-config__progress-item">
                  {avgScore > 0 && (
                    <>
                      <ProgressBarNew progressValue={Math.round(avgScore)} />{' '}
                      <span className="dashboard-config__progress-text">{Math.round(avgScore)}/100</span>
                    </>
                  )}
                </div>
              ),
              style: { gridColumn: '4/7', textAlign: 'right' }
            },
            {
              component: (
                <EditRowIcon
                  className="-cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setCloudAccountID(item.id)
                    setCloudAccount(item)
                    setIsConnectCloudAccountModalVisible(true)
                  }}
                />
              ),
              style: { textAlign: 'right' }
            }
          ],
          subTable: {
            header: [
              { label: 'Benchmark' },
              { label: 'Source' },
              { label: 'Assessment Score', style: { gridColumn: '3/6', textAlign: 'right' } },
              { label: '', style: { textAlign: 'right' } }
            ],
            lazyComponent: LazySubTableData,
            countCell: 6
          }
        }
      }),
      countCell: 6
    } as CustomTableProps
  }

  const getSolutionName = (type: any) => {
    switch (type.toUpperCase()) {
      case 'AZURE':
        return 'Azure'
      case 'AWS':
        return 'AWS'
      case 'GCP':
        return 'Google Cloud'
      case 'AWS_EKS':
        return 'EKS'
      case 'AZURE_AKS':
        return 'AKS'
      case 'GOOGLE_GKE':
        return 'GKE'
      default:
        return getDisplayName(type)
    }
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable  jsx-a11y/no-static-element-interactions */
  return (
    <div className="collapse--no-animate">
      <div className={`engagement-table__row`}>
        <div className="engagement-table__flex-left" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
          <span>{getSolutionById(cloudSolution.name)?.icon()}</span> {getSolutionName(cloudSolution?.name)}
        </div>
        <div className="engagement-table__flex-left">
          <span className="engagement-table__title">Status</span>
          {isNotEmptyData ? (
            createBadgeFromClassification(cloudSolution?.analytics?.classification)
          ) : (
            <span className="na-value">N/A</span>
          )}
        </div>
        <div className="engagement-table__flex-left">
          <span className="engagement-table__title">Risk Level</span>
          {isNotEmptyData ? createBadgeFromRisk(cloudSolution.criticality) : <span className="na-value">N/A</span>}
        </div>
        <div className="engagement-table__flex-left">
          <span className="engagement-table__title">Score</span>
          {isNotEmptyData ? (
            <span className="engagement-table__big-text">
              <span>{Math.round(cloudSolution.analytics.score)}</span> / 100
            </span>
          ) : (
            <span className="na-value--big">N/A</span>
          )}
        </div>
        <div className="engagement-table__flex-right">
          <button
            type="button"
            className="a-btn a-btn-new a-btn-primary"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setIsConnectCloudAccountModalVisible(true)
            }}
          >
            Add accounts
          </button>
        </div>
      </div>
      <Collapse isOpened={data?.cloudAccounts?.length || loading}>
        <div style={{ paddingTop: '40px' }}>
          {loading ? (
            <Loading />
          ) : (
            !!data?.cloudAccounts && <CustomTable {...getTableData(data?.cloudAccounts)} emptyText="No Accounts" />
          )}
        </div>
      </Collapse>
      {isConnectCloudAccountModalVisible && (
        <CloudAccountManageModal
          visible
          isShowLoader={false}
          cloudSolution={{ ...cloudSolution, type: cloudSolution.name.toUpperCase() }}
          engagementId={engagementId}
          cloudAccountId={cloudAccountID}
          deleteModalCb={() => {
            setIsConnectCloudAccountModalVisible(false)
            setIsDeleteAccountModalVisible(true)
          }}
          cancelModalCb={() => {
            setIsConnectCloudAccountModalVisible(false)
            setCloudAccountID(null)
            setCloudAccount(null)
          }}
        />
      )}
      {isDeleteCloudAccountModalVisible && (
        <CloudAccountDeleteModal
          id={cloudAccountID as any}
          engagementId={engagementId as any}
          cloudSolutionId={cloudSolution.id}
          cloudAccountName={cloudAccount?.name ?? ''}
          deleteCb={() => {
            setIsDeleteAccountModalVisible(false)
          }}
          closeModalCb={() => {
            setIsDeleteAccountModalVisible(false)
            setCloudAccountID(null)
            setCloudAccount(null)
          }}
        />
      )}
    </div>
  )
}

type EngagementTableProps = {
  masterDashboardQuery: any
}

const EngagementTable: React.FC<EngagementTableProps> = ({ masterDashboardQuery }) => {
  const avgEngagementScore = (checkCategory: string | CheckCategory) => {
    if (masterDashboardQuery?.data?.masterDashboard?.cloudSolutions?.length) {
      return Math.round(
        // eslint-disable-next-line no-unsafe-optional-chaining
        masterDashboardQuery?.data?.masterDashboard?.cloudSolutions
          ?.filter((cloudSolution) => cloudSolution.checkCategory === checkCategory)
          .reduce?.((accumulator: any, currentValue: any) => accumulator + (currentValue?.analytics?.score || 0), 0) /
          (masterDashboardQuery?.data?.masterDashboard?.cloudSolutions?.filter(
            (cloudSolution) => cloudSolution.analytics.score !== 0 && cloudSolution.checkCategory === checkCategory
          ).length || 1)
      )
    } else {
      return 0
    }
  }

  const groupedSolutions = useMemo(() => {
    return groupArrBy(masterDashboardQuery?.data?.masterDashboard?.cloudSolutions, 'checkCategory')
  }, [masterDashboardQuery])

  const getSolutionName = (checkCategory: string | CheckCategory) => {
    switch (checkCategory) {
      case CheckCategory.PaaS_IaaS:
        return `IaaS / PaaS`
      case CheckCategory.Kubernetes:
        return `Kubernetes`
      default:
        return checkCategory.replace(/_+/g, ' ')
    }
  }

  return (
    <>
      {Object.keys(groupedSolutions).map((key) => {
        return (
          <CardTable
            key={key}
            className="engagement-table"
            header={getSolutionName(key)}
            rightItem={
              <p className="engagement-table__right-value">
                <span className="engagement-table__title">Overall Score</span>
                {avgEngagementScore(key) !== 0 ? (
                  <span className="engagement-table__big-text">
                    <span>{avgEngagementScore(key)}</span> / 100
                  </span>
                ) : (
                  <span className="na-value--big">N/A</span>
                )}
              </p>
            }
          >
            {(groupedSolutions[key] || []).map((cloudSolution) => (
              <EngagementTableRow key={cloudSolution.id} cloudSolution={cloudSolution} />
            ))}
          </CardTable>
        )
      })}
    </>
  )
}

export default EngagementTable
