import React from 'react'
import { useQuery } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { ReadUsersPaginated } from '../../graphql/__generated__/ReadUsersPaginated'
import { READ_USERS_PAGINATED } from '../../graphql/user'
import { Error } from '..'
import LoadingPanel from '../Status/LoadingPanel'
import UserTable from './UserTable'

type UsersOverviewProps = {
  cloudAccountId: string
  urlParent?: string
  urlParams?: string
}

const UserOverviewPaginated: React.FC<UsersOverviewProps> = ({ cloudAccountId, urlParent = '', urlParams = '' }) => {
  const { data, loading, error, fetchMore } = useQuery<ReadUsersPaginated>(READ_USERS_PAGINATED, {
    variables: { cloudAccountId }
  })

  if (error) {
    return <Error />
  }

  const nextLink = data?.usersPaginated?.pageInfo.nextLink
  const users = data && data.usersPaginated && data.usersPaginated.users ? data.usersPaginated.users : []

  const onLoadMore = () => {
    fetchMore({
      variables: {
        cloudAccountId,
        nextLink
      }
    })
  }

  return (
    <LoadingPanel
      title="Users"
      className="new-panel"
      isLoading={loading}
      renderRight={() => <div>{nextLink ? <Button onClick={onLoadMore}>Load More</Button> : null}</div>}
    >
      <div>
        <UserTable users={users} path="." urlParent={urlParent} urlParams={urlParams} />
      </div>
    </LoadingPanel>
  )
}

UserOverviewPaginated.defaultProps = {
  urlParent: '',
  urlParams: ''
}

export default UserOverviewPaginated
