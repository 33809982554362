import { ControlStatus, ControlType } from '../../graphql/__generated__/globalTypes'

class Control {
  id: string = ''

  pluginName: string = ''

  rating: number = 0

  description: string = ''

  remediation: string = ''

  controlId: string = ''

  controlName: string = ''

  type: ControlType = ControlType.AUTOMATIC

  controlStatus: ControlStatus = ControlStatus.INACTIVE
}

export default Control
