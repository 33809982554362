import gql from 'graphql-tag'
import { AssessmentAnalyticsResultFields } from './global'

export const READ_MASTER_DASHBOARD = gql`
  query ReadMasterDashboard($engagementId: ID!) {
    masterDashboard(engagementId: $engagementId) {
      cloudSolutions {
        id
        name
        analytics {
          ...AssessmentAnalyticsResultFields
        }
        criticality
        checkCategory
      }
    }
  }
  ${AssessmentAnalyticsResultFields}
`
