import React from 'react'
import './StaticPage.scss'
import { Panel } from '@pwc-de/appkit-react'

const DataPrivacy: React.FC = () => (
  <>
    <div className="layout-container__title layout-container__title mb-4">Data Privacy</div>
    <Panel className="new-table static">
      <div className="static-page">
        <div className="static-page__wrap">
          <p>Last updated: 20.02.2023</p>
          <br />
          <p>
            PwC Solutions GmbH has developed a web application to assess and monitor cloud security in your cloud
            environment. This data protection notice is intended for all persons who use this web application and
            describes the processing of personal data in this context.
          </p>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">1. Responsible party and contact details</h2>
            <p className="static-page__sub-p">
              The responsible party within the meaning of Art. 4 (7) EU General Data Protection Regulation (DSGVO) for
              the processing of your personal data is
            </p>
            <h2 className="static-page__sub-header">PwC Solutions GmbH</h2>
            <p>Friedrich-Ebert-Anlage 35-37</p>
            <p className="static-page__sub-p">60327 Frankfurt am Main</p>
            <p>
              Email: <a href="mailto:DE_Kontakt@pwc.com">DE_Kontakt@pwc.com</a>
            </p>
            <p>
              Telephone: <a href="tel:+496995850">+49 69 9585-0</a>
            </p>
            <p className="static-page__sub-p">
              Fax: <a href="tel:496995851000">+49 69 9585-1000</a>
            </p>
            <p className="static-page__sub-p">
              The data protection officer appointed in accordance with Art. 37 DSGVO, Dr. Tobias Gräber, can be reached
              directly at the above contact details and also at the e-mail address{' '}
              <a href="mailto:tobias.graeber@pwc.com">tobias.graeber@pwc.com</a>.
            </p>
          </div>

          <div className="static-page__row">
            <h2 className="static-page__sub-header">2. Notes on data processing</h2>
            <p className="static-page__sub-p">
              The purpose of processing of personal data is limited to operational requirements of the web application.
              No personal data pertaining to client is stored in the application other than the name of the client in
              some cases. In particular, the following categories of personal data are processed in the course of use:
            </p>
            <p>Defined profile information (email address, first and last name) of internal PwC staff. </p>
            <p className="static-page__sub-p">This data is processed for the following purposes:</p>
            <p>Creating a user profile</p>
            <p>Defined Role based access linked to email address of the internal user</p>
            <p>Audit read-write actions performed by internal users</p>

            <p className="static-page__sub-p">
              During the onboarding process, the user&apos;s consent (Terms of use, Privacy policy) is requested; the
              user can agree or decline. The user must consent to certain data processing in order to use the web
              application.
            </p>
            <p className="static-page__sub-p">
              The provision of the above data by the user is voluntary. There is neither a legal nor a contractual
              obligation to provide the data, nor is this necessary to fulfill an obligation to conclude a contract.
              Failure to provide the data has no consequences for the user, except that the functions of the web
              application are limited.
            </p>
            <p className="static-page__sub-p">
              The processing of personal data takes place with the consent of the user on the legal basis of Art. 6
              para. 1 lit. a) DSGVO.
            </p>
            <p className="static-page__sub-p">
              Users with admin access can revoke access for other users that will trigger deletion of personal
              data(email address, name) kept for authorization. But data linked(email address, name) to past operations
              performed by users will be retained as per the data retention policy of upto 2 years.
            </p>
          </div>
          <div className="static-page__row">
            <h2 className="static-page__sub-header">3. Data subject rights</h2>
            <p className="static-page__sub-p">
              Every data subject has rights according to Art. 15 - 21 GDPR. This concerns, for example, the right to
              information (Art. 15 GDPR), rectification (Art. 16 GDPR), erasure (Art. 17 GDPR), restriction of
              processing (Art. 18 GDPR), data transfer (Art. 20 GDPR) and objection to the processing of personal data
              concerning him or her (Art. 21 GDPR). These rights can be asserted at any time via our data protection
              officer at the contact details listed above. A restriction of these rights may result from legal
              provisions. To find out how you can assert your rights with regard to the data processing by Google
              described in 2. b) and c), please refer to the data protection notices of Google, which are linked under
              2. b).
            </p>
            <p className="static-page__sub-p">
              If the data subject has given consent to the processing of personal data for specific purposes, this
              consent may be revoked at any time with effect for the future (Art. 7 (3) DSGVO).
            </p>
            <p>
              In addition, data subjects have the right to lodge a complaint with a data protection supervisory
              authority (Art. 77 DSGVO).
            </p>
          </div>
        </div>
      </div>
    </Panel>
  </>
)

export default DataPrivacy
