import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import {
  UPDATE_AWS_CLOUD_ACCOUNT,
  UPDATE_AZURE_CLOUD_ACCOUNT,
  UPDATE_GCP_CLOUD_ACCOUNT,
  READ_CLOUD_SUBSCRIPTIONS,
  READ_ALL_CLOUD_ACCOUNTS,
  UPDATE_GOOGLE_GKE_CLOUD_ACCOUNT,
  UPDATE_AZURE_AKS_CLOUD_ACCOUNT,
  UPDATE_AWS_EKS_CLOUD_ACCOUNT
} from '../../graphql/account'
import { CloudAccountProps } from './CloudAccount'
import { Loading, Error, AuthorizeRole } from '..'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'

const CloudAccountUpdate: React.FC<CloudAccountProps> = ({
  account,
  cloudSolutionId,
  engagementId,
  closeModalCb,
  isValid,
  isShowLoader,
  deleteModalCb
}) => {
  const updatedAccount = JSON.parse(JSON.stringify(account))
  delete updatedAccount.credentials.__typename

  const [mutate, { loading, error }] = useMutation(getUpdateQuery(updatedAccount.type), {
    variables: updatedAccount,
    refetchQueries: [
      {
        query: READ_CLOUD_SUBSCRIPTIONS,
        variables: { cloudAccountId: account.id }
      },
      {
        query: READ_ALL_CLOUD_ACCOUNTS,
        variables: { cloudSolutionId, engagementId }
      }
    ]
  })

  if (error) {
    return <Error />
  }
  if (loading && isShowLoader) {
    return <Loading />
  }

  return (
    <div className="flex-between">
      <AuthorizeRole role="admin">
        <span className="pointer align-center a-text-red" onClick={() => deleteModalCb()} aria-hidden="true">
          <DeleteIcon className="a-mr-10" style={{ color: 'red' }} /> Delete Account
        </span>
      </AuthorizeRole>
      <div className="align-center flex-shrink-0">
        <Button className="a-btn a-btn-new a-btn-outline" onClick={() => closeModalCb()}>
          Cancel
        </Button>
        <Button
          id="saveCloudAccount"
          className="a-btn a-btn-new a-btn-primary"
          disabled={!isValid}
          onClick={() => {
            mutate()
            closeModalCb()
          }}
        >
          Update Account
        </Button>
      </div>
    </div>
  )
}

function getUpdateQuery(type: CloudCheckType) {
  switch (type) {
    case CloudCheckType.AWS:
      return UPDATE_AWS_CLOUD_ACCOUNT

    case CloudCheckType.AZURE:
      return UPDATE_AZURE_CLOUD_ACCOUNT

    case CloudCheckType.GCP:
      return UPDATE_GCP_CLOUD_ACCOUNT

    case CloudCheckType.AWS_EKS:
      return UPDATE_AWS_EKS_CLOUD_ACCOUNT

    case CloudCheckType.AZURE_AKS:
      return UPDATE_AZURE_AKS_CLOUD_ACCOUNT

    case CloudCheckType.GOOGLE_GKE:
      return UPDATE_GOOGLE_GKE_CLOUD_ACCOUNT
  }
}

export default CloudAccountUpdate
