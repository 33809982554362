import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Panel, Pagination } from '@pwc-de/appkit-react'
import { useMutation } from '@apollo/client'
import { Collapse } from 'react-collapse'
import './CategoryControl.scss'
import { ControlType } from '../../graphql/__generated__/globalTypes'
import { ReactComponent as RemoveIcon } from '../../assets/images/remove.svg'
import { ReactComponent as EditGrayIcon } from '../../assets/images/edit-gray.svg'
import { ReactComponent as AddBtnIcon, ReactComponent as AddIcon } from '../../assets/images/add-btn-table.svg'
import {
  UpdateAssessmentControl,
  UpdateAssessmentControlVariables
} from '../../graphql/__generated__/UpdateAssessmentControl'
import { UPDATE_ASSESSMENT_CONTROL } from '../../graphql/assessment'
import { Loading } from '../index'
import {
  ReadBenchmark_benchmark,
  ReadBenchmark_benchmark_categories,
  ReadBenchmark_benchmark_categories_controls
} from '../../graphql/__generated__/ReadBenchmark'
import { DeleteCategory, DeleteCategoryVariables } from '../../graphql/__generated__/DeleteCategory'
import { CREATE_CATEGORY, DELETE_CATEGORY, UPDATE_CATEGORY } from '../../graphql/category'
import { UpdateCategory, UpdateCategoryVariables } from '../../graphql/__generated__/UpdateCategory'
import { CreateCategory, CreateCategoryVariables } from '../../graphql/__generated__/CreateCategory'
import ControlView from './ControlView'
import { ReactComponent as ArrowTableActiveIcon } from '../../assets/images/arrow-table-active.svg'
import { ReactComponent as ArrowTableIcon } from '../../assets/images/arrow-table.svg'
import ControlsAssignModal from './ControlsAssignModal'

type CategoryControlRowProps = {
  control: ReadBenchmark_benchmark
  resource: any
  fetch: any
  filter: any
  updateItems: any
  deleteItems: any
}

const CategoryControlRow: React.FC<CategoryControlRowProps> = ({
  control,
  fetch,
  filter,
  updateItems,
  deleteItems,
  resource
}) => {
  const [isEdit, setIsEdit] = useState(!resource?.id)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenControlsDialog, setIsOpenControlsDialog] = useState(false)
  const [state, setState]: any = useState({ ...resource })

  useEffect(() => {
    if (resource.total !== state.total) {
      setState({ ...state, total: resource.total, manual: resource.manual, automatic: resource.automatic })
    }
  }, [resource])

  const openHandler = () => {
    if (!isEdit) {
      setIsOpen(!isOpen)
    }
  }

  return (
    <div className="category-control__row">
      <div className={`category-control__row-items ${!isEdit && 'pointer'} ${isOpen && 'fw-bold'}`}>
        <div className="category-control__min-cell-position">
          {!isEdit && (
            <div className="pointer" onClick={openHandler} aria-hidden="true">
              {isOpen ? <ArrowTableActiveIcon /> : <ArrowTableIcon />}
            </div>
          )}
        </div>
        <div className="category-control__min-cell" onClick={openHandler} aria-hidden="true">
          {isEdit ? (
            <Input
              className="new-input w-100"
              type="text"
              value={state.position}
              onChange={(position: string) => {
                setState({ ...state, position })
              }}
            />
          ) : (
            state.position
          )}
        </div>
        <div onClick={openHandler} aria-hidden="true">
          {isEdit ? (
            <Input
              className="new-input w-100"
              type="text"
              value={state.name}
              onChange={(name: string) => {
                setState({ ...state, name })
              }}
            />
          ) : (
            <span className="a-text-underline">{state.name}</span>
          )}
        </div>
        {!isEdit && (
          <>
            <div className="category-control__min-cell" onClick={openHandler} aria-hidden="true">
              {state.manual}
            </div>
            <div className="category-control__min-cell" onClick={openHandler} aria-hidden="true">
              {state.automatic}
            </div>
            <div className="category-control__min-cell" onClick={openHandler} aria-hidden="true">
              {state.total}
            </div>
          </>
        )}

        <div className="category-control__min-cell">
          <RemoveIcon
            className={`category-control__icon ${isEdit ? '' : 'category-control__icon--gray'}`}
            onClick={() => deleteItems(state)}
          />
          {!isEdit && (
            <EditGrayIcon
              className="category-control__icon"
              onClick={() => {
                setIsOpen(false)
                setIsEdit(!isEdit)
              }}
            />
          )}
          {!isEdit && <AddIcon className="category-control__icon" onClick={() => setIsOpenControlsDialog(true)} />}
        </div>
      </div>
      {isEdit && (
        <div className="category-control__buttons-row">
          <Button
            onClick={() => {
              if (state?.id) {
                setState({
                  ...resource
                })
                setIsEdit(false)
              } else {
                deleteItems(state)
              }
            }}
            className="a-btn a-btn-new a-btn-new-sm a-btn-outline"
          >
            Cancel
          </Button>
          <Button
            id="runAssessment"
            onClick={() => {
              if (state.position && state.name) {
                updateItems(state)
                setIsEdit(false)
              }
            }}
            className="a-btn a-btn-new a-btn-new-sm a-btn-primary"
          >
            Save
          </Button>
        </div>
      )}
      <div className="category-control__collapse">
        <Collapse isOpened={isOpen}>
          <ControlView
            isOpened={isOpen}
            category={resource}
            fetch={fetch}
            filter={filter}
            openAddControl={() => setIsOpenControlsDialog(true)}
          />
        </Collapse>
      </div>
      {isOpenControlsDialog && (
        <ControlsAssignModal
          visible={isOpenControlsDialog}
          categoryId={state.id}
          cancelModalCb={(flag) => {
            if (flag) {
              fetch()
            }
            setIsOpenControlsDialog(false)
          }}
        />
      )}
    </div>
  )
}

type ResourcesControlProps = {
  control: ReadBenchmark_benchmark
  fetch: any
}

const CategoryControl: React.FC<ResourcesControlProps> = ({ control, fetch }) => {
  const [search, setSearch]: any = useState('')
  const [controlState, setControlState]: any = useState([])
  const [isAddButton, setIsAddButton]: any = useState(true)
  const [pageState, setPageState] = useState({ page: 0, perPage: 10 })

  const data = useMemo(() => {
    const newData = [] as any

    controlState.forEach((item: any) => {
      if (search === '' || item.name?.toLowerCase()?.includes(search?.toLowerCase())) {
        newData.push({ ...item })
      } else {
        for (let index = 0; index < item.controls.length; index += 1) {
          if (item.controls[index].controlName?.toLowerCase()?.includes(search?.toLowerCase())) {
            newData.push({ ...item })
            break // we need only one match
          }
        }
      }
    })

    return newData
  }, [controlState, search])

  useEffect(() => {
    const newData = [] as any

    control?.categories?.forEach((controlSub: ReadBenchmark_benchmark_categories) => {
      const controlsTypeCount = {
        manual: 0,
        automatic: 0
      }

      controlSub.controls.forEach((controlItem: ReadBenchmark_benchmark_categories_controls) => {
        const isManual = controlItem.type === ControlType.MANUAL

        if (isManual) {
          controlsTypeCount.manual += 1
        } else {
          controlsTypeCount.automatic += 1
        }
      })

      const {
        groups: { position, name }
      }: any = /^(?<position>(\d\.?)*)?(?<name>.*)/.exec(controlSub.name)
      newData.push({
        id: controlSub.id,
        position: position || '',
        name: name.trim() || '',
        total: controlsTypeCount.manual + controlsTypeCount.automatic,
        manual: controlsTypeCount.manual,
        automatic: controlsTypeCount.automatic,
        controls: controlSub.controls
      })
    })

    setControlState(
      newData.sort((prev, next) => {
        const prevValue = parseFloat(prev.position)
        const nextValue = parseFloat(next.position)

        return (isNaN(prevValue) ? 0 : prevValue) - (isNaN(nextValue) ? 0 : nextValue)
      })
    )
  }, [control])

  const [mutate, { loading }] = useMutation<UpdateAssessmentControl, UpdateAssessmentControlVariables>(
    UPDATE_ASSESSMENT_CONTROL
  )

  const [deleteCategory, { loading: loadingDeleteCategory }] = useMutation<DeleteCategory, DeleteCategoryVariables>(
    DELETE_CATEGORY
  )
  const [updateCategory, { loading: loadingUpdateCategory }] = useMutation<UpdateCategory, UpdateCategoryVariables>(
    UPDATE_CATEGORY
  )
  const [createCategory, { loading: loadingCreateCategory, error }] = useMutation<
    CreateCategory,
    CreateCategoryVariables
  >(CREATE_CATEGORY)

  const toEndPage = (offset = 0) => {
    const endPage = Math.ceil((data.length + offset) / pageState.perPage) - 1
    if (endPage !== pageState.page) {
      setPageState({ ...pageState, page: endPage })
    }
  }

  const updateItems = (updateItem) => {
    const newStateItem = { ...updateItem }
    if (!updateItem?.id) {
      createCategory({
        variables: { benchmarkId: control.id, name: `${newStateItem.position}. ${newStateItem.name}` }
      }).then(() => fetch())
      toEndPage(1)
    } else {
      updateCategory({
        variables: {
          id: newStateItem.id,
          name: `${newStateItem.position}. ${newStateItem.name}`,
          controlIds: newStateItem.controls ? newStateItem.controls.map((control: any) => control.id) : []
        }
      }).then(() => fetch())
    }
    setIsAddButton(true)
  }

  const deleteItems = (removeItem) => {
    if (removeItem.id) {
      deleteCategory({ variables: { id: removeItem.id } }).then(() => {
        fetch()
        toEndPage(-1)
      })
    }

    setIsAddButton(true)
  }

  const perPageItems = pageState.page * pageState.perPage

  if (loading || loadingDeleteCategory) {
    return <Loading />
  }

  return (
    <div className="category-control">
      <Panel
        className="new-panel"
        title="Controls"
        renderRight={() => {
          return (
            <Input
              className="new-input"
              placeholder="Search"
              type="text"
              value={search}
              prefix={
                <span className="select-toggle-icon appkiticon icon-search-outline apply-opacity-in-closed-toggle" />
              }
              onChange={(message: string) => {
                setPageState({ ...pageState, page: 0 })
                setSearch(message)
              }}
            />
          )
        }}
      >
        <div className="category-control__table">
          <div className="category-control__header">
            <div className="category-control__min-cell-position" />
            <div className="category-control__min-cell">Nr.</div>
            <div>Control Category</div>
            <div className="category-control__min-cell">Controls (Manual)</div>
            <div className="category-control__min-cell">Controls (Automatic)</div>
            <div className="category-control__min-cell">Controls (Total)</div>
            <div className="category-control__min-cell" />
          </div>
          <div className="category-control__rows">
            {data.slice(perPageItems, perPageItems + pageState.perPage).map((resource) => (
              <CategoryControlRow
                control={control}
                key={resource.id}
                resource={resource}
                fetch={fetch}
                filter={search}
                updateItems={updateItems}
                deleteItems={deleteItems}
              />
            ))}
            {!isAddButton && (
              <CategoryControlRow
                control={control}
                key="new"
                resource={{}}
                fetch={fetch}
                filter={search}
                updateItems={updateItems}
                deleteItems={deleteItems}
              />
            )}
          </div>
          {isAddButton && (
            // eslint-disable-next-line
            <div
              className="category-control__new"
              onClick={() => {
                setIsAddButton(false)
              }}
            >
              Add Category <AddBtnIcon />
            </div>
          )}
        </div>
        <Pagination
          current={pageState.page + 1}
          total={data.length}
          defaultPageSize={10}
          pageSize={pageState.perPage}
          showSizeOptions
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          onPageSizeChange={(perPage: number) => setPageState({ ...pageState, perPage, page: 0 })}
          onChange={(update: string) =>
            setPageState({ ...pageState, page: pageState.page + (update === 'next' ? 1 : -1) })
          }
        />
      </Panel>
    </div>
  )
}

export default CategoryControl
