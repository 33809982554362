import gql from 'graphql-tag'

export const ControlFields = gql`
  fragment ControlFields on Control {
    id
    pluginName
    rating
    type
    controlId
    controlName
    description
    remediation
    controlStatus
  }
`

export const READ_CONTROLS = gql`
  query ReadControls($cloudSolutionId: ID) {
    controls(cloudSolutionId: $cloudSolutionId) {
      ...ControlFields
    }
  }
  ${ControlFields}
`

export const READ_ADDABLE_CONTROLS = gql`
  query ReadAddableControls($cloudSolutionId: ID!) {
    addableControls(cloudSolutionId: $cloudSolutionId) {
      ...ControlFields
    }
  }
  ${ControlFields}
`

export const READ_CONTROL = gql`
  query ReadControl($id: ID!) {
    control(id: $id) {
      id
      pluginName
      rating
      description
      remediation
      type
      controlId
      controlName
      controlStatus
    }
  }
`

export const CREATE_CONTROL = gql`
  mutation CreateControl(
    $cloudSolutionId: ID!
    $pluginName: String!
    $rating: Int!
    $description: String!
    $remediation: String!
    $type: ControlType!
    $controlId: String!
    $controlName: String!
    $controlStatus: ControlStatus!
  ) {
    createControl(
      cloudSolutionId: $cloudSolutionId
      value: {
        pluginName: $pluginName
        rating: $rating
        description: $description
        remediation: $remediation
        type: $type
        controlId: $controlId
        controlName: $controlName
        controlStatus: $controlStatus
      }
    ) {
      ...ControlFields
    }
  }
  ${ControlFields}
`

export const UPDATE_CONTROL = gql`
  mutation UpdateControl(
    $id: ID!
    $pluginName: String!
    $rating: Int!
    $description: String!
    $remediation: String!
    $type: ControlType!
    $controlId: String!
    $controlName: String!
    $controlStatus: ControlStatus!
  ) {
    updateControl(
      id: $id
      value: {
        pluginName: $pluginName
        rating: $rating
        description: $description
        remediation: $remediation
        type: $type
        controlId: $controlId
        controlName: $controlName
        controlStatus: $controlStatus
      }
    ) {
      ...ControlFields
    }
  }
  ${ControlFields}
`

// export const ARCHIVE_CONTROL = gql`
//   mutation ArchiveControl($id: ID!) {
//     archiveControl(id: $id)
//   }
// `
export const DELETE_CONTROL = gql`
  mutation DeleteControl($id: ID!) {
    deleteControl(id: $id)
  }
`

export const READ_PLUGINS = gql`
  query ReadPlugins($cloudSolutionId: ID!) {
    plugins(cloudSolutionId: $cloudSolutionId) {
      name
      content
    }
  }
`
