import React, { Component } from 'react'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Container, Row, Col } from 'react-grid-system'
import { Routes, Route } from 'react-router-dom'
import {
  EngagementDetails,
  CloudAccountOverview,
  CloudSubsOverview,
  AssessmentAccountOverview,
  AssessmentManage,
  AssessmentOverview,
  AnalyticsDashboard,
  GroupOverview,
  GroupDetails,
  UserOverview,
  UserDetails,
  RoleOverview,
  RoleDetails,
  IamAccountOverview
} from '..'
import './Content.scss'
import Redirect from '../Nav/Redirect'

class ContentEngagement extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Container fluid>
        <div className="config__content">
          <Row>
            <Col md={12}>
              <Routes>
                <Route path="/" element={<Redirect to="details" />} />
                <Route path="/details" element={<EngagementDetails />} />
                <Route path="cloud/:cloudSolutionId/accounts" element={<CloudAccountOverview />} />
                <Route path="cloud/:cloudSolutionId/iam_accounts" element={<IamAccountOverview />} />
                <Route path="cloud/:cloudSolutionId/iam_accounts/:cloudAccountId/users" element={<UserOverview />} />
                <Route
                  path="cloud/:cloudSolutionId/iam_accounts/:cloudAccountId/users/:userId"
                  element={<UserDetails />}
                />
                <Route path="cloud/:cloudSolutionId/iam_accounts/:cloudAccountId/groups" element={<GroupOverview />} />
                <Route
                  path="cloud/:cloudSolutionId/iam_accounts/:cloudAccountId/groups/:groupId"
                  element={<GroupDetails />}
                />
                <Route path="cloud/:cloudSolutionId/iam_accounts/:cloudAccountId/roles" element={<RoleOverview />} />
                <Route
                  path="cloud/:cloudSolutionId/iam_accounts/:cloudAccountId/roles/:roleId"
                  element={<RoleDetails />}
                />
                <Route
                  path="cloud/:cloudSolutionId/accounts/:cloudAccountId/subscriptions"
                  element={<CloudSubsOverview />}
                />
                <Route path="cloud/:cloudSolutionId/assessments_accounts" element={<AssessmentAccountOverview />} />
                <Route
                  path="cloud/:cloudSolutionId/assessments_accounts/:cloudAccountId/assessments"
                  element={<AssessmentOverview />}
                />
                <Route
                  path="cloud/:cloudSolutionId/assessments_accounts/:cloudAccountId/assessments/create"
                  element={<AssessmentManage cancelModalCb={() => {}} />}
                />
                <Route path="/analytics_dashboard/*" element={<AnalyticsDashboard />} />
              </Routes>
            </Col>
          </Row>
        </div>
      </Container>
    )
  }
}

export default ContentEngagement
