import React from 'react'
import { Container, Row } from 'react-grid-system'
import { Button } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router'
import { AuthorizeRole } from '..'

const CloudAccountNavLink: React.FC = () => {
  const navigate: any = useNavigate()

  return (
    <Container fluid>
      <Row align="center" justify="center" style={{ height: 150 }}>
        <div>
          <div>There are no cloud accounts. Please connect an account first.</div>
          <div>
            <AuthorizeRole role="admin">
              <Button
                style={{ position: 'relative', left: '30%', top: '20px' }}
                onClick={() => navigate('./accounts/create')}
              >
                Connect Cloud Account
              </Button>
            </AuthorizeRole>
          </div>
        </div>
      </Row>
    </Container>
  )
}

export default CloudAccountNavLink
