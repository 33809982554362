/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AssessmentExecutionStatus {
  CREATED = "CREATED",
  DONE = "DONE",
  FAILED = "FAILED",
}

export enum BenchmarkStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  INACTIVE = "INACTIVE",
}

export enum CapabilityStatus {
  ACTIVE = "ACTIVE",
  INVALID = "INVALID",
  NOT_APPLICABLE = "NOT_APPLICABLE",
}

export enum CheckCategory {
  Kubernetes = "Kubernetes",
  PaaS_IaaS = "PaaS_IaaS",
}

export enum Classification {
  COMPLIANT = "COMPLIANT",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NOT_COMPLIANT = "NOT_COMPLIANT",
  UNKNOWN = "UNKNOWN",
}

export enum CloudCheckType {
  AWS = "AWS",
  AWS_EKS = "AWS_EKS",
  AZURE = "AZURE",
  AZURE_AKS = "AZURE_AKS",
  GCP = "GCP",
  GOOGLE_GKE = "GOOGLE_GKE",
}

export enum ControlRating {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum ControlStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  INACTIVE = "INACTIVE",
}

export enum ControlType {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

export enum Criticality {
  CRITICAL = "CRITICAL",
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  UNKNOWN = "UNKNOWN",
}

export enum DocumentStatus {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export enum DocumentType {
  AWS_CLOUD = "AWS_CLOUD",
  AZURE_CLOUD = "AZURE_CLOUD",
  GCP_CLOUD = "GCP_CLOUD",
}

export enum ResourceStatus {
  FAILED = "FAILED",
  PASSED = "PASSED",
  SKIPPED = "SKIPPED",
  UNKNOWN = "UNKNOWN",
}

export interface AssessmentResourceResultInput {
  id: string;
  resourceId: string;
  subscriptionId: string;
  status: ResourceStatus;
  message: string;
}

export interface BenchmarkGroupInput {
  name: string;
}

export interface BenchmarkSourceInput {
  name: string;
}

export interface CredentialsAwsInput {
  accessKey: string;
  secretAccessKey: string;
}

export interface CredentialsAzureInput {
  appID: string;
  tenantID: string;
  clientSecret: string;
}

export interface CredentialsGcpInput {
  credentialsJson: string;
}

export interface OverrideAssessmentControlInput {
  overridden: boolean;
  overrideStatus: Classification;
  overrideReason: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
