import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Input, Modal, ModalBody, ModalFooter } from '@pwc-de/appkit-react'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'
import { Loading, Error } from '..'
import { DeleteCloudAccount, DeleteCloudAccountVariables } from '../../graphql/__generated__/DeleteCloudAccount'
import { ReadCloudAccounts, ReadCloudAccountsVariables } from '../../graphql/__generated__/ReadCloudAccounts'
import { DELETE_CLOUD_ACCOUNT, READ_CLOUD_ACCOUNTS, READ_ALL_CLOUD_ACCOUNTS } from '../../graphql/account'
import { ReadAllCloudAccounts, ReadAllCloudAccountsVariables } from '../../graphql/__generated__/ReadAllCloudAccounts'
import EngagementDelete from '../Engagement/EngagementDelete'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'

type CloudAccountDeleteProps = {
  id?: string
  engagementId?: string
  cloudSolutionId?: string
  cloudAccountName?: string
  closeModalCb?: () => void
  deleteCb?: () => void
}

const CloudAccountDeleteModal: React.FC<CloudAccountDeleteProps> = ({
  id,
  engagementId = '',
  cloudSolutionId = '',
  cloudAccountName = '',
  closeModalCb,
  deleteCb
}) => {
  const [mutate, { loading, error }] = useMutation<DeleteCloudAccount, DeleteCloudAccountVariables>(
    DELETE_CLOUD_ACCOUNT,
    {
      variables: {
        id: id || '',
        engagementId
      },
      update: (store, { data }) => {
        const cacheData = store.readQuery<ReadCloudAccounts, ReadCloudAccountsVariables>({
          query: READ_CLOUD_ACCOUNTS,
          variables: { cloudSolutionId, engagementId }
        })

        if (data && cacheData && cacheData.cloudAccounts) {
          const cloudAccounts = cacheData.cloudAccounts.filter((cloudAcc) =>
            data.deleteCloudAccount ? cloudAcc.id !== data.deleteCloudAccount.id : false
          )

          store.writeQuery<ReadCloudAccounts, ReadCloudAccountsVariables>({
            query: READ_CLOUD_ACCOUNTS,
            variables: { cloudSolutionId, engagementId },
            data: { cloudAccounts }
          })
        }

        const cache = store.readQuery<ReadAllCloudAccounts, ReadAllCloudAccountsVariables>({
          query: READ_ALL_CLOUD_ACCOUNTS,
          variables: { cloudSolutionId, engagementId }
        })

        if (data && cache && cache.cloudAccounts) {
          const cloudAccounts = cache.cloudAccounts.map((cloudAcc) => {
            if (data.deleteCloudAccount.id === cloudAcc.id) {
              const updatedCloudAcc = { ...cloudAcc }
              updatedCloudAcc.deleted = true
              return updatedCloudAcc
            }
            return cloudAcc
          })

          store.writeQuery<ReadAllCloudAccounts, ReadAllCloudAccountsVariables>({
            query: READ_ALL_CLOUD_ACCOUNTS,
            variables: { cloudSolutionId, engagementId },
            data: { cloudAccounts }
          })
        }
      }
    }
  )

  const [input, setInput] = useState('')

  if (error) {
    return <Error />
  }

  const deleteCloudAccountCb = async () => {
    await mutate()
  }

  const isValid = input === 'DELETE'

  return (
    <Modal
      className="baseline-modal-showcase form-modal new-modal center-middle"
      visible
      onCancel={() => {
        if (closeModalCb) closeModalCb()
      }}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div className="a-panel-title">Delete {cloudAccountName}</div>
      </ModalHeaderWrapper>
      <ModalBody className="px-3">
        <h2 className="a-mt-20 a-mb-10">{cloudAccountName}</h2>
        <p>
          Type ‘DELETE’ into the field below to delete this account. This action cannot be undone. All data and
          assessments for this account will also be deleted.
        </p>
        <Input
          className={'fix-first'}
          id="delete"
          placeholder="DELETE"
          value={input}
          onChange={(name: string) => {
            setInput(name)
          }}
        />
      </ModalBody>

      <ModalFooter>
        <div className="button-comp">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Button
                className="a-btn a-btn-new a-btn-outline"
                onClick={() => {
                  if (closeModalCb) closeModalCb()
                }}
              >
                Cancel
              </Button>
              <Button
                id="engagementDeleteModal"
                className="a-btn a-btn-new a-btn-primary"
                disabled={!isValid || loading}
                onClick={async () => {
                  await deleteCloudAccountCb()
                  if (deleteCb) deleteCb()
                }}
              >
                Delete
              </Button>
            </>
          )}
        </div>
      </ModalFooter>
    </Modal>
  )
}

CloudAccountDeleteModal.defaultProps = {
  id: '',
  engagementId: '',
  cloudSolutionId: ''
}

export default CloudAccountDeleteModal
