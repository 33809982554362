import React, { useMemo, useRef, useState } from 'react'
import { Select, SelectOption } from '@pwc-de/appkit-react'
import { ReadAssessment_assessment } from '../../../graphql/__generated__/ReadAssessment'
import { ControlType, Classification } from '../../../graphql/__generated__/globalTypes'
import { useFilterContext } from './FilterContext'
import './Filter.scss'
import SelectTree from '../../SelectTree/SelectTree'

//--------------------------------------------------------------------------
const AssessmentResultFilter: React.FC<{
  assessment: ReadAssessment_assessment
}> = ({ assessment }) => {
  const { state, dispatch } = useFilterContext()
  const initialAddCategoryData: any = useRef([])

  const [checked, setChecked]: any = useState([])
  const setCheckedCategory = (data) => {
    const selectedCategories: string[] = []
    const selectedControls: string[] = []
    setChecked(data)
    data.forEach((item) => {
      const itemData = JSON.parse(item)
      if (!selectedCategories.includes(itemData.parentId)) {
        selectedCategories.push(itemData.parentId)
      }

      selectedControls.push(itemData.id)
    })

    dispatch({ type: 'setSelectedCategories', selectedCategories })
    dispatch({ type: 'setSelectedControls', selectedControls })
    dispatch({ type: 'applyFilters' })
  }

  const categoryControl = useMemo(() => {
    const data: any[] = []

    const list = assessment.categories.map((category) => ({
      value: category.id,
      label: category.name,
      children: category.controls.map((control) => {
        const value = JSON.stringify({ id: control.id, parentId: category.id })
        data.push(value)
        return {
          value,
          label: control.controlId
        }
      })
    }))

    initialAddCategoryData.current = data

    if (!checked.length) {
      setTimeout(() => setChecked(initialAddCategoryData.current), 0)
    }

    return list
  }, [assessment])

  return (
    <div className="filter-section">
      <h3 className="filter-section__header">Filter options</h3>
      <div className="filter-section__filters">
        <SelectTree
          nodes={categoryControl}
          checked={checked}
          allSelectedTextCustom="Categories & Controls"
          selectedTextCustom={`${state.selectedCategories.length} categories & ${state.selectedControls.length} controls selected`}
          setChecked={setCheckedCategory}
          allData={initialAddCategoryData}
        />
        <Select
          className="filter-section__select-item"
          placeholder="Control Type"
          customSelectAllStringOnList={'All Types'}
          customSelectAllStringOnToggle={'All Types'}
          value={state.selectedControlTypes}
          onSelect={(e) => {
            dispatch({ type: 'setSelectedControlTypes', selectedControlTypes: e })
            dispatch({ type: 'applyFilters' })
          }}
          multiple
        >
          <SelectOption value={ControlType.AUTOMATIC}>Automatic</SelectOption>
          <SelectOption value={ControlType.MANUAL}>Manual</SelectOption>
        </Select>
        <Select
          className="filter-section__select-item"
          placeholder="Control Classification"
          customSelectAllStringOnList={'All Classification'}
          customSelectAllStringOnToggle={'All Classification'}
          value={state.selectedControlClassification}
          onSelect={(e) => {
            dispatch({ type: 'setSelectedControlClassification', selectedControlClassification: e })
            dispatch({ type: 'applyFilters' })
          }}
          multiple
        >
          <SelectOption value={Classification.COMPLIANT}>Compliant</SelectOption>
          <SelectOption value={Classification.NOT_COMPLIANT}>Not Compliant</SelectOption>
          <SelectOption value={Classification.NOT_APPLICABLE}>Not Applicable</SelectOption>
        </Select>
      </div>
    </div>
  )
}

export default AssessmentResultFilter
