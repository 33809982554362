import React from 'react'
import CloudSubsOverview from '../CloudSubs/CloudSubsOverview'

type CloudAccountSubscriptionProps = {
  cloudAccountId
}

const CloudAccountCapabilitiesCard: React.FC<CloudAccountSubscriptionProps> = ({ cloudAccountId }) => (
  <CloudSubsOverview cloudAccountId={cloudAccountId} />
)

export default CloudAccountCapabilitiesCard
