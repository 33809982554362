import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { SelectTable, Button, Panel } from '@pwc-de/appkit-react'
import { READ_CATEGORY } from '../../graphql/category'
import { READ_ADDABLE_CONTROLS } from '../../graphql/control'
import { ReadCategory, ReadCategoryVariables } from '../../graphql/__generated__/ReadCategory'
import Category from '../Category/Category'
import { CategoryUpdate } from '../Category/CategoryUpdate'
import { Loading, Error } from '..'
import sortControls from './ControlSorting'
import { ReadAddableControls, ReadAddableControlsVariables } from '../../graphql/__generated__/ReadAddableControls'

type CategoryControlAssignParams = {
  categoryId?: string
  cloudSolutionId?: string
}

const CategoryControlAssign: React.FC = () => {
  const { categoryId, cloudSolutionId }: Readonly<CategoryControlAssignParams> = useParams()
  const categoryData = useQuery<ReadCategory, ReadCategoryVariables>(READ_CATEGORY, { variables: { id: categoryId! } })

  let category = new Category()
  if (categoryData.data && categoryData.data.category) {
    category = categoryData.data.category
  }

  const categoryControlMap = sortControls(category.controls)

  const [selectedControls, setSelectedControls] = useState(categoryControlMap.map((c) => c.control))

  const [selectAll, setSelectAll] = useState<boolean>(false)

  const { data, loading, error } = useQuery<ReadAddableControls, ReadAddableControlsVariables>(READ_ADDABLE_CONTROLS, {
    variables: { cloudSolutionId: cloudSolutionId! }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }
  if (!data) {
    return <p>Not found</p>
  }

  const controls = sortControls(data.addableControls)

  return (
    <ControlAssign
      category={category}
      selectedControls={selectedControls}
      setSelectedControls={setSelectedControls}
      selectAll={selectAll}
      setSelectAll={setSelectAll}
      controls={controls.map((c) => c.control)}
    />
  )
}

type ControlAssignProps = {
  category: any
  selectedControls: any
  setSelectedControls: any
  selectAll: any
  setSelectAll: any
  controls: any
}

const ControlAssign: React.FC<ControlAssignProps> = ({
  category,
  selectedControls,
  setSelectedControls,
  selectAll,
  setSelectAll,
  controls
}) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'controlId',
      Cell: ({ original, value }) => (
        <Link to={`${original.id}${location?.search}`}>
          {value} {original.controlName}
        </Link>
      ),
      filterable: true,
      filterMethod: (filter: any, row: any) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase())
    }
  ]

  return (
    <Panel
      title={`${category.name}: Select Control`}
      renderRight={() => {
        const updatedCategory = { ...category }
        updatedCategory.controls = selectedControls

        return (
          <>
            <Link to={`..${location?.search}`}>
              <CategoryUpdate category={updatedCategory}>Save</CategoryUpdate>
            </Link>
            <Link to={`..${location?.search}`}>
              <Button gray>Cancel</Button>
            </Link>
          </>
        )
      }}
    >
      <SelectTable
        toggleSelection={toggleSelection.bind(null, selectedControls, setSelectedControls)}
        toggleAll={toggleAll.bind(null, !selectAll, setSelectAll, [], setSelectedControls, controls)}
        selectAll={selectAll}
        isSelected={isSelected.bind(null, selectedControls)}
        selectType="checkbox"
        keyField="id"
        data={controls}
        columns={columns}
        sortable={false}
      />
    </Panel>
  )
}

const toggleSelection = (selection: any, setSelection: any, key: any, shift: any, row: any) => {
  const keyIndex = selection.map((s: any) => s.id).indexOf(key)
  if (keyIndex < 0) {
    setSelection([
      ...selection,
      {
        id: row.id
      }
    ])
  } else {
    setSelection([...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)])
  }
}

const toggleAll = (selectAll: any, setSelectAll: any, selection: any, setSelection: any, data: any) => {
  if (selectAll) {
    data.forEach((record: any) => {
      selection.push({
        id: record.id
      })
    })
  }
  setSelection(selection)
  setSelectAll(selectAll)
}

const isSelected = (selection: any, key: any) => selection.map((s: any) => s.id).includes(key)

export default CategoryControlAssign
