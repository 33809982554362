import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Container, Row, Col } from 'react-grid-system'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { Route, Routes, useLocation, useParams } from 'react-router-dom'
import { ReadCloudSolutions } from '../../graphql/__generated__/ReadCloudSolutions'
import { READ_CLOUD_SOLUTIONS } from '../../graphql/cloudSolution'
import { Loading, Error, AssessmentError } from '..'
import LoadingPanel from '../Status/LoadingPanel'
import { ReadAllCloudAccounts, ReadAllCloudAccountsVariables } from '../../graphql/__generated__/ReadAllCloudAccounts'
import { READ_ALL_CLOUD_ACCOUNTS, READ_CLOUD_ACCOUNT } from '../../graphql/account'
import { ReadBenchmarks, ReadBenchmarksVariables } from '../../graphql/__generated__/ReadBenchmarks'
import { READ_BENCHMARKS } from '../../graphql/benchmark'
import { READ_MASTER_DASHBOARD } from '../../graphql/master-dashboard'
import { ReadMasterDashboard, ReadMasterDashboardVariables } from '../../graphql/__generated__/ReadMasterDashboard'
import { READ_SOLUTION_DASHBOARD } from '../../graphql/solution-dashboard'
import {
  ReadSolutionDashboard,
  ReadSolutionDashboardVariables
} from '../../graphql/__generated__/ReadSolutionDashboard'
import './AnalyticsDashboard.scss'
import CloudAccountCard from './CloudAccountCard'
import CloudAccountCapabilitiesCard from './CloudAccountCapabilitiesCard'
import AccountInfoCard from '../AccountInfoCard/AccountInfoCard'
import UserOverview from '../User/UserOverview'
import RoleOverview from '../Role/RoleOverview'
import IamAccountOverview from '../Iam/IamAccountOverview'
import GroupOverview from '../Group/GroupOverview'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import { ReadEngagement, ReadEngagementVariables } from '../../graphql/__generated__/ReadEngagement'
import { READ_ENGAGEMENT } from '../../graphql/engagement'
import EngagementTable from './EngagementTable'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import BenchmarkCard from './BenchmarkCard'
import ControlPage from './ControlPage'
import {
  ReadAssessment,
  ReadAssessment_assessment,
  ReadAssessment_assessment_categories,
  ReadAssessment_assessment_categories_controls,
  ReadAssessmentVariables
} from '../../graphql/__generated__/ReadAssessment'
import { READ_ASSESSMENT } from '../../graphql/assessment'
import AssessmentPage from './AssessmentPage'

const parseQueryString = require('query-string')

export const pageListDashboard = [
  { name: 'Assessments', value: 'assessments' },
  { name: 'Cloud Capabilities & Subscriptions', value: 'capabilities' },
  { name: 'Identity and Access Management', value: 'management' }
]

type DashboardSolutionProps = {
  engagementId: string
  engagementName: string
  solutionId: string
  solutionName: string
  accountId: string
  accountName: string
  benchmarkId: string
  assessmentId: string
  controlId: string
  assessmentPageId: string
}

const DashboardSolution: React.FC<DashboardSolutionProps> = ({
  engagementId = '',
  engagementName = '',
  solutionId = '',
  solutionName = '',
  accountId = '',
  accountName = '',
  benchmarkId = '',
  controlId = '',
  assessmentId = '',
  assessmentPageId = ''
}) => {
  const location: any = useLocation()
  const navigate: any = useNavigate()
  const urlLatest = location.pathname.match(/[a-zA-Z]*$/)?.[0]
  const [pageActive, setPageActive] = useState(
    pageListDashboard.find((page) => page.value === urlLatest)?.value || pageListDashboard[0].value
  )
  const [assessmentSelected, setAssessmentSelected]: any = useState(null)

  const [assessment, setAssessment] = useState<ReadAssessment_assessment>()
  const [control, setControl]: any = useState<ReadAssessment_assessment_categories_controls>()

  useEffect(() => {
    const newPage: string | undefined = pageListDashboard.find((page) => page.value === urlLatest)?.value
    if (pageActive !== newPage) {
      setPageActive(newPage as string)
    }
  }, [urlLatest])

  const masterDashboardQuery = useQuery<ReadMasterDashboard, ReadMasterDashboardVariables>(READ_MASTER_DASHBOARD, {
    variables: { engagementId },
    fetchPolicy: 'network-only'
  })

  const engagementQuery = useQuery<ReadEngagement, ReadEngagementVariables>(READ_ENGAGEMENT, {
    variables: { id: engagementId! },
    fetchPolicy: 'network-only'
  })

  const analyticsQuery = useQuery<ReadSolutionDashboard, ReadSolutionDashboardVariables>(READ_SOLUTION_DASHBOARD, {
    fetchPolicy: 'network-only',
    variables: { cloudSolutionId: solutionId!, engagementId },
    skip: !solutionId
  })

  const accountsQuery = useQuery<ReadAllCloudAccounts, ReadAllCloudAccountsVariables>(READ_ALL_CLOUD_ACCOUNTS, {
    variables: { cloudSolutionId: solutionId!, engagementId },
    skip: !solutionId
  })

  const assessmentQuery = useQuery<ReadAssessment, ReadAssessmentVariables>(READ_ASSESSMENT, {
    variables: { id: assessmentSelected?.value },
    skip: !assessmentSelected?.value,
    onCompleted: (data) => {
      setAssessment(data.assessment)
    }
  })

  const benchmarks =
    analyticsQuery && analyticsQuery.data
      ? analyticsQuery.data.solutionDashboard.cloudAccounts?.find((item) => item.cloudAccount.id === accountId)
          ?.benchmarks
      : []
  const benchmarkName = benchmarks?.find((benchmark) => benchmark.benchmarkId === benchmarkId)?.name || ''
  const filteredAccountData =
    accountsQuery && accountsQuery.data
      ? accountsQuery.data?.cloudAccounts?.filter((item) => item.id === accountId)
      : []

  const typeData = useMemo(
    () => accountsQuery.data?.cloudAccounts?.find((item) => item.id === accountId)?.type,
    [accountsQuery, accountId]
  )

  const results = useMemo(() => {
    if (!filteredAccountData[0]?.assessments?.length) {
      return []
    }

    const filteredAssessments = filteredAccountData[0]?.assessments.filter(
      (assessmentItem) => assessmentItem.name === benchmarkName || assessmentItem.id === assessmentPageId
    )
    return filteredAssessments
      .sort((prev, next) => (new Date(next.timestamp) as any) - (new Date(prev.timestamp) as any))
      .map((assessmentItem) => {
        const dateStr = new Date(assessmentItem.timestamp)
        return {
          value: assessmentItem.id,
          name: moment(dateStr).format('DD/MM/YYYY HH:mm:ss'),
          score: assessmentItem.analytics.score,
          executionStatus: assessmentItem.executionStatus
        }
      })
  }, [accountsQuery])

  useEffect(() => {
    if (controlId) {
      if (assessment?.categories?.length) {
        const controls: ReadAssessment_assessment_categories_controls[] = []
        assessment?.categories?.forEach((categoryItem: ReadAssessment_assessment_categories) =>
          categoryItem.controls.forEach((controlItem) => controls.push(controlItem))
        )
        const controlItem: any = controls.find((controlData) => controlData.controlId === controlId)
        setControl(controlItem)
      }
    } else {
      setControl(null)
    }
  }, [controlId, assessment])

  useEffect(() => {
    if (results) {
      const value = assessmentPageId
        ? results.find((item: any) => item.value === assessmentPageId)
        : assessmentId
        ? results.find((item: any) => item.value === assessmentId)
        : results?.[0]

      if (
        assessmentPageId
          ? assessmentSelected?.value === assessmentPageId &&
            value?.executionStatus === assessmentSelected?.executionStatus
          : assessmentId
          ? assessmentSelected?.value === assessmentId && value?.executionStatus === assessmentSelected?.executionStatus
          : results?.[0]?.value === assessmentSelected?.value &&
            results?.[0]?.executionStatus === assessmentSelected?.executionStatus
      ) {
        return
      }

      setAssessmentSelected(value)
    }
  }, [results, assessmentPageId])

  // const onUpdateSolution = ({ name, value }) => {
  //   if (value && value !== "All CloudSolutions") {
  //     navigate(
  //       `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${value}&cloudSolutionName=${name}`
  //     );
  //   } else {
  //     navigate(`analytics_dashboard`);
  //   }
  // };

  const onUpdateAccount = ({ name, value }) => {
    window.scrollTo(0, 0)
    localStorage.removeItem('inputValue')
    localStorage.removeItem('openValue')
    localStorage.removeItem('scrollValue')
    if (value && value !== 'All Accounts' && solutionId) {
      navigate(
        `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}&accountName=${name}&accountId=${value}`
      )
    } else {
      navigate(
        `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}`
      )
    }
  }

  const onUpdateBenchmark = ({ name, value, assessmentId }) => {
    window.scrollTo(0, 0)
    localStorage.removeItem('inputValue')
    localStorage.removeItem('openValue')
    localStorage.removeItem('scrollValue')
    if (value && solutionId && accountId) {
      navigate(
        `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}&accountName=${accountName}&accountId=${accountId}&benchmarkName=${name}&benchmarkId=${value}${
          assessmentId ? `&assessmentPageId=${assessmentId}` : ''
        }`
      )
    } else {
      navigate(
        `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}&accountName=${accountName}&accountId=${accountId}${
          assessmentId ? `&assessmentPageId=${assessmentId}` : ''
        }`
      )
    }
  }

  const onUpdateControl = ({ name, value, lastSearch }) => {
    window.scrollTo(0, 0)
    if (lastSearch) localStorage.setItem('inputValue', lastSearch)
    navigate(
      `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}&accountName=${accountName}&accountId=${accountId}&benchmarkName=${benchmarkName}&benchmarkId=${benchmarkId}&controlName=${name}&controlId=${value}&assessmentId=${assessmentSelected?.value}`
    )
  }

  if (
    engagementQuery.loading ||
    masterDashboardQuery.loading ||
    accountsQuery.loading ||
    analyticsQuery.loading ||
    assessmentQuery.loading
  ) {
    return <Loading />
  }
  if (
    engagementQuery.error ||
    masterDashboardQuery.error ||
    accountsQuery.error ||
    analyticsQuery.error ||
    assessmentQuery.error
  ) {
    return <Error />
  }

  if (assessmentQuery.data && assessmentQuery.data.assessment.executionStatus === 'FAILED') {
    const errorMessage = assessmentQuery.data.assessment.errorMessage || ''
    return <AssessmentError errorMessage={errorMessage} />
  }

  if (!masterDashboardQuery.data) {
    return null
  }

  return (
    <>
      <Row nogutter>
        <AccountInfoCard
          solutionId={solutionId}
          solutionName={solutionName}
          engagementId={engagementId}
          engagementName={engagementName}
          accountId={accountId}
          accountName={accountName}
          benchmarkId={benchmarkId}
          benchmarkName={benchmarkName}
          control={control}
          engagementQuery={engagementQuery}
          masterDashboardQuery={masterDashboardQuery}
          analyticsQuery={analyticsQuery}
          pageActive={pageActive}
          pageList={pageListDashboard}
          results={results}
          assessmentSelected={assessmentSelected}
          setAssessmentSelected={(id: string) => {
            setAssessmentSelected(results.find((item: any) => item.value === id))
            navigate(
              `/engagements/${engagementId}/analytics_dashboard?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}&accountName=${accountName}&accountId=${accountId}&benchmarkName=${benchmarkName}&benchmarkId=${benchmarkId}&assessmentPageId=${id}`
            )
          }}
          onChangePage={(value) => {
            setPageActive(value)
            navigate(`${value}${location?.search}`)
          }}
        />
      </Row>
      <Row nogutter className="flex-grow-1">
        <Col className="h-100">
          <div className="dashboard-grid--container">
            {!solutionId && !accountId && !benchmarkId && (
              <EngagementTable masterDashboardQuery={masterDashboardQuery} />
            )}
            {
              // cloudAccountLevel
              solutionId && !accountId && !benchmarkId && (
                <CloudAccountCard
                  engagementId={engagementId}
                  cloudSolution={masterDashboardQuery.data.masterDashboard.cloudSolutions.find(
                    (cs) => cs.id === solutionId
                  )}
                  analyticsQuery={analyticsQuery}
                  onUpdateAccount={onUpdateAccount}
                />
              )
            }
          </div>

          {
            // benchmarkLevel
            solutionId && accountId && benchmarkId && !controlId && assessmentSelected?.value && (
              <BenchmarkCard
                assessmentId={assessmentSelected?.value}
                assessmentQuery={assessmentQuery}
                assessment={assessment}
                setAssessment={setAssessment}
                accountId={accountId}
                benchmarkId={benchmarkId}
                analyticsQuery={analyticsQuery}
                onUpdateControl={onUpdateControl}
              />
            )
          }

          {
            // assessmentLevel
            solutionId && accountId && benchmarkId && controlId && (
              <ControlPage control={control} assessmentId={assessmentId} />
            )
          }

          {!benchmarkId && (
            <Routes>
              <Route
                path="assessments"
                element={
                  <div>
                    <AssessmentPage
                      accountId={accountId}
                      analyticsQuery={analyticsQuery}
                      cloudAccounts={filteredAccountData}
                      onUpdateBenchmark={onUpdateBenchmark}
                    />
                  </div>
                }
              />
              <Route
                path="capabilities"
                element={
                  <div>
                    <div className="top-right">
                      <CloudAccountCapabilitiesCard cloudAccountId={accountId} />
                    </div>
                  </div>
                }
              />
              <Route
                path="management"
                element={
                  <div>
                    <IamAccountOverview engagementId={engagementId} cloudSolutionId={solutionId} isShowAccessError>
                      <UserOverview
                        cloudAccountIdParams={accountId}
                        urlParent={`/engagements/${engagementId}/cloud/${solutionId}/iam_accounts/${accountId}/users/`}
                        urlParams={`${location.search}`}
                      />
                      {typeData === CloudCheckType.AWS && (
                        <GroupOverview
                          cloudAccountIdParams={accountId}
                          urlParent={`/engagements/${engagementId}/cloud/${solutionId}/iam_accounts/${accountId}/groups/`}
                          urlParams={`${location.search}`}
                        />
                      )}
                      {typeData !== CloudCheckType.AWS && (
                        <RoleOverview
                          cloudAccountIdParams={accountId}
                          urlParent={`/engagements/${engagementId}/cloud/${solutionId}/iam_accounts/${accountId}/roles/`}
                          urlParams={`${location.search}`}
                        />
                      )}
                    </IamAccountOverview>
                  </div>
                }
              />
            </Routes>
          )}
        </Col>
      </Row>
    </>
  )
}

type AnalyticsDashboardParams = {
  engagementId?: string
}

export const AnalyticsDashboard: React.FC = () => {
  const { engagementId }: Readonly<AnalyticsDashboardParams> = useParams()
  const { data, loading, error } = useQuery<ReadCloudSolutions>(READ_CLOUD_SOLUTIONS)

  const queryParams = parseQueryString.parse(location?.search)

  if (error) {
    return <Error />
  }
  if (!data) {
    return null
  }

  return (
    <LoadingPanel title="Analytics Dashboard" isLoading={loading} isPanel={false}>
      {data.cloudSolutions.length && (
        <Container fluid className="dashboard-container d-flex flex-column">
          <DashboardSolution
            engagementId={engagementId || ''}
            engagementName={queryParams.engagementName || ''}
            solutionId={queryParams.cloudSolutionId}
            solutionName={queryParams.cloudSolutionName}
            accountId={queryParams.accountId}
            accountName={queryParams.accountName}
            benchmarkId={queryParams.benchmarkId}
            controlId={queryParams.controlId}
            assessmentId={queryParams.assessmentId}
            assessmentPageId={queryParams.assessmentPageId}
          />
        </Container>
      )}
    </LoadingPanel>
  )
}

export default AnalyticsDashboard
