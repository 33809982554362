import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Table, Button } from '@pwc-de/appkit-react'
import CloudAccountDelete from './CloudAccountDelete'
import { READ_CLOUD_ACCOUNTS } from '../../graphql/account'
import { Error, AuthorizeRole } from '..'
import { getHeaderProps, getProps } from '../util'
import { ReadCloudAccounts, ReadCloudAccountsVariables } from '../../graphql/__generated__/ReadCloudAccounts'
import LoadingPanel from '../Status/LoadingPanel'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import { CredentialsAws, CredentialsAzure } from './CloudAccount'

type CloudAccountProps = {
  engagementId?: string
  cloudSolutionId?: string
}

const CloudAccountOverview: React.FC = () => {
  const { engagementId, cloudSolutionId }: Readonly<CloudAccountProps> = useParams()

  const { data, loading, error } = useQuery<ReadCloudAccounts, ReadCloudAccountsVariables>(READ_CLOUD_ACCOUNTS, {
    variables: {
      cloudSolutionId: cloudSolutionId || '',
      engagementId: engagementId || ''
    }
  })

  if (error) {
    return <Error />
  }

  const { cloudAccounts, appIdHeader } = getAppIDHeader(data)

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ original, value }) => <Link to={`${original.id}/subscriptions`}>{value}</Link>
    },
    {
      Header: appIdHeader,
      accessor: 'appID'
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ original, value }) => (
        <>
          <Link to={`${original.id}`}>
            <AuthorizeRole role="admin">
              <Button id="editCloudAccount">Edit</Button>
            </AuthorizeRole>
          </Link>
          <Link to=".">
            <AuthorizeRole role="admin">
              <CloudAccountDelete id={value} engagementId={engagementId} cloudSolutionId={cloudSolutionId} />
            </AuthorizeRole>
          </Link>
        </>
      ),
      getHeaderProps,
      getProps
    }
  ]

  return (
    <LoadingPanel
      title="Accounts"
      renderRight={() => (
        <Link to="create">
          <AuthorizeRole role="admin">
            <Button id="createCloudAccount">Connect Account</Button>
          </AuthorizeRole>
        </Link>
      )}
      isLoading={loading}
    >
      <Table data={cloudAccounts} columns={columns} defaultSorted={[{ id: 'name' }]} />
    </LoadingPanel>
  )
}

export function getAppIDHeader(result?: ReadCloudAccounts) {
  let appIdHeader = ''
  return {
    cloudAccounts: result?.cloudAccounts.map((cloudAccount) => {
      switch (cloudAccount.type) {
        case CloudCheckType.AWS:
        case CloudCheckType.AWS_EKS: {
          appIdHeader = 'Access key'
          return { ...cloudAccount, appID: (cloudAccount.credentials as CredentialsAws).accessKey }
        }
        case CloudCheckType.AZURE:
        case CloudCheckType.AZURE_AKS: {
          appIdHeader = 'App ID'
          return { ...cloudAccount, appID: (cloudAccount.credentials as CredentialsAzure).appID }
        }
        case CloudCheckType.GCP:
        case CloudCheckType.GOOGLE_GKE: {
          appIdHeader = 'Client ID'
          // TODO: Add appID
          return { ...cloudAccount, appID: '' }
        }
        default: {
          appIdHeader = 'Unknown'
          return { ...cloudAccount, appID: 'Unknown' }
        }
      }
    }),
    appIdHeader
  }
}

export default CloudAccountOverview
