import gql from 'graphql-tag'

const CloudAccountFields = gql`
  fragment CloudAccountFields on CloudAccountOutput {
    id
    name
    type
    deleted
    credentials {
      ... on CredentialsAwsOutput {
        accessKey
        secretAccessKey
      }
      ... on CredentialsAzureOutput {
        appID
        tenantID
        clientSecret
      }
      ... on CredentialsGcpOutput {
        credentialsJson
      }
    }
    accountCapability {
      runAssessments
      isIamAccount
      readIamUsers
      readIamGroups
      readIamGroupMember
      readIamRoles
    }
    assessments {
      id
      name
      timestamp
      executionStatus
      analytics {
        score
        classification
        numControls
        numControlsUnknown
        numControlsNotApplicable
        numControlsNotCompliant
        numControlsCompliant
      }
    }
  }
`

export const READ_CLOUD_ACCOUNTS = gql`
  query ReadCloudAccounts($cloudSolutionId: ID!, $engagementId: ID!) {
    cloudAccounts(cloudSolutionId: $cloudSolutionId, engagementId: $engagementId, isDeleted: false) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const READ_ALL_CLOUD_ACCOUNTS = gql`
  query ReadAllCloudAccounts($cloudSolutionId: ID!, $engagementId: ID!) {
    cloudAccounts(cloudSolutionId: $cloudSolutionId, engagementId: $engagementId) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const READ_CLOUD_ACCOUNT = gql`
  query ReadCloudAccount($id: ID!) {
    cloudAccount(id: $id) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const DELETE_CLOUD_ACCOUNT = gql`
  mutation DeleteCloudAccount($id: ID!, $engagementId: ID!) {
    deleteCloudAccount(value: { id: $id, engagementId: $engagementId }) {
      id
    }
  }
`

export const CREATE_AWS_CLOUD_ACCOUNT = gql`
  mutation CreateAwsCloudAccount(
    $cloudSolutionId: ID!
    $engagementId: ID!
    $name: String!
    $credentials: CredentialsAwsInput!
  ) {
    createAwsCloudAccount(
      cloudSolutionId: $cloudSolutionId
      engagementId: $engagementId
      value: { name: $name, credentialsInput: $credentials }
    ) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const CREATE_AWS_EKS_CLOUD_ACCOUNT = gql`
  mutation CreateAwsEksCloudAccount(
    $cloudSolutionId: ID!
    $engagementId: ID!
    $name: String!
    $credentials: CredentialsAwsInput!
  ) {
    createAwsEksCloudAccount(
      cloudSolutionId: $cloudSolutionId
      engagementId: $engagementId
      value: { name: $name, credentialsInput: $credentials }
    ) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const CREATE_AZURE_CLOUD_ACCOUNT = gql`
  mutation CreateAzureCloudAccount(
    $cloudSolutionId: ID!
    $engagementId: ID!
    $name: String!
    $credentials: CredentialsAzureInput!
  ) {
    createAzureCloudAccount(
      cloudSolutionId: $cloudSolutionId
      engagementId: $engagementId
      value: { name: $name, credentialsInput: $credentials }
    ) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const CREATE_AZURE_AKS_CLOUD_ACCOUNT = gql`
  mutation CreateAzureAksCloudAccount(
    $cloudSolutionId: ID!
    $engagementId: ID!
    $name: String!
    $credentials: CredentialsAzureInput!
  ) {
    createAzureAksCloudAccount(
      cloudSolutionId: $cloudSolutionId
      engagementId: $engagementId
      value: { name: $name, credentialsInput: $credentials }
    ) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const CREATE_GCP_CLOUD_ACCOUNT = gql`
  mutation CreateGcpCloudAccount(
    $cloudSolutionId: ID!
    $engagementId: ID!
    $name: String!
    $credentials: CredentialsGcpInput!
  ) {
    createGcpCloudAccount(
      cloudSolutionId: $cloudSolutionId
      engagementId: $engagementId
      value: { name: $name, credentialsInput: $credentials }
    ) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const CREATE_GOGGLE_GKE_CLOUD_ACCOUNT = gql`
  mutation CreateGoggleGkeCloudAccount(
    $cloudSolutionId: ID!
    $engagementId: ID!
    $name: String!
    $credentials: CredentialsGcpInput!
  ) {
    createGoogleGkeCloudAccount(
      cloudSolutionId: $cloudSolutionId
      engagementId: $engagementId
      value: { name: $name, credentialsInput: $credentials }
    ) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_AWS_CLOUD_ACCOUNT = gql`
  mutation UpdateAwsCloudAccount($id: ID!, $name: String!, $credentials: CredentialsAwsInput!) {
    updateAwsCloudAccount(id: $id, value: { name: $name, credentialsInput: $credentials }) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_AWS_EKS_CLOUD_ACCOUNT = gql`
  mutation UpdateAwsEksCloudAccount($id: ID!, $name: String!, $credentials: CredentialsAwsInput!) {
    updateAwsEksCloudAccount(id: $id, value: { name: $name, credentialsInput: $credentials }) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_AZURE_CLOUD_ACCOUNT = gql`
  mutation UpdateAzureCloudAccount($id: ID!, $name: String!, $credentials: CredentialsAzureInput!) {
    updateAzureCloudAccount(id: $id, value: { name: $name, credentialsInput: $credentials }) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_AZURE_AKS_CLOUD_ACCOUNT = gql`
  mutation UpdateAzureAksCloudAccount($id: ID!, $name: String!, $credentials: CredentialsAzureInput!) {
    updateAzureAksCloudAccount(id: $id, value: { name: $name, credentialsInput: $credentials }) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_GCP_CLOUD_ACCOUNT = gql`
  mutation UpdateGcpCloudAccount($id: ID!, $name: String!, $credentials: CredentialsGcpInput!) {
    updateGcpCloudAccount(id: $id, value: { name: $name, credentialsInput: $credentials }) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_GOOGLE_GKE_CLOUD_ACCOUNT = gql`
  mutation UpdateGoogleGkeCloudAccount($id: ID!, $name: String!, $credentials: CredentialsGcpInput!) {
    updateGoogleGkeCloudAccount(id: $id, value: { name: $name, credentialsInput: $credentials }) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const UPDATE_CLOUD_ACCOUNT_CAPABILITIES = gql`
  mutation UpdateCloudAccountCapabilities($id: ID!) {
    updateCloudAccountCapabilities(id: $id) {
      ...CloudAccountFields
    }
  }
  ${CloudAccountFields}
`

export const READ_CLOUD_SUBSCRIPTIONS = gql`
  query ReadCloudSubscriptions($cloudAccountId: ID!) {
    subscriptions(cloudAccountId: $cloudAccountId) {
      name
      subscriptionId
    }
  }
`

export const EXPORT_IAM_ACCOUNT_CSV = gql`
  query ExportIamAccountCsv($cloudAccountId: ID!) {
    exportIamAccount(cloudAccountId: $cloudAccountId) {
      usersExportOutput {
        encodedFile
        fileName
      }
      groupsExportOutput {
        encodedFile
        fileName
      }
      rolesExportOutput {
        encodedFile
        fileName
      }
    }
  }
`
