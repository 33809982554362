/* eslint-disable max-classes-per-file */
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import {
  ReadCloudAccount_cloudAccount_credentials as Credentials,
  ReadCloudAccount_cloudAccount_credentials_CredentialsAwsOutput as CredentialsAwsOutput,
  ReadCloudAccount_cloudAccount_credentials_CredentialsAzureOutput as CredentialsAzureOutput,
  ReadCloudAccount_cloudAccount_credentials_CredentialsGcpOutput as CredentialsGcpOutput
} from '../../graphql/__generated__/ReadCloudAccount'

export type CloudAccount = {
  id: string
  name: string
  type: CloudCheckType
  credentials: Credentials
}

export class CredentialsAws implements CredentialsAwsOutput {
  __typename: any = 'CredentialsAws'

  accessKey: string = ''

  secretAccessKey: string = ''
}

export class CredentialsAzure implements CredentialsAzureOutput {
  __typename: any = 'CredentialsAzure'

  appID: string = ''

  tenantID: string = ''

  clientSecret: string = ''
}

export class CredentialsGcp implements CredentialsGcpOutput {
  __typename: any = 'CredentialsGcp'
  // eslint-disable-next-line lines-between-class-members
  credentialsJson: string = '{}'
}

export type CloudAccountProps = {
  account: CloudAccount
  engagementId: string
  cloudSolutionId: string
  closeModalCb: any
  isValid: boolean
  isShowLoader: boolean
  deleteModalCb: () => void
}

export type CredentialsProps = {
  accountState: CloudAccount
  setAccountState: any
}
