export default function sortControls(controls) {
  const indexedControls = controls.map((control) => {
    let index = control.controlId.match(/\d+(\.\d+)+/)

    if (index && index.length) {
      index = index[0].split(/\./g)
      index = index.map((i: any) => parseInt(i))
    } else {
      index = []
    }

    return {
      index,
      control
    }
  })

  return indexedControls.sort((a, b) => {
    for (let i = 0; i < Math.max(a.index.length, b.index.length); i += 1) {
      if (a.index.length <= i) {
        return -1
      }
      if (b.index.length <= i) {
        return 1
      }
      if (a.index[i] < b.index[i]) {
        return -1
      }
      if (a.index[i] > b.index[i]) {
        return 1
      }
    }
    return 1
  })
}
