import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { DELETE_CATEGORY } from '../../graphql/category'
import { READ_BENCHMARK } from '../../graphql/benchmark'
import { Loading, Error } from '..'
import { ReadBenchmark, ReadBenchmarkVariables } from '../../graphql/__generated__/ReadBenchmark'
import { DeleteCategory, DeleteCategoryVariables } from '../../graphql/__generated__/DeleteCategory'

type CategoryDeleteProps = {
  categoryId: string
  benchmarkId: string
}

const CategoryDelete: React.FC<CategoryDeleteProps> = ({ categoryId, benchmarkId }) => {
  const [mutate, { loading, error }] = useMutation<DeleteCategory, DeleteCategoryVariables>(DELETE_CATEGORY, {
    variables: { id: categoryId! },
    update: (store, { data }) => {
      const cacheData = store.readQuery<ReadBenchmark, ReadBenchmarkVariables>({
        query: READ_BENCHMARK,
        variables: { id: benchmarkId }
      })

      if (data && cacheData && cacheData.benchmark) {
        const categories = cacheData.benchmark.categories.filter((category) =>
          data.deleteCategory ? category.id !== data.deleteCategory : false
        )

        store.writeQuery<ReadBenchmark, ReadBenchmarkVariables>({
          query: READ_BENCHMARK,
          variables: { id: benchmarkId },
          data: { benchmark: { ...cacheData.benchmark, categories } }
        })
      }
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <Button id="deleteCategory" onClick={() => mutate()}>
      X
    </Button>
  )
}

export default CategoryDelete
