import React from 'react'
import styles from './StatusIndicator.module.scss'
import { Classification, ResourceStatus } from '../../graphql/__generated__/globalTypes'

//--------------------------------------------------------------------------
export function getStatusFromResourceStatus(status: ResourceStatus) {
  switch (status) {
    case ResourceStatus.PASSED:
      return <div className={styles.IndicatorCompliant} />
    case ResourceStatus.SKIPPED:
      return <div className={styles.IndicatorNotApplicable} />
    case ResourceStatus.FAILED:
      return <div className={styles.IndicatorNotCompliant} />
    default:
      return <div className={styles.IndicatorNotApplicable} />
  }
}

//--------------------------------------------------------------------------
export function getStatusFromClassification(classification: Classification) {
  switch (classification) {
    case Classification.COMPLIANT:
      return <div className={styles.IndicatorCompliant} />
    case Classification.NOT_APPLICABLE:
      return <div className={styles.IndicatorNotApplicable} />
    case Classification.NOT_COMPLIANT:
      return <div className={styles.IndicatorNotCompliant} />
    default:
      return <div className={styles.IndicatorNotApplicable} />
  }
}
