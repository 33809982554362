import React from 'react'
import { Table } from '@pwc-de/appkit-react'
import { Link } from 'react-router-dom'
import { UserDtoFields } from '../../graphql/__generated__/UserDtoFields'
import { parseDate, setStateUrl } from '../util'

type UserTableProps = {
  users: Array<UserDtoFields>
  path: string
  urlParent?: string
  urlParams?: string
}

const UserTable: React.FC<UserTableProps> = ({ users, path, urlParent = '', urlParams = '' }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value, original }) => (
        <Link
          to={`${urlParent}${path}/${original.id}${urlParams}${urlParams ? `&user=${value}` : `?user=${value}`}`}
          onClick={() => setStateUrl({ userName: value })}
        >
          {value}
        </Link>
      )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Type',
      accessor: 'userType'
    },
    {
      Header: 'Created',
      accessor: 'created',
      Cell: ({ value }) => <div>{parseDate(value)}</div>
    }
  ]

  return (
    <Table className="new-table" data={users} columns={columns} defaultSorted={[{ id: 'name' }]} defaultPageSize={10} />
  )
}

UserTable.defaultProps = {
  urlParent: '',
  urlParams: ''
}

export default UserTable
