import gql from 'graphql-tag'
import { AssessmentAnalyticsResultFields } from './global'

export const READ_ASSESSMENT = gql`
  query ReadAssessment($id: ID!) {
    assessment(id: $id) {
      id
      name
      executionStatus
      analytics {
        ...AssessmentAnalyticsResultFields
      }
      criticality
      cloudCheckType
      cloudSubscriptions {
        name
        subscriptionId
      }
      categories {
        id
        name
        executionStatus
        analytics {
          ...AssessmentAnalyticsResultFields
        }
        controls {
          id
          name
          type
          controlId
          controlName
          description
          remediation
          controlRating
          controlStatus
          isOverridden
          overrideStatus
          overrideReason
          overrideUser
          overrideTimestamp
          controlResult {
            id
            score
            classification
            finalScore
          }
          controlSubscriptions {
            name
            subscriptionId
            subscriptionResult {
              id
              score
              classification
              finalScore
              resourceResults {
                id
                resourceId
                resourceName
                status
                startTime
                description
                message
                userName
                updateTime
              }
            }
          }
        }
      }
      errorMessage
    }
  }
  ${AssessmentAnalyticsResultFields}
`

export const CREATE_ASSESSMENT = gql`
  mutation CreateAssessment(
    $cloudAccountId: ID!
    $name: String!
    $benchmarkId: ID!
    $previousAssessmentFlag: Boolean!
  ) {
    createAssessment(
      cloudAccountId: $cloudAccountId
      value: { name: $name, benchmarkId: $benchmarkId, previousAssessmentFlag: $previousAssessmentFlag }
    ) {
      id
      name
      timestamp
      executionStatus
    }
  }
`

export const UPDATE_ASSESSMENT_CONTROL = gql`
  mutation UpdateAssessmentControl($assessmentControlId: ID!, $resourceResults: [AssessmentResourceResultInput!]!) {
    updateAssessmentControl(assessmentControlId: $assessmentControlId, value: { resourceResults: $resourceResults }) {
      id
      name
      executionStatus
      analytics {
        ...AssessmentAnalyticsResultFields
      }
      criticality
      cloudCheckType
      cloudSubscriptions {
        name
        subscriptionId
      }
      categories {
        id
        name
        executionStatus
        analytics {
          ...AssessmentAnalyticsResultFields
        }
        controls {
          id
          name
          type
          controlId
          controlName
          controlStatus
          description
          remediation
          controlRating
          isOverridden
          overrideStatus
          overrideReason
          overrideUser
          overrideTimestamp
          controlResult {
            id
            score
            classification
            finalScore
          }
          controlSubscriptions {
            name
            subscriptionId
            subscriptionResult {
              id
              score
              classification
              finalScore
              resourceResults {
                id
                resourceId
                resourceName
                status
                startTime
                description
                message
                userName
                updateTime
              }
            }
          }
        }
      }
    }
  }
  ${AssessmentAnalyticsResultFields}
`

export const OVERRIDE_ASSESSMENT_CONTROL = gql`
  mutation OverrideAssessmentControl(
    $assessmentControlId: ID!
    $overrideAssessmentControl: OverrideAssessmentControlInput!
  ) {
    overrideAssessmentControl(assessmentControlId: $assessmentControlId, value: $overrideAssessmentControl) {
      id
      name
      executionStatus
      analytics {
        ...AssessmentAnalyticsResultFields
      }
      criticality
      categories {
        id
        name
        executionStatus
        analytics {
          ...AssessmentAnalyticsResultFields
        }
        controls {
          id
          name
          type
          controlId
          controlName
          description
          remediation
          controlRating
          controlStatus
          isOverridden
          overrideStatus
          overrideReason
          overrideUser
          overrideTimestamp
          controlResult {
            id
            score
            classification
            finalScore
          }
          controlSubscriptions {
            name
            subscriptionId
            subscriptionResult {
              id
              score
              classification
              finalScore
              resourceResults {
                id
                resourceId
                resourceName
                status
                startTime
                description
                message
              }
            }
          }
        }
      }
    }
  }
  ${AssessmentAnalyticsResultFields}
`

export const EXPORT_ASSESSMENT_CSV = gql`
  query ExportAssessment($id: ID!) {
    exportAssessment(id: $id) {
      encodedFile
      fileName
    }
  }
`

export const READ_ASSESSMENT_EVENTS = gql`
  subscription ReadAssessmentEvent($id: ID!) {
    assessmentEventsByAssessment(assessmentId: $id) {
      source {
        __typename
        ... on AssessmentCompletedEvent {
          id
          message
        }
        ... on AssessmentFailedEvent {
          id
          message
        }
      }
    }
  }
`

export const READ_ASSESSMENT_SUBSCRIPTION_SCORES = gql`
  query ReadAssessmentSubscriptionScores($id: ID!) {
    subscriptionScores(id: $id) {
      subscription {
        name
        subscriptionId
      }
      analytics {
        score
        classification
        numControls
        numControlsUnknown
        numControlsNotApplicable
        numControlsNotCompliant
        numControlsCompliant
      }
    }
  }
`

export const READ_PREVIOUS_ASSESSMENT = gql`
  query ReadPreviousAssessment($cloudAccountId: ID!, $benchmarkName: String!) {
    getHasPreviousAssessment(cloudAccountId: $cloudAccountId, benchmarkName: $benchmarkName) {
      hasPreviousAssessment
    }
  }
`

export const DELETE_ASSESSMENTS = gql`
  mutation DeleteAssessments($cloudAccountId: ID!, $benchmarkName: String!) {
    deleteAssessments(value: { cloudAccountId: $cloudAccountId, benchmarkName: $benchmarkName })
  }
`