export class Engagement {
  id: string = ''

  name: string = ''

  manager: string = ''

  clientContact: string = ''

  startDate: string = new Date().toISOString()

  endDate: string = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString()

  active: boolean = true
}

export type EngagementProps = {
  engagement: Engagement
}

export type EngagementModalProps = {
  engagement: Engagement
  isCurrentPage: boolean
  isValid: boolean
  closeModalCb: any
  onDeleteCb?: any
}
