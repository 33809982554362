import React, { useMemo, useState } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Keycloak, { KeycloakInstance } from 'keycloak-js'
import { useApolloClient } from '@apollo/client'
import 'bootstrap/dist/css/bootstrap.css'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import parseQueryString from 'query-string'
import {
  Header,
  Footer,
  EngagementOverview,
  ContentEngagement,
  ContentConfig,
  Error,
  AppError,
  About,
  AccessDenied,
  Loading
} from './components'
import './App.scss'
import { READ_USER_ROLES } from './graphql/client'
import UserProfile from './components/Header/UserProfile'
import Redirect from './components/Nav/Redirect'
import Imprint from './components/StaticPage/Imprint'
import TermOfUse from './components/StaticPage/TermOfUse'
import DataPrivacy from './components/StaticPage/DataPrivacy'
import Disclaimer from './components/StaticPage/Disclaimer'
import Breadcrumbs, { IBredcrumb } from './components/Breadcrumbs/Breadcrumbs'
import { pageListDashboard } from './components/Dashboard/AnalyticsDashboard'
import styles from './App.module.scss'
import ConfigSidebar from './components/Content/ConfigSidebar'

interface AppState {
  keycloak: KeycloakInstance | null
  authenticated: Boolean
  userProfile: UserProfile
}

const App: React.FC = () => {
  const location = useLocation()
  const [appState, setState] = useState<AppState>({
    keycloak: null,
    authenticated: false,
    userProfile: new UserProfile()
  })

  const queryParams = parseQueryString.parse(location?.search)

  const breadcrumbs = useMemo(() => {
    if (/^\/engagements/.test(location.pathname)) {
      if (location.pathname === '/engagements') {
        return []
      }

      const bredcrumbs: IBredcrumb[] = [{ label: 'Engagements', url: '/engagements' }] as IBredcrumb[]
      const urlLatest =
        queryParams.role || queryParams.user || queryParams.group ? 'management' : pageListDashboard[0].value
      const activePageDashboard =
        pageListDashboard.find((page) => page.value === urlLatest)?.value || pageListDashboard[0].value

      if (queryParams.engagementId) {
        bredcrumbs.push({
          label: queryParams.engagementName,
          url: `/engagements/${queryParams.engagementId}/analytics_dashboard?engagementId=${queryParams.engagementId}&engagementName=${queryParams.engagementName}`
        } as IBredcrumb)
      }

      if (queryParams.accountId) {
        bredcrumbs.push({
          label: queryParams.accountName,
          url: `/engagements/${queryParams.engagementId}/analytics_dashboard/${activePageDashboard}?engagementId=${queryParams.engagementId}&engagementName=${queryParams.engagementName}&cloudSolutionId=${queryParams.cloudSolutionId}&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${queryParams.accountName}`
        } as IBredcrumb)
      }

      if (queryParams.benchmarkId) {
        bredcrumbs.push({
          label: queryParams.benchmarkName,
          url: `/engagements/${queryParams.engagementId}/analytics_dashboard?engagementId=${queryParams.engagementId}&engagementName=${queryParams.engagementName}&cloudSolutionId=${queryParams.cloudSolutionId}&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${queryParams.accountName}&benchmarkId=${queryParams.benchmarkId}&benchmarkName=${queryParams.benchmarkName}&assessmentId=${queryParams.assessmentId}`
        } as IBredcrumb)
      }

      if (queryParams.resultId) {
        bredcrumbs.push({
          label: `${queryParams.controlName}`,
          url: `/engagements/${queryParams.engagementId}/cloud/${queryParams.cloudSolutionId}/assessments_accounts/${queryParams.accountId}/assessments/${queryParams.resultId}/results?engagementId=${queryParams.engagementId}&engagementName=${queryParams.engagementName}&cloudSolutionId=${queryParams.cloudSolutionId}&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${queryParams.accountName}&benchmarkId=${queryParams.benchmarkId}&benchmarkName=${queryParams.benchmarkName}&resultId=${queryParams.resultId}`
        } as IBredcrumb)
      }

      if (queryParams.controlId) {
        bredcrumbs.push({
          label: `${queryParams.controlName}`,
          url: `/engagements/${queryParams.engagementId}/analytics_dashboard?engagementId=${queryParams.engagementId}&engagementName=${queryParams.engagementName}&cloudSolutionId=${queryParams.cloudSolutionId}&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${queryParams.accountName}&benchmarkId=${queryParams.benchmarkId}&benchmarkName=${queryParams.benchmarkName}&controlName=${queryParams.controlName}&controlId=${queryParams.controlId}`
        } as IBredcrumb)
      }

      if (queryParams.role) {
        bredcrumbs.push({
          label: `Role ${queryParams.role}`,
          url: `/engagements/${queryParams.engagementId}/cloud/${queryParams.solutionId}/iam_accounts/${
            queryParams.accountId
          }/roles/${encodeURIComponent(queryParams.role as string)}?engagementId=${
            queryParams.engagementId
          }&engagementName=${queryParams.engagementName}&cloudSolutionId=${
            queryParams.cloudSolutionId
          }&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${
            queryParams.accountName
          }`
        } as IBredcrumb)
      }

      if (queryParams.user) {
        bredcrumbs.push({
          label: `User ${queryParams.user}`,
          url: `/engagements/${queryParams.engagementId}/cloud/${queryParams.solutionId}/iam_accounts/${
            queryParams.accountId
          }/users/${encodeURIComponent(queryParams.role as string)}?engagementId=${
            queryParams.engagementId
          }&engagementName=${queryParams.engagementName}&cloudSolutionId=${
            queryParams.cloudSolutionId
          }&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${
            queryParams.accountName
          }`
        } as IBredcrumb)
      }

      if (queryParams.group) {
        bredcrumbs.push({
          label: `Group ${queryParams.group}`,
          url: `/engagements/${queryParams.engagementId}/cloud/${queryParams.solutionId}/iam_accounts/${
            queryParams.accountId
          }/groups/${encodeURIComponent(queryParams.group as string)}?engagementId=${
            queryParams.engagementId
          }&engagementName=${queryParams.engagementName}&cloudSolutionId=${
            queryParams.cloudSolutionId
          }&cloudSolutionName=${queryParams.cloudSolutionName}&accountId=${queryParams.accountId}&accountName=${
            queryParams.accountName
          }`
        } as IBredcrumb)
      }

      return bredcrumbs
    }

    return []
  }, [location, queryParams])

  const client = useApolloClient()

  const onLogOut = async () => {
    window.sessionStorage.removeItem('auth_token')
    localStorage.clear()
    await appState.keycloak?.logout({ redirectUri: window.location.origin })
    setState({ keycloak: null, authenticated: false, userProfile: new UserProfile() })
  }

  if (!appState.keycloak) {
    const keycloak: KeycloakInstance = Keycloak({
      url: window.keycloakUrl,
      realm: 'CCSI',
      clientId: 'frontend'
    })
    keycloak
      .init({
        onLoad: 'login-required'
      })
      .then((authenticated) => {
        const userProfile = new UserProfile()
        if (authenticated) {
          client.writeQuery({
            query: READ_USER_ROLES,
            data: { userRoles: keycloak.tokenParsed?.realm_access?.roles || [] }
          })
          window.sessionStorage.setItem('auth_token', keycloak.token || '')
        } else {
          client.writeQuery({ query: READ_USER_ROLES, data: { userRoles: [] } })
          window.sessionStorage.removeItem('auth_token')
        }
        setState({ keycloak, authenticated, userProfile })

        keycloak.loadUserProfile().then((user) => {
          userProfile.email = user.email!
          userProfile.username = user.username!
          setState({ keycloak, authenticated, userProfile })
        })
      })
      .catch((e: any) => console.error(e))
    // update auth_token when expired
    keycloak.onTokenExpired = () => {
      keycloak
        .updateToken(5)
        .then(() => {
          window.sessionStorage.setItem('auth_token', keycloak.token || '')
        })
        .catch((e) => {
          onLogOut()
          console.error(e)
        })
    }
  }

  if (appState.keycloak) {
    if (appState.authenticated) {
      const roles = appState.keycloak.tokenParsed?.realm_access?.roles
      const hasRequiredRole = roles?.includes('user') || roles?.includes('admin')
      const isUserAdmin = !!roles?.includes('platform-admin')
      const userAdminUrl = `${appState.keycloak.authServerUrl}/admin/${appState.keycloak.realm}/console/#/realms/${appState.keycloak.realm}/users`
      if (hasRequiredRole) {
        return (
          <div className="nav-template template-theme-two d-flex flex-column">
            <AppError />
            <div className={styles.appContainer}>
              <div className={styles.appContainer__sidebar}>
                <ConfigSidebar />
              </div>
              <div className={styles.appContainer__content}>
                <Header
                  hasAccess
                  onLogout={onLogOut}
                  user={appState.userProfile}
                  isUserAdmin={isUserAdmin}
                  userAdminUrl={userAdminUrl}
                />
                <div className="a-template-content" style={{ height: '100%', width: '100%' }}>
                  {!!breadcrumbs.length && <Breadcrumbs items={breadcrumbs} />}

                  <Routes>
                    <Route path="/" element={<Redirect to="engagements" />} />
                    <Route path="/engagements" element={<EngagementOverview />} />
                    <Route path="/engagements/:engagementId/*" element={<ContentEngagement />} />
                    <Route path="/configuration/*" element={<ContentConfig />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/disclaimer" element={<Disclaimer />} />
                    <Route path="/imprint" element={<Imprint />} />
                    <Route path="/term-of-use" element={<TermOfUse />} />
                    <Route path="/data-privacy" element={<DataPrivacy />} />
                    <Route path="/error" element={<Error />} />
                  </Routes>
                </div>
              </div>
            </div>
            <Footer />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </div>
        )
      }
      return (
        <div className="nav-template template-theme-two d-flex flex-column">
          <Header
            hasAccess={false}
            onLogout={onLogOut}
            user={appState.userProfile}
            isUserAdmin={isUserAdmin}
            userAdminUrl={userAdminUrl}
          />
          <div className="a-template-content">
            <AccessDenied />
          </div>
        </div>
      )
    }
    return <div>Unable to authenticate!</div>
  }
  return (
    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
      <Loading />
    </div>
  )
}

export default App
