import React, { useState, useEffect, useCallback } from 'react'

import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { Table, Tooltip } from '@pwc-de/appkit-react'
import { Error, AuthorizeRole } from '..'
import Control from './Control'
import sortControls from './ControlSorting'
import InputSearch from '../Search/InputSearch'
import ControlArchiveButton from './ControlArchiveButton'
import ControlDeleteButton from './ControlDeleteButton'
import ControlEditButton from './ControlEditButton'
import ControlCreateButton from './ControlCreateButton'
import ControlDuplicateButton from './ControlDuplicateButton'
import ControlOverviewFilterC from './ControlOverviewFilter'
import { ReactComponent as ArchiveControlIcon } from '../../assets/images/icon/archive-control.svg'
import Layout from '../Layout/Layout'
import { getHeaderProps, getProps, getSolutionById } from '../util'
import { ReadCloudSolutions } from '../../graphql/__generated__/ReadCloudSolutions'
import { READ_CLOUD_SOLUTIONS } from '../../graphql/cloudSolution'
import { READ_CONTROLS } from '../../graphql/control'
import type {
  ReadControls_controls,
  ReadControls,
  ReadControlsVariables
} from '../../graphql/__generated__/ReadControls'

import type { ControlOverviewFilter } from '../../models/types/control'
import { ControlStatus } from '../../graphql/__generated__/globalTypes'

import './ControlOverview.scss'

const initialFilter: ControlOverviewFilter = {
  search: '',
  additionalFilter: {
    cloudSolutions: { options: new Set<string>([]), value: '', label: 'Select a cloud solution' },
    controlStatus: { options: new Set<string>([]), value: '', label: 'All Statuses' },
    type: { options: new Set<string>([]), value: '', label: 'All Types' },
    rating: { options: new Set<string>([]), value: '', label: 'All Ratings' }
  }
}

const ControlOverview: React.FC = () => {
  const [controls, setControls] = useState<ReadControls_controls[]>([])
  const [sortedControls, setSortedControls] = useState<Control[]>([])
  const [shownSortedControls, setShownSortedControls] = useState<Control[]>([])
  const [cloudSolutionId, setCloudSolutionId] = useState<string>('')
  const [filter, setFilter] = useState<ControlOverviewFilter>(initialFilter)
  const navigate = useNavigate()

  const {
    data: cloudSolutionData,
    loading: cloudSolutionLoading,
    error: cloudSolutionError
  } = useQuery<ReadCloudSolutions>(READ_CLOUD_SOLUTIONS, {
    onCompleted: (data) => {
      const id = filter?.additionalFilter?.cloudSolutions?.value || data?.cloudSolutions?.[0]?.id || ''
      setCloudSolutionId(id)

      setFilter((_filter) => {
        data?.cloudSolutions.forEach(({ id: _id }) => {
          _filter.additionalFilter.cloudSolutions.options.add(_id)
        })
        if (!_filter.additionalFilter.cloudSolutions.value) {
          _filter.additionalFilter.cloudSolutions.value = id
        }
        return _filter
      })
    }
  })

  const { data, error, loading } = useQuery<ReadControls, ReadControlsVariables>(READ_CONTROLS, {
    variables: { cloudSolutionId: cloudSolutionId || '' },
    skip: !cloudSolutionId
  })

  const onChangeHandler = useCallback(
    (value) => {
      setFilter((_filter) => {
        _filter.search = value
        return { ..._filter }
      })
    },
    [setFilter]
  )

  const renderRightHandler = useCallback(() => {
    return <InputSearch onChange={onChangeHandler} />
  }, [onChangeHandler])

  const renderActionsHandler = useCallback(() => {
    return (
      <AuthorizeRole role="admin">
        <ControlCreateButton />
      </AuthorizeRole>
    )
  }, [])

  useEffect(() => {
    if (!data?.controls) return
    let _controls: ReadControls_controls[] = controls
    let _sortedControls: Control[] = sortedControls

    if (JSON.stringify(data.controls) !== JSON.stringify(_controls)) {
      _controls = data.controls

      Object.keys(filter.additionalFilter).forEach((key) => {
        if (key === 'cloudSolutions') return
        filter.additionalFilter[key].options = new Set<string>([])
      })

      _sortedControls = sortControls(_controls).map(({ control }) => {
        const _control = { ...control }
        const _solution = getSolutionById(_control.controlId)?.name ?? ''

        if (_solution) {
          _control.cloudSolution = _solution
        }

        Object.keys(filter.additionalFilter).forEach((key) => {
          if (key === 'cloudSolutions') return
          filter.additionalFilter[key].options.add(`${_control[key] ?? ''}`)
        })
        setFilter({ ...filter })

        return _control
      })
      setControls(_controls)
      setSortedControls(_sortedControls)
    }

    const _shownSortedControls = sortedControls.filter((control) => {
      if (filter.search) {
        const isSearchInvalid =
          control.controlId.toLocaleLowerCase().includes(filter.search.toLowerCase()) ||
          control.controlName.toLocaleLowerCase().includes(filter.search.toLowerCase())
        if (!isSearchInvalid) return
      }

      const isAdditionalFilterValid = Object.entries(filter.additionalFilter).every(([key, { value }]) => {
        if (key === 'cloudSolutions') return true
        if (!value) return true
        return control[key].toString() === value.toString()
      })
      return isAdditionalFilterValid
    })
    setShownSortedControls(_shownSortedControls)
  }, [controls, data, filter, sortedControls])

  useEffect(() => {
    if (cloudSolutionId) {
      // @ts-ignore
      window.history.replaceState(null, null, `?solution=${cloudSolutionId}`)
    }
  }, [cloudSolutionId])

  const columns = [
    {
      Header: '',
      headerClassName: 'disable-sort',
      accessor: 'id',
      width: 50,
      Cell: ({ original }) => {
        if (original) {
          return getSolutionById(original.controlId).icon(28)
        }
        return React.Fragment
      }
    },
    {
      Header: 'Control Name',
      accessor: 'controlId',
      minWidth: 200,
      Cell: ({ original, value }) => (
        <span
          className="link"
          role="link"
          tabIndex={0}
          onClick={() =>
            navigate(
              `/configuration/cloud/controls/manage/${original.id}?cloudSolutionId=${cloudSolutionId}&controlId=${original.id}&controlName=${original.controlName}`
            )
          }
          onKeyDown={() =>
            navigate(
              `/configuration/cloud/controls/manage/${original.id}?cloudSolutionId=${cloudSolutionId}&controlId=${original.id}&controlName=${original.controlName}`
            )
          }
        >
          {value} {original.controlName}
          {original.controlStatus === ControlStatus.ARCHIVED && <ArchiveControlIcon />}
        </span>
      )
    },
    {
      Header: 'Risk Level',
      accessor: 'rating',
      width: 120
    },
    {
      Header: 'Type',
      accessor: 'type',
      width: 120,
      Cell: ({ value }) => (
        <Tooltip content={value}>
          <div className="control__type">{value.charAt(0).toUpperCase()}</div>
        </Tooltip>
      )
    },
    {
      Header: '',
      accessor: 'id',
      width: 165,
      Cell: ({ original, value }) => (
        <div className="control__actions">
          <AuthorizeRole role="admin">
            {original.controlStatus === ControlStatus.INACTIVE && <ControlArchiveButton control={original} />}
            {original.controlStatus === ControlStatus.ARCHIVED && (
              <ControlDeleteButton controlId={value} cloudSolutionId={cloudSolutionId || ''} />
            )}
            <ControlEditButton controlId={value} />
            <ControlDuplicateButton controlId={value} />
          </AuthorizeRole>
        </div>
      ),
      getHeaderProps,
      getProps
    }
  ]

  if (error || cloudSolutionError) {
    return <Error />
  }

  return (
    <Layout
      className="new-panel control-overview"
      title="Controls"
      subtitle="Controls"
      actions={renderActionsHandler}
      renderRight={renderRightHandler}
      loading={loading || cloudSolutionLoading}
    >
      <div className="control-filter">
        <p className="control-filter__title">Filter options</p>
        <ControlOverviewFilterC
          setCloudSolutionId={setCloudSolutionId}
          solutions={cloudSolutionData}
          filter={filter}
          setFilter={setFilter}
        />
      </div>
      {shownSortedControls.length ? (
        <Table data={shownSortedControls} columns={columns} defaultSorted={[{ id: 'controlId' }]} />
      ) : (
        <div className="control-overview__container--no-data">
          <p className="control-overview__title--no-data">No Controls</p>
          <p className="control-overview__text--no-data">
            There doesn’t appear to be any controls. Create a control to use across Azure benchmarks
          </p>
          <AuthorizeRole role="admin">
            <ControlCreateButton />
          </AuthorizeRole>
        </div>
      )}
    </Layout>
  )
}

export default ControlOverview
