import React from 'react'
import './ProgressBar.scss'
import { colorGradient } from '../ClassificationHelper'

type ProgressBarProps = {
  progressValue: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progressValue, ...props }) => {
  const barStyle = {
    width: `${progressValue}%`,
    backgroundColor: `#${colorGradient(progressValue)}`
  }

  return (
    <div className="meter" {...props}>
      <span style={barStyle} />
    </div>
  )
}

export default ProgressBar
