//------------------------------------------------------
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Input, Modal, ModalBody, ModalFooter, Switch } from '@pwc-de/appkit-react'
import { Col, Container, Row } from 'react-grid-system'
import { CloudAccount, CredentialsAws, CredentialsAzure, CredentialsGcp } from './CloudAccount'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import { READ_CLOUD_ACCOUNT } from '../../graphql/account'
import CloudAccountCreate from './CloudAccountCreate'
import CloudAccountUpdate from './CloudAccountUpdate'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'
import { Loading, Error } from '..'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import CloudAccountAzureFields from './CloudAccountInputFields/CloudAccountAzureFields'
import CloudAccountAwsFields from './CloudAccountInputFields/CloudAccountAwsFields'
import CloudAccountGcpFields from './CloudAccountInputFields/CloudAccountGcpFields'
import { ReadCloudSolution_cloudSolution } from '../../graphql/__generated__/ReadCloudSolution'

const CloudAccountManageModal: React.FC<any> = ({
  visible,
  cancelModalCb,
  deleteModalCb,
  cloudSolution,
  engagementId,
  cloudAccountId = '',
  isShowLoader = true
}) => {
  const { type } = cloudSolution
  const [accountState, setAccountState] = useState<CloudAccount>(initCloudAccount(type))
  let isCreate = !cloudAccountId
  const [checked, setChecked] = useState(false)

  const { loading, error } = useQuery<ReadCloudAccount, ReadCloudAccountVariables>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId },
    skip: !cloudAccountId,
    onCompleted: (data) => {
      if (data) {
        // Workaround: copy object and remove __typename property to avoid graphQL error
        const updatedAccount = JSON.parse(JSON.stringify(data.cloudAccount))
        delete updatedAccount._typename
        delete updatedAccount.credentials.__typename

        isCreate = false
        setAccountState(updatedAccount)
      }
    }
  })

  if (error) {
    return <Error />
  }
  if (loading && isShowLoader) {
    return <Loading />
  }

  const modalTitle = isCreate ? 'Add' : 'Edit'
  const CloudAccountComponent = isCreate ? CloudAccountCreate : CloudAccountUpdate

  const isValid = isValidData(isCreate, checked, accountState, type)

  return (
    <Modal
      className="baseline-modal-showcase new-modal"
      visible={visible}
      onCancel={() => cancelModalCb()}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div className="a-panel-title">
            {modalTitle} {getName(type)}
          </div>
        </div>
      </ModalHeaderWrapper>

      <ModalBody>
        <Container fluid>
          <Row>
            <Col md={12}>
              <p style={{ width: '100%' }}>
                <span>
                  Unique Account Name
                  <span className="required-color">*</span>
                  <span>
                    {!isCreate && (
                      <Switch
                        id="credentials"
                        label="Credentials"
                        style={{ float: 'right' }}
                        defaultChecked={checked}
                        onChange={(checked: boolean) => {
                          setChecked(checked)
                        }}
                      />
                    )}
                  </span>
                </span>
              </p>
              <Input
                className={'fix-first'}
                id="cloudAccountName"
                placeholder="Unique account name"
                value={accountState.name}
                onChange={(name: string) => {
                  setAccountState({ ...accountState, name })
                }}
              />
              {(isCreate || checked) && renderCloudSolution(cloudSolution, accountState, setAccountState)}
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <div className="button-comp button-comp_sm-p-b">
          <CloudAccountComponent
            account={accountState}
            engagementId={engagementId}
            isShowLoader
            cloudSolutionId={cloudSolution.id}
            closeModalCb={cancelModalCb}
            deleteModalCb={deleteModalCb}
            isValid={isValid}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

const getName = (type: CloudCheckType) => {
  switch (type) {
    case CloudCheckType.AWS:
      return 'AWS Account'

    case CloudCheckType.AZURE:
      return 'Microsoft Azure Account'

    case CloudCheckType.GCP:
      return 'Google Cloud Account'

    case CloudCheckType.AWS_EKS:
      return 'AWS-EKS Credentials'

    case CloudCheckType.AZURE_AKS:
      return 'Microsoft Azure AKS Credentials'

    case CloudCheckType.GOOGLE_GKE:
      return 'Google Cloud GKE Credentials'
  }
}

function renderCloudSolution(
  cloudSolution: ReadCloudSolution_cloudSolution,
  accountState: CloudAccount,
  setAccountState: any
) {
  switch (cloudSolution.type) {
    case CloudCheckType.AWS:
    case CloudCheckType.AWS_EKS: {
      return <CloudAccountAwsFields accountState={accountState} setAccountState={setAccountState} />
    }
    case CloudCheckType.AZURE:
    case CloudCheckType.AZURE_AKS: {
      return <CloudAccountAzureFields accountState={accountState} setAccountState={setAccountState} />
    }
    case CloudCheckType.GCP:
    case CloudCheckType.GOOGLE_GKE: {
      return <CloudAccountGcpFields accountState={accountState} setAccountState={setAccountState} />
    }
  }
}

function isValidData(isCreate: boolean, checked: boolean, state: any, type: CloudCheckType) {
  switch (type) {
    case CloudCheckType.AWS:
    case CloudCheckType.AWS_EKS: {
      return !!(!isCreate && !checked
        ? state?.name?.length
        : state?.name?.length && state?.credentials.accessKey?.length && state?.credentials.secretAccessKey?.length)
    }
    case CloudCheckType.AZURE:
    case CloudCheckType.AZURE_AKS: {
      return !!(!isCreate && !checked
        ? state?.name?.length
        : state?.name?.length &&
          state?.credentials.appID?.length &&
          state?.credentials.tenantID?.length &&
          state?.credentials.clientSecret?.length)
    }
    case CloudCheckType.GCP:
    case CloudCheckType.GOOGLE_GKE: {
      return !!(!isCreate && !checked
        ? state?.name?.length
        : state?.name?.length && state?.credentials.credentialsJson?.length > 42)
    }
  }
}

const initCloudAccount = (cloudSolutionType: CloudCheckType) => {
  switch (cloudSolutionType) {
    case CloudCheckType.AWS: {
      return { id: '', name: '', type: CloudCheckType.AWS, credentials: new CredentialsAws() }
    }
    case CloudCheckType.AZURE: {
      return { id: '', name: '', type: CloudCheckType.AZURE, credentials: new CredentialsAzure() }
    }
    case CloudCheckType.GCP: {
      return { id: '', name: '', type: CloudCheckType.GCP, credentials: new CredentialsGcp() }
    }
    case CloudCheckType.AWS_EKS: {
      return { id: '', name: '', type: CloudCheckType.AWS_EKS, credentials: new CredentialsAws() }
    }
    case CloudCheckType.AZURE_AKS: {
      return { id: '', name: '', type: CloudCheckType.AZURE_AKS, credentials: new CredentialsAzure() }
    }
    case CloudCheckType.GOOGLE_GKE: {
      return { id: '', name: '', type: CloudCheckType.GOOGLE_GKE, credentials: new CredentialsGcp() }
    }
  }
}

CloudAccountManageModal.defaultProps = {
  isShowLoader: true
}

export default CloudAccountManageModal
