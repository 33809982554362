import React from 'react'

import LoadingPanel from '../Status/LoadingPanel'

import type { LayoutProps } from '../../models/types/layout'

import './Layout.scss'

const Layout: React.FC<LayoutProps> = ({
  title = '',
  subtitle = '',
  actions = () => null,
  loading = false,
  renderRight,
  className = '',
  children
}) => (
  <div className={`${className} layout-container`}>
    <div className="layout-container__header">
      <p className="layout-container__title">{title}</p>
      <div className="layout-container__actions">{actions()}</div>
    </div>
    <LoadingPanel title={subtitle} renderRight={renderRight} isLoading={loading}>
      {children}
    </LoadingPanel>
  </div>
)

export default Layout
