import gql from 'graphql-tag'

export const READ_APP_CONFIG = gql`
  query ReadAppConfig {
    appConfig {
      build
      controlsFrameworkVersion
    }
  }
`
