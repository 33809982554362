import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, ButtonGroup, ButtonGroupItem, Modal, ModalBody, ModalFooter, PopBanner } from '@pwc-de/appkit-react'
import FileSaver from 'file-saver'
import { Upload } from '@pwc-de/appkit-react-upload'
import { Error } from '..'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import LoadingPanel from '../Status/LoadingPanel'
import { ExportConfiguration } from '../../graphql/__generated__/ExportConfiguration'
import { ImportConfiguration } from '../../graphql/__generated__/ImportConfiguration'
import { EXPORT_CONFIGURATION, IMPORT_CONFIGURATION } from '../../graphql/configExportImport'
import './ConfigImportExportOverview.scss'

const ConfigImportExportOverview: React.FC<any> = ({ open, onClose }) => {
  const [createAndSaveExport, { loading, error }] = useLazyQuery<ExportConfiguration>(EXPORT_CONFIGURATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const content = data && data.exportConfiguration ? data.exportConfiguration : ''

      const now = new Date()
      const timestamp = [now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes()].join(
        '_'
      )
      const fileName = `ccsi_benchmarks_configuration_${timestamp}.json`

      const file = new File([content], fileName, { type: 'text/plain' })
      FileSaver.saveAs(file)

      setCancelButtonDisabled(false)
      setExportButtonDisabled(false)
      onClose()
    }
  })

  const [fileContent, setFileContent] = useState('')
  const [importButtonDisabled, setImportButtonDisabled] = useState(true)
  const [exportButtonDisabled, setExportButtonDisabled] = useState(false)
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(false)
  const [tabValue, setTabValue] = useState('1')
  const [fileName, setFileName] = useState('')
  const [refUploader] = useState(React.createRef())
  const [location, setCurrentLocation] = useState(window.location)

  const [doImport] = useMutation<ImportConfiguration>(IMPORT_CONFIGURATION, {
    variables: { fileContent },
    onCompleted: (data) => {
      onClose()
      setCancelButtonDisabled(false)

      if (data) {
        PopBanner({
          content: (
            <span>
              The Benchmarks and Controls Configuration was successfully imported. Please{' '}
              <Button
                className="a-banner-example-link"
                style={{ cursor: 'pointer', backgroundColor: '#22992e' }}
                onClick={() => location.reload()}
                id="reloadButton"
              >
                reload
              </Button>{' '}
              the page!
            </span>
          ),
          status: 'success',
          closeable: true
        })
      }
    }
  })

  const upload = (_, file) => {
    const fileReader = new FileReader()
    fileReader.onload = function onFileLoad() {
      const fileReaderResult = fileReader.result ? (fileReader.result as string) : ''
      setFileContent(fileReaderResult)
    }
    fileReader.readAsText(file.originFile)
  }

  const handleTabChange = (e) => {
    setTabValue(e.target.getAttribute('data-value'))
  }

  const handleImport = () => {
    setImportButtonDisabled(true)

    // eslint-disable-next-line no-alert
    const confirmation = window.confirm(
      'All existing benchmarks and controls are deleted when a new configuration is imported!'
    )

    if (confirmation) {
      setCancelButtonDisabled(true)
      setCurrentLocation(window.location)

      const uploader = refUploader.current as Upload

      // This will call our upload() to load the content of the file in fileContent,
      // which will, when completed, trigger our effect below.
      uploader.submit()
    }
  }

  const handleUploadChange = (e) => {
    if (e.status === 'deleted') {
      setFileName('')
      setImportButtonDisabled(true)
    } else {
      setFileName(e.name)
      setImportButtonDisabled(false)
    }
  }

  const handleExport = () => {
    setCancelButtonDisabled(true)
    setExportButtonDisabled(true)
    createAndSaveExport()
  }

  // We need to wait for content of the file to be loaded to start the real import
  useEffect(() => {
    if (fileContent !== '') {
      doImport()
      setFileContent('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileContent])

  if (error) {
    return <Error />
  }

  return (
    <div>
      <Modal className="form-modal" title="Import/Export Configuration" visible={open}>
        <LoadingPanel title="Import/Export Configuration" isLoading={loading}>
          <ModalBody>
            <ButtonGroup size={'sm'} className="xxport-modal-radio-group" value={tabValue} onChange={handleTabChange}>
              <ButtonGroupItem data-value="1"> Import File </ButtonGroupItem>
              <ButtonGroupItem data-value="2"> Export File </ButtonGroupItem>
            </ButtonGroup>
            <div className="row">
              <div className="col-12">
                <div className="xxport-modal-content-zone flex-fill">
                  {tabValue === '1' && (
                    <div className="input-first-line-container">
                      <Upload
                        action="localhost"
                        autoUpload={false}
                        acceptFileType=".json"
                        externalUpload={upload}
                        onChange={handleUploadChange}
                        showFullFileName
                        ref={refUploader}
                      >
                        {fileName === '' && (
                          <div className="upload-drop a-apply-theme-background-gray-level-2">
                            <span className="appkiticon icon-upload-outline" />
                            <span className="upload-drop-span">&nbsp;Drag and drop or</span>
                            <span className="upload-browse-span" data-mode="files">
                              &nbsp;choose configuration file
                            </span>
                            <span className="upload-drop-span">&nbsp;to import</span>
                          </div>
                        )}
                      </Upload>
                    </div>
                  )}
                  {tabValue === '2' && (
                    <div className="export-modal-content-zone">
                      <div className="export-modal-content">
                        <div>&nbsp;</div>
                        <div className="export-modal-first-line">
                          <span className="appkiticon icon-download-outline" />
                          <span>&nbsp;Export File</span>
                        </div>
                        <div>&nbsp;</div>
                        <div className="export-modal-second-line">
                          Export all benchmark and control data into a json file ?
                        </div>
                        <div>&nbsp;</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </LoadingPanel>

        <ModalFooter>
          <div className="xxport-modal-button-comp">
            <Button
              id="cancelButton"
              className="a-btn__cancel"
              disabled={cancelButtonDisabled}
              size="lg"
              onClick={onClose}
            >
              {' '}
              Cancel{' '}
            </Button>
            {tabValue === '1' && (
              <Button
                id="importButton"
                className="a-upload-btn xxport-modal-action-btn"
                disabled={importButtonDisabled}
                size="lg"
                onClick={handleImport}
              >
                {' '}
                Import file{' '}
              </Button>
            )}
            {tabValue === '2' && (
              <Button
                id="exportButton"
                className="a-upload-btn xxport-modal-action-btn"
                disabled={exportButtonDisabled}
                size="lg"
                onClick={handleExport}
              >
                {' '}
                Export file{' '}
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default ConfigImportExportOverview
