import React from 'react'
import { TextArea } from '@pwc-de/appkit-react'
import { CredentialsProps } from '../CloudAccount'

const CloudAccountGcpFields: React.FC<CredentialsProps> = ({ accountState, setAccountState }) => (
  <div>
    <TextArea
      label={
        <span>
          Credentials (json) <span className="required-color">*</span>
        </span>
      }
      id="gcpCredentialsJSON"
      autoComplete="off"
      rows={20}
      placeholder="Enter new credentials"
      onChange={(credentialsJson: string) => {
        setAccountState({
          ...accountState,
          credentials: {
            ...accountState.credentials,
            credentialsJson
          }
        })
      }}
    />
  </div>
)

export default CloudAccountGcpFields
