import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Row, Col } from 'react-grid-system'
import { Error } from '..'
import { ReadGroup } from '../../graphql/__generated__/ReadGroup'
import { UserDtoFields } from '../../graphql/__generated__/UserDtoFields'
import { READ_GROUP } from '../../graphql/group'
import LoadingPanel from '../Status/LoadingPanel'
import UserTable from '../User/UserTable'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import { READ_CLOUD_ACCOUNT } from '../../graphql/account'
import { CapabilityStatus } from '../../graphql/__generated__/globalTypes'
import ShowIamData from '../Iam/ShowIamData'
import { getStateUrl } from '../util'

export class Group {
  name: string = ''

  description: string = ''

  users: UserDtoFields[] = []
}

type GroupDetailsProps = {
  cloudAccountId?: string
  groupId?: string
}

const GroupDetails: React.FC<GroupDetailsProps> = () => {
  const { cloudAccountId, groupId }: Readonly<GroupDetailsProps> = useParams()
  const { groupName } = getStateUrl()
  const cloudAccountData = useQuery<ReadCloudAccount, ReadCloudAccountVariables>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId! }
  })

  const { data, loading, error } = useQuery<ReadGroup>(READ_GROUP, {
    variables: {
      cloudAccountId: cloudAccountId || '',
      groupId: groupId || '',
      groupName
    }
  })

  if (error) {
    return <Error />
  }

  const group = data && data.group ? data.group : new Group()

  const cloudAccount =
    cloudAccountData.data && cloudAccountData.data.cloudAccount
      ? cloudAccountData.data.cloudAccount
      : { accountCapability: { readIamGroupMember: CapabilityStatus.NOT_APPLICABLE } }

  return (
    <LoadingPanel title="Group Details" isLoading={loading}>
      <Row>
        <Col>
          <div className="a-text-heading a-mt-10">{group.name}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="a-text-caption a-mt-10 a-text-9e9">{group.description}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="a-py-10 border-top a-border-light a-mt-10">
            <ShowIamData capabilityStatus={cloudAccount.accountCapability.readIamGroupMember} data={group.users}>
              <UserTable users={group.users} path="../../users" />
            </ShowIamData>
          </div>
        </Col>
      </Row>
    </LoadingPanel>
  )
}

export default GroupDetails
