import React from 'react'
import { Input } from '@pwc-de/appkit-react'
import { CredentialsProps, CredentialsAzure } from '../CloudAccount'

const CloudAccountAzureFields: React.FC<CredentialsProps> = ({ accountState, setAccountState }) => (
  <div>
    <Input
      label={
        <span>
          App ID <span className="required-color">*</span>
        </span>
      }
      id="azureAppId"
      placeholder="App ID"
      value={(accountState.credentials as CredentialsAzure).appID}
      onChange={(appID: string) => {
        setAccountState({
          ...accountState,
          credentials: {
            ...accountState.credentials,
            appID
          }
        })
      }}
    />

    <Input
      label={
        <span>
          Tenant <span className="required-color">*</span>
        </span>
      }
      id="azureTenantId"
      placeholder="Tenant ID"
      value={(accountState.credentials as CredentialsAzure).tenantID}
      onChange={(tenantID: string) => {
        setAccountState({
          ...accountState,
          credentials: {
            ...accountState.credentials,
            tenantID
          }
        })
      }}
    />

    <Input
      label={
        <span>
          Client Secret <span className="required-color">*</span>
        </span>
      }
      id="azureClientSecret"
      autoComplete="off"
      placeholder="Enter new client secret"
      onChange={(clientSecret: string) => {
        setAccountState({
          ...accountState,
          credentials: {
            ...accountState.credentials,
            clientSecret
          }
        })
      }}
    />
  </div>
)

export default CloudAccountAzureFields
