import React from 'react'

import { useQuery } from '@apollo/client'
import { ReadUsers } from '../../graphql/__generated__/ReadUsers'
import { READ_USERS } from '../../graphql/user'
import { Error } from '..'
import LoadingPanel from '../Status/LoadingPanel'
import UserTable from './UserTable'

type UsersOverviewProps = {
  cloudAccountId: string
  urlParent?: string
  urlParams?: string
}

const UserOverviewAll: React.FC<UsersOverviewProps> = ({ cloudAccountId, urlParent = '', urlParams = '' }) => {
  const { data, loading, error } = useQuery<ReadUsers>(READ_USERS, {
    variables: { cloudAccountId }
  })

  if (error) {
    return <Error />
  }

  const users = data && data.users ? data.users : []

  return (
    <LoadingPanel title="Users" isLoading={loading} className="new-panel">
      <UserTable users={users} path="." urlParent={urlParent} urlParams={urlParams} />
    </LoadingPanel>
  )
}

UserOverviewAll.defaultProps = {
  urlParent: '',
  urlParams: ''
}

export default UserOverviewAll
