import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Loading, Error } from '..'
import { DeleteBenchmark, DeleteBenchmarkVariables } from '../../graphql/__generated__/DeleteBenchmark'
import { READ_BENCHMARKS, DELETE_BENCHMARK } from '../../graphql/benchmark'
import { ReadBenchmarks, ReadBenchmarksVariables } from '../../graphql/__generated__/ReadBenchmarks'

type BenchmarkDeleteProps = {
  id: string
  cloudSolutionId: string
  isValid: boolean
  cancelModalCb: any
}

const BenchmarkDelete: React.FC<BenchmarkDeleteProps> = ({ id, cloudSolutionId, isValid, cancelModalCb }) => {
  const [mutate, { loading, error }] = useMutation<DeleteBenchmark, DeleteBenchmarkVariables>(DELETE_BENCHMARK, {
    variables: { id, cloudSolutionId },
    update: (store, { data }) => {
      const cacheData = store.readQuery<ReadBenchmarks, ReadBenchmarksVariables>({
        query: READ_BENCHMARKS,
        variables: { cloudSolutionId }
      })

      if (data && cacheData && cacheData.benchmarks) {
        const benchmarks = cacheData.benchmarks.filter((benchmark) =>
          data.deleteBenchmark ? benchmark.id !== data.deleteBenchmark : false
        )

        store.writeQuery<ReadBenchmarks, ReadBenchmarksVariables>({
          query: READ_BENCHMARKS,
          variables: { cloudSolutionId },
          data: { benchmarks }
        })
      }
    },
    onCompleted: () => {
      cancelModalCb()
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div className="button-comp">
      <Button className="a-btn a-btn-new a-btn-outline" onClick={() => cancelModalCb()}>
        Cancel
      </Button>
      <Button
        id="benchmarkDeleteModal"
        className="a-btn a-btn-new a-btn-primary"
        disabled={!isValid}
        onClick={() => {
          mutate()
        }}
      >
        Delete
      </Button>
    </div>
  )
}

export default BenchmarkDelete
