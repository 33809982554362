import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

type RedirectProps = {
  to: string
}

const Redirect: React.FC<RedirectProps> = ({ to }) => {
  const navigate: any = useNavigate()

  useEffect(() => {
    navigate(to)
  })

  return null
}

export default Redirect
