import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Button, Input, Panel } from '@pwc-de/appkit-react'
import Category from './Category'
import CategoryCreate from './CategoryCreate'
import CategoryUpdate from './CategoryUpdate'
import { READ_CATEGORY } from '../../graphql/category'
import { Loading, Error } from '..'
import { ReadCategory, ReadCategoryVariables } from '../../graphql/__generated__/ReadCategory'

type CategoryManageParams = {
  benchmarkId?: string
  categoryId?: string
}

const CategoryManage: React.FC = () => {
  const { benchmarkId, categoryId }: Readonly<CategoryManageParams> = useParams()
  const [categoryState, setCategoryState] = useState<Category>(new Category())

  const { loading, error } = useQuery<ReadCategory, ReadCategoryVariables>(READ_CATEGORY, {
    variables: { id: categoryId || '' },
    skip: !categoryId,
    onCompleted: (data) => {
      if (data) {
        setCategoryState(data.category)
      }
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  const title = categoryState.id.length ? 'Edit Category' : 'Create Category'

  return (
    <Panel
      title={title}
      renderRight={() => {
        if (categoryState.id) {
          return (
            <>
              <Link to="..">
                <CategoryUpdate category={categoryState}>Save</CategoryUpdate>
              </Link>
              <Link to="..">
                <Button gray>Cancel</Button>
              </Link>
            </>
          )
        }
        return <CategoryCreate category={categoryState} benchmarkId={benchmarkId || ''} />
      }}
    >
      <Input
        id="categoryName"
        label="Name*"
        value={categoryState.name}
        onChange={(name: string) => {
          setCategoryState({ ...categoryState, name })
        }}
      />
    </Panel>
  )
}

export default CategoryManage
