import React, { Fragment } from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Link } from 'react-router-dom'
import { EngagementModalProps } from './Engagement'
import { Error } from '..'
import { CREATE_ENGAGEMENT, READ_ENGAGEMENTS } from '../../graphql/engagement'
import { CreateEngagement, CreateEngagementVariables } from '../../graphql/__generated__/CreateEngagement'
import { ReadEngagements, ReadEngagementsVariables } from '../../graphql/__generated__/ReadEngagements'
import { LoadingDefaultSmall } from '../Status/Loading'

//--------------------------------------------------------------------------
const EngagementCreate: React.FC<EngagementModalProps> = ({
  engagement,
  isCurrentPage = false,
  isValid = true,
  closeModalCb
}) => {
  const url: string = isCurrentPage ? '' : '../engagements'

  const [mutate, { loading, error }] = useMutation<CreateEngagement, CreateEngagementVariables>(CREATE_ENGAGEMENT, {
    variables: engagement,
    update: (store, { data }) => {
      const cacheData = store.readQuery<ReadEngagements, ReadEngagementsVariables>({
        query: READ_ENGAGEMENTS,
        variables: { active: true }
      })

      if (data && cacheData && cacheData.engagements) {
        store.writeQuery<ReadEngagements, ReadEngagementsVariables>({
          query: READ_ENGAGEMENTS,
          variables: { active: true },
          data: { engagements: [...cacheData.engagements, data.createEngagement] }
        })
      }
    },
    onCompleted: (data) => {
      if (data) {
        closeModalCb()
      }
    },
    refetchQueries: [
      {
        query: READ_ENGAGEMENTS,
        variables: { active: true }
      }
    ]
  })

  if (error) {
    closeModalCb()
    return <Error />
  }
  if (loading) {
    return <LoadingDefaultSmall />
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable react/jsx-no-useless-fragment */
  return (
    <>
      <Button onClick={closeModalCb} className="a-btn a-btn-new a-btn-outline">
        Cancel
      </Button>
      <Button
        id="saveEngagement"
        className="a-btn a-btn-new a-btn-primary"
        disabled={!isValid}
        onClick={() => mutate()}
      >
        Create Engagement
      </Button>
    </>
  )
}

EngagementCreate.defaultProps = {
  isCurrentPage: false,
  isValid: true
}

export default EngagementCreate
