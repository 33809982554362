import React, { ReactElement, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import './ConfigSidebar.scss'

import Error from '../Status/Error'
import Loading from '../Status/Loading'
import { ReadCloudSolutions, ReadCloudSolutions_cloudSolutions } from '../../graphql/__generated__/ReadCloudSolutions'
import { READ_CLOUD_SOLUTIONS } from '../../graphql/cloudSolution'
import { ReactComponent as AzureIcon } from '../../assets/images/icon/azure.svg'
import { ReactComponent as AmazonIcon } from '../../assets/images/icon/aws-white.svg'
import { ReactComponent as GoogleCloudIcon } from '../../assets/images/icon/googleCloud.svg'
import { ReactComponent as EngagementIcon } from '../../assets/images/icon/engagement.svg'
import { ReactComponent as BenchmarkIcon } from '../../assets/images/icon/benchmark.svg'
import { ReactComponent as ImportExportIcon } from '../../assets/images/icon/import-export.svg'
import { ReactComponent as ManageControlIcon } from '../../assets/images/icon/manage-controls.svg'
import { ReactComponent as AKSIcon } from '../../assets/images/icon/aks.svg'
import { ReactComponent as EKSIcon } from '../../assets/images/icon/eks.svg'
import { ReactComponent as GKEIcon } from '../../assets/images/icon/gke.svg'
import { groupArrBy } from '../../misc/helperFunctions'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
// import { ReactComponent as TerraformIcon } from "../../assets/images/icon/teraform-icon.svg";

import ConfigImportExportOverview from '../ConfigImportExport/ConfigImportExportOverview'

interface NavItemData {
  icon: ReactElement
  label: string
  url: string
  id: string
}

type NavigateItemProps = {
  header?: string | null
  label: string
  url: string
  isBorder?: boolean
  icon: ReactElement
  // eslint-disable-next-line react/require-default-props
  className?: string
  isButton?: boolean
  onClick?: (e) => void | null
}

const NavigateItem: React.FC<NavigateItemProps> = ({
  className,
  header,
  label,
  url,
  icon,
  isBorder = false,
  isButton = false,
  onClick = null
}) => {
  const location = useLocation()
  const isActive: boolean = useMemo(() => new RegExp(url).test(location.pathname), [location])

  const handleClick = onClick
    ? onClick
    : (e: any) => {
        console.log('Unhandled NavigateItem onClick()')
      }

  return (
    <>
      {header && <h4 className="navigate-item__header">{header}</h4>}
      <Link className={`navigate-item ${isBorder && 'navigate-item--border'} ${className ? className : ''}`} to={url}>
        <div className="navigate-item__list">
          <div className={`navigate-item__item ${isActive && 'navigate-item__item--active'}`}>
            {isButton && (
              <div className="import-button" role="link" onClick={handleClick} onKeyDown={handleClick} tabIndex={0}>
                {icon}
                <span className="navigate-item__label">{label}</span>
              </div>
            )}
            {!isButton && (
              <>
                {icon}
                <span className="navigate-item__label">{label}</span>
              </>
            )}
          </div>
        </div>
      </Link>
    </>
  )
}

NavigateItem.defaultProps = {
  header: null,
  isBorder: false
}

const NavigateListItemSection: React.FC<NavItemData> = ({ icon, label, url, id }) => {
  const location = useLocation()
  const isActive: boolean = useMemo(() => location.pathname.includes(id), [location])

  return (
    <Link className={`navigate-item navigate-item_m0`} to={url}>
      <div className="navigate-item__list">
        <div className={`navigate-item__item ${isActive && 'navigate-item__item--active'}`}>
          {icon}
          <span className="navigate-item__label">{label}</span>
        </div>
      </div>
    </Link>
  )
}

type NavigateIListItemProps = {
  label: string
  list: any
}

const NavigateListItem: React.FC<NavigateIListItemProps> = ({ label, list }) => (
  <div className="navigate-list-item">
    <h5 className="navigate-list-item__header">{label}</h5>
    {list.map((data) => (
      <NavigateListItemSection key={data.label} {...data} />
    ))}
  </div>
)

type ConfigSidebarProps = {}

const ConfigSidebar: React.FC<ConfigSidebarProps> = () => {
  const [groupedSolutions, setGroupedSolutions]: any = useState([])
  const [configXxportModalShow, setConfigXxportModalShow] = useState(false)

  const getNavItem = (type: CloudCheckType, id: string) => {
    const classes = `navigate-list-item-section__icon`
    const navItemData: NavItemData = { label: '', url: '', id } as NavItemData
    switch (type) {
      case 'AZURE':
        navItemData.icon = <AzureIcon className={classes} />
        navItemData.label = 'Azure'
        navItemData.url = `/configuration/cloud/${id}/benchmarks?cloudSolutionId=${id}`
        break
      case 'AWS':
        navItemData.icon = <AmazonIcon className={classes} />
        navItemData.label = 'AWS'
        navItemData.url = `/configuration/cloud/${id}/benchmarks?cloudSolutionId=${id}`
        break
      case 'GCP':
        navItemData.icon = <GoogleCloudIcon className={classes} />
        navItemData.label = 'Google Cloud'
        navItemData.url = `/configuration/cloud/${id}/benchmarks?cloudSolutionId=${id}`
        break
      case 'AWS_EKS':
        navItemData.icon = <EKSIcon className={classes} />
        navItemData.label = 'EKS'
        navItemData.url = `/configuration/cloud/${id}/benchmarks?cloudSolutionId=${id}`
        break
      case 'AZURE_AKS':
        navItemData.icon = <AKSIcon className={classes} />
        navItemData.label = 'AKS'
        navItemData.url = `/configuration/cloud/${id}/benchmarks?cloudSolutionId=${id}`
        break
      case 'GOOGLE_GKE':
        navItemData.icon = <GKEIcon className={classes} />
        navItemData.label = 'GKE'
        navItemData.url = `/configuration/cloud/${id}/benchmarks?cloudSolutionId=${id}`
        break
    }

    return navItemData
  }
  const { loading, error } = useQuery<ReadCloudSolutions>(READ_CLOUD_SOLUTIONS, {
    onCompleted: (data) => {
      const grouped = groupArrBy(data.cloudSolutions, 'checkCategory')

      Object.keys(grouped).forEach((key: string) => {
        grouped[key] = grouped[key].map((solution: ReadCloudSolutions_cloudSolutions) => {
          return getNavItem(solution.type, solution.id)
        })
      })

      setGroupedSolutions(grouped)
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  const closeConfigXxportModal = () => setConfigXxportModalShow(false)
  const handleConfigXxportClick = (e) => {
    e.preventDefault()
    setConfigXxportModalShow(true)
  }

  return (
    <div className="config-sidebar">
      <NavigateItem
        header="Assess"
        label="Engagements"
        icon={<EngagementIcon className="navigate-item__icon" />}
        url="/engagements"
      />
      <NavigateItem
        header="Insights"
        label="Benchmark Summary"
        icon={<BenchmarkIcon className="navigate-item__icon" />}
        url="/configuration/dashboard"
      />
      <h4 className="config-sidebar__header">Configure</h4>
      <div>
        {Object.keys(groupedSolutions)?.length &&
          Object.keys(groupedSolutions).map((key: string) => {
            return <NavigateListItem key={key} label={key.replace(/_/g, ' ')} list={groupedSolutions[key]} />
          })}
      </div>
      <NavigateItem
        className="navigate-item__manage-controls"
        label="Manage Controls"
        icon={<ManageControlIcon className="navigate-item__icon" />}
        url="/configuration/cloud/controls"
        isBorder
      />
      <div className="config-sidebar__import-export-btn">
        <ConfigImportExportOverview open={configXxportModalShow} onClose={closeConfigXxportModal} />
        <div className="config-sidebar__import-button">
          <NavigateItem
            label="Import/Export"
            icon={<ImportExportIcon className="navigate-item__icon" />}
            url="/configuration/import_export"
            isBorder
            isButton
            onClick={handleConfigXxportClick}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfigSidebar
