import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Loading, Error, AuthorizeRoles } from '..'
import { UPDATE_CONTROL, READ_CONTROL } from '../../graphql/control'
import { UpdateControl, UpdateControlVariables } from '../../graphql/__generated__/UpdateControl'
import { getSolutionById } from '../util'

import type { ControlUpdateProps } from '../../models/types/control'
import { ControlType } from '../../graphql/__generated__/globalTypes'

const ControlUpdate: React.FC<ControlUpdateProps> = ({ cloudSolutionId, control, cloudSolutions }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    const solution = cloudSolutions?.find(({ id }) => id === cloudSolutionId)?.name
    const solutionAcronym = getSolutionById(solution)?.acronym
    if (solution && !control?.controlId.startsWith(solutionAcronym)) {
      control.controlId = `${getSolutionById(solution)?.acronym}${control.controlId}`
    }
    mutate({ variables: control })
  }

  const [mutate, { loading, error }] = useMutation<UpdateControl, UpdateControlVariables>(UPDATE_CONTROL, {
    onCompleted: () => navigate(-1),
    refetchQueries: [
      {
        query: READ_CONTROL,
        variables: { id: control.id }
      }
    ]
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Button onClick={() => navigate(-1)} className="a-btn__cancel">
        Cancel
      </Button>
      <AuthorizeRoles role={['admin', 'platform-admin']}>
        <Button
          disabled={
            !cloudSolutionId || !control.controlName || (control.type === ControlType.AUTOMATIC && !control.pluginName)
          }
          className="a-btn--big"
          onClick={handleClick}
        >
          Save
        </Button>
      </AuthorizeRoles>
    </>
  )
}

export default ControlUpdate
