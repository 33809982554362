import React from 'react'

import { Button } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router-dom'

const ControlCreateButton: React.FC = () => {
  const navigate = useNavigate()
  const handleClick = () => navigate(`/configuration/cloud/controls/manage/${location.search}`)

  return (
    <Button className="a-btn a-btn-new a-btn-primary a-btn-header" onClick={handleClick}>
      Create Control
    </Button>
  )
}

export default ControlCreateButton
