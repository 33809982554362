import React from 'react'
import { Table } from '@pwc-de/appkit-react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Error } from '..'
import { ReadGroups } from '../../graphql/__generated__/ReadGroups'
import { READ_GROUPS } from '../../graphql/group'
import LoadingPanel from '../Status/LoadingPanel'
import { setStateUrl } from '../util'

type GroupOverviewProps = {
  cloudAccountIdParams?: string
  urlParent?: string
  urlParams?: string
}

type GroupOverviewParams = {
  cloudAccountId?: string
}

const GroupOverview: React.FC<GroupOverviewProps> = ({
  cloudAccountIdParams: cloudAccountIdProps,
  urlParent = '',
  urlParams = ''
}) => {
  const { cloudAccountId: cloudAccountIdParams }: Readonly<GroupOverviewParams> = useParams()
  const cloudAccountId = cloudAccountIdParams || cloudAccountIdProps
  const { data, loading, error } = useQuery<ReadGroups>(READ_GROUPS, {
    variables: { cloudAccountId: cloudAccountId || '' }
  })

  if (error) {
    return <Error />
  }

  const groups = data && data.groups ? data.groups : []

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ original, value }) => (
        <Link
          to={`${urlParent}${original.id}${urlParams}${urlParams ? `&group=${value}` : `?group=${value}`}`}
          onClick={() => setStateUrl({ groupName: value })}
        >
          {value}
        </Link>
      )
    },
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Users',
      accessor: 'users.length'
    }
  ]

  return (
    <LoadingPanel title="Groups" isLoading={loading} className="new-panel">
      <Table className="new-table" data={groups} columns={columns} defaultSorted={[{ id: 'name' }]} />
    </LoadingPanel>
  )
}

GroupOverview.defaultProps = {
  cloudAccountIdParams: '',
  urlParent: '',
  urlParams: ''
}

export default GroupOverview
