import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Table, Button } from '@pwc-de/appkit-react'
import { Benchmark } from '../Benchmark/Benchmark'
import { READ_BENCHMARK } from '../../graphql/benchmark'
import CategoryDelete from './CategoryDelete'
import { getHeaderProps, getProps } from '../util'
import { Error } from '..'
import { ReadBenchmark, ReadBenchmarkVariables } from '../../graphql/__generated__/ReadBenchmark'
import LoadingPanel from '../Status/LoadingPanel'

type CategoryOverviewParams = {
  benchmarkId?: string
}

const CategoryOverview: React.FC = () => {
  const { benchmarkId }: Readonly<CategoryOverviewParams> = useParams()
  const { data, loading, error } = useQuery<ReadBenchmark, ReadBenchmarkVariables>(READ_BENCHMARK, {
    variables: { id: benchmarkId! }
  })

  if (error) {
    return <Error />
  }

  const benchmark = data && data.benchmark ? data.benchmark : new Benchmark()

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ original, value }) => <Link to={`${original.id}/controls${location?.search}`}>{value}</Link>
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ original, value }) => (
        <>
          <Link to={`${original.id}${location?.search}`}>
            <Button id="editCategory">Edit</Button>
          </Link>
          <Link to={`.${location?.search}`}>
            <CategoryDelete categoryId={value} benchmarkId={benchmark.id} />
          </Link>
        </>
      ),
      getHeaderProps,
      getProps
    }
  ]

  return (
    <div>
      <LoadingPanel
        title={`${benchmark.name} - Categories`}
        renderRight={() => (
          <Link to={`create${location?.search}`}>
            <Button id="createCategory">Create Category</Button>
          </Link>
        )}
        isLoading={loading}
      >
        <Table data={benchmark.categories} columns={columns} defaultSorted={[{ id: 'name' }]} />
      </LoadingPanel>
    </div>
  )
}

export default CategoryOverview
