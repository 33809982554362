import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'
import { ReactComponent as PwCLogo } from '../../assets/images/pwc_logo_bw.svg'

interface ILink {
  label: string
  link: string
}

const Footer: React.FC = () => {
  const [date, setDate] = useState<string>()

  const linkList: ILink[] = [
    { label: 'About', link: '/about' },
    { label: 'Disclaimer', link: '/disclaimer' },
    { label: 'Imprint', link: '/imprint' },
    { label: 'Terms of Use', link: '/term-of-use' },
    { label: 'Data Privacy', link: '/data-privacy' }
  ]

  useMemo(() => {
    setDate(`2017 - ${new Date().getFullYear()}`)
  }, [])

  return (
    <div className="a-footer">
      <div className="a-footer__wrap">
        <div className="a-footer__container">
          <p className="a-footer__text">
            © {date} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms,
            each of which is a separate legal entity. <br />
            Please see{' '}
            <a href="https://www.pwc.com/structure" target="_blank" rel="noopener noreferrer">
              www.pwc.com/structure
            </a>{' '}
            for further details.
          </p>
          <div>
            {linkList.map(({ label, link }): any => (
              <Link key={`link-${link}`} className="a-footer__link" to={link}>
                {label}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
