import React, { Fragment } from 'react'
import { useMutation } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Button } from '@pwc-de/appkit-react'
import Category from './Category'
import { CREATE_CATEGORY } from '../../graphql/category'
import { READ_BENCHMARK } from '../../graphql/benchmark'
import { Loading, Error } from '..'
import { CreateCategory, CreateCategoryVariables } from '../../graphql/__generated__/CreateCategory'
import { ReadBenchmark, ReadBenchmarkVariables } from '../../graphql/__generated__/ReadBenchmark'

type CategoryCreateProps = {
  category: Category
  benchmarkId: string
}

const CategoryCreate: React.FC<CategoryCreateProps> = ({ category, benchmarkId }) => {
  const [mutate, { loading, error }] = useMutation<CreateCategory, CreateCategoryVariables>(CREATE_CATEGORY, {
    variables: { ...category, benchmarkId },
    update: (store, { data }) => {
      const cacheData = store.readQuery<ReadBenchmark, ReadBenchmarkVariables>({
        query: READ_BENCHMARK,
        variables: { id: benchmarkId }
      })

      if (data && cacheData && cacheData.benchmark) {
        const benchmark = {
          ...cacheData.benchmark,
          categories: [...cacheData.benchmark.categories, data.createCategory]
        }

        store.writeQuery<ReadBenchmark, ReadBenchmarkVariables>({
          query: READ_BENCHMARK,
          variables: { id: benchmarkId },
          data: { benchmark } as any
        })
      }
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Link to="..">
        <Button id="saveCategory" onClick={() => mutate()}>
          Save
        </Button>
      </Link>
      <Link to="..">
        <Button gray>Cancel</Button>
      </Link>
    </>
  )
}

export default CategoryCreate
