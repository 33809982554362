import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  from,
  HttpLink,
  gql
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getOperationDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { ThemeContainer } from '@pwc-de/appkit-react'
import { BrowserRouter } from 'react-router-dom'
import { READ_APP_ERRORS } from './graphql/client'
import { ReadAppErrors, ReadAppErrors_appErrors } from './graphql/__generated__/ReadAppErrors'
import { resolvers, typeDefs } from './graphql/resolvers'
import registerServiceWorker from './registerServiceWorker'
import App from './App'
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    backendUrl: any
    keycloakUrl: any
  }
}

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        appErrors: {
          merge(existing, incoming) {
            return incoming
          }
        },
        engagements: {
          merge(existing, incoming) {
            return incoming
          }
        },
        usersPaginated: {
          keyArgs: ['cloudAccountId'],
          // eslint-disable-next-line default-param-last
          merge(existing = {}, incoming = null, options) {
            const cloudAccountId = options.args ? options.args.cloudAccountId : null
            if (cloudAccountId) {
              const existingUsers = existing[cloudAccountId] ? existing[cloudAccountId] : { users: [] }
              return {
                [cloudAccountId]: {
                  __typename: incoming.__typename,
                  pageInfo: incoming.pageInfo,
                  users: [...existingUsers.users, ...incoming.users]
                }
              }
            }
            return {}
          },
          // eslint-disable-next-line default-param-last
          read(existing = {}, { args }) {
            const cloudAccountId = args ? args.cloudAccountId : null
            if (cloudAccountId) {
              return existing[cloudAccountId]
            }
            return null
          }
        }
      }
    }
  }
})

const httpLink = new HttpLink({
  uri: `${window.backendUrl}/graphql`
})

const wsLink = new WebSocketLink({
  uri: `${window.backendUrl.replace('http', 'ws')}/subscriptions`,
  options: {
    reconnect: true,
    connectionParams: () => ({
      authorization: sessionStorage.getItem('auth_token') ? `Bearer ${sessionStorage.getItem('auth_token')}` : ''
    })
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, locations, path } = error
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
    const errors: ReadAppErrors_appErrors[] = graphQLErrors.map((error) => ({
      __typename: 'AppErrorType',
      id: `${error.message}_appError_${new Date().getTime()}`,
      message: error.message
    }))
    const previous = cache.readQuery<ReadAppErrors>({ query: READ_APP_ERRORS })
    if (previous && previous.appErrors) {
      const appErrors = previous.appErrors.concat(errors)
      cache.writeQuery({ query: READ_APP_ERRORS, data: { appErrors } })
    } else {
      cache.writeQuery({ query: READ_APP_ERRORS, data: { appErrors: errors } })
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    window.location.replace('/error')
  }
})

const link = split(
  ({ query }) => {
    const operation = getOperationDefinition(query)

    if (!operation) {
      return false
    }
    return operation.kind === 'OperationDefinition' && operation.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('auth_token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: from([authLink, errorLink, link]),
  typeDefs,
  resolvers,
  defaultOptions: {
    mutate: { errorPolicy: 'all' }
  }
})

cache.writeQuery({
  query: gql`
    query GetCacheData {
      currentEngagement {
        id
        name
      }
      appErrors {
        id
        message
      }
      userRoles
    }
  `,
  data: {
    currentEngagement: null,
    keycloak: null,
    authenticated: false,
    appErrors: [],
    userRoles: []
  }
})

ReactDOM.render(
  <ThemeContainer theme="theme-light">
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </ThemeContainer>,
  document.getElementById('root')
)
registerServiceWorker()
