import React from 'react'
import '@pwc-de/appkit-react/style/appkit-react.default.css'
import { Container, Row, Col } from 'react-grid-system'

const Loading: React.FC = () => (
  <Container fluid>
    <Row>
      <Col>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <div className="a-loading a-primary a-m-10" />
        </div>
      </Col>
    </Row>
  </Container>
)

export const LoadingDefaultSmall: React.FC = () => (
  <div className="a-loading a-primary a-m-10" style={{ width: '2em', height: '2em' }} />
)

export default Loading
