import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Input, Panel } from '@pwc-de/appkit-react'
import { useParams } from 'react-router-dom'
import { Benchmark } from './Benchmark'
import { READ_BENCHMARK } from '../../graphql/benchmark'
import BenchmarkCreate from './BenchmarkCreate'
import BenchmarkUpdate from './BenchmarkUpdate'
import { Loading, Error } from '..'
import { ReadBenchmark, ReadBenchmarkVariables } from '../../graphql/__generated__/ReadBenchmark'

type BenchmarkManageParams = {
  benchmarkId?: string
  cloudSolutionId?: string
}

const BenchmarkManage: React.FC = () => {
  const { benchmarkId: benchmarkIdParams, cloudSolutionId: cloudSolutionIdParams }: Readonly<BenchmarkManageParams> =
    useParams()
  const benchmarkId: string = benchmarkIdParams || ''
  const cloudSolutionId: string = cloudSolutionIdParams || ''
  const [benchmarkState, setBenchmarkState] = useState<Benchmark>(new Benchmark())

  const { loading, error } = useQuery<ReadBenchmark, ReadBenchmarkVariables>(READ_BENCHMARK, {
    variables: { id: benchmarkId! },
    skip: !benchmarkId,
    onCompleted: (data) => {
      if (data) {
        setBenchmarkState(data.benchmark)
      }
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  const title = benchmarkState.id.length ? 'Edit Benchmark' : 'Create Benchmark'

  const BenchmarkComponent = benchmarkState.id.length ? BenchmarkUpdate : BenchmarkCreate

  return (
    <Panel
      title={title}
      renderRight={() => <BenchmarkComponent benchmark={benchmarkState} cloudSolutionId={cloudSolutionId} />}
    >
      <Input
        id="benchmarkSource"
        label="Source*"
        value={benchmarkState.benchmarkSource.name}
        onChange={(benchmarkSourceName: string) => {
          const benchmarkSource = { ...benchmarkState.benchmarkSource, name: benchmarkSourceName }
          const name = `${benchmarkSource.name} ${benchmarkState.benchmarkGroup.name} ${benchmarkState.version}`
          setBenchmarkState({ ...benchmarkState, name, benchmarkSource })
        }}
      />
      <Input
        id="benchmarkGroup"
        label="Group*"
        value={benchmarkState.benchmarkGroup.name}
        onChange={(benchmarkGroupName: string) => {
          const benchmarkGroup = { ...benchmarkState.benchmarkGroup, name: benchmarkGroupName }
          const name = `${benchmarkState.benchmarkSource.name} ${benchmarkGroup.name} ${benchmarkState.version}`
          setBenchmarkState({ ...benchmarkState, name, benchmarkGroup })
        }}
      />
      <Input
        id="benchmarkVersion"
        label="Version*"
        value={benchmarkState.version}
        onChange={(version: string) => {
          const name = `${benchmarkState.benchmarkSource.name} ${benchmarkState.benchmarkGroup.name} ${version}`
          setBenchmarkState({ ...benchmarkState, name, version })
        }}
      />
    </Panel>
  )
}

export default BenchmarkManage
