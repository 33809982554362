import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Input, InputNumber, Select, SelectOption, Switch, TextArea, Checkbox } from '@pwc-de/appkit-react'
import { Slider } from '@pwc-de/appkit-react-slider'
import { useParams } from 'react-router-dom'
import { AuthorizeRole, Error, Loading } from '..'
import { READ_CONTROL, READ_PLUGINS } from '../../graphql/control'
import Control from './Control'
import Plugin from './Plugin'
import ControlUpdate from './ControlUpdate'
import ControlCreate from './ControlCreate'
import Layout from '../Layout/Layout'
import { ReadControl, ReadControlVariables } from '../../graphql/__generated__/ReadControl'
import { ControlType, ControlStatus } from '../../graphql/__generated__/globalTypes'
import { ReadPlugins, ReadPluginsVariables } from '../../graphql/__generated__/ReadPlugins'
import { ReadCloudSolutions } from '../../graphql/__generated__/ReadCloudSolutions'
import { READ_CLOUD_SOLUTIONS } from '../../graphql/cloudSolution'
import { getSolutionById } from '../util'
import { createBadgeFromScore } from '../ClassificationHelper'

import '@pwc-de/appkit-react/style/appkit-react.default.css'
import './ControlManage.scss'

import type { ControlManageParams } from '../../models/types/control'

const emptyState = 'empty'
const sliderMin = 0
const sliderMax = 100

const ControlManage: React.FC = () => {
  const { controlId }: Readonly<ControlManageParams> = useParams()
  const [controlState, setControlState] = useState<Control>(new Control())
  const [checked, setChecked] = useState(false)
  const [controlInitialState, setControlInitialState] = useState<Control>(new Control())
  const [pluginState, setPluginState] = useState<Plugin>(new Plugin('', ''))
  const [cloudSolutionIdState, setCloudSolutionIdState] = useState<string>(
    new URLSearchParams(window.location.search).get('solution') || ''
  )

  const duplicate = useMemo(() => !!new URLSearchParams(location.search).get('duplicate') ?? false, [])

  const title = useMemo(
    () => (controlId && duplicate ? 'Duplicate Control' : controlId ? 'Edit Control' : 'Create New Control'),
    [controlId, duplicate]
  )

  const ControlComponent = useMemo(
    () => (controlId && !duplicate ? ControlUpdate : ControlCreate),
    [controlId, duplicate]
  )

  const { loading: controlLoading, error: controlError } = useQuery<ReadControl, ReadControlVariables>(READ_CONTROL, {
    variables: { id: controlId || '' },
    skip: !controlId,
    onCompleted: (data) => {
      const _data = { ...data.control }
      if (duplicate) {
        _data.id = ''
      }
      setControlState(_data)
      setControlInitialState(_data)
    }
  })

  const {
    data: cloudSolutions,
    loading: cloudSolutionLoading,
    error: cloudSolutionError
  } = useQuery<ReadCloudSolutions>(READ_CLOUD_SOLUTIONS)

  const {
    loading: pluginsLoading,
    data: pluginsData,
    error: pluginsError
  } = useQuery<ReadPlugins, ReadPluginsVariables>(READ_PLUGINS, {
    variables: { cloudSolutionId: cloudSolutionIdState || '' },
    skip: !cloudSolutionIdState,
    onCompleted: (data) => {
      if (data.plugins.length) {
        const selectedPlugin = data?.plugins.find((plugin) => plugin.name === controlState.pluginName)
        setControlState({ ...controlState, pluginName: selectedPlugin ? selectedPlugin.name : data.plugins[0].name })
        setPluginState(selectedPlugin ? selectedPlugin : data.plugins[0])
      }
    }
  })

  const renderActionsHandler = useCallback(
    () => (
      <ControlComponent
        cloudSolutions={cloudSolutions?.cloudSolutions}
        control={controlState}
        cloudSolutionId={cloudSolutionIdState || ''}
      />
    ),
    [ControlComponent, cloudSolutionIdState, cloudSolutions?.cloudSolutions, controlState]
  )

  useEffect(() => {
    if (cloudSolutions && controlState.controlId) {
      const cloudSolution = cloudSolutions?.cloudSolutions?.find((solution) => {
        return solution?.name === getSolutionById(controlState.controlId)?.fullName
      })
      if (cloudSolution) {
        setCloudSolutionIdState(cloudSolution?.id)
      }
    }
  }, [cloudSolutions, controlState.controlId])

  if (pluginsLoading || controlLoading || cloudSolutionLoading) {
    return <Loading />
  }

  if (pluginsError || controlError || cloudSolutionError) {
    return <Error />
  }

  return (
    <Layout className="control-manage" title={title} subtitle="Control Settings" actions={renderActionsHandler}>
      <div className="flex">
        <Input
          placeholder="Control Name"
          id="controlName"
          label="Control Name"
          style={{ width: '100%' }}
          value={controlState.controlName}
          disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
          onChange={(controlName: string) => {
            setControlState({ ...controlState, controlName })
          }}
        />
        <Input
          placeholder="Control ID"
          id="controlId"
          disabled={!!controlId && !duplicate}
          label="Control ID"
          style={{ width: '15rem' }}
          value={controlState.controlId}
          onChange={(controlIdText: string) => {
            setControlState({ ...controlState, controlId: controlIdText })
          }}
        />
        <div style={{ width: '15rem' }}>
          <p className="a-form-label">Cloud Solution</p>
          <Select
            placeholder="Solutions"
            value={cloudSolutionIdState}
            showSearchOnList
            disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
            onSelect={(selectedCloudSolutionId: string) => {
              setCloudSolutionIdState(selectedCloudSolutionId)
            }}
          >
            <SelectOption key={emptyState} disabled value={emptyState}>
              Select Cloud Solution
            </SelectOption>
            {cloudSolutions?.cloudSolutions.map((_cloudSolution) => (
              <SelectOption key={_cloudSolution.id} value={_cloudSolution.id}>
                {getSolutionById(_cloudSolution.name).icon()} {getSolutionById(_cloudSolution.name).name}
              </SelectOption>
            ))}
          </Select>
        </div>
      </div>

      <div className="flex">
        <div>
          <p style={{ marginTop: '0.938rem', marginBottom: '0.5rem' }} className="a-form-label">
            Automatic
          </p>
          <div className="flex" style={{ height: 34 }}>
            <Switch
              checked={controlState.type === ControlType.AUTOMATIC}
              onText="On"
              offText="Off"
              disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
              onChange={(selected: boolean) => {
                const type = selected ? ControlType.AUTOMATIC : ControlType.MANUAL
                if (selected) {
                  const selectedPlugin = controlId
                    ? pluginsData?.plugins.find((plugin) => plugin.name === controlInitialState?.pluginName)
                    : pluginsData?.plugins[0]

                  setPluginState(selectedPlugin ?? new Plugin('', ''))
                  setControlState({
                    ...controlState,
                    type,
                    pluginName: (controlId ? controlInitialState?.pluginName : selectedPlugin?.name) ?? ''
                  })
                } else {
                  setPluginState(new Plugin('', ''))
                  setControlState({ ...controlState, type, pluginName: '' })
                }
              }}
            />
          </div>
        </div>
        <div style={{ width: '30rem' }}>
          <p className="a-form-label">Select Plugin</p>
          <Select
            placeholder="Select a Plugin"
            value={controlState.pluginName}
            showSearchOnList
            disabled={controlState.type === ControlType.MANUAL || controlState.controlStatus === ControlStatus.ARCHIVED}
            onSelect={(selectedPluginName: string) => {
              const selectedPlugin = pluginsData?.plugins.find((plugin) => plugin.name === selectedPluginName)

              if (selectedPlugin) {
                const pluginName = selectedPlugin.name
                setPluginState(selectedPlugin)
                setControlState({ ...controlState, pluginName })
              } else {
                const pluginName = selectedPluginName
                setPluginState(new Plugin(pluginName, ''))
                setControlState({ ...controlState, pluginName })
              }
            }}
          >
            {controlState.type === ControlType.MANUAL && (
              <SelectOption key={emptyState} value={emptyState}>
                No Plugin selected
              </SelectOption>
            )}
            {pluginsData?.plugins.map((plugin) => (
              <SelectOption key={plugin.name} value={plugin.name}>
                {plugin.name}
              </SelectOption>
            ))}
          </Select>
        </div>
        <div style={{ width: '100%' }}>
          <p className="a-form-label">Rating</p>
          <div className="a-slider-wrapper flex">
            <InputNumber
              style={{ width: '3.438rem' }}
              size="sm"
              value={controlState.rating}
              disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
              onChange={(rating) => {
                setControlState({ ...controlState, rating })
              }}
              min={sliderMin}
              max={sliderMax}
            />
            <div className="slider-container">
              <Slider
                style={{ padding: '0', marginBottom: '0', width: '100%' }}
                value={controlState.rating}
                min={sliderMin}
                max={sliderMax}
                disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
                onChange={(rating: number) => {
                  setControlState({ ...controlState, rating })
                }}
              />
            </div>
            <div style={{ width: '8rem', textAlign: 'center' }}>{createBadgeFromScore(controlState.rating)}</div>
          </div>
        </div>
      </div>
      <div className="flex a-form-label">
        <label htmlFor="controlDescription" style={{ width: '100%' }}>
          Description
        </label>
        <Checkbox
          id="controlStatus"
          style={{ width: '1rem' }}
          defaultChecked={controlState.controlStatus === ControlStatus.ARCHIVED}
          onChange={(checked: boolean) => {
            if (checked) {
              const controlStatus =
                controlInitialState?.controlStatus === ControlStatus.ARCHIVED
                  ? ControlStatus.ACTIVE
                  : ControlStatus.ARCHIVED
              setControlState({ ...controlState, controlStatus })
            }
          }}
        />
        <label htmlFor="controlStatus">Deprecated</label>
      </div>
      <TextArea
        id="controlDescription"
        rows={10}
        placeholder="Add control description"
        disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
        value={controlState.description}
        onChange={(description: string) => {
          setControlState({ ...controlState, description })
        }}
      />

      <TextArea
        label="Remediation"
        rows={10}
        placeholder="Add remediation steps"
        disabled={controlState.controlStatus === ControlStatus.ARCHIVED}
        value={controlState.remediation}
        onChange={(remediation: string) => {
          setControlState({ ...controlState, remediation })
        }}
      />
      {pluginState.name !== emptyState && controlState.type === ControlType.AUTOMATIC && (
        <AuthorizeRole role="admin">
          <TextArea
            label="Plugin content"
            placeholder="Add plugin content"
            rows={10}
            disabled
            value={pluginState.content}
            className="resizable"
            resize
          />
        </AuthorizeRole>
      )}
    </Layout>
  )
}

export default ControlManage
