import gql from 'graphql-tag'

export const RoleFields = gql`
  fragment RoleFields on RoleOutput {
    id
    name
    description
    roleTemplateId
    users {
      id
      name
      created
      userType
    }
  }
`

export const RoleDtoFields = gql`
  fragment RoleDtoFields on RoleDto {
    id
    name
    description
  }
`

export const GET_ROLES = gql`
  query ReadRoles($cloudAccountId: ID!) {
    roles(cloudAccountId: $cloudAccountId) {
      ...RoleFields
    }
  }
  ${RoleFields}
`

export const GET_ROLE = gql`
  query ReadRole($cloudAccountId: ID!, $roleId: String!, $roleName: String!) {
    role(cloudAccountId: $cloudAccountId, roleId: $roleId, roleName: $roleName) {
      ...RoleFields
    }
  }
  ${RoleFields}
`
