import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Link } from 'react-router-dom'
import { Error, Loading } from '..'
import { BenchmarkProps } from './Benchmark'
import { CREATE_BENCHMARK, READ_BENCHMARKS } from '../../graphql/benchmark'
import { CreateBenchmark, CreateBenchmarkVariables } from '../../graphql/__generated__/CreateBenchmark'
import {
  ReadBenchmarks,
  ReadBenchmarks_benchmarks,
  ReadBenchmarksVariables
} from '../../graphql/__generated__/ReadBenchmarks'

const BenchmarkCreate: React.FC<BenchmarkProps> = ({ benchmark, cloudSolutionId }) => {
  const newBenchmark = JSON.parse(JSON.stringify(benchmark))
  delete newBenchmark.benchmarkGroup.__typename
  delete newBenchmark.benchmarkSource.__typename

  const [mutate, { loading, error }] = useMutation<CreateBenchmark, CreateBenchmarkVariables>(CREATE_BENCHMARK, {
    variables: { cloudSolutionId, ...newBenchmark },
    update: (store, { data }) => {
      const cacheData = store.readQuery<ReadBenchmarks, ReadBenchmarksVariables>({
        query: READ_BENCHMARKS,
        variables: { cloudSolutionId }
      })

      if (data && cacheData && cacheData.benchmarks) {
        store.writeQuery<ReadBenchmarks, ReadBenchmarksVariables>({
          query: READ_BENCHMARKS,
          variables: { cloudSolutionId },
          data: { benchmarks: [data.createBenchmark as ReadBenchmarks_benchmarks, ...cacheData.benchmarks] }
        })
      }
    }
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Link to="..">
        <Button id="saveBenchmark" onClick={() => mutate()}>
          Save
        </Button>
      </Link>
      <Link to="..">
        <Button gray>Cancel</Button>
      </Link>
    </>
  )
}

export default BenchmarkCreate
