import { Classification } from '../../graphql/__generated__/globalTypes'

//--------------------------------------------------------------------------
export function getDisplayName(classification: Classification) {
  return classification.replace(/_/g, ' ')
}

//--------------------------------------------------------------------------
export function generateAssessmentCreateLink(engagementId: string, solutionId: string) {
  return `/engagements/${engagementId}/cloud/${solutionId}/assessments_accounts`
}

//--------------------------------------------------------------------------
export function generateAssessmentResultLink({
  engagementId,
  engagementName,
  solutionId,
  solutionName,
  accountId,
  accountName,
  benchmarkId,
  benchmarkName,
  assessmentId
}) {
  return `/engagements/${engagementId}/cloud/${solutionId}/assessments_accounts/${accountId}/assessments/${assessmentId}/results?engagementId=${engagementId}&engagementName=${engagementName}&cloudSolutionId=${solutionId}&cloudSolutionName=${solutionName}&accountId=${accountId}&accountName=${accountName}&benchmarkId=${benchmarkId}&benchmarkName=${benchmarkName}&resultId=${assessmentId}`
}
