import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { ReadCloudAccount } from '../../graphql/__generated__/ReadCloudAccount'
import { READ_CLOUD_ACCOUNT } from '../../graphql/account'
import { Error } from '..'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import UserOverviewAll from './UserOverviewAll'
import UserOverviewPaginated from './UserOverviewPaginated'

type UsersOverviewProps = {
  cloudAccountIdParams?: string
  urlParent?: string
  urlParams?: string
}

type UsersOverviewParams = {
  cloudAccountId?: string
}

const UserOverview: React.FC<UsersOverviewProps> = ({
  cloudAccountIdParams: cloudAccountIdProps,
  urlParent = '',
  urlParams = ''
}) => {
  const { cloudAccountId: cloudAccountIdParams }: Readonly<UsersOverviewParams> = useParams()
  const cloudAccountId = cloudAccountIdParams || cloudAccountIdProps
  const { data, error } = useQuery<ReadCloudAccount>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId }
  })

  if (error) {
    return <Error />
  }

  const accountType = (data && data.cloudAccount && data.cloudAccount.type) || null

  if (accountType === CloudCheckType.AZURE) {
    return <UserOverviewPaginated cloudAccountId={cloudAccountId || ''} urlParent={urlParent} urlParams={urlParams} />
  }
  return <UserOverviewAll cloudAccountId={cloudAccountId || ''} urlParent={urlParent} urlParams={urlParams} />
}

UserOverview.defaultProps = {
  cloudAccountIdParams: '',
  urlParent: '',
  urlParams: ''
}

export default UserOverview
