import Category from '../Category/Category'

export class Benchmark {
  id: string = ''

  name: string = ''

  benchmarkGroup: BenchmarkGroup = new BenchmarkGroup()

  benchmarkSource: BenchmarkSource = new BenchmarkSource()

  version: string = '1.0.0'

  categories: Category[] = []
}

export class BenchmarkGroup {
  name: string = ''
}

export class BenchmarkSource {
  name: string = ''
}

export type BenchmarkProps = {
  benchmark: Benchmark
  cloudSolutionId: string
  onDeleteCb?: any
}
