import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Table, Button } from '@pwc-de/appkit-react'
import Category from '../Category/Category'
import { READ_CATEGORY } from '../../graphql/category'
import { ReadCategory, ReadCategoryVariables } from '../../graphql/__generated__/ReadCategory'
import { Error } from '..'
import LoadingPanel from '../Status/LoadingPanel'
import sortControls from './ControlSorting'

type CategoryControlOverviewParams = {
  categoryId?: string
}

const CategoryControlOverview: React.FC = () => {
  const { categoryId }: Readonly<CategoryControlOverviewParams> = useParams()
  let category = new Category()

  const { data, loading, error } = useQuery<ReadCategory, ReadCategoryVariables>(READ_CATEGORY, {
    variables: { id: categoryId! }
  })

  if (error) {
    return <Error />
  }

  if (data && data.category) {
    category = data.category
  }

  const columns = [
    {
      Header: 'Name',
      accessor: 'controlId',
      Cell: ({ original, value }) => `${value} ${original.controlName}`
    }
  ]

  const categoryControlMap = sortControls(category.controls)

  return (
    <div>
      <LoadingPanel
        title={`${category.name} - Controls`}
        renderRight={() => (
          <Link to={`assign${location?.search}`}>
            <Button id="editControls">Edit</Button>
          </Link>
        )}
        isLoading={loading}
      >
        <Table data={categoryControlMap.map((c) => c.control)} columns={columns} sortable={false} />
      </LoadingPanel>
    </div>
  )
}

export default CategoryControlOverview
