import React from 'react'
import { Panel } from '@pwc-de/appkit-react'
import { Loading } from '..'

type LoadingPanelProps = {
  isLoading: boolean
  title: string
  className?: string
  // eslint-disable-next-line react/require-default-props
  renderRight?: any
  isPanel?: boolean
}

const LoadingPanel: React.FC<LoadingPanelProps> = ({
  title,
  className = '',
  renderRight,
  isLoading,
  isPanel = true,
  children
}) => {
  const component = (
    <>
      {isLoading && <Loading />}
      {!isLoading && children}
    </>
  )

  return isPanel ? (
    <Panel title={title} renderRight={renderRight} className={className}>
      {component}
    </Panel>
  ) : (
    component
  )
}

LoadingPanel.defaultProps = {
  isPanel: true,
  className: ''
}

export default LoadingPanel
