import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router'
import { Error } from '..'
import { DELETE_ENGAGEMENT, READ_ENGAGEMENTS } from '../../graphql/engagement'
import { DeleteEngagement, DeleteEngagementVariables } from '../../graphql/__generated__/DeleteEngagement'
import { ReadEngagements, ReadEngagementsVariables } from '../../graphql/__generated__/ReadEngagements'
import { LoadingDefaultSmall } from '../Status/Loading'

type EngagementDeleteProps = {
  engagementId: string
  cancelModalCb: any
  isValid: boolean
}

const EngagementDelete: React.FC<EngagementDeleteProps> = ({ engagementId, isValid, cancelModalCb }) => {
  const navigate = useNavigate()
  const [mutate, { loading, error }] = useMutation<DeleteEngagement, DeleteEngagementVariables>(DELETE_ENGAGEMENT, {
    variables: { id: engagementId },
    update: (store, { data }) => {
      const cacheData = store.readQuery<ReadEngagements, ReadEngagementsVariables>({
        query: READ_ENGAGEMENTS,
        variables: { active: true }
      })

      if (data && cacheData && cacheData.engagements) {
        const engagements = cacheData.engagements.filter((e) => {
          if (e && data.deleteEngagement) {
            return e.id !== data.deleteEngagement
          }
          return false
        })

        store.writeQuery<ReadEngagements, ReadEngagementsVariables>({
          query: READ_ENGAGEMENTS,
          variables: { active: true },
          data: { engagements }
        })
      }
    },
    onCompleted: () => {
      cancelModalCb()
      navigate('/engagements')
    }
  })

  if (error) {
    cancelModalCb()
    return <Error />
  }
  if (loading) {
    return <LoadingDefaultSmall />
  }

  return (
    <div className="button-comp">
      <Button className="a-btn a-btn-new a-btn-outline" onClick={() => cancelModalCb()}>
        Cancel
      </Button>
      <Button
        id="engagementDeleteModal"
        className="a-btn a-btn-new a-btn-primary"
        disabled={!isValid}
        onClick={() => {
          mutate()
        }}
      >
        Delete
      </Button>
    </div>
  )
}

export default EngagementDelete
