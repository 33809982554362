import React from 'react'

import { Button, Tooltip } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as EditControlIcon } from '../../assets/images/icon/edit-control.svg'

import type { EditControlProps } from '../../models/types/control'

const ControlEditButton: React.FC<EditControlProps> = ({ controlId }) => {
  const navigate = useNavigate()
  const handleClick = () => navigate(`/configuration/cloud/controls/manage/${controlId}`)

  return (
    <Button onClick={handleClick}>
      <Tooltip content="Edit" placement="left">
        <EditControlIcon />
      </Tooltip>
    </Button>
  )
}

export default ControlEditButton
