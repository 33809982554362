import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { CloudAccountProps } from './CloudAccount'
import { Error } from '..'
import {
  CREATE_AWS_CLOUD_ACCOUNT,
  READ_CLOUD_ACCOUNTS,
  CREATE_AZURE_CLOUD_ACCOUNT,
  CREATE_GCP_CLOUD_ACCOUNT,
  READ_ALL_CLOUD_ACCOUNTS,
  CREATE_AWS_EKS_CLOUD_ACCOUNT,
  CREATE_AZURE_AKS_CLOUD_ACCOUNT,
  CREATE_GOGGLE_GKE_CLOUD_ACCOUNT
} from '../../graphql/account'
import { CloudCheckType } from '../../graphql/__generated__/globalTypes'
import { LoadingDefaultSmall } from '../Status/Loading'

export const CloudAccountCreate: React.FC<CloudAccountProps> = ({
  account,
  engagementId,
  cloudSolutionId,
  closeModalCb,
  isValid,
  isShowLoader
}) => {
  const newAccount = JSON.parse(JSON.stringify(account))
  delete newAccount.credentials.__typename

  const [mutate, { loading, error }] = useMutation(getCreateQuery(newAccount.type), {
    variables: { cloudSolutionId, engagementId, ...newAccount },
    refetchQueries: [
      {
        query: READ_CLOUD_ACCOUNTS,
        variables: { cloudSolutionId, engagementId }
      },
      {
        query: READ_ALL_CLOUD_ACCOUNTS,
        variables: { cloudSolutionId, engagementId }
      }
    ],
    onCompleted: (data) => {
      if (data) {
        closeModalCb()
      }
    }
  })

  if (error) {
    closeModalCb()
    return <Error />
  }
  if (loading && isShowLoader) {
    return <LoadingDefaultSmall />
  }

  return (
    <>
      <Button className="a-btn a-btn-new a-btn-outline" onClick={() => closeModalCb()}>
        Cancel
      </Button>
      <Button
        id="saveCloudAccount"
        className="a-btn a-btn-new a-btn-primary"
        disabled={!isValid}
        onClick={() => mutate()}
      >
        Add Account
      </Button>
    </>
  )
}

function getCreateQuery(type: CloudCheckType) {
  switch (type) {
    case CloudCheckType.AWS:
      return CREATE_AWS_CLOUD_ACCOUNT

    case CloudCheckType.AZURE:
      return CREATE_AZURE_CLOUD_ACCOUNT

    case CloudCheckType.GCP:
      return CREATE_GCP_CLOUD_ACCOUNT

    case CloudCheckType.AWS_EKS:
      return CREATE_AWS_EKS_CLOUD_ACCOUNT

    case CloudCheckType.AZURE_AKS:
      return CREATE_AZURE_AKS_CLOUD_ACCOUNT

    case CloudCheckType.GOOGLE_GKE:
      return CREATE_GOGGLE_GKE_CLOUD_ACCOUNT
  }
}

export default CloudAccountCreate
