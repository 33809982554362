import React from 'react'
import { Modal, ModalBody } from '@pwc-de/appkit-react'
import { Container } from 'react-grid-system'
import AssessmentManage from './AssessmentManage'

//--------------------------------------------------------------------------
const AssessmentManageModal: React.FC<any> = ({
  visible,
  cancelModalCb = () => {},
  cloudSolutionId,
  cloudSolutionName,
  cloudAccountId,
  accountName,
  engagementId = '',
  selectedBenchmark
}) => (
  <Modal
    className="form-modal new-modal center-middle"
    visible={visible}
    onCancel={() => cancelModalCb()}
    backdropClosable={false}
  >
    <ModalBody>
      <Container fluid>
        <AssessmentManage
          cloudSolutionId={cloudSolutionId}
          cloudSolutionName={cloudSolutionName}
          cloudAccountId={cloudAccountId}
          accountName={accountName}
          engagementId={engagementId}
          cancelModalCb={cancelModalCb}
          selectedBenchmark={selectedBenchmark}
        />
      </Container>
    </ModalBody>
  </Modal>
)

export default AssessmentManageModal
