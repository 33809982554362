import gql from 'graphql-tag'

const EngagementFields = gql`
  fragment EngagementFields on Engagement {
    id
    name
    manager
    clientContact
    startDate
    endDate
    active
  }
`

export const READ_ENGAGEMENTS = gql`
  query ReadEngagements($active: Boolean!) {
    engagements(active: $active) {
      ...EngagementFields
    }
  }
  ${EngagementFields}
`

export const READ_ENGAGEMENT = gql`
  query ReadEngagement($id: ID!) {
    engagement(id: $id) {
      ...EngagementFields
    }
  }
  ${EngagementFields}
`

export const DELETE_ENGAGEMENT = gql`
  mutation DeleteEngagement($id: ID!) {
    deleteEngagement(id: $id)
  }
`

export const CREATE_ENGAGEMENT = gql`
  mutation CreateEngagement(
    $name: String!
    $manager: String!
    $clientContact: String!
    $startDate: LocalDateTime!
    $endDate: LocalDateTime!
    $active: Boolean!
  ) {
    createEngagement(
      value: {
        name: $name
        manager: $manager
        clientContact: $clientContact
        startDate: $startDate
        endDate: $endDate
        active: $active
      }
    ) {
      ...EngagementFields
    }
  }
  ${EngagementFields}
`

export const UPDATE_ENGAGEMENT = gql`
  mutation UpdateEngagement(
    $id: ID!
    $name: String!
    $manager: String!
    $clientContact: String!
    $startDate: LocalDateTime!
    $endDate: LocalDateTime!
    $active: Boolean!
  ) {
    updateEngagement(
      id: $id
      value: {
        name: $name
        manager: $manager
        clientContact: $clientContact
        startDate: $startDate
        endDate: $endDate
        active: $active
      }
    ) {
      ...EngagementFields
    }
  }
  ${EngagementFields}
`
