import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Loading, Error, AuthorizeRole } from '..'
import { CreateControl, CreateControlVariables } from '../../graphql/__generated__/CreateControl'
import { CREATE_CONTROL, READ_CONTROLS } from '../../graphql/control'
import { getSolutionById } from '../util'

import type { ControlCreateProps } from '../../models/types/control'

const ControlCreate: React.FC<ControlCreateProps> = ({ cloudSolutionId, control, cloudSolutions }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    const solution = cloudSolutions?.find(({ id }) => id === cloudSolutionId)?.name
    const solutionAcronym = getSolutionById(solution)?.acronym
    if (solution && !control?.controlId.startsWith(solutionAcronym)) {
      control.controlId = `${getSolutionById(solution)?.acronym}${control.controlId}`
    }
    mutate({ variables: { cloudSolutionId, ...control } })
  }

  const [mutate, { loading, error }] = useMutation<CreateControl, CreateControlVariables>(CREATE_CONTROL, {
    onCompleted: () => navigate(-1),
    refetchQueries: [
      {
        query: READ_CONTROLS,
        variables: { cloudSolutionId }
      }
    ]
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Button onClick={() => navigate(-1)} className="a-btn__cancel">
        Cancel
      </Button>
      <AuthorizeRole role="admin">
        <Button
          disabled={!cloudSolutionId || !control.controlName || !control.pluginName}
          className="a-btn--big"
          onClick={handleClick}
        >
          Save
        </Button>
      </AuthorizeRole>
    </>
  )
}

export default ControlCreate
