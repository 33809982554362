import React from 'react'
import { useQuery } from '@apollo/client'
import './BuildVersion.scss'
import LoadingPanel from '../Status/LoadingPanel'
import { ReadAppConfig } from '../../graphql/__generated__/ReadAppConfig'
import { READ_APP_CONFIG } from '../../graphql/config'

const BuildVersion: React.FC<any> = () => {
  const { data, loading } = useQuery<ReadAppConfig>(READ_APP_CONFIG)

  const buildVersion = data && data.appConfig && data.appConfig.build ? data.appConfig.build : ''
  const controlsFrameworkVersion =
    data && data.appConfig && data.appConfig.controlsFrameworkVersion ? data.appConfig.controlsFrameworkVersion : ''

  return (
    <LoadingPanel title="" isLoading={loading}>
      <p className="build-version__header">Version information</p>
      <table className="build-version__table">
        <thead>
          <th> </th>
          <th> </th>
        </thead>
        <tbody>
          <tr>
            <td>Platform</td>
            <td style={{ textAlign: 'right' }}>
              v{buildVersion === 'local-docker-build-version' ? '-' : buildVersion}
            </td>
          </tr>
          <tr>
            <td>Control Engine</td>
            <td style={{ textAlign: 'right' }}>v{controlsFrameworkVersion}</td>
          </tr>
        </tbody>
      </table>
    </LoadingPanel>
  )
}

export default BuildVersion
