import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from '@pwc-de/appkit-react'
import ModalHeaderWrapper from './ModalHeaderWrapper'

import type { NewModalProps } from '../../models/types/modal'

const NewModal: React.FC<NewModalProps> = ({
  title,
  text,
  visibility: { show, setShow },
  buttons: { cont, cancel }
}) => (
  <Modal
    className="form-modal new-modal center-middle"
    visible={show}
    onCancel={() => setShow(false)}
    backdropClosable={false}
  >
    <ModalHeaderWrapper>
      <div className="a-panel-title">{title}</div>
    </ModalHeaderWrapper>
    <ModalBody>
      <div className="assessment-copy ">
        <p className="assessment-copy__description a-panel-content">{text}</p>
      </div>
    </ModalBody>

    <ModalFooter>
      <div className="button-comp">
        <Button
          className={`a-btn-new a-btn-outline ${cancel.className ? cancel.className : ''}`}
          onClick={() => setShow(false)}
        >
          {cancel.text}
        </Button>
        <Button onClick={cont.onClick} className={`a-btn-new ${cont.className ? cont.className : ''}`}>
          {cont.text}
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

export default NewModal
