import React from 'react'
import { useQuery } from '@apollo/client'
import { Row, Col } from 'react-grid-system'
import { useParams, useLocation } from 'react-router-dom'
import { Error } from '..'
import { ReadRole } from '../../graphql/__generated__/ReadRole'
import { GET_ROLE } from '../../graphql/role'
import { UserDtoFields } from '../../graphql/__generated__/UserDtoFields'
import LoadingPanel from '../Status/LoadingPanel'
import UserTable from '../User/UserTable'
import { ReadCloudAccount, ReadCloudAccountVariables } from '../../graphql/__generated__/ReadCloudAccount'
import { READ_CLOUD_ACCOUNT } from '../../graphql/account'
import { CapabilityStatus } from '../../graphql/__generated__/globalTypes'
import ShowIamData from '../Iam/ShowIamData'
import { getStateUrl } from '../util'

export class Role {
  name: string = ''

  id: string = ''

  description: string = ''

  users: UserDtoFields[] = []
}

type RoleDetailsParams = {
  cloudAccountId?: string
  roleId?: string
}

const RoleDetails: React.FC = () => {
  const { cloudAccountId, roleId }: Readonly<RoleDetailsParams> = useParams()
  const { roleName } = getStateUrl()
  const cloudAccountData = useQuery<ReadCloudAccount, ReadCloudAccountVariables>(READ_CLOUD_ACCOUNT, {
    variables: { id: cloudAccountId! }
  })

  const { data, loading, error } = useQuery<ReadRole>(GET_ROLE, {
    variables: { cloudAccountId, roleId: roleId || '', roleName: roleName || '' }
  })

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const engagementId = queryParams.get('engagementId')
  const solutionId = queryParams.get('cloudSolutionId')
  const accountId = queryParams.get('accountId')

  if (error) {
    return <Error />
  }

  const role = data && data.role ? data.role : new Role()

  const cloudAccount =
    cloudAccountData.data && cloudAccountData.data.cloudAccount
      ? cloudAccountData.data.cloudAccount
      : { accountCapability: { readIamUsers: CapabilityStatus.NOT_APPLICABLE } }

  return (
    <LoadingPanel title="Role Details" isLoading={loading}>
      <Row>
        <Col>
          <div className="a-text-heading a-mt-10">{role.name}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="a-text-caption a-text-9e9">{role.id}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="a-text-caption a-mt-10 a-text-9e9">{role.description}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="a-py-10 border-top a-border-light a-mt-10">
            <ShowIamData capabilityStatus={cloudAccount.accountCapability.readIamUsers} data={role.users}>
              <UserTable
                users={role.users}
                path="."
                urlParent={`/engagements/${engagementId}/cloud/${solutionId}/iam_accounts/${accountId}/users/`}
                urlParams={`${location.search}`}
              />
            </ShowIamData>
          </div>
        </Col>
      </Row>
    </LoadingPanel>
  )
}

export default RoleDetails
