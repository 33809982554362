import React from 'react'

import { Button, Tooltip } from '@pwc-de/appkit-react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DuplicateControlIcon } from '../../assets/images/icon/duplicate-control.svg'

import type { DuplicateControlProps } from '../../models/types/control'

const ControlDuplicateButton: React.FC<DuplicateControlProps> = ({ controlId }) => {
  const navigate = useNavigate()
  const handleClick = () => navigate(`/configuration/cloud/controls/manage/${controlId}?duplicate=true`)

  return (
    <Button onClick={handleClick}>
      <Tooltip content="Duplicate" placement="left">
        <DuplicateControlIcon />
      </Tooltip>
    </Button>
  )
}

export default ControlDuplicateButton
