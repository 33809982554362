import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, Panel, Select, SelectOption, Pagination, Tooltip, TextArea } from '@pwc-de/appkit-react'
import { v4 as uuidv4 } from 'uuid'
import { useMutation } from '@apollo/client'
import './ResourcesControl.scss'
import {
  ReadAssessment_assessment_categories_controls,
  ReadAssessment_assessment_categories_controls_controlSubscriptions,
  ReadAssessment_assessment_categories_controls_controlSubscriptions_subscriptionResult_resourceResults
} from '../../graphql/__generated__/ReadAssessment'
import { getStatusFromResourceStatus } from '../AssessmentResult/StatusIndicator'
import { ResourceStatus } from '../../graphql/__generated__/globalTypes'
import { ReactComponent as RemoveIcon } from '../../assets/images/remove.svg'
import { ReactComponent as EditGrayIcon } from '../../assets/images/edit-gray.svg'
import { ReactComponent as AddBtnIcon } from '../../assets/images/add-btn-table.svg'
import { ReactComponent as InfoIcon } from '../../assets/images/info.svg'
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg'
import { ReactComponent as NewIcon } from '../../assets/images/new-icon.svg'
import {
  UpdateAssessmentControl,
  UpdateAssessmentControlVariables
} from '../../graphql/__generated__/UpdateAssessmentControl'
import { UPDATE_ASSESSMENT_CONTROL } from '../../graphql/assessment'
import { Loading } from '../index'

type ResourcesControlRowProps = {
  control: ReadAssessment_assessment_categories_controls
  resource: any
  updateItems: any
  deleteItems: any
}

const ResourcesControlRow: React.FC<ResourcesControlRowProps> = ({ control, updateItems, deleteItems, resource }) => {
  const [isEdit, setIsEdit] = useState(!resource?.id)
  const [state, setState]: any = useState({ ...resource })

  return (
    <div className="resources-control__row">
      <div className="resources-control__row-items">
        <div>
          {resource.userName && resource.userName !== 'NEW_RESULT' && resource.userName !== 'CHANGE_IN_STATUS' && (
            <Tooltip
              content={`Resource was updated by ${resource.userName} on ${new Date(
                resource.updateTime
              ).toLocaleString()} with following reason: ${resource.message}`}
              placement="right"
              tooltipTheme="light"
            >
              <InfoIcon />
            </Tooltip>
          )}
          {resource.userName === 'NEW_RESULT' && (
            <Tooltip content={`New Resource Found`} placement="right" tooltipTheme="dark">
              <NewIcon />
            </Tooltip>
          )}
          {resource.userName === 'CHANGE_IN_STATUS' && (
            <Tooltip content={`Change in status from previous assessment`} placement="right" tooltipTheme="dark">
              <EyeIcon />
            </Tooltip>
          )}
        </div>
        <div>
          {isEdit ? (
            <Select
              className="modal-select"
              placeholder="Status"
              value={state.status}
              onSelect={(status: ResourceStatus) => setState({ ...state, status })}
            >
              <SelectOption key={ResourceStatus.PASSED} value={ResourceStatus.PASSED}>
                Compliant
              </SelectOption>
              <SelectOption key={ResourceStatus.FAILED} value={ResourceStatus.FAILED}>
                Not Compliant
              </SelectOption>
              <SelectOption key={ResourceStatus.SKIPPED} value={ResourceStatus.SKIPPED}>
                Not Applicable
              </SelectOption>
            </Select>
          ) : (
            getStatusFromResourceStatus(state.status)
          )}
        </div>
        <div className="text-break">
          {isEdit ? (
            <Input
              className="new-input w-100"
              placeholder="Resource"
              type="text"
              value={state.resourceId}
              onChange={(resourceId: string) => {
                setState({ ...state, resourceId })
              }}
            />
          ) : (
            state.resourceId
          )}
        </div>
        <div className="text-break">
          {isEdit ? (
            <Select
              className="modal-select"
              placeholder="Subscription/Project"
              value={state.subscriptionId}
              onSelect={(subscriptionId: string) => setState({ ...state, subscriptionId })}
            >
              {control.controlSubscriptions.map((controlSubscription) => (
                <SelectOption key={controlSubscription.subscriptionId} value={controlSubscription.subscriptionId}>
                  {controlSubscription.name} ({controlSubscription.subscriptionId})
                </SelectOption>
              ))}
            </Select>
          ) : (
            <>
              {control.controlSubscriptions
                .filter((item) => item.subscriptionId === state.subscriptionId)
                .map((item, idx) => (
                  <> {item.name} </>
                ))}
            </>
          )}
        </div>
        <div className={isEdit ? 'disabled-icons' : ''}>
          <div className="resources-control__icon">
            <Tooltip content={state.message} trigger="hover" placement="top" tooltipTheme="dark">
              <span className={`appkiticon icon-information-fill info-icon `} style={{ color: '#999', fontSize: 16 }} />
            </Tooltip>
          </div>

          <EditGrayIcon className="resources-control__icon" onClick={() => setIsEdit(!isEdit)} />
        </div>
      </div>
      {isEdit ? (
        <div className="resources-control__row-items">
          <div style={{ width: '100%', paddingTop: 0 }}>
            <TextArea
              className="new-textarea new-textarea_sm w-100"
              style={{ padding: '0.25rem' }}
              type="text"
              placeholder="Description"
              value={state.message}
              onChange={(message: string) => {
                setState({ ...state, message })
              }}
            />
          </div>
          <div className="hidden-el" />
        </div>
      ) : undefined}
      {isEdit && (
        <div className="resources-control__buttons-row">
          <Button
            onClick={() => {
              if (state?.id) {
                setState({
                  ...resource
                })
                setIsEdit(false)
              } else {
                deleteItems(state)
              }
            }}
            className="a-btn a-btn-new a-btn-new-sm a-btn-outline"
          >
            Cancel
          </Button>
          <Button
            id="runAssessment"
            onClick={() => {
              if (state.resourceId && state.subscriptionId && state.status && state.message) {
                updateItems(state)
                setIsEdit(false)
              }
            }}
            className="a-btn a-btn-new a-btn-new-sm a-btn-primary"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

type ResourcesControlProps = {
  control: ReadAssessment_assessment_categories_controls
}

const ResourcesControl: React.FC<ResourcesControlProps> = ({ control }) => {
  const [search, setSearch]: any = useState('')
  const [controlState, setControlState]: any = useState([])
  const [isAddButton, setIsAddButton]: any = useState(true)
  const [pageState, setPageState] = useState({ page: 0, perPage: 10 })

  const data = useMemo(() => {
    const newData = [] as any

    controlState.forEach((item: any) => {
      if (search === '' || item.resourceId?.toLowerCase()?.includes(search?.toLowerCase())) {
        newData.push({ ...item })
      }
    })

    return newData
  }, [controlState, search])

  useEffect(() => {
    const newData = [] as any

    control?.controlSubscriptions?.forEach(
      (controlSub: ReadAssessment_assessment_categories_controls_controlSubscriptions) => {
        controlSub.subscriptionResult.resourceResults.forEach(
          (
            resourceSub: ReadAssessment_assessment_categories_controls_controlSubscriptions_subscriptionResult_resourceResults
          ) => {
            newData.push({
              id: resourceSub.id,
              resourceId: resourceSub.resourceId,
              subscriptionId: controlSub.subscriptionId,
              status: resourceSub.status,
              message: resourceSub.message,
              userName: resourceSub.userName,
              updateTime: resourceSub.updateTime
            })
          }
        )
      }
    )

    setControlState(newData)
  }, [control])

  const [mutate, { loading }] = useMutation<UpdateAssessmentControl, UpdateAssessmentControlVariables>(
    UPDATE_ASSESSMENT_CONTROL
  )

  const toEndPage = (offset = 0) => {
    const endPage = Math.ceil((data.length + offset) / pageState.perPage) - 1
    if (endPage !== pageState.page) {
      setPageState({ ...pageState, page: endPage })
    }
  }

  const updateList = (dataUpdate: any[]) => {
    mutate({
      variables: {
        assessmentControlId: control.id,
        resourceResults: Array.from(dataUpdate)
      }
    })
  }

  const updateItems = (updateItem) => {
    const newStateItem = { ...updateItem }
    if (!updateItem?.id) {
      newStateItem.id = uuidv4()
    }

    updateList(
      updateItem.id
        ? controlState.map((item) => (item.id === newStateItem.id ? newStateItem : item))
        : [...controlState, newStateItem]
    )

    if (!updateItem?.id) {
      toEndPage(1)
    }
    setIsAddButton(true)
  }

  const deleteItems = (removeItem) => {
    if (removeItem.id) {
      updateList(controlState.filter((item) => item.id !== removeItem.id))

      toEndPage(-1)
    }

    setIsAddButton(true)
  }

  const perPageItems = pageState.page * pageState.perPage

  if (loading) {
    return <Loading />
  }

  return (
    <div className="resources-control">
      <Panel
        className="new-panel"
        title="Resources"
        renderRight={() => {
          return (
            <Input
              className="new-input"
              placeholder="Search"
              type="text"
              value={search}
              prefix={
                <span className="select-toggle-icon appkiticon icon-search-outline apply-opacity-in-closed-toggle" />
              }
              onChange={(message: string) => {
                setPageState({ ...pageState, page: 0 })
                setSearch(message)
              }}
            />
          )
        }}
      >
        <div className="resources-control__table">
          <div className="resources-control__header">
            <div />
            <div>Status</div>
            <div>Resource</div>
            <div>Subscription</div>
            <div />
          </div>
          <div className="resources-control__rows">
            {data.slice(perPageItems, perPageItems + pageState.perPage).map((resource) => (
              <ResourcesControlRow
                control={control}
                key={resource.id}
                resource={resource}
                updateItems={updateItems}
                deleteItems={deleteItems}
              />
            ))}
            {!isAddButton && (
              <ResourcesControlRow
                control={control}
                key="new"
                resource={{}}
                updateItems={updateItems}
                deleteItems={deleteItems}
              />
            )}
          </div>
          {isAddButton && (
            // eslint-disable-next-line
            <div
              className="resources-control__new"
              onClick={() => {
                setIsAddButton(false)
              }}
            >
              Add Resource <AddBtnIcon />
            </div>
          )}
        </div>
        <Pagination
          current={pageState.page + 1}
          total={data.length}
          defaultPageSize={10}
          pageSize={pageState.perPage}
          showSizeOptions
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          onPageSizeChange={(perPage: number) => setPageState({ ...pageState, perPage, page: 0 })}
          onChange={(update: string) =>
            setPageState({ ...pageState, page: pageState.page + (update === 'next' ? 1 : -1) })
          }
        />
      </Panel>
    </div>
  )
}

export default ResourcesControl
