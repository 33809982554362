import React, { useMemo, useState } from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, Select, SelectOption, Checkbox } from '@pwc-de/appkit-react'
import { Col, Container, Row } from 'react-grid-system'
import { CalendarPicker } from '@pwc-de/appkit-react-datepicker'
import { useMutation } from '@apollo/client'
import { BenchmarkStatus } from '../../graphql/__generated__/globalTypes'
import { CreateBenchmark, CreateBenchmarkVariables } from '../../graphql/__generated__/CreateBenchmark'
import { CREATE_BENCHMARK, UPDATE_BENCHMARK } from '../../graphql/benchmark'
import { Loading, Error } from '..'
import { UpdateBenchmark, UpdateBenchmarkVariables } from '../../graphql/__generated__/UpdateBenchmark'
import ModalHeaderWrapper from '../Modal/ModalHeaderWrapper'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'

//--------------------------------------------------------------------------
const BenchmarkModal: React.FC<any> = ({
  visible,
  cancelModalCb,
  benchmarkGroup,
  benchmark,
  cloudSolutionId,
  onDeleteCb
}) => {
  const [benchmarkState, setBenchmarkState] = useState<any>({
    benchmarkGroup: benchmark?.benchmarkGroup?.name ? benchmark.benchmarkGroup.name : null,
    name: benchmark?.name ? benchmark.name : '',
    source: benchmark?.benchmarkSource?.name ? benchmark?.benchmarkSource?.name : '',
    version: benchmark?.version ? benchmark?.version : '1.0.0',
    benchmarkStatus: benchmark?.benchmarkStatus ? benchmark?.benchmarkStatus : BenchmarkStatus.ACTIVE
  })

  const isCreate = !benchmark?.id

  const isNewBenchmark = benchmarkState.benchmarkGroup === 'null'
  const [checked, setChecked] = useState(false)

  const [createBenchmark, { loading, error }] = useMutation<CreateBenchmark, CreateBenchmarkVariables>(CREATE_BENCHMARK)

  const [updateBenchmark, { loading: loadingUpdate, error: errorUpdate }] = useMutation<
    UpdateBenchmark,
    UpdateBenchmarkVariables
  >(UPDATE_BENCHMARK)

  const modalTitle = isCreate ? 'Create ' : 'Update '

  const isValid = useMemo(() => {
    return isNewBenchmark
      ? benchmarkState?.name?.length && benchmarkState?.source?.length && benchmarkState?.version?.length
      : benchmarkState?.source?.length && benchmarkState?.version?.length
  }, [benchmarkState])

  if (loading || loadingUpdate) {
    return <Loading />
  }
  if (error || errorUpdate) {
    return <Error />
  }

  return (
    <Modal
      className="form-modal new-modal"
      visible={visible}
      onCancel={() => cancelModalCb?.()}
      backdropClosable={false}
    >
      <ModalHeaderWrapper>
        <div className="a-panel-title">{modalTitle} Benchmark</div>
      </ModalHeaderWrapper>
      <ModalBody className="a-px-20 a-py-12">
        <Container fluid>
          <Row>
            <Col md={12}>
              <div className="flex a-form-label" style={{ display: 'flex' }}>
                <p style={{ width: '100%' }}>New or Existing benchmark</p>
                {!isCreate && (
                  <Checkbox
                    id="benchmarkStatus"
                    style={{ 'align-items': 'center', width: '1.5rem' }}
                    defaultChecked={benchmarkState.benchmarkStatus === BenchmarkStatus.ARCHIVED}
                    onChange={(checked: boolean) => {
                      if (checked) {
                        const benchmarkStatus =
                          benchmarkState?.benchmarkStatus === BenchmarkStatus.ARCHIVED
                            ? BenchmarkStatus.ACTIVE
                            : BenchmarkStatus.ARCHIVED
                        setBenchmarkState({ ...benchmarkState, benchmarkStatus })
                      }
                    }}
                  />
                )}
                {!isCreate && <label htmlFor="benchmarkStatus">Deprecated</label>}
              </div>
              <Select
                onSelect={(benchmarkGroupItem) => {
                  const currentGroup = benchmarkGroup.find((el) => el.name === benchmarkGroupItem)
                  const currentBenchmark = currentGroup?.benchmarks?.[currentGroup.benchmarks?.length - 1]
                  const state = { ...benchmarkState, benchmarkGroup: benchmarkGroupItem }

                  if (benchmarkGroupItem !== 'null') {
                    const versionArr = currentBenchmark.version.split('.')
                    state.version = `${+versionArr[0]}.${+versionArr[1]}.${+versionArr[2] + 1}`
                    state.source = currentBenchmark.benchmarkSource.name
                  } else {
                    state.source = ''
                    state.version = '1.0.0'
                  }

                  setBenchmarkState(state)
                }}
                value={benchmarkState.benchmarkGroup}
                id="BenchmarkId"
                className="new-select-list"
                placeholder="Select a benchmark"
              >
                <SelectOption key="placeholder" value="null" selectedDisplay="New benchmark">
                  <span>New benchmark</span>
                </SelectOption>
                {benchmarkGroup.map((benchmarkItem) => (
                  <SelectOption key={benchmarkItem.id} value={benchmarkItem.name} placeholder="Select a benchmark">
                    <span>{benchmarkItem.name}</span>
                  </SelectOption>
                ))}
              </Select>
            </Col>
            {isNewBenchmark && (
              <Col md={12}>
                <Input
                  id="benchmarkName"
                  label={<span>Benchmark Name</span>}
                  placeholder="Enter the name of your benchmark"
                  value={benchmarkState.name}
                  disabled={benchmarkState.benchmarkStatus === BenchmarkStatus.ARCHIVED}
                  onChange={(name: string) => {
                    setBenchmarkState({ ...benchmarkState, name })
                  }}
                />
              </Col>
            )}
            <Col md={12}>
              <Input
                id="benchmarkSource"
                label={<span>Benchmark Source</span>}
                placeholder="Enter the benchmark source"
                value={benchmarkState.source}
                disabled={benchmarkState.benchmarkStatus === BenchmarkStatus.ARCHIVED}
                onChange={(source: string) => {
                  setBenchmarkState({ ...benchmarkState, source })
                }}
              />
            </Col>
            <Col md={12}>
              <Input
                id="versionNumber"
                label={<span>Version Number</span>}
                placeholder="Enter the benchmark version number"
                value={benchmarkState.version}
                disabled={benchmarkState.benchmarkStatus === BenchmarkStatus.ARCHIVED}
                onChange={(version: string) => {
                  setBenchmarkState({ ...benchmarkState, version })
                }}
              />
            </Col>
            <Col md={12} style={{ display: 'none' }}>
              <Input
                id="versionNumber"
                label={<span>Benchmark Document Reference Link</span>}
                placeholder="https://"
                disabled
                value={benchmarkState.document}
                onChange={(document: string) => {
                  setBenchmarkState({ ...benchmarkState, document })
                }}
              />
            </Col>

            <Col md={6} style={{ display: 'none' }}>
              <p className="a-form-label">Benchmark Publication Date</p>
              <CalendarPicker
                className="full-width"
                disabled
                format="dd.mm.yyyy"
                defaultValue={new Date()}
                onChange={(publicDate: string) => {
                  setBenchmarkState({ ...benchmarkState, publicDate })
                }}
              />
            </Col>
          </Row>
        </Container>
      </ModalBody>

      <ModalFooter>
        <div className="button-comp">
          <div className="flex-between">
            <span
              className="pointer align-center a-text-red"
              style={{ display: isCreate ? 'None' : 'inline' }}
              onClick={() => {
                onDeleteCb()
              }}
              aria-hidden="true"
            >
              <DeleteIcon className="a-mr-10" style={{ color: 'red' }} /> Delete Benchmark
            </span>
          </div>
          <div className="flex align-center">
            <Button onClick={cancelModalCb} className="a-btn a-btn-new a-btn-outline">
              Cancel
            </Button>
            <Button
              className="a-btn a-btn-new a-btn-primary"
              disabled={!isValid}
              onClick={async () => {
                if (benchmark?.id) {
                  await updateBenchmark({
                    variables: {
                      id: benchmark.id,
                      benchmarkGroup: {
                        name: benchmark.benchmarkGroup.name
                      },
                      benchmarkSource: {
                        name: benchmark.benchmarkSource.name
                      },
                      name: `${benchmarkState.source} ${
                        isNewBenchmark ? benchmarkState.name : benchmarkState.benchmarkGroup
                      } ${benchmarkState.version}`,
                      version: benchmarkState.version,
                      benchmarkStatus: benchmarkState.benchmarkStatus
                    } as any
                  })
                } else {
                  await createBenchmark({
                    variables: {
                      benchmarkGroup: { name: isNewBenchmark ? benchmarkState.name : benchmarkState.benchmarkGroup },
                      benchmarkSource: { name: benchmarkState.source },
                      cloudSolutionId,
                      name: `${benchmarkState.source} ${
                        isNewBenchmark ? benchmarkState.name : benchmarkState.benchmarkGroup
                      } ${benchmarkState.version}`,
                      version: benchmarkState.version,
                      benchmarkStatus: benchmarkState.benchmarkStatus
                    }
                  })
                }
                cancelModalCb(true)
              }}
            >
              {modalTitle} Benchmark
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default BenchmarkModal
