import React, { useMemo, useRef, useState } from 'react'
import CheckboxTree from 'react-checkbox-tree'
import './SelectTree.scss'
import { ReactComponent as ArrowUpIcon } from '../../assets/images/arrow-table.svg'
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-table-active.svg'
import { useOnClickOutside } from '../helpersHook'

type SelectTreeParams = {
  nodes: any[]
  checked: any[]
  allData: any
  allSelectedTextCustom?: string
  selectedTextCustom?: string
  setChecked: any
}

const SelectTree: React.FC<SelectTreeParams> = ({
  nodes,
  allSelectedTextCustom,
  selectedTextCustom,
  checked,
  allData,
  setChecked
}) => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded]: any = useState([])
  useOnClickOutside(ref, () => setOpen(false))

  const allSelectedText = useMemo(() => allSelectedTextCustom ?? 'All Selected', [allSelectedTextCustom])
  const selectedText = useMemo(() => selectedTextCustom ?? `${checked.length} items selected`, [selectedTextCustom])

  return (
    <div className="select-tree" ref={ref}>
      <div
        className={`select-tree__select ${open ? 'select-tree__select--open' : ''}`}
        onClick={() => setOpen(!open)}
        aria-hidden="true"
      >
        {allData.current.length && checked.length === allData.current.length ? allSelectedText : selectedText}
        {open ? (
          <span className="select-toggle-icon appkiticon arrow-icon icon-up-chevron-fill" />
        ) : (
          <span className="select-toggle-icon appkiticon arrow-icon icon-down-chevron-fill apply-opacity-in-closed-toggle" />
        )}
      </div>
      {open && (
        <div className="select-tree__list">
          <CheckboxTree
            nodes={nodes}
            checked={checked}
            expanded={expanded}
            icons={{
              expandOpen: <ArrowDownIcon width={10} />,
              expandClose: <ArrowUpIcon width={10} />,
              check: (
                <span className="a-checkbox a-checkbox-checked pointer">
                  <span className="a-checkbox-inner">
                    <span className="appkiticon icon-check-mark-fill" />
                  </span>
                </span>
              ),
              uncheck: (
                <span className="a-checkbox pointer">
                  <span className="a-checkbox-inner">
                    <span className="appkiticon icon-check-mark-fill" />
                  </span>
                </span>
              ),
              halfCheck: (
                <span className="a-checkbox pointer half">
                  <span className="a-checkbox-inner">
                    <span className="appkiticon icon-check-mark-fill">-</span>
                  </span>
                </span>
              )
            }}
            onCheck={(checked) => setChecked(checked)}
            onExpand={(expanded) => setExpanded(expanded)}
          />
        </div>
      )}
    </div>
  )
}

SelectTree.defaultProps = {
  allSelectedTextCustom: '',
  selectedTextCustom: ''
}

export default SelectTree
