import React, { useState } from 'react'
import { Table, Panel, Button } from '@pwc-de/appkit-react'
import { useQuery } from '@apollo/client'
import { getHeaderProps, getProps } from '../util'
import { AuthorizeRole, Loading, Error } from '..'
import { READ_CLOUD_ACCOUNTS } from '../../graphql/account'
import { READ_CLOUD_SOLUTION } from '../../graphql/cloudSolution'
import { ReadCloudAccounts, ReadCloudAccountsVariables } from '../../graphql/__generated__/ReadCloudAccounts'
import ProgressBar from './ProgressBar'
import styles from './CloudAccountCard.module.scss'
import CloudAccountManageModal from '../CloudAccount/CloudAccountManageModal'
import { ReadCloudSolution, ReadCloudSolutionVariables } from '../../graphql/__generated__/ReadCloudSolution'
import CloudAccountDeleteModal from '../CloudAccount/CloudAccountDelete'

type CloudAccountCardProps = {
  engagementId: string
  cloudSolution: any
  onUpdateAccount: any
  analyticsQuery: any
}

const CloudAccountCard: React.FC<CloudAccountCardProps> = ({
  engagementId = '',
  cloudSolution,
  onUpdateAccount,
  analyticsQuery
}) => {
  const cloudSolutionId = cloudSolution?.id
  const [cloudAccountId, setCloudAccountId] = useState('')
  const [isConnectCloudAccountModalVisible, setIsConnectCloudAccountModalVisible] = useState(false)
  const [isEditCloudAccountModalVisible, setIsEditCloudAccountModalVisible] = useState(false)
  const [isDeleteCloudAccountModalVisible, setIsDeleteAccountModalVisible] = useState(false)

  const { data } = useQuery<ReadCloudAccounts, ReadCloudAccountsVariables>(READ_CLOUD_ACCOUNTS, {
    variables: {
      cloudSolutionId,
      engagementId
    },
    skip: !cloudSolutionId
  })

  const {
    data: cloudSolutionData,
    loading: cloudSolutionLoading,
    error: cloudSolutionError
  } = useQuery<ReadCloudSolution, ReadCloudSolutionVariables>(READ_CLOUD_SOLUTION, {
    variables: { id: cloudSolutionId },
    skip: !cloudSolutionId
  })

  if (cloudSolutionError) {
    return <Error />
  }
  if (cloudSolutionLoading) {
    return <Loading />
  }

  const connectAccount = () => (
    <>
      {isConnectCloudAccountModalVisible && (
        <CloudAccountManageModal
          visible
          cloudSolution={cloudSolutionData?.cloudSolution}
          engagementId={engagementId}
          cancelModalCb={() => setIsConnectCloudAccountModalVisible(false)}
          deleteModalCb={() => setIsDeleteAccountModalVisible(true)}
        />
      )}
      {isEditCloudAccountModalVisible && (
        <CloudAccountManageModal
          visible
          cloudSolution={cloudSolutionData?.cloudSolution}
          engagementId={engagementId}
          cloudAccountId={cloudAccountId}
          cancelModalCb={() => setIsEditCloudAccountModalVisible(false)}
          deleteModalCb={() => setIsDeleteAccountModalVisible(true)}
        />
      )}
      {isDeleteCloudAccountModalVisible && (
        <CloudAccountDeleteModal
          id={cloudAccountId}
          engagementId={engagementId}
          cloudAccountName={cloudSolutionData?.cloudSolution.name}
          cloudSolutionId={cloudSolutionId}
          deleteCb={() => {
            setIsDeleteAccountModalVisible(false)
          }}
          closeModalCb={() => {
            setIsDeleteAccountModalVisible(false)
          }}
        />
      )}
      <Button
        kind="secondary"
        size="md"
        style={{ border: 'none' }}
        onClick={() => setIsConnectCloudAccountModalVisible(true)}
        id="createCloudAccount"
      >
        <span className="appkiticon icon-plus-outline span-create" />
        <span className={styles.ConnectAccount__text}>Connect Account</span>
      </Button>
    </>
  )

  const getScore = (row) => {
    if (row.original.assessments.length > 0) {
      const cloudAccountAnalytics = analyticsQuery.data.solutionDashboard.cloudAccounts.find(
        (ca) => ca.cloudAccount.id === row.original.id
      )
      if (cloudAccountAnalytics) {
        return cloudAccountAnalytics.analytics.score
      }
    }
    return 0
  }

  const columns = [
    {
      Header: 'Cloud Account',
      accessor: 'name'
    },
    {
      Header: 'Score',
      accessor: 'score',
      Cell: (row: any) => (
        <div className="progress-wrapper">
          <ProgressBar progressValue={getScore(row)} />
        </div>
      )
    },
    {
      Header: '',
      accessor: 'score',
      Cell: (row: any) => <>{Math.round(getScore(row))}/100</>
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ original, value }) => (
        <div className={styles.ConnectAccount__flex}>
          <Button id="detailsCloudAccount" onClick={() => onUpdateAccount({ name: original.name, value: original.id })}>
            Details
          </Button>
          <AuthorizeRole role="admin">
            <Button
              id="editCloudAccount"
              onClick={() => {
                setCloudAccountId(original.id)
                setIsEditCloudAccountModalVisible(true)
              }}
            >
              Edit
            </Button>
          </AuthorizeRole>
        </div>
      ),
      getHeaderProps,
      getProps
    }
  ]

  return (
    <Panel title="Cloud Accounts" className="next-level--card top-right" renderRight={connectAccount}>
      <Table
        data={data?.cloudAccounts}
        columns={columns}
        defaultSorted={[{ id: 'name', desc: true }]}
        defaultPageSize={5}
      />
    </Panel>
  )
}

export default CloudAccountCard
