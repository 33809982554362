import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select, SelectOption, Switch, Tooltip } from '@pwc-de/appkit-react'
import { useParams } from 'react-router-dom'
import { READ_BENCHMARKS } from '../../graphql/benchmark'
import { ReadBenchmarks, ReadBenchmarksVariables } from '../../graphql/__generated__/ReadBenchmarks'
import { AssessmentCreate, AuthorizeRole, Error } from '..'
import './Assessment.scss'
import LoadingPanel from '../Status/LoadingPanel'
import Assessment from './Assessment'
import { READ_PREVIOUS_ASSESSMENT } from '../../graphql/assessment'
import {
  ReadPreviousAssessment,
  ReadPreviousAssessmentVariables
} from '../../graphql/__generated__/ReadPreviousAssessment'
import { ReadSolutionDashboard_solutionDashboard_cloudAccounts_benchmarks } from '../../graphql/__generated__/ReadSolutionDashboard'

type AssessmentManageProps = {
  cloudSolutionId?: string
  cloudSolutionName?: string
  cloudAccountId?: string
  accountName?: string
  engagementId?: string
}

interface AssessmentManageExtended {
  selectedBenchmark?: ReadSolutionDashboard_solutionDashboard_cloudAccounts_benchmarks
  cancelModalCb: () => void
}

const AssessmentManage: React.FC<AssessmentManageProps & AssessmentManageExtended> = ({
  cloudSolutionId: cloudSolutionIdProp,
  cloudSolutionName: cloudSolutionNameProp,
  cloudAccountId: cloudAccountIdProp,
  accountName: accountNameProp,
  engagementId: engagementIdProp,
  cancelModalCb = () => {},
  selectedBenchmark = undefined
}) => {
  const {
    cloudSolutionId: cloudSolutionIdParam,
    cloudSolutionName: cloudSolutionNameParam,
    cloudAccountId: cloudAccountIdParam,
    accountName: accountNameParam,
    engagementId: engagementIdParam
  }: Readonly<AssessmentManageProps> = useParams()
  const cloudSolutionId = cloudSolutionIdProp || cloudSolutionIdParam || ''
  const cloudSolutionName = cloudSolutionNameProp || cloudSolutionNameParam || ''
  const cloudAccountId = cloudAccountIdProp || cloudAccountIdParam || ''
  const accountName = accountNameProp || accountNameParam || ''
  const engagementId = engagementIdProp || engagementIdParam || ''

  const [assessmentState, setAssessmentState] = useState<Assessment>(new Assessment())
  const [benchmarkId, setBenchmarkId] = useState<string>(selectedBenchmark?.benchmarkId || 'false')
  const [benchmarkName, setBenchmarkName] = useState<string>(selectedBenchmark?.name || '')
  const [hasPreviousAssessment, setHasPreviousAssessment] = useState<boolean>(false)

  const isRunDisabled = useMemo(() => {
    return !benchmarkId || benchmarkId === 'false' || !benchmarkName
  }, [benchmarkId, benchmarkName])
  const benchmarkSelected = useMemo(() => benchmarkId && benchmarkId !== 'false', [benchmarkId])

  const { data, loading, error } = useQuery<ReadBenchmarks, ReadBenchmarksVariables>(READ_BENCHMARKS, {
    variables: { cloudSolutionId }
  })

  const benchmarks = data && data.benchmarks ? data.benchmarks : []
  const filteredBenchmarks = benchmarks && benchmarks.filter((b) => b.benchmarkStatus === 'ACTIVE')

  const { refetch } = useQuery<ReadPreviousAssessment, ReadPreviousAssessmentVariables>(READ_PREVIOUS_ASSESSMENT, {
    variables: {
      cloudAccountId,
      benchmarkName: benchmarks.find((b) => b.id === benchmarkId)?.name ?? ''
    },
    skip: isRunDisabled
  })

  const onBenchmarkSelect = async (value: string) => {
    const currentBenchmark = benchmarks.find((b) => b.id === value)

    setAssessmentState({ ...assessmentState, previousAssessmentFlag: false })
    setBenchmarkId(value)
    setBenchmarkName(currentBenchmark?.name ?? '')

    if (!value || value === 'false') return

    const response = await refetch({ cloudAccountId, benchmarkName: currentBenchmark?.name as string })
    setHasPreviousAssessment(response.data?.getHasPreviousAssessment?.hasPreviousAssessment)
  }

  useEffect(() => {
    if (benchmarks?.length && benchmarkId) {
      onBenchmarkSelect(benchmarkId)
    }
  }, [selectedBenchmark, benchmarks])

  if (error) {
    return <Error />
  }

  return (
    <LoadingPanel title="Run Assessment" isLoading={loading}>
      <div className="assessment">
        <div className="assessment__select">
          <p className="a-form-label">Benchmark</p>
          <Select
            value={benchmarkId}
            onSelect={(value) => onBenchmarkSelect(value)}
            id="BenchmarkId"
            className="new-select-list"
          >
            <SelectOption
              key="placeholder"
              value="false"
              selectedDisplay="Select a benchmark"
              placeholder="Select a benchmark"
            >
              Select a benchmark
            </SelectOption>
            {filteredBenchmarks.map((benchmark) => (
              <SelectOption
                key={benchmark.id}
                value={benchmark.id}
                selectedDisplay={
                  <>
                    <span>{benchmark.name.replace(` ${benchmark.version}`, '')}</span>
                    {benchmark.version && <span className="assessment__version">{benchmark.version}</span>}
                  </>
                }
                placeholder="Select a benchmark"
              >
                <span>{benchmark.name.replace(` ${benchmark.version}`, '')}</span>
                {benchmark.version && <span className="assessment__version">{benchmark.version}</span>}
              </SelectOption>
            ))}
          </Select>
        </div>
        <AuthorizeRole role="admin">
          <div className={`assessment-copy ${!hasPreviousAssessment || !benchmarkSelected ? 'disabled' : ''}`}>
            <div className="assessment-copy__header">
              <div className="flex-row">
                <p className="a-form-label fw-bold">Copy previous results?</p>
                <Tooltip
                  content="Previous results can only be copied over from existing assessments"
                  hasArrow
                  trigger="hover"
                  placement="top"
                  tooltipTheme="dark"
                >
                  <span className={`appkiticon icon-information-fill info-icon`} />
                </Tooltip>
              </div>
              <Switch
                checked={assessmentState.previousAssessmentFlag}
                onText="Yes"
                offText="No"
                disabled={!hasPreviousAssessment}
                onChange={(previousAssessmentFlag: boolean) => {
                  setAssessmentState({ ...assessmentState, previousAssessmentFlag })
                }}
              />
            </div>
            <p className="assessment-copy__description">
              Would you like to copy the results of manual controls and overridden automated controls from the previous
              assessment? Note: All manual and overridden automated controls will be applied
            </p>
          </div>
        </AuthorizeRole>
        <AssessmentCreate
          cloudSolutionId={cloudSolutionId}
          solutionName={cloudSolutionName}
          cloudAccountId={cloudAccountId}
          cloudAccountName={accountName}
          benchmarkId={benchmarkId}
          benchmarkName={benchmarkName as string}
          isRunDisabled={isRunDisabled}
          engagementId={engagementId}
          previousAssessmentFlag={assessmentState.previousAssessmentFlag}
          cancelModalCb={cancelModalCb}
        />
      </div>
    </LoadingPanel>
  )
}

AssessmentManage.defaultProps = {
  cloudSolutionId: '',
  cloudAccountId: '',
  engagementId: ''
}

export default AssessmentManage
