import React from 'react'
import { useMutation } from '@apollo/client'
import { Button } from '@pwc-de/appkit-react'
import { Loading, Error } from '..'
import { DeleteAssessments, DeleteAssessmentsVariables } from '../../graphql/__generated__/DeleteAssessments'
import { READ_SOLUTION_DASHBOARD } from '../../graphql/solution-dashboard'
import { DELETE_ASSESSMENTS } from '../../graphql/assessment'
import {
  ReadSolutionDashboard,
  ReadSolutionDashboardVariables
} from '../../graphql/__generated__/ReadSolutionDashboard'

type BenchmarkAssessmentDeleteProps = {
  cloudAccountId: string
  benchmarkName: string
  cloudSolutionId: string
  engagementId: string
  isValid: boolean
  cancelModalCb: any
}

const BenchmarkAssessmentDelete: React.FC<BenchmarkAssessmentDeleteProps> = ({
  cloudAccountId,
  benchmarkName,
  cloudSolutionId,
  engagementId,
  isValid,
  cancelModalCb
}) => {
  const [mutate, { data, loading, error }] = useMutation(DELETE_ASSESSMENTS, {
    variables: { cloudAccountId, benchmarkName },
    onCompleted: () => {
      cancelModalCb()
    },
    refetchQueries: [
      {
        query: READ_SOLUTION_DASHBOARD,
        variables: { cloudSolutionId, engagementId }
      }
    ]
  })

  if (error) {
    return <Error />
  }
  if (loading) {
    return <Loading />
  }

  return (
    <div className="button-comp">
      <Button className="a-btn a-btn-new a-btn-outline" onClick={() => cancelModalCb()}>
        Cancel
      </Button>
      <Button
        id="benchmarkAssessmentDeleteModal"
        className="a-btn a-btn-new a-btn-primary"
        disabled={!isValid}
        onClick={() => {
          mutate()
        }}
      >
        Delete
      </Button>
    </div>
  )
}

export default BenchmarkAssessmentDelete
